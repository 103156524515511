import {
  Button,
  Checkbox,
  Divider,
  Flex,
  Input,
  Textarea,
  Typography,
} from "djuno-design";
import { WorkingPage } from "../../../layouts/NotData";

const DomainProfileTab = () => {
  return (
    <Flex direction="col" className="mt-6">
      <Typography.Text size="base">
        Transfer domain to another address
      </Typography.Text>
      <Typography.Text uiType="secondary" size="sm" className="mt-2">
        You must have the private key in order to manage your domain. If you
        transfer this domain to an exchange or any other custodial account where
        you do not control the private key, you will not be able to access your
        domain. Not your keys, not your domain.
      </Typography.Text>

      <form>
        <Flex direction="col" className="mt-8 gap-5 lg:w-2/3">
          {/* Main Info */}
          <Flex direction="col" className="mt-2 gap-3">
            <Typography.Text size="base" className="font-semibold">
              Main Info
            </Typography.Text>

            <div className="grid grid-cols-12">
              <div className="col-span-3">
                <Typography.Text size="sm" className="font-semibold">
                  Name
                </Typography.Text>
              </div>
              <div className="col-span-9 flex gap-2 flex-1">
                <Input
                  placeholder="Add your name"
                  containerClassName="!flex-1"
                />
              </div>
            </div>
            <div className="grid grid-cols-12">
              <div className="col-span-3">
                <Typography.Text size="sm" className="font-semibold">
                  Short bio
                </Typography.Text>
              </div>
              <div className="col-span-9 flex gap-2 flex-1">
                <Textarea
                  placeholder="Add short bio"
                  rows={5}
                  containerClassName="!flex-1"
                />
              </div>
            </div>
            <div className="grid grid-cols-12">
              <div className="col-span-3">
                <Typography.Text size="sm" className="font-semibold">
                  Location
                </Typography.Text>
              </div>
              <div className="col-span-9 flex gap-2 flex-1">
                <Input
                  placeholder="Add location"
                  containerClassName="!flex-1"
                />
              </div>
            </div>
            <div className="grid grid-cols-12">
              <div className="col-span-3">
                <Typography.Text size="sm" className="font-semibold">
                  Website
                </Typography.Text>
              </div>
              <div className="col-span-9 flex gap-2 flex-1">
                <Input placeholder="https://" containerClassName="!flex-1" />
              </div>
            </div>
          </Flex>

          <Divider className="!my-0" />

          {/* Socials */}
          <Flex direction="col" className="mt-2 gap-3">
            <Typography.Text size="base" className="font-semibold">
              Socials
            </Typography.Text>

            <div className="grid grid-cols-12">
              <div className="col-span-3">
                <Typography.Text size="sm" className="font-semibold">
                  Twitter (X)
                </Typography.Text>
              </div>
              <div className="col-span-9 flex gap-2 flex-1">
                <Input
                  placeholder="@username or URL"
                  containerClassName="!flex-1"
                />
              </div>
            </div>
            <div className="grid grid-cols-12">
              <div className="col-span-3">
                <Typography.Text size="sm" className="font-semibold">
                  Discord
                </Typography.Text>
              </div>
              <div className="col-span-9 flex gap-2 flex-1">
                <Input placeholder="username" containerClassName="!flex-1" />
              </div>
            </div>
            <div className="grid grid-cols-12">
              <div className="col-span-3">
                <Typography.Text size="sm" className="font-semibold">
                  YouTube
                </Typography.Text>
              </div>
              <div className="col-span-9 flex gap-2 flex-1">
                <Input
                  placeholder="YouTube channel URL"
                  containerClassName="!flex-1"
                />
              </div>
            </div>
            <div className="grid grid-cols-12">
              <div className="col-span-3">
                <Typography.Text size="sm" className="font-semibold">
                  Reddit
                </Typography.Text>
              </div>
              <div className="col-span-9 flex gap-2 flex-1">
                <Input
                  placeholder="username or URL"
                  containerClassName="!flex-1"
                />
              </div>
            </div>
            <div className="grid grid-cols-12">
              <div className="col-span-3">
                <Typography.Text size="sm" className="font-semibold">
                  Telegram
                </Typography.Text>
              </div>
              <div className="col-span-9 flex gap-2 flex-1">
                <Input placeholder="username" containerClassName="!flex-1" />
              </div>
            </div>
            <div className="grid grid-cols-12">
              <div className="col-span-3">
                <Typography.Text size="sm" className="font-semibold">
                  Github
                </Typography.Text>
              </div>
              <div className="col-span-9 flex gap-2 flex-1">
                <Input placeholder="username" containerClassName="!flex-1" />
              </div>
            </div>
            <div className="grid grid-cols-12">
              <div className="col-span-3">
                <Typography.Text size="sm" className="font-semibold">
                  Linkedin
                </Typography.Text>
              </div>
              <div className="col-span-9 flex gap-2 flex-1">
                <Input
                  placeholder="Linkedin URL"
                  containerClassName="!flex-1"
                />
              </div>
            </div>
          </Flex>

          <Divider className="!my-0" />

          {/* Profile Metadata */}
          <Flex direction="col" className="mt-2 gap-3">
            <Typography.Text size="base" className="font-semibold">
              Profile Metadata
            </Typography.Text>
            <Typography.Text size="sm">
              Shared information that apps can request when you{" "}
              <Typography.Link size="sm">
                log in with your domain.
              </Typography.Link>
            </Typography.Text>
            <div className="grid grid-cols-12">
              <div className="col-span-3">
                <Typography.Text size="sm" className="font-semibold">
                  Email
                </Typography.Text>
              </div>
              <div className="col-span-9 flex flex-col gap-4">
                <Input placeholder="Add email" />
                <Checkbox label="Receive newsletters and marketing emails from Unstoppable Domains." />
              </div>
            </div>
          </Flex>
        </Flex>

        {/* footer */}
        <Flex
          items="center"
          className="sticky bottom-0 gap-3 mt-5 h-16 bg-white border-t"
        >
          <Button uiType="primary" type="submit">
            Confirm Changes
          </Button>
          <Button uiType="simple" type="submit">
            Cancle
          </Button>
        </Flex>
      </form>
    </Flex>
  );
};

export default DomainProfileTab;
