import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  createInstanceManualBackupAsync,
  getInstanceAsync,
  handleToggleInstanceRunManualBackupModal,
  selectInstance,
  selectInstanceLoading,
  selectInstanceShowManualBackupModal,
  selectInstanceSnapshotsActionLoading,
} from "../../../../store/instances/instanceSlice";
// import { getInstancesAsync } from "../../../../store/instances/instancesSlice";
import { Alert, Button, Flex, Modal, Typography } from "djuno-design";

const formatDateTimeInstanceBackUp = (date: any) => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

const InstancesBackupModal = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [combinedValue, setCombinedValue] = useState("");
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectInstanceShowManualBackupModal);

  const instanceBackupsActionLoading = useAppSelector(
    selectInstanceSnapshotsActionLoading
  );
  const instanceLoading = useAppSelector(selectInstanceLoading);
  const instance = useAppSelector(selectInstance);

  const handleCreate = () => {
    if (instance && !instanceLoading && !instanceBackupsActionLoading) {
      const formData = {
        snapshotName: combinedValue,
      };
      dispatch(
        createInstanceManualBackupAsync({
          instanceId: instance?.id,
          data: formData,
        })
      ).then((action) => {
        if (action.type === "instance/snapshot/manual-backup/fulfilled") {
          // dispatch(getInstancesAsync({ withoutLoading: false }));
          dispatch(getInstanceAsync({ instanceId: instance.id }));
          handleOnClose();
        }
      });
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setCombinedValue(
      `${instance?.name || ""} ${formatDateTimeInstanceBackUp(currentTime)}`
    );
  }, [instance, currentTime]);

  const handleOnClose = useCallback(() => {
    dispatch(handleToggleInstanceRunManualBackupModal(false));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      contentClassName="max-w-lg"
      title="Creating a backup"
    >
      <div className="mt-5">
        <Flex items="center" className="mb-5 mt-1 gap-1">
          <Typography.Text strong className="!text-sm">
            Price:
          </Typography.Text>
          <Typography.Text className="!text-sm">
            €0.01 ex. VAT/month/GB
          </Typography.Text>
        </Flex>
        <Alert uiType="warning" className="text-xs my-3" showIcon>
          <Typography.Text className="!text-xs">
            Your instance will be suspended for a few seconds, and the disk’s
            read/write performance may be impacted until the backup is complet.
          </Typography.Text>
        </Alert>
      </div>

      <div className="mt-4 flex justify-end gap-2">
        <Button uiType="light" onClick={handleOnClose}>
          Cancel
        </Button>
        <Button
          uiType="primary"
          onClick={handleCreate}
          loading={instanceBackupsActionLoading || instanceLoading}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export default InstancesBackupModal;
