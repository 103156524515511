import {
  Button,
  cn,
  EmptyState,
  Flex,
  Input,
  Loading,
  Tooltip,
  Typography,
} from "djuno-design";
import { ReactComponent as SearchIcon } from "./../../../assets/icons/search.svg";
import { ReactComponent as ShoppingCartIcon } from "./../../../assets/icons/shopping-cart.svg";
import { ReactComponent as GlobIcon } from "./../../../assets/icons/glob-alt.svg";
import { ReactComponent as CheckIcon } from "./../../../assets/icons/check.svg";
import { ReactComponent as NotAllowIcon } from "./../../../assets/icons/close.svg";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as RemoveIcon } from "./../../../assets/icons/archive-box.svg";

import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  handleAddDomainInBasket,
  handleRemoveDomaFromInBasket,
  searchDomainsAysnc,
  selectSearchedDomains,
  selectDomainsBasket,
  selectDomainsSearchLoading,
} from "../../../store/domains/domainsSlice";
import { currency } from "../../../utils/currency";
import { DomainProduct } from "../../../types/domains";

const DomainsSearchTab = () => {
  const [searchInputValue, setSearchInputValue] = useState<string>("");
  const [search, setSearch] = useState<string>("");

  const serchedDomains = useAppSelector(selectSearchedDomains);
  const serchLoading = useAppSelector(selectDomainsSearchLoading);

  const basket = useAppSelector(selectDomainsBasket);
  const dispatch = useAppDispatch();

  const handleSearch = () => {
    setSearch(searchInputValue);
  };

  useEffect(() => {
    if (search) {
      dispatch(searchDomainsAysnc({ query: search }));
    }
  }, [dispatch, search]);

  const totalDue = useMemo(() => {
    let total = 0;
    basket.forEach((i) => (total += i.price));
    return total;
  }, [basket]);

  return (
    <div className="grid grid-cols-6 gap-8 mt-8 ">
      <div className="col-span-4">
        <Flex direction="col" className="w-full">
          <div className="flex transition-all duration-500 w-full">
            <Input
              containerClassName="w-full"
              className="flex-1"
              onChange={(e: any) => setSearchInputValue(e.target.value)}
              onKeyDown={(e: any) => {
                if (e.code === "Enter") handleSearch();
              }}
              placeholder="Search for your new domain"
              AfterComponent={
                <button
                  onClick={handleSearch}
                  className="w-10 flex items-center justify-center dark:text-white border-l border-solid border-transparent rounded-r-xl bg-primary-400 text-white"
                >
                  <SearchIcon className="w-4" />
                </button>
              }
            />
          </div>
          <div className="mt-6">
            {serchLoading && (
              <Flex items="center" justify="center" className="min-h-[300px]">
                <Loading borderSize={2} />
              </Flex>
            )}

            {!serchLoading && serchedDomains.length === 0 && (
              <EmptyState
                text="Find your perfect domain by typing into the search field above."
                icon={<SearchIcon className="w-8" />}
                className="min-h-[300px]"
              />
            )}

            {!serchLoading && serchedDomains.length > 0 && (
              <ol className="border border-gray-200 dark:border-gray-700 rounded-lg">
                {serchedDomains.map((domainProduct, i) => (
                  <DomainProductRow key={i} domainProduct={domainProduct} />
                ))}
              </ol>
            )}
          </div>
        </Flex>
      </div>
      <div className="col-span-2">
        <div className="border border-gray-200 dark:border-gray-700 rounded-lg w-full p-4 sticky top-10 max-h-full">
          <Flex items="center" className="gap-1 mb-6">
            <ShoppingCartIcon className="w-5 h-5 text-slate-800 dark:text-slate-200" />
            <Typography.Text className="font-medium">Your Cart</Typography.Text>
          </Flex>
          <ol className="w-full">
            {basket &&
              basket.map((domainProduct, i) => (
                <DomainProductBasketRow key={i} domainProduct={domainProduct} />
              ))}
            {basket.length === 0 && (
              <EmptyState
                text="Empty Card"
                icon={<EmptyState.PRESENTED_IMAGE_DEFAULT className="!w-8" />}
                className="min-h-[130px]"
              />
            )}
          </ol>
          {basket.length > 0 && (
            <Flex direction="col" className="w-full gap-3 !mt-4 ">
              <Flex items="center" justify="between" className="w-full">
                <Typography.Text>Total due</Typography.Text>
                <Typography.Text size="sm" className="font-semibold">
                  {currency(totalDue / 100)}
                </Typography.Text>
              </Flex>
              <Button uiType="primary" className="!w-full !justify-center">
                Pay and Claim
              </Button>
            </Flex>
          )}
        </div>
      </div>
    </div>
  );
};

const DomainProductRow: React.FC<{
  domainProduct: DomainProduct;
}> = ({ domainProduct }) => {
  const basket = useAppSelector(selectDomainsBasket);

  const isInBasket = useMemo(() => {
    return !!basket.find((d) => d.productCode === domainProduct.productCode);
  }, [basket, domainProduct]);

  const dispatch = useAppDispatch();

  return (
    <li className="grid grid-cols-12 items-center px-4 py-3 gap-3 border-b border-gray-200 dark:border-gray-700 last:border-0">
      <div className={cn("col-span-1 flex items-center justify-center")}>
        {domainProduct.availability ? (
          <CheckIcon className="text-green-500 w-5 h-5" />
        ) : (
          <NotAllowIcon className="text-red-500 w-5 h-5" />
        )}
      </div>
      <div className="col-span-1 flex items-center justify-center">
        <Tooltip
          theme="black"
          className="!text-xs"
          content="Web2 domains are compatible with most Internet services and infrastructure"
        >
          <GlobIcon className="w-5 h-6 text-slate-700 dark:text-slate-200" />
        </Tooltip>
      </div>
      <div className="col-span-5">
        <Typography.Text size="sm">{domainProduct.domain.name}</Typography.Text>
      </div>
      <div className="col-span-2 flex items-center justify-center">
        {domainProduct.price > 0 && (
          <Typography.Text size="sm" className="font-medium">
            {currency(domainProduct.price / 100)}
          </Typography.Text>
        )}
      </div>
      <div className="col-span-3">
        {domainProduct.availability && (
          <Flex className="flex w-full">
            {!isInBasket && (
              <Button
                uiType="primary"
                uiSize="small"
                className="!flex-1 !justify-center !px-1"
                // loading
                onClick={() => dispatch(handleAddDomainInBasket(domainProduct))}
              >
                <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
                Add to Card
              </Button>
            )}
            {isInBasket && (
              <Button
                uiSize="small"
                className="!flex-1 !justify-center !px-1"
                // loading
              >
                <CheckIcon className="w-3 h-3 text-green-700" />
                Added
              </Button>
            )}
          </Flex>
        )}
      </div>
    </li>
  );
};

const DomainProductBasketRow: React.FC<{
  domainProduct: DomainProduct;
}> = ({ domainProduct }) => {
  const dispatch = useAppDispatch();

  return (
    <Flex
      direction="col"
      className="px-4 py-3 gap-3 border-b border-gray-200 dark:border-gray-700 last:border-0 w-full"
    >
      <Flex items="center" className="gap-1">
        <GlobIcon className="w-5 h-6  text-slate-700 dark:text-slate-200" />
        <Typography.Text size="sm">{domainProduct.domain.name}</Typography.Text>
      </Flex>

      <div className="col-span-3">
        <Flex items="center" justify="between" className="w-full ">
          <Button
            onClick={() =>
              dispatch(
                handleRemoveDomaFromInBasket({
                  productCode: domainProduct.productCode,
                })
              )
            }
            uiSize="small"
            uiType="icon"
            className="!px-1"
          >
            <RemoveIcon className="w-3 h-3 text-slate-700 dark:text-slate-300" />
            Remove
          </Button>
          <div className="col-span-2 flex items-center justify-center">
            {domainProduct.price > 0 && (
              <Typography.Text size="sm" className="font-medium">
                {currency(domainProduct.price / 100)}
              </Typography.Text>
            )}
          </div>
        </Flex>
      </div>
    </Flex>
  );
};

export default DomainsSearchTab;
