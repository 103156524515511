import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { jwtEnvAxios } from "../../../../../apis";
import { isAxiosError } from "axios";

export interface IGitFile {
  path: string;
  index: string;
  working_dir: string;
}
interface IGitStatus {
  not_added: string[];
  conflicted: string[];
  created: string[];
  deleted: string[];
  modified: string[];
  renamed: string[];
  files: Array<IGitFile>;
  staged: string[];
  ahead: number;
  behind: number;
  current: string;
  tracking: null | string;
  detached: boolean;
}

export type GitFlowType =
  | "Commit"
  | "AddAndCommit"
  | "Push"
  | "CommitAndPush"
  | "AddAndCommitAndPush"
  | "CommitAndSync"
  | "AddAndCommitAndSync"
  | "Sync";

const remoteName = "origin";
const branchName = "main";

type AddRemotePayload = {
  username: string;
  token: string;
  repoUrl: string;
};

interface DappGitContextType {
  gitHasInit: boolean;
  gitStatus: IGitStatus | null;

  gitHasRemote: boolean;

  gitInitLoading: boolean;
  handleGitInit: () => Promise<void>;

  gitStatusLoading: boolean;
  handleGitStatus: (hard?: boolean) => Promise<void>;

  handleGitLog: () => Promise<void>;

  gitAddLoading: boolean;
  handleGitAdd: (files: string | string[]) => Promise<void>;

  gitCommitLoading: boolean;
  handleGitCommit: (message: string) => Promise<void>;

  getRemoteLoading: boolean;
  handleGitGetRemote: () => Promise<void>;

  addRemoteLoading: boolean;
  handleGitAddRemote: (data: AddRemotePayload) => Promise<void>;

  diffLoading: boolean;
  handleGitDiff: () => Promise<void>;

  gitPushLoading: boolean;
  handleGitPush: () => Promise<void>;

  gitPullLoading: boolean;
  handleGitPull: () => Promise<void>;

  stagedChangedFiles: Array<IGitFile>;
  changedFiles: Array<IGitFile>;
}

const DappGitContext = createContext<DappGitContextType | null>(null);

export const DappGitProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [gitHasInit, setGitHasInit] = useState(false);
  const [gitStatus, setGitStatus] = useState<IGitStatus | null>(null);
  const [gitHasRemote, setGitHasRemote] = useState(false);

  const [gitStatusLoading, setGitStatusLoading] = useState(false);
  const handleGitStatus = useCallback(
    async (hard: boolean = false) => {
      if (!hard && !gitHasInit) return;
      try {
        setGitStatusLoading(true);
        const response = await jwtEnvAxios("dapp").post(`/git/status`);
        setGitHasInit(true);
        setGitStatus(response.data.status);
      } catch (e) {
        if (isAxiosError(e)) {
          if (e.response?.status === 400) {
            setGitHasInit(false);
          }
        }
      } finally {
        setGitStatusLoading(false);
      }
    },
    [gitHasInit]
  );

  const [gitInitLoading, setGitInitLoading] = useState(false);
  const handleGitInit = useCallback(async () => {
    try {
      setGitInitLoading(true);
      await jwtEnvAxios("dapp").post(`/git/init`);
      setGitHasInit(true);
      handleGitStatus(true);
    } catch (e) {
      console.log("init error:", e);
    } finally {
      setGitInitLoading(false);
    }
  }, [handleGitStatus]);

  const [gitAddLoading, setGitAddLoading] = useState(false);
  const handleGitAdd = useCallback(async (files: string | string[]) => {
    try {
      setGitAddLoading(true);
      const response = await jwtEnvAxios("dapp").post(`/git/add`, {
        files,
      });
      console.log(response);
    } catch (e) {
    } finally {
      setGitAddLoading(false);
    }
  }, []);

  const [gitCommitLoading, setGitCommitLoading] = useState(false);
  const handleGitCommit = useCallback(async (message: string) => {
    try {
      setGitCommitLoading(true);
      await jwtEnvAxios("dapp").post(`/git/commit`, {
        message,
      });
    } catch (e) {
    } finally {
      setGitCommitLoading(false);
    }
  }, []);

  const [getRemoteLoading, setGetRemoteLoading] = useState(false);
  const handleGitGetRemote = useCallback(async () => {
    try {
      setGetRemoteLoading(true);
      const response = await jwtEnvAxios("dapp").get(`/git/remotes`);
      const remotes = response.data.remotes as Array<{ name: string }>;
      setGitHasRemote(!!remotes.find((r) => r.name === remoteName));
    } catch (e) {
    } finally {
      setGetRemoteLoading(false);
    }
  }, []);

  const [addRemoteLoading, setAddRemoteLoading] = useState(false);
  const handleGitAddRemote = useCallback(
    async ({ username, token, repoUrl }: AddRemotePayload) => {
      try {
        setAddRemoteLoading(true);
        await jwtEnvAxios("dapp").post(`/git/add-remote`, {
          remoteName,
          username,
          token,
          repoUrl,
        });
        setGitHasRemote(true);
        handleGitStatus();
      } catch (e) {
      } finally {
        setAddRemoteLoading(false);
      }
    },
    [handleGitStatus]
  );

  const [diffLoading, setDiffLoading] = useState(false);
  const handleGitDiff = useCallback(async () => {
    try {
      setDiffLoading(true);
      const response = await jwtEnvAxios("dapp").post(
        `/git/commit-differences`,
        {
          remote: remoteName,
          branch: branchName,
        }
      );
      console.log(response);
    } catch (e) {
    } finally {
      setDiffLoading(false);
    }
  }, []);

  const [gitPushLoading, setGitPushLoading] = useState(false);
  const handleGitPush = useCallback(async () => {
    try {
      setGitPushLoading(true);
      await jwtEnvAxios("dapp").post(`/git/push`, {
        remote: remoteName,
        branch: branchName,
      });
    } catch (e) {
    } finally {
      setGitPushLoading(false);
    }
  }, []);

  const [gitPullLoading, setGitPullLoading] = useState(false);
  const handleGitPull = useCallback(async () => {
    try {
      setGitPullLoading(true);
      await jwtEnvAxios("dapp").post(`/git/pull`, {
        remote: remoteName,
        branch: branchName,
      });
    } catch (e) {
    } finally {
      setGitPullLoading(false);
    }
  }, []);

  const handleGitLog = useCallback(async () => {
    try {
      const response = await jwtEnvAxios("dapp").post(`/git/log`);
      console.log(response);
    } catch {
    } finally {
    }
  }, []);

  // calc staged changes files
  const stagedChangedFiles = useMemo(() => {
    const files: Array<IGitFile> = [];
    if (!gitStatus || gitStatus.files.length === 0) return [];

    for (const file of gitStatus.files) {
      if (
        file.index === "A" ||
        (file.working_dir === "M" && file.index !== " ")
      ) {
        files.push(file);
      }
    }
    return files;
  }, [gitStatus]);

  // calc changed files
  const changedFiles = useMemo(() => {
    const files: Array<IGitFile> = [];
    if (!gitStatus || gitStatus.files.length === 0) return [];

    for (const file of gitStatus.files) {
      if (file.index === "?" || file.working_dir === "M") files.push(file);
    }
    return files;
  }, [gitStatus]);

  return (
    <DappGitContext.Provider
      value={{
        gitHasInit,
        gitStatus,
        gitHasRemote,

        gitInitLoading,
        handleGitInit,

        gitStatusLoading,
        handleGitStatus,

        handleGitLog,

        gitAddLoading,
        handleGitAdd,

        gitCommitLoading,
        handleGitCommit,

        getRemoteLoading,
        handleGitGetRemote,

        addRemoteLoading,
        handleGitAddRemote,

        diffLoading,
        handleGitDiff,

        gitPushLoading,
        handleGitPush,

        gitPullLoading,
        handleGitPull,

        stagedChangedFiles,
        changedFiles,
      }}
    >
      {children}
    </DappGitContext.Provider>
  );
};

export const useDappGit = () => {
  const context = useContext(DappGitContext);
  if (!context)
    throw new Error("useDappGit must be used within DappGitProvider");
  return context;
};
