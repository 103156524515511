import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getWebAppAsync,
  selectWebApp,
  selectWebAppUpdateLoading,
  updateWebAppAsync,
} from "../../../store/web-app/webAppSlice";
import Slider from "../../general/Slider";
import { Alert, Button, Card, cn, Flex, Input, Typography } from "djuno-design";
import WebAppPlanTab from "./WebAppPlanTab";
import toast from "react-hot-toast";
import {
  getWebAppsAsync,
  selectWebApps,
  selectWebAppsLoading,
  selectWebAppsPlans,
  selectWebAppsPlansLoading,
} from "../../../store/web-app/webAppsSlice";
import useLimitations, {
  ServiceReplicaWithPlan,
} from "../../../hooks/useLimitations";

const FREE_PLAN_ID = 17;

const WebAppScalingTab = () => {
  // use prev web-apps to calc limits in WebAppPlansGroup
  const webApps = useAppSelector(selectWebApps);
  const webAppsLoading = useAppSelector(selectWebAppsLoading);

  const webApp = useAppSelector(selectWebApp);

  const webAppPlans = useAppSelector(selectWebAppsPlans);
  const webAppPlansLoading = useAppSelector(selectWebAppsPlansLoading);

  const plan = useMemo(() => {
    return webAppPlans.find((p) => p.Id === webApp?.PlanId);
  }, [webApp?.PlanId, webAppPlans]);

  const updateLoading = useAppSelector(selectWebAppUpdateLoading);
  const [changedScaling, setChangedScaling] = useState<number>(
    webApp?.ReplicaCounts || 1
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (webApps.length === 0 && !webAppsLoading) {
      dispatch(getWebAppsAsync({}));
    }
  }, [dispatch, webApps.length, webAppsLoading]);

  const scaling = useMemo(() => {
    if (webApp) {
      return webApp.ReplicaCounts;
    }
  }, [webApp]);

  const existingWebApps = useMemo(() => {
    const existItems: ServiceReplicaWithPlan[] = [];
    for (const webApp of webApps) {
      const plan = webAppPlans.find((p) => p.Id === webApp.PlanId);
      if (plan) existItems.push({ replicaCounts: webApp.ReplicaCounts, plan });
    }
    return existItems;
  }, [webAppPlans, webApps]);

  console.log({ existingWebApps });

  const {
    planPriceLimit,
    planPriceLimitChecker,
    amountOfCpuLimit,
    amountOfCpuLimitChecker,
    amountOfMemoryLimit,
    amountOfMemoryLimitChecker,
  } = useLimitations({
    cases: ["PlanPrice", "Cpu", "Memory"],
    existingServices: existingWebApps,
  });

  const isFreePlan = useMemo(() => {
    return webApp?.PlanId === FREE_PLAN_ID;
  }, [webApp?.PlanId]);

  const isExceedPrice = useMemo(() => {
    return planPriceLimitChecker(plan?.Price || 0, changedScaling);
  }, [changedScaling, plan?.Price, planPriceLimitChecker]);

  const { CPU, RAM } = JSON.parse(plan?.PlanDescription || "{}");

  const isExceedCpu = useMemo(() => {
    return amountOfCpuLimitChecker(CPU, changedScaling);
  }, [CPU, amountOfCpuLimitChecker, changedScaling]);

  const isExceedMemory = useMemo(() => {
    return amountOfMemoryLimitChecker(RAM, changedScaling);
  }, [RAM, amountOfMemoryLimitChecker, changedScaling]);

  const handleChangeScallingInput = (e: ChangeEvent<HTMLInputElement>) => {
    let val = Number(e.target.value);
    console.log("Input value:", val);
    if (val < 1) val = 1;
    if (val > 10) val = 10;
    setChangedScaling(val);
  };

  const handleUpdateManualScaling = () => {
    if (webApp && !isFreePlan && !updateLoading) {
      dispatch(
        updateWebAppAsync({
          webAppId: webApp.Id.toString(),
          data: {
            ...webApp,
            ReplicaCounts: changedScaling,
          },
        })
      ).then((action) => {
        if (action.type === "web-app/update/fulfilled") {
          toast.success("Scale update successfully");
          dispatch(getWebAppAsync({ webAppId: webApp.Id.toString() }));
        }
      });
    }
  };

  useEffect(() => {
    if (webApp) {
      setChangedScaling(webApp.ReplicaCounts || 1);
    }
  }, [webApp]);

  return (
    <>
      <Card
        title="Manual Scaling"
        description="Scale your app horizontally by creating multiple instances that are automatically load balanced by Djuno Cloud . All instances use the same instance type and are billed accordingly"
        className="mb-5"
      >
        {isFreePlan && (
          <Flex>
            <Alert
              showIcon
              uiType="warning"
              message="For the free instance type, you cannot increase the app scale."
              className="!w-auto !py-2 mb-5"
            />
          </Flex>
        )}
        <div className="grid grid-cols-12 gap-5">
          <div className="col-span-2 md:col-span-1">
            <Input
              value={changedScaling}
              type="number"
              onChange={handleChangeScallingInput}
              disabled={isFreePlan || webApp?.IsSuspended}
            />
          </div>
          <div className="col-span-10 md:col-span-11 flex items-center">
            <Slider
              range
              min={1}
              max={10}
              onChange={(value) =>
                setChangedScaling(Array.isArray(value) ? value[0] : value)
              }
              value={changedScaling}
              disabled={isFreePlan || webApp?.IsSuspended}
            />
          </div>
        </div>

        <Flex>
          {isExceedPrice && (
            <Alert className="mt-4" uiType="info">
              <Typography.Text size="sm">
                {planPriceLimit?.LimitationMessage}
              </Typography.Text>
            </Alert>
          )}

          {isExceedCpu && (
            <Alert className="mt-4" uiType="info">
              <Typography.Text size="sm">
                {amountOfCpuLimit?.LimitationMessage}
              </Typography.Text>
            </Alert>
          )}

          {isExceedMemory && (
            <Alert className="mt-4" uiType="info">
              <Typography.Text size="sm">
                {amountOfMemoryLimit?.LimitationMessage}
              </Typography.Text>
            </Alert>
          )}
        </Flex>

        <div className={cn("w-full flex items-center justify-end mt-3")}>
          <Button
            uiType="primary"
            disabled={
              webApp?.IsSuspended ||
              changedScaling === scaling ||
              isExceedPrice ||
              isExceedCpu ||
              isExceedMemory
            }
            onClick={handleUpdateManualScaling}
            loading={updateLoading}
            className="!whitespace-nowrap"
          >
            Save Changes
          </Button>
        </div>
      </Card>

      <WebAppPlanTab />
    </>
  );
};

export default WebAppScalingTab;
