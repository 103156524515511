import { useMemo, useRef } from "react";
import FileIcon, { extToType } from "./../../general/FileIcon";
import { humanizeSize } from "../../../utils/file";
import {
  getBucketObjectsAsync,
  handleChangeCheckedObjects,
  handleClearCheckedObjects,
  updateBreadCrumb,
} from "../../../store/s3/buckets/bucketSlice";
import {
  handleHideObjectDrawer,
  handleShowObjectDrawer,
  selectSelectedObject,
} from "../../../store/s3/buckets/objectSlice";
import { dateConvertor } from "../../../utils/date";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useSearch } from "../../../providers/SearchProvider";
import { S3BucketDetails, S3BucketObject } from "../../../types/s3-bucket";
import {
  objectIsFolder,
  getFolderName,
  getFileNameAndExt,
} from "../../../utils/bucket";
import { FileTypes } from "../../../types";
import HighlighterText from "../../general/HighlighterText";
import { Checkbox, Flex, SimpleTable, Tooltip, Typography } from "djuno-design";

export type BucketObjectProps = {
  object: S3BucketObject;
  bucketDetails: S3BucketDetails | null;
  checked?: boolean;
  onOpenFile?: (object: S3BucketObject) => void;
  onDeleteFile?: (object: S3BucketObject) => void;
  onOpenError?: () => void;
};

function BucketObject({ object, checked, bucketDetails }: BucketObjectProps) {
  const ref = useRef(null);

  //for show details in the object-drawer component
  const selectedObject = useAppSelector(selectSelectedObject);
  const dispatch = useAppDispatch();
  const { value: searchValue } = useSearch();

  function handleFile(object: S3BucketObject, e: any) {
    dispatch(handleClearCheckedObjects());
    if (objectIsFolder(object.name)) {
      const folderName = getFolderName(object.name);
      if (folderName) {
        dispatch(updateBreadCrumb({ title: folderName }));
        dispatch(handleHideObjectDrawer());
        if (bucketDetails) {
          dispatch(getBucketObjectsAsync({ name: bucketDetails.name }));
        }
      }
    } else {
      dispatch(handleShowObjectDrawer({ object }));
    }
  }

  function handleChangeChecked() {
    // e.stopPropagation();
    dispatch(handleHideObjectDrawer());
    dispatch(handleChangeCheckedObjects(object));
  }

  const objectName = useMemo(
    () =>
      objectIsFolder(object.name)
        ? getFolderName(object.name) || ""
        : `${getFileNameAndExt(object.name)?.fileName || ""}.${
            getFileNameAndExt(object.name)?.fileExt || ""
          }`,
    [object.name]
  );

  return (
    <SimpleTable.Row
      selected={object.name === selectedObject?.name}
      ref={ref}
      onClick={(e) => handleFile(object, e)}
    >
      <SimpleTable.TD>
        <Flex items="center" className="gap-1">
          <div onClick={(event) => event.stopPropagation()}>
            <Checkbox value={checked} onChange={handleChangeChecked} />
          </div>
          <FileIcon
            type={
              objectIsFolder(object.name)
                ? "folder"
                : (extToType(
                    getFileNameAndExt(object.name)?.fileExt || ""
                  ) as FileTypes)
            }
          />
          <Tooltip content={objectName} className="!text-xs" place="top-start">
            <Flex className="">
              <HighlighterText
                searchValue={searchValue}
                textToHighlight={objectName}
                className="max-w-[110px] md:max-w-[400px] lg:max-w-[350px] xl:max-w-[400px] truncate whitespace-pre"
              />
            </Flex>
          </Tooltip>
        </Flex>
      </SimpleTable.TD>
      <SimpleTable.TD>
        <Typography.Text className="!text-xs md:!text-sm">
          {object.size !== 0
            ? humanizeSize(Number(object.size), { fractionDigits: 2 }).join("B")
            : 0}
        </Typography.Text>
      </SimpleTable.TD>
      <SimpleTable.TD>
        <Typography.Text className="!text-xs md:!text-sm">
          {object.last_modified && dateConvertor(object.last_modified)}
        </Typography.Text>
      </SimpleTable.TD>
    </SimpleTable.Row>
  );
}

export default BucketObject;
