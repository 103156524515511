import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { IThunkRejectValue, RootState } from "../../types";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import { DappStudio, DappStudioState } from "../../types/dapp-studio";
import {
  activateDappStudioApi,
  deactivateDappStudioApi,
  getDappStudioApi,
} from "../../apis/daapStudioAPI";
import { getPlansApi } from "../../apis/billingAPI";
import { IPlanGroup } from "../../types/billing";

export const DappStudioRefreshStatus = [0, 1, 4];

const initialState: DappStudioState = {
  nodeStatus: 0,

  dapp: null,
  dappLoading: false,
  actionLoading: false,

  plans: [],
  plansLoading: false,
};

export const getDappStudioAsync = createAsyncThunk<
  { dapp: DappStudio | null; NodeStatus: number },
  { withoutLoading?: boolean },
  IThunkRejectValue
>(
  "dapp-studio",
  async (
    { withoutLoading = false },
    { rejectWithValue, fulfillWithValue, dispatch, requestId }
  ) => {
    try {
      dispatch(
        getDappStudioAsync.pending(requestId, {
          withoutLoading,
        })
      );

      const response = await getDappStudioApi();
      const { NodeStatus, Result: dapp } = response.data;

      return fulfillWithValue({ dapp: dapp || null, NodeStatus });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const activateDappStudioAsync = createAsyncThunk<
  { NodeStatus: number },
  undefined,
  IThunkRejectValue
>(
  "dapp-studio/activate",
  async (_, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await activateDappStudioApi();
      const { NodeStatus } = response.data;

      return fulfillWithValue({ NodeStatus });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const deactivateDappStudioAsync = createAsyncThunk<
  { NodeStatus: number },
  undefined,
  IThunkRejectValue
>(
  "dapp-studio/deactivate",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await deactivateDappStudioApi();
      const { NodeStatus } = response.data;

      return fulfillWithValue({ NodeStatus });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getDappStudioPlansAsync = createAsyncThunk<
  { plans: Array<IPlanGroup> },
  {
    withoutLoading?: boolean;
  },
  IThunkRejectValue
>(
  "dapp-studio/plans",
  async (
    { withoutLoading = false },
    { rejectWithValue, fulfillWithValue, requestId, dispatch }
  ) => {
    try {
      dispatch(
        getDappStudioPlansAsync.pending(requestId, {
          withoutLoading,
        })
      );
      const response = await getPlansApi({
        serviceType: process.env.REACT_APP_DAPP_SERVICE_TYPE,
      });
      const plans = response.data.Result;
      return fulfillWithValue({ plans });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const dappStudioSlice = createSlice({
  name: "dapp-studio",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDappStudioAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.dappLoading = true;
      })
      .addCase(getDappStudioAsync.fulfilled, (state, action) => {
        const { dapp, NodeStatus } = action.payload;
        state.dapp = dapp;
        state.dappLoading = false;
        state.nodeStatus = NodeStatus;
      })
      .addCase(getDappStudioAsync.rejected, (state, { payload }) => {
        state.dappLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(activateDappStudioAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(activateDappStudioAsync.fulfilled, (state, action) => {
        state.actionLoading = false;
        state.nodeStatus = action.payload.NodeStatus;
      })
      .addCase(activateDappStudioAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deactivateDappStudioAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(deactivateDappStudioAsync.fulfilled, (state, action) => {
        state.actionLoading = false;
        state.nodeStatus = action.payload.NodeStatus;
      })
      .addCase(deactivateDappStudioAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getDappStudioPlansAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.plansLoading = true;
      })
      .addCase(
        getDappStudioPlansAsync.fulfilled,
        (state, { payload: { plans } }) => {
          state.plansLoading = false;
          if (plans.length) state.plans = plans[0].PlanDetail;
        }
      )
      .addCase(getDappStudioPlansAsync.rejected, (state, { payload }) => {
        state.plansLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectDappStudioStatus = (state: RootState) =>
  state.dappStudio.nodeStatus;

export const selectDappStudio = (state: RootState) => state.dappStudio.dapp;
export const selectDappStudioLoading = (state: RootState) =>
  state.dappStudio.dappLoading;
export const selectDappStudioActionLoading = (state: RootState) =>
  state.dappStudio.actionLoading;

export const selectDappStudioPlans = (state: RootState) =>
  state.dappStudio.plans;
export const selectDappStudioPlansLoading = (state: RootState) =>
  state.dappStudio.plansLoading;

// export const {  } = dappStudioSlice.actions;
export default dappStudioSlice.reducer;
