import { Modal } from "djuno-design";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  handleHideNamespaceEditorModal,
  selectShowNamespaceEditor,
} from "../../../store/database/serviceSlice";
import DBSNamespaceCreateTab from "./DBSNamespaceCreateTab";
import { useCallback, useEffect } from "react";

const DBSNamespaceEditorModal = () => {
  const isOpen = useAppSelector(selectShowNamespaceEditor);
  const dispatch = useAppDispatch();

  const handleOnClose = useCallback(() => {
    dispatch(handleHideNamespaceEditorModal());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      contentClassName="max-w-4xl"
      title={"Update Namespace"}
    >
      <DBSNamespaceCreateTab />
    </Modal>
  );
};

export default DBSNamespaceEditorModal;
