import { Outlet, useNavigate, useParams } from "react-router-dom";
import {
  DomainAutoRenewalUrl,
  DomainBuyandSellCryptoUrl,
  DomainCryptoUrl,
  DomainDNSRecordsUrl,
  DomainEmailUrl,
  DomainNameserversUrl,
  DomainReverseResolutionUrl,
  DomainSellUrl,
  DomainsUrl,
  DomainTokenizationUrl,
  DomainTransferUrl,
  DomainUrl,
  DomainWebsiteUrl,
} from "../../utils/urls";
import { ReactComponent as RightArrow } from "./../../assets/icons/arrow-right.svg";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Helmet } from "react-helmet";
import { DomainNotActiveTab, NotExist } from "../../components/layouts/NotData";
import { ReactComponent as UserIcon } from "./../../assets/icons/user.svg";
import { ReactComponent as CurrencyDollarIcon } from "./../../assets/icons/currency-dollar.svg";
import { ReactComponent as WebsiteIcon } from "./../../assets/icons/computer-desktop.svg";
import { ReactComponent as GlobeIcon } from "./../../assets/icons/globe-alt.svg";
import { ReactComponent as TransferIcon } from "./../../assets/icons/arrow-up-right.svg";
import { ReactComponent as BarIcon } from "./../../assets/icons/bars-3-bottom-left.svg";
import { ReactComponent as LinkIcon } from "./../../assets/icons/link.svg";
import { ReactComponent as TagIcon } from "./../../assets/icons/tag.svg";
import { ReactComponent as BankNotesIcon } from "./../../assets/icons/bank-notes.svg";
import { ReactComponent as ArrowsRithLeftIcon } from "./../../assets/icons/arrows-right-left.svg";
import { ReactComponent as EmailIcon } from "./../../assets/icons/email.svg";
import { ReactComponent as ArrowPathIcon } from "./../../assets/icons/arrow-path.svg";
import { Flex, Skeleton, Typography, TabOptions, Tabs } from "djuno-design";
import {
  getDomainAsync,
  selectDomain,
  selectDomainLoading,
} from "../../store/domains/domainSlice";

const generateDomainRoutes = (id: string | undefined): TabOptions => {
  return [
    {
      label: (
        <div className="flex items-center gap-1">
          <UserIcon className="w-3" />
          Profile
        </div>
      ),
      url: DomainUrl(id ? +id : 0),
    },

    {
      label: (
        <div className="flex items-center gap-1">
          <CurrencyDollarIcon className="w-3" />
          Crypto
        </div>
      ),
      url: DomainCryptoUrl(id ? +id : 0),
    },
    {
      label: (
        <div className="flex items-center gap-1">
          <WebsiteIcon className="w-3" />
          Website
        </div>
      ),
      url: DomainWebsiteUrl(id ? +id : 0),
    },
    {
      label: (
        <div className="flex items-center gap-1">
          <GlobeIcon className="w-3" />
          DNS Records
        </div>
      ),
      url: DomainDNSRecordsUrl(id ? +id : 0),
    },
    {
      label: (
        <div className="flex items-center gap-1">
          <TransferIcon className="w-3" />
          Transfer
        </div>
      ),
      url: DomainTransferUrl(id ? +id : 0),
    },
    {
      label: (
        <div className="flex items-center gap-1">
          <BarIcon className="w-3" />
          Nameservers
        </div>
      ),
      url: DomainNameserversUrl(id ? +id : 0),
    },
    {
      label: (
        <div className="flex items-center gap-1">
          <LinkIcon className="w-3" />
          Tokenization
        </div>
      ),
      url: DomainTokenizationUrl(id ? +id : 0),
    },
    {
      label: (
        <div className="flex items-center gap-1">
          <TagIcon className="w-3" />
          Sell Domain
        </div>
      ),
      url: DomainSellUrl(id ? +id : 0),
    },
    {
      label: (
        <div className="flex items-center gap-1">
          <BankNotesIcon className="w-3" />
          Buy and Sell Crypto
        </div>
      ),
      url: DomainBuyandSellCryptoUrl(id ? +id : 0),
    },
    {
      label: (
        <div className="flex items-center gap-1">
          <ArrowsRithLeftIcon className="w-3" />
          Reverse Resolution
        </div>
      ),
      url: DomainReverseResolutionUrl(id ? +id : 0),
    },
    {
      label: (
        <div className="flex items-center gap-1">
          <EmailIcon className="w-3" />
          Email
        </div>
      ),
      url: DomainEmailUrl(id ? +id : 0),
    },
    {
      label: (
        <div className="flex items-center gap-1">
          <ArrowPathIcon className="w-3" />
          Auto Renewal
        </div>
      ),
      url: DomainAutoRenewalUrl(id ? +id : 0),
    },
  ];
};

const DomainPage = () => {
  const { id } = useParams();
  const domain = useAppSelector(selectDomain);
  const loading = useAppSelector(selectDomainLoading);

  const domainPageMenus = generateDomainRoutes(id);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isExist, setIsExist] = useState<boolean>(true);

  useEffect(() => {
    if (id) {
      dispatch(getDomainAsync({ id: +id })).then((action) => {
        if (action && action.type === "rpc/rejected") {
          setIsExist(false);
        }
      });
    }
  }, [dispatch, id]);

  // useEffect(() => {
  //   let interval: NodeJS.Timeout | undefined;
  //   let intervalTime =
  //     Number(process.env.REACT_APP_NODES_REFRESH_INTERVAL) || 20000;

  //   if (domain && DomainRefreshStatus.includes(domain.RPCNodeStatus)) {
  //     interval = setInterval(() => {
  //       if (id) {
  //         dispatch(getRpcAsync({ id: +id, withoutLoading: true })).then(
  //           (action) => {
  //             if (action && action.type === "rpc/rejected") {
  //               setIsExist(false);
  //             }
  //           }
  //         );
  //       }
  //     }, intervalTime);
  //   } else {
  //     if (interval) clearInterval(interval);
  //   }
  //   return () => {
  //     if (interval) {
  //       clearInterval(interval);
  //     }
  //   };
  // }, [dispatch, id, domain]);

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | Domain ({domain ? domain.Name : ""})
        </title>
        <meta name="description" content="" />
      </Helmet>
      <Flex items="center" justify="between" className="h-16">
        <Flex items="center" justify="between" className="px-6 flex-1">
          <Flex items="center" className="gap-2">
            <RightArrow
              onClick={() => navigate(DomainsUrl)}
              className="rotate-180 w-5 h-5 hover:scale-110 transition-all duration-500 text-slate-800 dark:text-slate-100 cursor-pointer"
            />
            {domain && !loading && (
              <div className="flex flex-col">
                <Typography.Title level={5} className="!mb-0">
                  {domain.Name}
                </Typography.Title>
              </div>
            )}
            {loading && (
              <Flex direction="col" className="gap-1">
                <Skeleton
                  shape="rectangle"
                  style={{ width: 200, height: 20 }}
                />
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>

      {/* && domain?.RPCNodeStatus === "RUNNING" */}
      {isExist && (
        <div className="px-6 mt-5 mb-10">
          <Tabs
            options={domainPageMenus.map((o) => ({
              label: o.label,
              url: o.url,
              testId: o.testId,
            }))}
          />
          <div className="py-6">
            <Outlet />
          </div>
        </div>
      )}
      {!isExist && <NotExist url={() => navigate(DomainsUrl)} />}

      {/* && DomainRefreshStatus.includes(domain.RPCNodeStatus) */}
      {!loading && domain && <DomainNotActiveTab />}
      {/* {!loading && domain && domain.RPCNodeStatus === "ERROR_CREATION" && (
        <CreationErrorTab />
      )} */}
    </>
  );
};
export default DomainPage;
