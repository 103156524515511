import { Button, Flex, Typography } from "djuno-design";
import { ReactComponent as ArrowsRithLeftIcon } from "./../../../../assets/icons/arrows-right-left.svg";

const DomainReverseResolutionTab = () => {
  return (
    <Flex
      direction="col"
      className="mt-6 max-w-lg border rounded-lg mx-auto p-6 items-center"
    >
      <ArrowsRithLeftIcon className="w-10 h-10 text-primary-400" />
      <Typography.Text size="base" className="text-center mt-5">
        Set djuno.u as the primary domain for your wallet address:
        0x1c91eb...72ae.
      </Typography.Text>
      <Typography.Text size="base" uiType="secondary" className="text-center">
        Reverse resolution allows web3 applications to display djuno.u instead
        of your wallet's address.
      </Typography.Text>
      <Flex items="center" justify="center" className="w-full mt-8"></Flex>
      <Button uiType="primary" className="mt-10">
        Start Record Update
      </Button>
    </Flex>
  );
};

export default DomainReverseResolutionTab;
