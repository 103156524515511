import { Button, Card, Flex, Input, Tabs } from "djuno-design";
import CardDetailsBox from "../settings/billing/CardDetailsBox";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  makeBillingIntentAsync,
  activeWithPromoAsync,
  selectLoadingCardDetail,
  selectActiveWithPromoLoading,
  selectMakeIntentLoading,
  waitingStatusAsync,
} from "../../store/billing/billingSlice";
import { BillingIntentResponse } from "../../types/billing";
import { selectOnStageEnv, selectUser } from "../../store/auth/authSlice";
import { useCallback, useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

const CardInformation: React.FC<{
  handleValidateSelectedEnv: () => void;
  validateEnvLoading: boolean;
}> = ({ handleValidateSelectedEnv, validateEnvLoading }) => {
  const dispatch = useAppDispatch();

  const selectedEnv = useAppSelector(selectOnStageEnv);
  const user = useAppSelector(selectUser);

  const makeIntentLoading = useAppSelector(selectMakeIntentLoading);
  const activeWithPromoLoading = useAppSelector(selectActiveWithPromoLoading);
  const savingLoading = useAppSelector(selectLoadingCardDetail);

  const [selectedMethod, setSelectedMethod] = useState(0);

  const [promoCode, setPromoCode] = useState("");
  const promoRef = useRef<HTMLInputElement>(null);

  const handleBeforInitPaymentMethod = useCallback(async () => {
    if (selectedEnv) {
      const envStatus = selectedEnv.EnvironmentStatus; //available status: 0,1,2
      if (envStatus === 0 || envStatus === 1 || envStatus === 2) {
        return dispatch(makeBillingIntentAsync()).then((action) => {
          if (action.type === "billing/intent/fulfilled") {
            const orderToken = (action.payload as BillingIntentResponse).Token;
            return orderToken;
            // return "4ba308d2-f98e-44e6-9785-e427ab034814";
          } else {
            return undefined;
          }
        });
      } else {
        if (selectedMethod === 0) handleValidateSelectedEnv();
      }
    }
  }, [dispatch, handleValidateSelectedEnv, selectedEnv, selectedMethod]);

  const handleAfterInitPaymentMethod = async (orderToken: string) => {
    if (selectedEnv)
      dispatch(waitingStatusAsync({ token: orderToken })).then((action) => {
        if (action.type === "billing/waiting-status/fulfilled") {
          // dispatch(getEnvironmentsAsync());
          // dispatch(getEnvironmentAsync({ id: selectedEnv.Id })).then(
          //   (action) => {
          //     if (action.type === "environments/getOne/fulfilled") {
          //       const env = (action.payload as { environment: Environment })
          //         .environment;
          //       dispatch(handleSelectEnv({ env }));
          //       // handleSelectedEnv();
          //     }
          //   }
          // );
          handleValidateSelectedEnv();
        }
      });
  };

  const handleSavePromoCode = useCallback(() => {
    if (promoCode) {
      dispatch(activeWithPromoAsync({ PromoCode: promoCode })).then(
        (action) => {
          if (action.type === "billing/active-with-promo/fulfilled") {
            handleValidateSelectedEnv();
          }
        }
      );
    }
  }, [dispatch, handleValidateSelectedEnv, promoCode]);

  useEffect(() => {
    if (selectedMethod === 1 && promoRef && promoRef.current) {
      promoRef.current.focus();
    } else {
      setPromoCode("");
    }
  }, [selectedMethod]);

  return (
    <div className="w-full h-[calc(100vh-4rem)] flex justify-center items-center">
      <Card title="Payment details" className="max-w-xl">
        <Tabs
          tabType="creamy"
          options={[{ label: "Credit Card" }, { label: "Promo Code" }]}
          onChange={({ index }) => setSelectedMethod(index || 0)}
          selectedIndex={selectedMethod}
        />

        <div className="min-h-[150px] flex flex-col">
          <AnimatePresence>
            {selectedMethod === 0 && (
              <motion.div className="flex-1 flex flex-col justify-between">
                <CardDetailsBox
                  beforInitFn={handleBeforInitPaymentMethod}
                  afterInitFn={handleAfterInitPaymentMethod}
                  initLoading={makeIntentLoading || validateEnvLoading}
                  savingLoading={savingLoading}
                  username={user?.FullName}
                />
              </motion.div>
            )}
            {selectedMethod === 1 && (
              <motion.div className="flex-1 flex flex-col justify-between">
                <div className="pt-8">
                  <Input
                    label="Promo Code"
                    placeholder="Enter your Promo Code"
                    onChange={(e) => setPromoCode(e.target.value)}
                    value={promoCode}
                    ref={promoRef}
                    disabled={
                      validateEnvLoading ||
                      activeWithPromoLoading ||
                      savingLoading
                    }
                  />
                </div>
                <Flex items="center" justify="end" className="w-full">
                  <Button
                    className="w-[40px]"
                    uiType="primary"
                    loading={
                      validateEnvLoading ||
                      activeWithPromoLoading ||
                      savingLoading
                    }
                    disabled={
                      validateEnvLoading ||
                      activeWithPromoLoading ||
                      savingLoading
                    }
                    onClick={handleSavePromoCode}
                  >
                    Save
                  </Button>
                </Flex>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </Card>
    </div>
  );
};

export default CardInformation;
