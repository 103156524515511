import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  deleteWorkflowAsync,
  getWorkflowsAsync,
  handleChangeFlowDir,
  selectWorkflowDirection,
  selectWorkflowStatus,
  selectWorkflows,
  selectWorkflowsActionLoading,
  selectWorkflowsLoading,
} from "../../../store/workflows/workflowsSlice";

import HighlighterText from "../../general/HighlighterText";
import { WorkflowNotActiveTab } from "../../layouts/NotData";
import { useSearch } from "../../../providers/SearchProvider";
import { IWorkflowResponse } from "../../../types/workflows";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as ArrowRightIcon } from "./../../../assets/icons/arrow-right.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as DetailsIcon } from "./../../../assets/icons/bars-3-bottom-left.svg";
import { ReactComponent as DirectionIcon } from "./../../../assets/icons/arrows-right-left.svg";
import { ReactComponent as Template } from "./../../../assets/icons/rectangle-group.svg";
import { ReactComponent as Arrowdown } from "./../../../assets/icons/arrow-down.svg";
import { DeleteModal } from "../../modals/QuestionModal";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { WorkflowTempalteUrl, WorkflowUrl } from "../../../utils/urls";
import { getNodeIconUrl } from "../../../utils/wfHelper";
import {
  Dropdown,
  Button,
  SimpleTable,
  Tag,
  Typography,
  Tooltip,
  EmptyState,
  cn,
  Flex,
} from "djuno-design";
// import { useTour } from "../../../providers/TourProvider";

const WorkflowsTab = () => {
  const workflowStatus = useAppSelector(selectWorkflowStatus);
  const workflows = useAppSelector(selectWorkflows);
  const workflowsLoading = useAppSelector(selectWorkflowsLoading);
  const workflowsActionLoading = useAppSelector(selectWorkflowsActionLoading);
  const flowDirection = useAppSelector(selectWorkflowDirection);
  const [filteredWorkflows, setFilteredWorkflows] = useState<
    IWorkflowResponse[]
  >([]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // const { run } = useTour("workflows");

  const [deleteWorkflow, setDeleteWorkflow] =
    useState<IWorkflowResponse | null>(null);
  const [images, setImages] = useState<Record<string, string[]>>({});
  const { value: searchValue } = useSearch();

  useEffect(() => {
    dispatch(getWorkflowsAsync());
  }, [dispatch]);

  useEffect(() => {
    const lookedUpWorkflows = workflows.filter((workflow) =>
      workflow.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredWorkflows(lookedUpWorkflows);
  }, [dispatch, searchValue, workflows]);

  useEffect(() => {
    const getNodesImages = async () => {
      if (workflows) {
        try {
          const images: Record<string, string[]> = {};
          for (let i = 0; i < workflows.length; i += 1) {
            const flowDataStr = workflows[i].flowData;
            const flowData = JSON.parse(flowDataStr);
            const nodes = flowData.nodes || [];
            images[workflows[i].shortId] = [];

            for (let j = 0; j < nodes.length; j += 1) {
              const imageSrc = getNodeIconUrl(nodes[j].data.icon);
              if (!images[workflows[i].shortId].includes(imageSrc)) {
                images[workflows[i].shortId].push(imageSrc);
              }
            }
          }
          setImages(images);
        } catch (e) {
          console.error(e);
        }
      }
    };
    getNodesImages();
  }, [workflows]);

  const changeFlowDirection = useCallback(() => {
    dispatch(handleChangeFlowDir());
  }, [dispatch]);

  if (!workflowStatus) {
    return <WorkflowNotActiveTab />;
  }

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Workflows</title>
        <meta name="description" content="" />
      </Helmet>
      <div className="flex transition-all duration-500 items-center justify-between gap-3 workflows-header">
        <div></div>
        <div className="flex items-center justify-end gap-7 ">
          {/* <Button
            onClick={() => {
              run();
            }}
          >
            Start Tour
          </Button> */}
          <div className="flex items-center gap-0.5 flow-direction-button">
            <Typography.Text className="whitespace-nowrap !font-medium !text-sm ">
              flow direction
            </Typography.Text>
            <Tooltip
              content={
                flowDirection === "ltr" ? `left to right` : "top to bottom"
              }
            >
              <DirectionIcon
                onClick={changeFlowDirection}
                className={cn(
                  "w-5 h-5 text-blue-500 cursor-pointer hover:text-blue-600 hover:scale-110 duration-200",
                  { "rotate-90": flowDirection === "ttb" }
                )}
              />
            </Tooltip>
          </div>

          <div className="flex create-workflow-button">
            <Button
              onClick={() => navigate(WorkflowUrl())}
              uiType="primary"
              className="!pr-1 !pl-2 !rounded-r-none"
            >
              Create New
            </Button>
            <Flex>
              <Dropdown
                anchor="bottom end"
                itemsClassName="!w-auto"
                menu={[
                  {
                    key: "end",
                    label: (
                      <div className="flex items-center gap-1">
                        <Template className="w-4" />
                        Add from template
                      </div>
                    ),
                    onClick: (_, close) => {
                      close();
                      navigate(WorkflowTempalteUrl());
                    },
                  },
                ]}
              >
                <Button
                  uiType="primary"
                  className="!px-1 !rounded-l-none !bg-primary-500"
                >
                  <Arrowdown className="w-4 " />
                </Button>
              </Dropdown>
            </Flex>
          </div>
        </div>
      </div>
      <div className="mt-5 w-full workflows-table">
        <SimpleTable
          loading={workflowsLoading}
          className="table-auto"
          containerClassName="min-h-[240px]"
        >
          <SimpleTable.Head>
            <SimpleTable.Row>
              <SimpleTable.TH lable="Name" />
              <SimpleTable.TH lable="Execution" />
              <SimpleTable.TH lable="Steps" />
              {/* <SimpleTable.TH lable="Created" /> */}
              <SimpleTable.TH lable="Status" />
              <SimpleTable.TH lable="" />
            </SimpleTable.Row>
          </SimpleTable.Head>
          <SimpleTable.Body>
            {filteredWorkflows.map((flow, index) => {
              const workflowNodesImage = images[flow.shortId];
              return (
                <SimpleTable.Row
                  key={index}
                  onClick={() => navigate(WorkflowUrl(flow.shortId))}
                  className="cursor-pointer"
                >
                  <SimpleTable.TD>
                    <Flex className="">
                      <HighlighterText
                        searchValue={searchValue}
                        textToHighlight={flow.name}
                        className="max-w-[110px] md:max-w-[400px] lg:max-w-[350px] xl:max-w-[400px] truncate"
                      />
                    </Flex>
                  </SimpleTable.TD>
                  <SimpleTable.TD>
                    <Typography.Text className="!text-xs md:!text-sm">
                      {flow.executionCount}
                    </Typography.Text>
                  </SimpleTable.TD>
                  <SimpleTable.TD>
                    <div className="flex flex-row min-w-[210px] max-w-[210px] overflow-x-hidden">
                      {workflowNodesImage?.slice(0, 3).map((img) => (
                        <div
                          key={img}
                          className="w-[32px] h-[32px] aspect-square mr-1 rounded-full bg-white dark:bg-white/10"
                        >
                          <img
                            className="w-full h-full p-1 object-contain"
                            alt=""
                            src={img}
                          />
                        </div>
                      ))}
                      {workflowNodesImage.length > 3 && (
                        <div className="w-[32px] h-[32px] aspect-square mr-1 rounded-full bg-white dark:bg-white/10 flex justify-center items-start">
                          ...
                        </div>
                      )}
                    </div>
                  </SimpleTable.TD>
                  {/* <SimpleTable.TD>
                  <Text className="text-xs md:text-sm" type="primary-alert">
                    --
                  </Text>
                </SimpleTable.TD> */}
                  <SimpleTable.TD>
                    <div className="flex">
                      {flow.deployed && (
                        <Tag className="!text-xs" color="success">
                          Deployed
                        </Tag>
                      )}
                    </div>
                  </SimpleTable.TD>
                  <SimpleTable.TD className="w-20 gap-1">
                    <div className="h-full w-full inline-flex items-center justify-end gap-1">
                      <div className="w-8 flex justify-center items-center">
                        <Dropdown
                          anchor="bottom end"
                          itemsClassName="!w-40"
                          menu={[
                            {
                              key: "1",
                              label: (
                                <div className="flex items-center gap-1">
                                  <DetailsIcon className="w-4" />
                                  Get details
                                </div>
                              ),
                              onClick: () =>
                                navigate(WorkflowUrl(flow.shortId)),
                              disabled: workflowsLoading,
                            },
                            {
                              type: "divider",
                            },
                            {
                              key: "end",
                              label: (
                                <div className="flex items-center gap-1">
                                  <ArchiveIcon className="w-4" />
                                  Delete
                                </div>
                              ),
                              danger: true,
                              onClick: (_, close) => {
                                close();
                                setDeleteWorkflow(flow);
                              },
                              disabled: workflowsLoading,
                            },
                          ]}
                        >
                          <Button
                            uiType="icon"
                            uiSize="small"
                            className="!px-2"
                          >
                            <MoreIcon className="w-4 h-4" />
                          </Button>
                        </Dropdown>
                      </div>
                      <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" />
                    </div>
                  </SimpleTable.TD>
                </SimpleTable.Row>
              );
            })}
            {workflows.length === 0 && (
              <SimpleTable.Row withoutHoverStyle={true} className="h-[200px]">
                <SimpleTable.TD colSpan={5} className="!border-none">
                  <EmptyState />
                </SimpleTable.TD>
              </SimpleTable.Row>
            )}
          </SimpleTable.Body>
        </SimpleTable>
      </div>

      <DeleteModal
        title="Delete Workflow"
        isOpen={deleteWorkflow !== null}
        onClose={() => setDeleteWorkflow(null)}
        description=""
        confirmButtonText="Confirm"
        confirmButtonType="danger"
        confirmButtonClassName="w-full"
        onConfirm={() => {
          deleteWorkflow &&
            dispatch(deleteWorkflowAsync({ id: deleteWorkflow.shortId })).then(
              (action) => {
                if (action.type === "workflows/delete/fulfilled") {
                  dispatch(getWorkflowsAsync());
                } else {
                }
                setDeleteWorkflow(null);
              }
            );
        }}
        loading={workflowsActionLoading}
        confirmString={deleteWorkflow?.name}
      />
    </>
  );
};

export default WorkflowsTab;
