import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  CreationErrorTab,
  DappNotActiveTab,
  NotActiveTab,
} from "../../layouts/NotData";
import { Link } from "react-router-dom";
import {
  getDappStudioAsync,
  selectDappStudio,
  selectDappStudioLoading,
  selectDappStudioStatus,
} from "../../../store/dapp-studio/dappStudioSlice";
import { Button, Flex, Loading, Typography } from "djuno-design";
import { DappPlansUrl } from "../../../utils/urls";
import { useEffect } from "react";
import DappStudioIDE from "./DappStudioIDE/DappStudioIDE";
import useServiceType from "../../../hooks/useServiceType";
import { ReactComponent as AILogo } from "../../../assets/icons/ai-platform.svg";
import useConfigIntercomeSetting from "../../../hooks/useConfigIntercomeSetting";

const DappStudioTab = () => {
  const dappStudioStatus = useAppSelector(selectDappStudioStatus);
  const dappStudio = useAppSelector(selectDappStudio);
  const dappStudioLoading = useAppSelector(selectDappStudioLoading);

  const dispatch = useAppDispatch();

  useConfigIntercomeSetting(0, true);

  useEffect(() => {
    dispatch(getDappStudioAsync({}));
  }, [dispatch]);

  if (dappStudioLoading) {
    return (
      <Flex justify="center" items="center" className="h-[300px]">
        <Loading borderSize={2} />
      </Flex>
    );
  }

  if (dappStudio === null) {
    return <DappPlanNotActiveTab />;
  }

  if (dappStudio !== null && dappStudioStatus === 3) {
    return <CreationErrorTab />;
  }

  if (dappStudio !== null && dappStudioStatus !== 2) {
    return <DappNotActiveTab />;
  }

  return (
    <>
      <div className="flex transition-all duration-500 items-center justify-between gap-3">
        <div></div>
      </div>
      <DappStudioIDE />
    </>
  );
};

const DappPlanNotActiveTab = () => {
  const { serviceType } = useServiceType(
    process.env.REACT_APP_DAPP_SERVICE_TYPE
  );
  return (
    <NotActiveTab
      text={
        <Flex direction="col" items="center" className="gap-4">
          <Flex direction="col" items="center" className="gap-2">
            <AILogo className="w-20 h-20 flex-shrink-0" />
            <Typography.Text>
              Activate {serviceType?.ServiceName} to start using AI empowered
              smart contracting IDE
            </Typography.Text>
          </Flex>
          <Link to={DappPlansUrl}>
            <Button>Active Subscription</Button>
          </Link>
        </Flex>
      }
    />
  );
};

export default DappStudioTab;
