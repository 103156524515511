import { Button, Flex, Input, Tag, Typography } from "djuno-design";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/archive-box.svg";

const DomainCryptoTab = () => {
  return (
    <Flex direction="col" className="mt-6">
      <Typography.Text size="base">
        Add cryptocurrency addresses
      </Typography.Text>
      <Typography.Text uiType="secondary" size="sm" className="mt-2">
        Add crypto addresses to receive payments using this domain. Optionally
        verify an address on Bitcoin, Ethereum, Polygon, Avalanche, Fantom,
        Cardano, Solana or Hedera to prove ownership.
      </Typography.Text>

      <form>
        <Flex direction="col" className="mt-8 gap-5 lg:w-2/3">
          <CryptoItem />
          <CryptoItem />
          {/* <Input
            label="Recipient address"
            placeholder="Enter recipient address"
            required
          />
          <Flex direction="col" className="mt-2 gap-3">
            <Checkbox label="I understand that I am transferring ownership of this domain and this action is irreversible" />
            <Checkbox label="I’m not transferring to an exchange address" />
            <Checkbox label="I’m transferring to a Base wallet address" />
            <Checkbox label="Clear records upon transfer (Optional)" />
          </Flex>
          <Button disabled={!isValid} uiType="primary" type="submit">
            Transfer Domain
          </Button> */}
        </Flex>
      </form>
    </Flex>
  );
};

export const CryptoItem = () => {
  return (
    <div className="grid grid-cols-12 w-full gap-4">
      <Flex
        items="start"
        justify={{ lg: "between" }}
        className="col-span-12 lg:col-span-4 gap-1"
      >
        <Flex items="center" className="gap-1">
          <span className="w-8 h-8 rounded-full bg-slate-200 dark:bg-dark-2"></span>
          <Typography.Text className="!text-sm">Cardano</Typography.Text>
        </Flex>
        <DeleteIcon className="w-5 h-5 text-slate-700 dark:text-slate-200 mt-2 hover:text-red-500 cursor-pointer" />
      </Flex>
      <Flex direction="col" className="col-span-12 lg:col-span-8 gap-2 w-full">
        {/* loop */}
        <Flex className="gap-1">
          {/* <Tag>ETH</Tag> */}
          <Input
            containerClassName="flex-1 w-full"
            placeholder={`Enter your Cardano address`}
            // error="Enter a valid address to receive payments in this currency."
          />
          <Button uiType="icon" className="!w-20 !justify-center">
            Verify
          </Button>
          {/* <Button
            uiType="icon"
            className="!w-20 !justify-center !text-green-500"
          >
            Verified
          </Button> */}
        </Flex>
      </Flex>
    </div>
  );
};
export default DomainCryptoTab;
