import { WorkingPage } from "../../../layouts/NotData";

const DomainSellTab = () => {
  return (
    <>
      <WorkingPage />
    </>
  );
};

export default DomainSellTab;
