import { Link } from "react-router-dom";
import { ReactComponent as BellIcon } from "./../../assets/icons/bell.svg";
import { Typography, Popover, Flex, EmptyState, Loading } from "djuno-design";
import { useAppSelector } from "../../hooks";
import {
  selectNotifications,
  selectNotificationsLoading,
} from "../../store/notifications/notificationsSlice";
import { NotificationToastItem } from "../settings/notifications/NotificationSocket";
import { useMemo } from "react";
import { SettingsNotificationsUrl } from "../../utils/urls";

const NotifDropdown: React.FunctionComponent = () => {
  const notifications = useAppSelector(selectNotifications);
  const notificationsLoading = useAppSelector(selectNotificationsLoading);

  const notReadedNotifications = useMemo(() => {
    return notifications.filter((notif) => !notif.IsRead);
  }, [notifications]);

  const Notifications: React.FC = () => {
    return (
      <Flex
        direction="col"
        className="!z-[51] min-w-[200px] max-w-md p-3 py-4 pb-2 border rounded-xl bg-white dark:bg-dark-1 dark:border-dark-2"
      >
        {notReadedNotifications.length === 0 && !notificationsLoading && (
          <EmptyState
            icon={<EmptyState.PRESENTED_IMAGE_DEFAULT className="!w-8" />}
            text=""
          />
        )}
        <Flex direction="col" className="gap-2 max-h-[300px] overflow-y-auto">
          {notificationsLoading && (
            <Flex items="center" justify="center" className="min-h-[100px]">
              <Loading borderSize={2} uiSize={15} />
            </Flex>
          )}
          {!notificationsLoading &&
            notReadedNotifications.map((notification, i) => (
              <NotificationToastItem key={i} notification={notification} />
            ))}
        </Flex>
        <div className="w-full dark:border-dark-2 border-t mt-3 mb-1" />
        <Flex items="center" justify="center" className="w-full">
          <Link to={SettingsNotificationsUrl} className="group">
            <Typography.Text size="xs" className="group-hover:text-primary-400">
              See All Notifications
            </Typography.Text>
          </Link>
        </Flex>
      </Flex>
    );
  };

  return (
    <Popover
      anchor="bottom end"
      content={<Notifications />}
      panelClassName="!z-40 !mt-1"
    >
      <div className="flex items-center gap-2">
        <div className="w-5 h-5 aspect-square rounded-full relative group cursor-pointer">
          {notReadedNotifications.length > 0 && (
            <div className="w-4 h-4 aspect-square rounded-full bg-red-500 absolute z-40 text-[0.65rem] flex items-center justify-center text-slate-200 dark:text-slate-900 font-medium p-0.5 px-2 -top-1.5 left-2.5 shadow-md">
              {notReadedNotifications.length}
            </div>
          )}
          <BellIcon className="w-full h-full group-hover:scale-110" />
        </div>
      </div>
    </Popover>
  );
};
export default NotifDropdown;
