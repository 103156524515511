import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { cn, EmptyState, Flex, Loading, Typography } from "djuno-design";
import {
  getWebAppEventsAsync,
  selectWebApp,
  selectWebAppEvents,
  selectWebAppEventsLoading,
} from "../../../store/web-app/webAppSlice";
import { WebAppEvent } from "../../../types/web-app";
import { formatTimestamp } from "../../../utils/date";

const WebAppEventsTab = () => {
  const webApp = useAppSelector(selectWebApp);
  const events = useAppSelector(selectWebAppEvents);
  const eventsLoading = useAppSelector(selectWebAppEventsLoading);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (webApp) {
      dispatch(getWebAppEventsAsync({ webAppId: webApp?.Id.toString() }));
    }
  }, [dispatch, webApp]);

  return (
    <>
      <div className="flex transition-all duration-500">
        <Typography.Text className="whitespace-nowrap font-medium mt-1.5 !mb-0 !text-sm">
          {webApp?.ImageName}
        </Typography.Text>
        <div className="ml-auto flex items-center justify-end gap-1 md:gap-2"></div>
      </div>

      {
        <div className="mt-5 w-full">
          {events.length > 0 && !eventsLoading && (
            <ol className="relative border-s border-gray-200 dark:border-gray-700">
              {events.map((event, i) => (
                <EventItem
                  key={i}
                  EventTime={event.EventTime}
                  FirstTimestamp={event.FirstTimestamp}
                  LastTimestamp={event.LastTimestamp}
                  InvolvedObject={event.InvolvedObject}
                  Message={event.Message}
                  Reason={event.Reason}
                  Type={event.Type}
                />
              ))}
            </ol>
          )}
          {eventsLoading && (
            <Flex
              items="center"
              justify="center"
              className="w-full !min-h-[300px]"
            >
              <Loading borderSize={2} />
            </Flex>
          )}

          {!eventsLoading && events.length === 0 && (
            <EmptyState className="w-full !min-h-[300px]" />
          )}
        </div>
      }
    </>
  );
};

const EventItem = ({
  EventTime,
  FirstTimestamp,
  InvolvedObject,
  LastTimestamp,
  Message,
  Reason,
  Type,
}: WebAppEvent) => {
  return (
    <li className="mb-10 ms-6 last:mb-0 relative pb-5">
      <span
        className={cn(
          "absolute flex items-center justify-center w-2 h-2 rounded-full -left-7 top-1 ring-4",
          {
            "bg-primary-300 ring-primary-50 dark:ring-primary-500/10 dark:bg-primary-400":
              Type === undefined || Type === "Normal",
            "bg-orange-400 ring-orange-50 dark:ring-orange-500/10 dark:bg-orange-400":
              Type === "Warning",
          }
        )}
      />
      <div className="flex flex-col gap-1">
        {/* Reason */}
        <div className="flex items-start justify-between">
          <div className="flex items-center gap-1">
            <Typography.Title level={6} className="!text-sm !mb-0">
              {InvolvedObject.Kind}
            </Typography.Title>
            <Typography.Text className="!text-sm">
              {Reason || "No Reason Provided"}
            </Typography.Text>
          </div>
          {FirstTimestamp && (
            <div className="flex items-start gap-1">
              <Typography.Text className="!text-xs" uiType="secondary">
                {formatTimestamp(FirstTimestamp).datetime} {Type}
              </Typography.Text>
            </div>
          )}
        </div>

        {/* Message */}
        {Message && (
          <Typography.Text className="!text-xs mt-3">{Message}</Typography.Text>
        )}
      </div>
    </li>
  );
};

export default WebAppEventsTab;
