import { useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { ReactComponent as ArrowRightIcon } from "./../../../assets/icons/arrow-right-circle.svg";
import { ReactComponent as ArrowTopRightIcon } from "./../../../assets/icons/arrow-top-right-on-square.svg";
import {
  getWebAppAsync,
  restartWebAppAsync,
  selectWebApp,
  selectWebAppLoading,
  selectWebAppOverview,
  selectWebAppOverviewLoading,
  selectWebAppRestartLoading,
} from "../../../store/web-app/webAppSlice";
import { Link, useParams } from "react-router-dom";
import { WebAppScalingUrl } from "../../../utils/urls";
import {
  Tag,
  Typography,
  Flex,
  EmptyState,
  Loading,
  cn,
  Input,
  Button,
} from "djuno-design";
import { WebAppActivity } from "../../../types/web-app";
import QuestionModal from "../../modals/QuestionModal";
import {
  // selectWebAppsPlans,
  selectWebAppsActionLoading,
} from "../../../store/web-app/webAppsSlice";
import { formatTimestamp } from "../../../utils/date";
import { useTour } from "../../../providers/TourProvider";

const WebAppOverviewTab = () => {
  const [showRestartModal, setShowRestartModal] = useState<boolean>(false);
  const { webAppId } = useParams();
  const { run } = useTour("webapp-page");

  const webApp = useAppSelector(selectWebApp);
  const webAppLoading = useAppSelector(selectWebAppLoading);

  const webAppOverview = useAppSelector(selectWebAppOverview);
  const webAppOverviewLoading = useAppSelector(selectWebAppOverviewLoading);

  // const webAppPlans = useAppSelector(selectWebAppsPlans);

  const actionLoading = useAppSelector(selectWebAppsActionLoading);
  const webAppRestartLoading = useAppSelector(selectWebAppRestartLoading);

  const dispatch = useAppDispatch();

  // const plan = useMemo(() => {
  //   return webAppPlans.find((p) => p.Id === webApp?.PlanId);
  // }, [webApp?.PlanId, webAppPlans]);

  const url = useMemo(() => {
    if (webAppOverview?.Production?.Spec?.Domain) {
      return `https://${webAppOverview.Production.Spec.Domain}`;
    }
    return undefined;
  }, [webAppOverview]);

  const handleRestart = () => {
    if (webApp)
      dispatch(
        restartWebAppAsync({
          webAppId: webApp.Id.toString(),
        })
      ).then((action) => {
        if (action.type === "web-app/restart/fulfilled") {
          setShowRestartModal(false);
          dispatch(getWebAppAsync({ webAppId: webApp.Id.toString() }));
        }
      });
  };

  return (
    <>
      <Flex direction="col" className="gap-3 mb-5">
        <Flex items="center" className="gap-2 webapp-page-header">
          <Typography.Text size="base" className="mr-2">
            {webAppOverview?.Production?.Spec?.Name || ""}
          </Typography.Text>

          <Tag>Image</Tag>

          {webApp && <Tag color="processing">{webApp.PlanName}</Tag>}

          <Link
            className="cursor-pointer text-sky-500 hover:text-sky-600 upgrade-tab"
            // to={WebAppPlanUrl(webAppId)}
            to={WebAppScalingUrl(webAppId)}
          >
            <Typography.Text
              uiType="transparent"
              size="sm"
              className="font-light flex items-center gap-0.5"
            >
              Upgrade your instance
              <ArrowRightIcon className="w-4 aspect-square" />
            </Typography.Text>
          </Link>

          <div className="ml-auto flex">
            <Button
              onClick={() => {
                run();
              }}
              className="mr-3"
            >
              Start Tour
            </Button>
            {webApp?.ServiceStatus !== 1 && webApp?.ServiceStatus !== 0 && (
              <Button
                uiType="light"
                disabled={webAppLoading}
                onClick={() => setShowRestartModal(true)}
                className="restart-button"
              >
                Restart Web App
              </Button>
            )}
          </div>
        </Flex>

        {url && (
          <Flex items="center" className="gap-2">
            <Input
              value={url}
              copyable
              containerClassName="!w-full md:!w-1/2 lg:!w-1/3"
              className="webapp-page-url"
            />
            <Button
              uiType="icon"
              className="!px-2.5 new-tab-button"
              onClick={() => window.open(url)}
              tooltip={{
                content: "Open in new tab",
                className: "!text-xs",
              }}
            >
              <ArrowTopRightIcon className="w-4 h-4 font-semibold" />
            </Button>
          </Flex>
        )}
      </Flex>

      {webAppOverviewLoading && (
        <Flex items="center" justify="center" className="min-h-[300px]">
          <Loading borderSize={2} />
        </Flex>
      )}

      {!webAppOverviewLoading &&
        (!webAppOverview?.Activities?.Audit ||
          webAppOverview.Activities.Audit.length === 0) && <EmptyState />}

      {!webAppOverviewLoading && webAppOverview?.Activities?.Audit && (
        <ul className="mt-8 relative border-s border-gray-200 dark:border-gray-700 pr-4 webapp-page-actions">
          {webAppOverview.Activities.Audit.map((event, index) => (
            <WebAppActivityItem key={index} {...event} />
          ))}
        </ul>
      )}

      <QuestionModal
        title="Restart Web App"
        description={
          <Flex direction="col" className="!mt-4">
            <Typography.Text size="sm" className="mt-2 text-sm">
              Are you sure you want to restart this web service?
            </Typography.Text>
          </Flex>
        }
        isOpen={showRestartModal}
        onClose={() => setShowRestartModal(false)}
        confirmButtonType="primary"
        confirmButtonText="Yes, I'm sure"
        onConfirm={handleRestart}
        loading={actionLoading || webAppRestartLoading}
      />
    </>
  );
};

const WebAppActivityItem = ({ Action, Timestamp, Message }: WebAppActivity) => {
  return (
    <li className="mb-10 ms-6 last:mb-0 relative">
      <span
        className={cn(
          "absolute flex items-center justify-center w-2 h-2  rounded-full -left-7 top-1 ring-4  ",
          {
            "bg-primary-300 ring-primary-50 dark:ring-primary-500/10 dark:bg-primary-400":
              Action === undefined || Action === "update",
            // "bg-orange-400 ring-orange-50 dark:ring-orange-500/10 dark:bg-orange-400":
            //   type === "warning",
            "bg-green-500 ring-green-50 dark:ring-green-500/10 dark:bg-green-500":
              Action === "create",
            "bg-red-500 ring-red-50 dark:ring-red-500/20": Action === "delete",
          }
        )}
      />
      <div className="flex flex-col gap-1">
        <div className="flex items-start justify-between">
          <div className="flex items-center gap-1">
            <Typography.Title level={6} className="!text-sm !mb-0">
              Action:
            </Typography.Title>
            <Typography.Text className="!text-sm">{Action}</Typography.Text>
          </div>
          <Typography.Text
            uiType="secondary"
            className="block !text-xs font-normal leading-none"
          >
            {formatTimestamp(Timestamp).datetime}
          </Typography.Text>
        </div>
        <div className="flex items-center gap-1">
          <Typography.Title level={6} className="!text-sm !mb-0">
            Message:
          </Typography.Title>
          <Typography.Text className="!text-sm">{Message}</Typography.Text>
        </div>
        {/* {value !== "" && (
          <div className="flex items-start gap-1">
            <Typography.Title level={6} className="!text-sm !mb-0">
              Data:
            </Typography.Title>
            <JsonViewer value={JSON.parse(value)} collapsed={0} />
          </div>
        )} */}
      </div>
    </li>
  );
};

export default WebAppOverviewTab;
