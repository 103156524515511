import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  activateDappStudioAsync,
  deactivateDappStudioAsync,
  getDappStudioAsync,
  selectDappStudio,
  selectDappStudioActionLoading,
  selectDappStudioLoading,
  selectDappStudioStatus,
} from "../../../store/dapp-studio/dappStudioSlice";
import { Button, Flex, Typography } from "djuno-design";
import { DeleteModal } from "../../modals/QuestionModal";
import { useCallback, useEffect, useMemo, useState } from "react";
import useServiceType from "../../../hooks/useServiceType";
import { useDappFilesContext } from "../studio/DappStudioIDE/contexts/DappFilesContext";
import useLimitations from "../../../hooks/useLimitations";

const DappPlansTab = () => {
  const [deactivate, setDeactivate] = useState(false);

  const { serviceType } = useServiceType(
    process.env.REACT_APP_DAPP_SERVICE_TYPE
  );

  const dappStudio = useAppSelector(selectDappStudio);
  const dappStudioLoading = useAppSelector(selectDappStudioLoading);
  const dappStudioStatus = useAppSelector(selectDappStudioStatus);
  const dappStudioActionLoading = useAppSelector(selectDappStudioActionLoading);

  const dispatch = useAppDispatch();

  const { clearDappStudio } = useDappFilesContext();

  useEffect(() => {
    dispatch(getDappStudioAsync({}));
  }, [dispatch]);

  const { planPriceLimit, planPriceLimitChecker } = useLimitations({
    cases: ["PlanPrice"],
  });

  const isPlanPriceExceed = useMemo(() => {
    return planPriceLimitChecker(9.9);
  }, [planPriceLimitChecker]);

  // if (dappStudio !== null && !dappStudioStatus) {
  //   return <DappNotActiveTab />;
  // }

  const handleActivate = useCallback(() => {
    if (!dappStudioLoading && !dappStudioActionLoading)
      dispatch(activateDappStudioAsync()).then((action) => {
        if (action.type === "dapp-studio/activate/fulfilled") {
          dispatch(getDappStudioAsync({}));
        }
      });
  }, [dappStudioActionLoading, dappStudioLoading, dispatch]);

  const handleDeactivate = useCallback(() => {
    if (!dappStudioLoading && !dappStudioActionLoading)
      dispatch(deactivateDappStudioAsync()).then((action) => {
        if (action.type === "dapp-studio/deactivate/fulfilled") {
          dispatch(getDappStudioAsync({}));
          setDeactivate(false);
          clearDappStudio();
        }
      });
  }, [clearDappStudio, dappStudioActionLoading, dappStudioLoading, dispatch]);

  return (
    <div className="flex flex-col max-w-md col-span-1 rounded-2xl border-2 dark:bg-dark-3 dark:border-dark-2 mx-auto gap-5 p-4 w-full mt-10">
      <Flex direction="col" className="gap-5">
        <Flex items="center" className="flex items-center gap-2">
          <Typography.Text className="text-base font-medium">
            {serviceType?.ServiceName} basic
          </Typography.Text>
        </Flex>
        <Flex direction="col" className="gap-2">
          <Flex items="center" className="gap-1 h-8">
            <Typography.Text className="!text-2xl !font-semibold">
              $9.9
            </Typography.Text>
            <Typography.Text className="!text-xs !font-medium">
              / month
            </Typography.Text>
          </Flex>
          {/* <Typography.Text className="text-sm font-light">
            $12 per month if paid annually
          </Typography.Text> */}
        </Flex>
        {dappStudio === null && (
          <Button
            onClick={handleActivate}
            disabled={isPlanPriceExceed}
            className="!w-full !justify-center"
            uiType="primary"
            loading={dappStudioActionLoading || dappStudioLoading}
            tooltip={{
              content: isPlanPriceExceed
                ? planPriceLimit?.LimitationMessage
                : "",
              className: "!text-xs",
            }}
          >
            Activate subscription
          </Button>
        )}
        {dappStudio !== null && (
          <Button
            onClick={() => setDeactivate(true)}
            disabled={dappStudioStatus !== 2 && dappStudioStatus !== 3}
            className="!w-full !justify-center"
            uiType="light"
            loading={dappStudioActionLoading || dappStudioLoading}
          >
            Deactivate subscription
          </Button>
        )}
      </Flex>

      <DeleteModal
        isOpen={deactivate}
        title="Deactivate subscription"
        description={
          <Flex direction="col" className="mt-5 gap-2">
            <Typography.Text size="sm" className=" block">
              Are you sure?
            </Typography.Text>
            <Typography.Text size="sm" className="block">
              by deativating your ide please make sure you have a backup of your
              code
            </Typography.Text>
          </Flex>
        }
        confirmButtonText="Yes, Deactivate it!"
        confirmButtonType="danger"
        confirmString="deactivate subscription"
        loading={dappStudioActionLoading}
        onClose={() => setDeactivate(false)}
        onConfirm={handleDeactivate}
      />
    </div>
  );
};

export default DappPlansTab;
