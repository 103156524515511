import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { useNavigate, useParams } from "react-router-dom";
import {
  getDBSRolesAsync,
  selectDBSLoadsLoading,
  selectDBSRoles,
} from "../../../store/database/servicesSlice";
import {
  createDBSUserAsync,
  getDBSUsersAsync,
  selectDatabaseService,
  selectDatabaseServiceUserLoading,
  selectDatabaseServiceUsers,
  selectDatabaseServiceUsersLoading,
  updateDBSUserAsync,
} from "../../../store/database/serviceSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DBSUserSchema, NamespacesSchema } from "../../../utils/validations";
import { DatabaseUsersUrl } from "../../../utils/urls";
import { ReactComponent as RightArrow } from "./../../../assets/icons/arrow-up.svg";
import { Button, Card, Input, Select, Tag, Typography } from "djuno-design";

//for redis user inputs
export interface Inputs {
  keys?: string[];
  categories?: string[];
  commands?: string[];
  channels?: string[];
  names?: string[];
}

const UserCreateTab = () => {
  const { id } = useParams();
  const [rows, setRows] = useState<
    { id: number; selectedRoles: string | null }[]
  >([{ id: 1, selectedRoles: null }]);
  const [rowsMongo, setRowsMongo] = useState<
    { id: number; selectedRoles: string | null; dbInputValue: string }[]
  >([{ id: 1, selectedRoles: null, dbInputValue: "" }]);

  const service = useAppSelector(selectDatabaseService);
  const roles = useAppSelector(selectDBSRoles);
  const rolesLoading = useAppSelector(selectDBSLoadsLoading);
  const users = useAppSelector(selectDatabaseServiceUsers);
  const usersLoading = useAppSelector(selectDatabaseServiceUsersLoading);
  const userLoading = useAppSelector(selectDatabaseServiceUserLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userRoles = useMemo(() => {
    if (!users || users.length === 0) return [];

    // Extract initial roles, ensuring user.roles exists
    const initialRoles = users
      .map((user) => user.roles || []) // Default to an empty array if user.roles is undefined or null
      .flat()
      .map((role) => role.split("@")[0].trim());

    // Extract roles from rowsMongo
    const mongoRoles = rowsMongo
      .filter((row) => row.selectedRoles !== null)
      .map(
        (row) =>
          (row.selectedRoles && row.selectedRoles.split("@")[0].trim()) || null
      )
      .filter(Boolean); // Remove null values

    // Combine and remove duplicates
    const combinedRoles = [...initialRoles, ...mongoRoles];
    return Array.from(new Set(combinedRoles));
  }, [users, rowsMongo]);

  const usernames = useMemo(() => {
    return users.map((user) => user.username);
  }, [users]);

  const [inputs, setInputs] = useState<Inputs>({
    keys: [],
    categories: [],
    commands: [],
    channels: [],
    names: usernames,
  });

  useEffect(() => {
    if (service && users[0]?.roles) {
      dispatch(getDBSRolesAsync({ id: service.id, engine: service.engine }));
    }
  }, [dispatch, service, users]);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    trigger,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(
      DBSUserSchema(
        // [...users.map((b) => b.username)],
        inputs
      )
    ),
  });
  useEffect(() => {
    const keyValue = watch("key");
    const categoryValue = watch("category");
    const commandValue = watch("command");
    const channelValue = watch("channel");

    if (keyValue) addInput("keys");
    if (categoryValue) addInput("categories");
    if (commandValue) addInput("commands");
    if (channelValue) addInput("channels");
  }, [watch]);

  useEffect(() => {
    if (users.length === 0 && service) {
      dispatch(getDBSUsersAsync({ id: service.id, engine: service.engine }));
    }
  }, [users.length, dispatch]);

  const onSubmit = async (data: any) => {
    // console.log("userRoles:", userRoles);
    //postgressql service

    const selectedRolesArray: string[] = rows
      .map((row) => row.selectedRoles)
      .filter(
        (role): role is string =>
          typeof role === "string" && userRoles.includes(role)
      );
    // Map and filter roles for mongodb service
    const selectedRolesArrayMongo =
      users[0]?.roles &&
      rowsMongo
        .map((row) => {
          if (row.selectedRoles) {
            if (row.selectedRoles.includes("@admin")) {
              // Keep roles with "@admin" as is
              return row.selectedRoles;
            } else if (row.selectedRoles.includes("@(defined db)")) {
              // Replace @(defined db) with the user input value
              const rolePrefix = row.selectedRoles.split("@(defined db)")[0];
              const updatedRole = `${rolePrefix}@${row.dbInputValue}`;
              return updatedRole;
            }
          }
          return null;
        })
        .filter((role): role is string => {
          if (role === null) return false;
          // Normalize the role for comparison
          const normalizedRole = role.split("@")[0].trim(); // Remove any suffix after "@"
          const isIncluded = userRoles.includes(normalizedRole);

          return isIncluded;
        });

    // Debugging: Check the resulting roles array

    const formdataWithRoles = {
      name: data.name,
      roles:
        service?.engine === "mongodb"
          ? selectedRolesArrayMongo
          : selectedRolesArray,
    };

    const formdataWithGroup = { name: data.name, group: data.group };
    const formData = { name: data.name };

    const formDataForRedis = {
      name: data.name,
      categories: inputs.categories,
      channels: inputs.channels,
      commands: inputs.commands,
      keys: inputs.keys,
    };

    console.log("formDataForRedis", formDataForRedis);
    const payload =
      service?.engine === "redis"
        ? formDataForRedis //TODO
        : users[0]?.roles
        ? formdataWithRoles
        : data.group
        ? formdataWithGroup
        : formData;

    if (service && !userLoading) {
      dispatch(
        createDBSUserAsync({
          engine: service.engine,
          id: service.id,
          data: payload,
        })
      ).then((action) => {
        if (action.type === "service/user/create/fulfilled") {
          reset();
          dispatch(
            getDBSUsersAsync({ id: service.id, engine: service.engine })
          );
          navigate(DatabaseUsersUrl(service.id));
        }
      });
    }
  };

  const handleRedisSubmit = () => {
    // Get the current values of the form
    const formData = getValues();

    // Directly call onSubmit without validation
    onSubmit(formData);
  };

  const getAvailableRoles = (currentIndex: number) => {
    const selectedRoles = rowsMongo
      .map((r, index) => (index !== currentIndex ? r.selectedRoles : null))
      .filter((r): r is string => r !== null);

    return roles.filter((role) => !selectedRoles.includes(role));
  };
  //Mongodb user
  const handleAddRowMongo = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const lastRow = rowsMongo[rowsMongo.length - 1];
    if (lastRow.selectedRoles !== null) {
      const newRow = {
        id: rowsMongo.length + 1,
        selectedRoles: null,
        dbInputValue: "",
      };
      setRowsMongo([...rowsMongo, newRow]);
    }
  };

  const handleDeleteRowMongo = (
    e: React.MouseEvent<HTMLButtonElement>,
    indexToRemove: number
  ) => {
    e.stopPropagation();
    e.preventDefault();
    if (indexToRemove === 0) {
      const updatedRows = [...rowsMongo];
      updatedRows[indexToRemove].selectedRoles = null;
      setRowsMongo(updatedRows);
    } else {
      const updatedRows = rowsMongo.filter(
        (_, index) => index !== indexToRemove
      );
      setRowsMongo(updatedRows);
    }
  };

  const handleRoleChange = (v: string | null | undefined, index: number) => {
    const updatedRows = [...rowsMongo];
    updatedRows[index].selectedRoles = v || null;

    if (v) {
      if (v.includes("@admin")) {
        updatedRows[index].dbInputValue = "admin";
      } else if (v.includes("@") && !v.includes("(defined db)")) {
        const databaseName = v.split("@")[1]?.trim();
        updatedRows[index].dbInputValue = databaseName || "";
      } else {
        updatedRows[index].dbInputValue = "";
      }
    } else {
      updatedRows[index].dbInputValue = "";
    }

    setRowsMongo(updatedRows);
  };

  const handleDbInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const updatedRows = [...rowsMongo];
    updatedRows[index].dbInputValue = e.target.value;
    setRowsMongo(updatedRows);
  };

  //postgre user
  const handleAddRow = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const lastRow = rows[rows.length - 1];
    if (lastRow.selectedRoles !== null) {
      const newRow = { id: rows.length + 1, selectedRoles: null };
      setRows([...rows, newRow]);
    }
  };

  const handleDeleteRow = (
    e: React.MouseEvent<HTMLButtonElement>,
    indexToRemove: number
  ) => {
    e.stopPropagation();
    e.preventDefault();
    if (indexToRemove === 0) {
      const updatedRows = [...rows];
      updatedRows[indexToRemove].selectedRoles = null;
      setRows(updatedRows);
    } else {
      const updatedRows = rows.filter((_, index) => index !== indexToRemove);
      setRows(updatedRows);
    }
  };

  //Redis user TODO

  const addInput = async (
    type: "keys" | "categories" | "commands" | "channels"
  ) => {
    // Map input state types to form field names
    const fieldMap: Record<
      "keys" | "categories" | "commands" | "channels",
      "key" | "category" | "command" | "channel"
    > = {
      keys: "key",
      categories: "category",
      commands: "command",
      channels: "channel",
    };

    const inputField = fieldMap[type];
    const currentInput = getValues(inputField);
    const currentInputArray = inputs[type] || [];

    // If the value is empty, do nothing
    if (!currentInput?.trim()) return;

    // Trigger validation for the specific input field
    const isValid = await trigger(inputField);

    // If validation fails, exit early
    if (!isValid) return;

    // Check for duplicates and only add valid inputs
    if (!currentInputArray.includes(currentInput.trim())) {
      setInputs((prev) => ({
        ...prev,
        [type]: [...currentInputArray, currentInput.trim()],
      }));
      setValue(inputField, "");
    }
  };

  const removeInput = (
    type: "keys" | "categories" | "commands" | "channels", // Updated type names to match with the state
    index: number
  ) => {
    const currentArray = inputs[type] ?? []; // Use nullish coalescing (??) to default to an empty array
    const updatedArray = currentArray.filter((_, idx) => idx !== index); // Remove the item by index
    setInputs((prev) => ({
      ...prev,
      [type]: updatedArray, // Update the respective array in state
    }));
  };

  //edit
  const selectedUser = useMemo(() => {
    return users.find((user) => user.id === id);
  }, [id]);

  useEffect(() => {
    if (selectedUser) {
      setValue("name", selectedUser.username);
      setValue("group", selectedUser.group);
      setInputs({
        keys: selectedUser.keys || [],
        categories: selectedUser.categories || [],
        commands: selectedUser.commands || [],
        channels: selectedUser.channels || [],
      });
    } else {
      setValue("name", "");
      setValue("group", "");
    }
  }, [selectedUser, setValue]);

  useEffect(() => {
    if (selectedUser) {
      const initialRows = (
        selectedUser.roles && selectedUser.roles.length > 0
          ? selectedUser.roles
          : [{ id: 1, selectedRoles: null }]
      ).map((role, index) => {
        const roleString = typeof role === "string" ? role : null;
        let dbInputValue = "";

        if (roleString) {
          if (roleString.includes("@admin")) {
            dbInputValue = "admin";
          } else if (roleString.includes("@")) {
            const databaseName = roleString.split("@")[1]?.trim();
            dbInputValue = databaseName || "";
          }
        }

        return {
          id: index + 1,
          selectedRoles: roleString,
          dbInputValue,
        };
      });

      if (service?.engine === "postgresql") {
        setRows(initialRows);
      } else if (service?.engine === "mongodb") {
        setRowsMongo(initialRows);
      }
    }
  }, [selectedUser, service]);

  const onSubmitEdit = (data: any) => {
    if (selectedUser && service) {
      let data;

      if (service.engine === "postgresql") {
        const roles = rows
          .map((row) => row.selectedRoles)
          .filter((role) => role !== null);
        data = { roles };
      } else if (service.engine === "mongodb") {
        const roles = rowsMongo
          .map((row) => {
            if (row.selectedRoles) {
              if (row.selectedRoles.includes("@admin")) {
                return row.selectedRoles;
              }
              // Replace @(defined db) with user input
              else if (row.selectedRoles.includes("@(defined db)")) {
                const rolePrefix = row.selectedRoles.split("@(defined db)")[0];
                return `${rolePrefix}@${row.dbInputValue}`;
              }
            }
            return null;
          })
          .filter((role): role is string => role !== null);
        data = { roles };
      } else if (service.engine === "m3db") {
        data = { group: getValues("group") };
      }
      // else if (service.engine === "redis") {
      //   data = {
      //     key: inputs.keys || [],
      //     category: inputs.categories || [],
      //     command: inputs.commands || [],
      //     channel: inputs.channels || [],
      //   };
      // }

      dispatch(
        updateDBSUserAsync({
          id: service.id,
          engine: service.engine,
          userId: selectedUser.id,
          data: data,
        })
      ).then((action) => {
        if (action.type === "service/user/update/fulfilled") {
          reset();
          dispatch(
            getDBSUsersAsync({ id: service.id, engine: service.engine })
          );
          navigate(DatabaseUsersUrl(service.id));
        }
      });
    }
  };
  const onSubmitEditForRedis = () => {
    if (selectedUser && service) {
      let data;

      data = {
        keys: inputs.keys || [],
        categories: inputs.categories || [],
        commands: inputs.commands || [],
        channels: inputs.channels || [],
      };

      dispatch(
        updateDBSUserAsync({
          id: service.id,
          engine: service.engine,
          userId: selectedUser.id,
          data: data,
        })
      ).then((action) => {
        if (action.type === "service/user/update/fulfilled") {
          reset();
          dispatch(
            getDBSUsersAsync({ id: service.id, engine: service.engine })
          );
          navigate(DatabaseUsersUrl(service.id));
        }
      });
    }
  };

  console.log(errors);
  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Database</title>
        <meta name="description" content="" />
      </Helmet>

      <div>
        <div className="flex items-center justify-between">
          <div className="w-full flex flex-col gap-5">
            {service && (
              <div
                className="group px-1 items-center flex flex-1 transition-all duration-150 cursor-pointer gap-x-0.5"
                onClick={() => navigate(DatabaseUsersUrl(service.id))}
              >
                <RightArrow className="-rotate-90 w-4 h-4 transition-all duration-500 text-primary-500 group-hover:translate-x-[-4px]" />
                <Typography.Title
                  level={6}
                  className="!text-sm !text-primary-500"
                >
                  Back to “Users & Roles”
                </Typography.Title>
              </div>
            )}

            <Card title="Add user">
              <div className="flex flex-col gap-4 ">
                <form
                  className="md:w-1/2"
                  onSubmit={(e) => {
                    e.preventDefault();

                    if (service?.engine === "redis") {
                      if (selectedUser) {
                        onSubmitEditForRedis();
                      } else {
                        handleRedisSubmit();
                      }
                    } else {
                      handleSubmit(selectedUser ? onSubmitEdit : onSubmit)(e);
                    }
                  }}
                >
                  <Input
                    label="Username"
                    {...register("name")}
                    disabled={!!selectedUser}
                    error={errors.name?.message}
                  />

                  {/* Service user inclode roles*/}
                  {users[0]?.roles && (
                    <>
                      <div className="w-32 mt-8 mb-5">
                        <Typography.Text className="!font-medium">
                          Edit roles
                        </Typography.Text>
                      </div>

                      {service?.engine === "postgresql" &&
                        rows.map((row, index) => {
                          const availableRoles = getAvailableRoles(index);

                          return (
                            <div
                              key={row.id}
                              className="grid gap-3 grid-cols-12 pb-2"
                            >
                              {/* Role Selector */}
                              <div className="col-span-10 mr-1">
                                <Select
                                  loading={rolesLoading}
                                  label="Role"
                                  value={row.selectedRoles ?? undefined} // Shows existing role or empty if null
                                  onChange={(v) => {
                                    const updatedRows = [...rows];
                                    updatedRows[index].selectedRoles =
                                      v || null;
                                    setRows(updatedRows);
                                  }}
                                  options={availableRoles.map((role) => ({
                                    label: role.split("@")[0],
                                    value: role,
                                  }))}
                                />
                              </div>

                              {/* Action Buttons */}
                              <div className="col-span-2 flex items-start justify-center mt-5 gap-1">
                                {/* Delete Row Button */}
                                <Button
                                  uiType="icon"
                                  onClick={(e) => handleDeleteRow(e, index)}
                                  className="!px-2 mt-1.5"
                                  uiSize="small"
                                >
                                  <ArchiveIcon className="w-5 text-slate-700 dark:text-slate-300 hover:text-red-500 hover:dark:text-red-400" />
                                </Button>

                                {/* Add Row Button */}
                                <Button
                                  uiType="light"
                                  onClick={handleAddRow}
                                  disabled={availableRoles.length === 1}
                                  loading={usersLoading}
                                  uiSize="small"
                                  className="!px-2 mt-1.5"
                                >
                                  <PlusIcon className="w-4" />
                                </Button>
                              </div>
                            </div>
                          );
                        })}

                      {service?.engine === "mongodb" &&
                        rowsMongo.map((rowMongo, index) => {
                          const availableRoles = getAvailableRoles(index);

                          if (
                            rowMongo.selectedRoles &&
                            !availableRoles.includes(rowMongo.selectedRoles)
                          ) {
                            availableRoles.push(rowMongo.selectedRoles);
                          }

                          return (
                            <div
                              key={rowMongo.id}
                              className="grid gap-3 grid-cols-12 pb-2"
                            >
                              <div className="col-span-5 ">
                                <Select
                                  loading={rolesLoading}
                                  label="Role"
                                  value={rowMongo.selectedRoles || undefined}
                                  onChange={(v) => handleRoleChange(v, index)}
                                  options={availableRoles.map((role) => ({
                                    label: role.split("@")[0],
                                    value: role,
                                  }))}
                                  optionsClassName="!max-h-[300px]"
                                />
                              </div>
                              <div className="col-span-5 mr-3">
                                <Input
                                  label="Database"
                                  type="text"
                                  value={rowMongo.dbInputValue}
                                  onChange={(e) =>
                                    handleDbInputChange(
                                      e as React.ChangeEvent<HTMLInputElement>,
                                      index
                                    )
                                  }
                                  disabled={
                                    rowMongo.selectedRoles?.includes(
                                      "@admin"
                                    ) || false
                                  }
                                  className=""
                                />
                              </div>
                              <div className="col-span-2 flex items-start justify-center mt-5 gap-3">
                                <Button
                                  uiType="icon"
                                  onClick={(e) =>
                                    handleDeleteRowMongo(e, index)
                                  }
                                  disabled={false}
                                  className="!px-2 mt-1.5"
                                  uiSize="small"
                                >
                                  <ArchiveIcon className="w-5 text-slate-700 dark:text-slate-300 hover:text-red-500 hover:dark:text-red-400" />
                                </Button>
                                <Button
                                  uiType="light"
                                  onClick={handleAddRowMongo}
                                  disabled={availableRoles.length === 1}
                                  className="!px-2 mt-1.5"
                                  uiSize="small"
                                  loading={usersLoading}
                                >
                                  <PlusIcon className="w-4" />
                                </Button>
                              </div>
                            </div>
                          );
                        })}

                      <div className="mt-1"></div>
                    </>
                  )}
                  {/* M3db service */}
                  {service?.engine === "m3db" && (
                    <div className="mt-5">
                      <div className="col-span-10 mt-5">
                        <Typography.Text className="!text-sm !font-medium">
                          Group
                        </Typography.Text>
                      </div>
                      <Input
                        {...register("group")}
                        error={errors.group?.message}
                      />
                    </div>
                  )}

                  {/* Redis service */}
                  {service?.engine === "redis" && (
                    <>
                      {/* Keys Input */}
                      <div className="flex items-center gap-2 mt-5">
                        <Input
                          placeholder="Add Key"
                          {...register("key")}
                          error={errors.key?.message}
                          containerClassName="w-full"
                        />

                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            addInput("keys");
                          }}
                          uiSize="small"
                          className="!px-2 mt-1.5 ml-2"
                        >
                          <PlusIcon className="w-4" />
                        </Button>
                      </div>
                      <div className=" flex-wrap gap-2 py-2 ">
                        {(inputs.keys || []).map((key, index) => (
                          <Tag
                            key={index}
                            closable
                            onClose={() => removeInput("keys", index)}
                            color="processing"
                            className="mr-1"
                          >
                            {key}
                          </Tag>
                        ))}
                      </div>

                      {/* Categories Input */}
                      <div className="flex items-center gap-2">
                        <Input
                          placeholder="Add Category"
                          {...register("category")}
                          error={errors.category?.message}
                          containerClassName="w-full"
                        />

                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            addInput("categories");
                          }}
                          uiSize="small"
                          className="!px-2 mt-1.5 ml-2"
                        >
                          <PlusIcon className="w-4" />
                        </Button>
                      </div>
                      <div className=" flex-wrap gap-2 py-2 ">
                        {(inputs.categories || []).map((category, index) => (
                          <Tag
                            key={index}
                            closable
                            onClose={() => removeInput("categories", index)}
                            color="processing"
                            className="mr-1"
                          >
                            {category}
                          </Tag>
                        ))}
                      </div>
                      {/* Commands Input */}
                      <div className="flex items-center gap-2">
                        <Input
                          placeholder="Add Command"
                          {...register("command")}
                          error={errors.command?.message}
                          containerClassName="w-full"
                        />
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            addInput("commands");
                          }}
                          uiSize="small"
                          className="!px-2 mt-1.5 ml-2"
                        >
                          <PlusIcon className="w-4" />
                        </Button>
                      </div>
                      <div className=" flex-wrap gap-2 py-2 ">
                        {(inputs.commands || []).map((command, index) => (
                          <Tag
                            key={index}
                            closable
                            onClose={() => removeInput("commands", index)}
                            color="processing"
                            className="mr-1"
                          >
                            {command}
                          </Tag>
                        ))}
                      </div>
                      {/* Channels Input */}
                      <div className="flex items-center gap-2">
                        <Input
                          placeholder="Add Channel"
                          {...register("channel")}
                          error={errors.channel?.message}
                          containerClassName="w-full"
                        />
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            addInput("channels");
                          }}
                          uiSize="small"
                          className="!px-2 mt-1.5 ml-2"
                        >
                          <PlusIcon className="w-4" />
                        </Button>
                      </div>
                      <div className=" flex-wrap gap-2 py-2 ">
                        {(inputs.channels || []).map((channel, index) => (
                          <Tag
                            key={index}
                            closable
                            onClose={() => removeInput("channels", index)}
                            color="processing"
                            className="mr-1"
                          >
                            {channel}
                          </Tag>
                        ))}
                      </div>
                    </>
                  )}
                  <div className="mt-4 flex gap-5">
                    <Button
                      uiType="primary"
                      type="submit"
                      loading={userLoading}
                    >
                      {selectedUser ? "Edit" : "Create User"}
                    </Button>
                  </div>
                </form>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserCreateTab;
