import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { ReactComponent as EditIcon } from "./../../../assets/icons/pencil-square.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as DetailsIcon } from "./../../../assets/icons/bars-3-bottom-left.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { useSearch } from "../../../providers/SearchProvider";
import {
  Typography,
  SimpleTable,
  EmptyState,
  Button,
  Dropdown,
  SecureCopy,
  Tag,
  Flex,
  Tabs,
  TabOptions,
} from "djuno-design";
import {
  getDomainsAysnc,
  selectDomains,
  selectDomainsLoading,
} from "../../../store/domains/domainsSlice";
import { Domain } from "../../../types/domains";
import HighlighterText from "../../general/HighlighterText";
import { useNavigate } from "react-router-dom";
import { DomainUrl } from "../../../utils/urls";

const categories: TabOptions = [
  { label: "All" },
  { label: "For Sale" },
  { label: "Sold" },
];

const DomainsTab = () => {
  // const [deleteApiKeyId, setDeleteApiKeyId] = useState<number | null>(null);

  const domains = useAppSelector(selectDomains);
  const loading = useAppSelector(selectDomainsLoading);

  const [filteredDomains, setFilteredDomains] = useState<Domain[]>([]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { value: searchValue } = useSearch();

  const [categoryIndex, setCategoryIndex] = useState(0);
  const selectedCategory = useMemo(() => {
    return categories[categoryIndex];
  }, [categoryIndex]);

  useEffect(() => {
    // dispatch(getDomainsAysnc());
  }, [dispatch]);

  useEffect(() => {
    const lookedUpFiles = domains?.filter((domain) =>
      domain.Name?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredDomains(lookedUpFiles);
  }, [dispatch, searchValue, domains]);

  return (
    <>
      <div className="flex justify-end transition-all duration-500">
        <Tabs
          tabType="creamy"
          options={categories}
          selectedIndex={categoryIndex}
          onChange={({ index }) => setCategoryIndex(index || 0)}
        />
      </div>
      <div className="mt-6">
        <SimpleTable loading={loading} containerClassName="min-h-[240px]">
          <SimpleTable.Head>
            <SimpleTable.Row>
              <SimpleTable.TH lable="Name" />
              <SimpleTable.TH lable="Expiry" />
              <SimpleTable.TH lable="Purchase" />
              <SimpleTable.TH lable="For Sale" />
              <SimpleTable.TH lable="Wallet" />
              <SimpleTable.TH lable="Tags" />
              <SimpleTable.TH lable="" />
            </SimpleTable.Row>
          </SimpleTable.Head>
          <SimpleTable.Body>
            {filteredDomains.map((domain, index) => (
              <SimpleTable.Row
                key={index}
                onClick={() => navigate(DomainUrl(domain.Id))}
              >
                <SimpleTable.TD className="w-36">
                  <HighlighterText
                    className="max-w-[110px] md:max-w-[400px] lg:max-w-[350px] xl:max-w-[400px] truncate"
                    searchValue={searchValue}
                    textToHighlight={domain.Name}
                  />
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm whitespace-nowrap">
                    {domain.Expiry}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm whitespace-nowrap">
                    {domain.Purchase}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm whitespace-nowrap">
                    {domain.ForSale}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <div
                    className="text-sm dark:text-slate-200"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <SecureCopy
                      text={domain.Wallet}
                      type="hide"
                      textClassName="!text-sm"
                      uiSize="small"
                    />
                  </div>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Flex className="gap-1">
                    {domain.Tags.map((tag, i) => (
                      <Tag key={i}>{tag}</Tag>
                    ))}
                  </Flex>
                </SimpleTable.TD>
                <SimpleTable.TD className="w-10">
                  <div className="w-8 flex justify-center items-center">
                    <Dropdown
                      anchor="bottom end"
                      itemsClassName="!w-40"
                      menu={[
                        {
                          key: "1",
                          label: (
                            <div className="flex items-center gap-1">
                              <DetailsIcon className="w-4" />
                              Get details
                            </div>
                          ),
                          onClick: (_, close) => {
                            close();
                            navigate(DomainUrl(domain.Id));
                          },
                          disabled: true,
                        },
                        {
                          key: "1",
                          label: (
                            <div className="flex items-center gap-1">
                              <EditIcon className="w-4" />
                              Edit
                            </div>
                          ),
                          onClick: (_, close) => {
                            close();
                            // dispatch(
                            //   handleShowWalletApiEditorModal({ apiKey })
                            // );
                          },
                          disabled: true,
                        },
                        {
                          type: "divider",
                        },
                        {
                          key: "end",
                          label: (
                            <div className="flex items-center gap-1">
                              <ArchiveIcon className="w-4" />
                              Delete
                            </div>
                          ),
                          danger: true,
                          onClick: (_, close) => {
                            close();
                            // setDeleteApiKeyId(apiKey.Id);
                          },
                          disabled: true,
                        },
                      ]}
                    >
                      <Button uiSize="small" uiType="icon" className="!px-2">
                        <MoreIcon className="w-4 h-4" />
                      </Button>
                    </Dropdown>
                  </div>
                </SimpleTable.TD>
              </SimpleTable.Row>
            ))}
            {filteredDomains.length === 0 && (
              <SimpleTable.Row withoutHoverStyle={true} className="h-[200px]">
                <SimpleTable.TD colSpan={7} className="!border-0">
                  <EmptyState text="No domains found" />
                </SimpleTable.TD>
              </SimpleTable.Row>
            )}
          </SimpleTable.Body>
        </SimpleTable>
      </div>

      {/* <DeleteModal
        title="Delete Access key"
        isOpen={deleteApiKeyId !== null}
        onClose={() => setDeleteApiKeyId(null)}
        description=""
        confirmButtonText="Confirm"
        confirmButtonType="danger"
        confirmButtonClassName="w-full"
        onConfirm={() => {
          deleteApiKeyId &&
            dispatch(deleteWalletApiApiKeyAsync({ id: deleteApiKeyId })).then(
              (action) => {
                if (action.type === "wallet-api/api-keys/delete/fulfilled") {
                  dispatch(getWalletApiApiKeysAysnc());
                }
                setDeleteApiKeyId(null);
              }
            );
        }}
        loading={actionLoading}
        // confirmString={apiKeys.find((api) => api.Id === deleteApiKeyId)?.Label}
      /> */}
    </>
  );
};

export default DomainsTab;
