import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  bootInstanceAsync,
  getInstanceAsync,
  handleToggleInstanceBootModal,
  selectInstance,
  selectInstanceLoading,
  selectShowBootModal,
} from "../../../../store/instances/instanceSlice";
import { getInstancesAsync } from "../../../../store/instances/instancesSlice";
import { Button, Flex, Modal, Typography } from "djuno-design";
import { useCallback, useEffect } from "react";

const InstanceBootModal = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectShowBootModal);
  const loading = useAppSelector(selectInstanceLoading);
  const instance = useAppSelector(selectInstance);

  const {
    handleSubmit,
    formState: { isValid },
  } = useForm({
    shouldUnregister: true,
    reValidateMode: "onChange",
  });

  const onSubmit = (data: any) => {
    if (instance) {
      dispatch(bootInstanceAsync({ instanceId: instance?.id, data })).then(
        (action) => {
          if (action.type === "instance/boot/fulfilled") {
            dispatch(getInstancesAsync({ withoutLoading: false }));
            dispatch(getInstanceAsync({ instanceId: instance.id }));
            handleOnClose();
          }
        }
      );
    }
  };

  const handleOnClose = useCallback(() => {
    dispatch(handleToggleInstanceBootModal(false));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      contentClassName="max-w-lg"
      //containerClassName="!items-start"
      title="Stop your instance"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5">
          <Typography.Text className="!text-sm mb-5">
            You are about to boot your {instance?.name} instance.
          </Typography.Text>
        </div>

        <Flex justify="end" className="mt-4 gap-2">
          <Button uiType="light" onClick={handleOnClose}>
            Cancel
          </Button>
          <Button
            uiType="primary"
            type="submit"
            disabled={!isValid}
            loading={loading}
          >
            Confirm
          </Button>
        </Flex>
      </form>
    </Modal>
  );
};

export default InstanceBootModal;
