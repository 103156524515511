import { useForm } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  getInstancesAsync,
  getInstancesImagesAsync,
  selectInstancesImages,
  selectInstancesImagesLoading,
} from "../../../../store/instances/instancesSlice";
import {
  getInstanceAsync,
  handleToggleInstanceRebootModal,
  rescueModeInstanceAsync,
  selectInstance,
  selectInstanceLoading,
  selectShowRebootModal,
} from "../../../../store/instances/instanceSlice";
import { Button, Flex, Modal, Select, Typography } from "djuno-design";

const InstanceRebootInRescueModeModal = () => {
  const [instanceImage, setInstanceImage] = useState<string | undefined>();
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectShowRebootModal);
  const loading = useAppSelector(selectInstanceLoading);
  const instance = useAppSelector(selectInstance);
  const images = useAppSelector(selectInstancesImages);
  const imagesLoading = useAppSelector(selectInstancesImagesLoading);

  const {
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm({
    shouldUnregister: true,
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (isOpen && instance && instance.image) {
      dispatch(getInstancesImagesAsync({ region: instance.image.region }));
    }
  }, [isOpen, instance, dispatch]);

  useEffect(() => {
    if (isOpen && instance && instance.image) {
      setInstanceImage(instance.image.id);
    }
  }, [isOpen, instance, setInstanceImage]);

  const onSubmit = (data: any) => {
    if (instance) {
      const formData = {
        instanceId: instance.id,
        imageId: instanceImage,
        rescue: true,
      };
      dispatch(
        rescueModeInstanceAsync({ instanceId: instance?.id, data: formData })
      ).then((action) => {
        if (action.type === "instance/rescue/fulfilled") {
          dispatch(getInstancesAsync({ withoutLoading: false }));
          dispatch(getInstanceAsync({ instanceId: instance.id }));
          handleOnClose();
        }
      });
    }
  };

  const handleOnClose = useCallback(() => {
    reset();
    dispatch(handleToggleInstanceRebootModal(false));
  }, [dispatch, reset]);

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      contentClassName="max-w-lg"
      title="Reboot in rescue mode"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5">
          <Flex direction="col" className="mb-6">
            <Typography.Text className="!text-sm mb-1">
              You are about to restart your instance in rescue mode.
            </Typography.Text>
            {/* <Typography.Text className="!text-sm">
              If you select “Made-in-OVH Rescue Distribution”, you will get a
              temporary password which you can use to connect to your instance
              in rescue mode. Otherwise, you can access your instance with your
              regular SSH key, but the instance's SSH fingerprint will have
              changed. The main drive will be available as a secondary unmounted
              disk.
            </Typography.Text> */}
          </Flex>
          <Select
            label="My image:"
            value={instanceImage}
            onChange={(v) => setInstanceImage(v)}
            options={
              images.map((image) => ({
                label: image.name,
                value: image.id,
              })) || []
            }
            // className="w-60 "
            loading={imagesLoading}
          />
        </div>

        <div className="mt-4 flex justify-end gap-2">
          <Button uiType="light" onClick={handleOnClose}>
            Cancel
          </Button>
          <Button
            uiType="primary"
            type="submit"
            disabled={!isValid}
            loading={loading}
          >
            Restart
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default InstanceRebootInRescueModeModal;
