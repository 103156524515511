import { Controller, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  getInstanceAsync,
  handleToggleInstanceVolumeModal,
  instancesAttachVolumeAsync,
  selectInstance,
  selectInstanceActionLoading,
  selectShowVolumeModal,
} from "../../../../store/instances/instanceSlice";
import { getInstancesAsync } from "../../../../store/instances/instancesSlice";
import {
  getInstancesVolumesAsync,
  selectInstancesVolumes,
  selectInstancesVolumesLoading,
} from "../../../../store/instances/instancesVolumesSlice";
import { useCallback, useEffect, useMemo, useState } from "react";
import { InstanceVolume } from "../../../../types/instance";
import { ReactComponent as ErrorIcon } from "./../../../assets/icons/logs/error.svg";
import { yupResolver } from "@hookform/resolvers/yup";
import { InstanceAttachVolumeSchema } from "../../../../utils/validations";
import { Alert, Button, Modal, Select } from "djuno-design";

const InstanceVolumeModal = () => {
  const [instanceVolume, setInstanceVolume] = useState<InstanceVolume | null>(
    null
  );
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectShowVolumeModal);
  const volumes = useAppSelector(selectInstancesVolumes);
  const volumesLoading = useAppSelector(selectInstancesVolumesLoading);
  const instance = useAppSelector(selectInstance);
  const instanceActionLoading = useAppSelector(selectInstanceActionLoading);

  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(InstanceAttachVolumeSchema),
    shouldUnregister: true,
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (isOpen && volumes.length) {
      dispatch(getInstancesVolumesAsync({}));
    }
  }, [dispatch, isOpen, volumes.length]);

  const filterVolumes = useMemo(() => {
    return volumes.filter(
      (volume) =>
        volume.region === instance?.region && volume.attachedTo.length === 0
    );
  }, [volumes, instance]);

  const onSubmit = (data: any) => {
    if (instanceVolume && instance && !instanceActionLoading) {
      const formData = { instanceId: instance.id };
      dispatch(
        instancesAttachVolumeAsync({
          volumeId: instanceVolume?.id,
          data: formData,
        })
      ).then((action) => {
        if (action.type === "instance/volume/attach/fulfilled") {
          dispatch(getInstancesAsync({ withoutLoading: false }));
          dispatch(getInstanceAsync({ instanceId: instance.id }));
          dispatch(getInstancesVolumesAsync({}));
          handleOnClose();
        }
      });
    }
  };

  const handleVolumeChange = (volumeId: string) => {
    const selectedVolume = filterVolumes.find((v) => v.id === volumeId) || null;
    setInstanceVolume(selectedVolume);
  };

  const handleOnClose = useCallback(() => {
    reset();
    dispatch(handleToggleInstanceVolumeModal(false));
  }, [dispatch, reset]);

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      contentClassName="max-w-lg"
      title="Attach a volume to your instance"
    >
      {filterVolumes.length > 0 ? (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-5">
              {volumes && (
                <Controller
                  control={control}
                  name="instanceId"
                  render={({ field: { value, onChange } }) => (
                    <Select
                      value={value?.toString()}
                      label="Volume"
                      options={filterVolumes.map((v) => ({
                        label: v.name,
                        value: v.id,
                      }))}
                      error={errors.instanceId?.message}
                      required
                      onChange={(value) => {
                        onChange(value);
                        if (value) {
                          handleVolumeChange(value);
                        }
                      }}
                      loading={volumesLoading}
                    />
                  )}
                />
              )}
            </div>

            <div className="mt-4 flex justify-end gap-2">
              <Button uiType="light" onClick={handleOnClose}>
                Cancel
              </Button>
              <Button
                uiType="primary"
                type="submit"
                //disabled: !isValid
                loading={instanceActionLoading}
              >
                Attach
              </Button>
            </div>
          </form>
        </>
      ) : (
        <Alert uiType="warning" className="text-xs my-5" showIcon>
          No volumes available in your instance’s region.
        </Alert>
      )}
    </Modal>
  );
};

export default InstanceVolumeModal;
