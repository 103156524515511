import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from ".";
import {
  getMeAsync,
  selectLimitationsEnum,
  selectLimitationsEnumLoading,
  selectOnStageEnv,
  selectUser,
  selectUserLoading,
} from "../store/auth/authSlice";
import { selectEnvironments } from "../store/environments/environmentsSlice";
import { IPlan } from "../types/billing";

type LimitationCase =
  | "PlanPrice"
  | "NumberOfEnvironment"
  | "NumberOfWebApps"
  | "NumberOfBlockChains"
  | "Cpu"
  | "Memory";

export type ServiceReplicaWithPlan = {
  replicaCounts: number;
  plan: IPlan;
};

const useLimitations = (props: {
  cases?: LimitationCase[];
  existingServices?: ServiceReplicaWithPlan[];
}) => {
  const cases = props.cases;

  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);
  const env = useAppSelector(selectOnStageEnv);
  const userLoading = useAppSelector(selectUserLoading);

  const allEnvs = useAppSelector(selectEnvironments);

  const limitsEnum = useAppSelector(selectLimitationsEnum);
  const limitsEnumLoading = useAppSelector(selectLimitationsEnumLoading);

  const refreshLimits = useCallback(() => {
    dispatch(getMeAsync({ withoutLoading: true }));
  }, [dispatch]);

  const loading = useMemo(() => userLoading, [userLoading]);

  // limits
  const accountLimits = useMemo(() => {
    return user?.AccountLimitations || [];
  }, [user?.AccountLimitations]);

  const envLimits = useMemo(() => {
    return env?.EnvironmentLimitations || [];
  }, [env?.EnvironmentLimitations]);

  const prevServicesData = useMemo(() => {
    let prevCpu = 0;
    let prevMemory = 0;
    let prevDisk = 0;

    if (props.existingServices) {
      for (const existingService of props.existingServices) {
        const { replicaCounts, plan } = existingService;
        const { CPU, RAM, DISK } = JSON.parse(plan.PlanDescription || "{}");
        if (CPU !== undefined) {
          prevCpu += replicaCounts * Number(CPU);
        }
        if (RAM !== undefined) {
          prevMemory += replicaCounts * parseFloat(RAM.replace("Gi", ""));
        }
        if (DISK !== undefined) {
          prevDisk += replicaCounts * parseFloat(DISK.replace("Gi", ""));
        }
      }
    }

    return { prevCpu, prevMemory, prevDisk };
  }, [props.existingServices]);

  // plan price settings
  const planPriceLimitEnum = useMemo(() => {
    return limitsEnum.find((e) => e.Text === "PlanPrice");
  }, [limitsEnum]);

  const planPriceLimit = useMemo(() => {
    if (
      !limitsEnumLoading &&
      planPriceLimitEnum &&
      cases?.includes("PlanPrice")
    ) {
      const accountPlanPriceLimits = accountLimits.filter(
        (l) => l.LimitationType === planPriceLimitEnum.Value
      );
      const envPlanPriceLimits = envLimits.filter(
        (l) => l.LimitationType === planPriceLimitEnum.Value
      );
      const planPriceLimits = [
        ...accountPlanPriceLimits,
        ...envPlanPriceLimits,
      ];

      if (planPriceLimits.length === 0) {
        return null;
      }

      return planPriceLimits.reduce((min, current) =>
        current.LimitationValue < min.LimitationValue ? current : min
      );
    }
    return null;
  }, [limitsEnumLoading, planPriceLimitEnum, cases, accountLimits, envLimits]);

  const planPriceLimitChecker = useCallback(
    (price: number | undefined, replicaCounts: number = 1) => {
      // exceeded or not
      if (planPriceLimit && price) {
        return price * replicaCounts > planPriceLimit.LimitationValue;
      }
      return false;
    },
    [planPriceLimit]
  );

  // number of environment settings
  const numberOfEnvironmentLimitEnum = useMemo(() => {
    return limitsEnum.find((e) => e.Text === "NumberOfEnvironment");
  }, [limitsEnum]);

  const numberOfEnvironmentLimit = useMemo(() => {
    if (
      !limitsEnumLoading &&
      numberOfEnvironmentLimitEnum &&
      cases?.includes("NumberOfEnvironment")
    ) {
      const accountEnvsLimits = accountLimits.filter(
        (l) => l.LimitationType === numberOfEnvironmentLimitEnum.Value
      );
      const envEnvsLimits = envLimits.filter(
        (l) => l.LimitationType === numberOfEnvironmentLimitEnum.Value
      );
      const numberOfEnvironmentsLimits = [
        ...accountEnvsLimits,
        ...envEnvsLimits,
      ];

      if (numberOfEnvironmentsLimits.length === 0) {
        return null;
      }

      return numberOfEnvironmentsLimits.reduce((min, current) =>
        current.LimitationValue < min.LimitationValue ? current : min
      );
    }
    return null;
  }, [
    accountLimits,
    cases,
    envLimits,
    limitsEnumLoading,
    numberOfEnvironmentLimitEnum,
  ]);

  const numberOfEnvironmentLimitChecker = useCallback(() => {
    // exceeded or not
    if (numberOfEnvironmentLimit) {
      return allEnvs.length >= numberOfEnvironmentLimit.LimitationValue;
    }
    return false;
  }, [numberOfEnvironmentLimit, allEnvs]);

  // number of web-app settings
  const numberOfWebAppLimitEnum = useMemo(() => {
    return limitsEnum.find((e) => e.Text === "NumberOfWebApps");
  }, [limitsEnum]);

  const numberOfWebAppsLimit = useMemo(() => {
    if (
      !limitsEnumLoading &&
      numberOfWebAppLimitEnum &&
      cases?.includes("NumberOfWebApps")
    ) {
      const accountWebAppsLimits = accountLimits.filter(
        (l) => l.LimitationType === numberOfWebAppLimitEnum.Value
      );
      const envWebAppsLimits = envLimits.filter(
        (l) => l.LimitationType === numberOfWebAppLimitEnum.Value
      );
      const numberOfWebAppsLimits = [
        ...accountWebAppsLimits,
        ...envWebAppsLimits,
      ];

      if (numberOfWebAppsLimits.length === 0) {
        return null;
      }

      return numberOfWebAppsLimits.reduce((min, current) =>
        current.LimitationValue < min.LimitationValue ? current : min
      );
    }
    return null;
  }, [
    accountLimits,
    cases,
    envLimits,
    limitsEnumLoading,
    numberOfWebAppLimitEnum,
  ]);

  const numberOfWebAppsLimitChecker = useCallback(
    (webAppsLength: number) => {
      // exceeded or not
      if (numberOfWebAppsLimit) {
        return webAppsLength >= numberOfWebAppsLimit.LimitationValue;
      }
      return false;
    },
    [numberOfWebAppsLimit]
  );

  // number of blockchain settings
  const numberOfBlockchainLimitEnum = useMemo(() => {
    return limitsEnum.find((e) => e.Text === "NumberOfBlockChains");
  }, [limitsEnum]);

  const numberOfBlockchainsLimit = useMemo(() => {
    if (
      !limitsEnumLoading &&
      numberOfBlockchainLimitEnum &&
      cases?.includes("NumberOfBlockChains")
    ) {
      const accountWebAppsLimits = accountLimits.filter(
        (l) => l.LimitationType === numberOfBlockchainLimitEnum.Value
      );
      const envWebAppsLimits = envLimits.filter(
        (l) => l.LimitationType === numberOfBlockchainLimitEnum.Value
      );
      const numberOfBlockchainsLimits = [
        ...accountWebAppsLimits,
        ...envWebAppsLimits,
      ];

      if (numberOfBlockchainsLimits.length === 0) {
        return null;
      }

      return numberOfBlockchainsLimits.reduce((min, current) =>
        current.LimitationValue < min.LimitationValue ? current : min
      );
    }
    return null;
  }, [
    accountLimits,
    cases,
    envLimits,
    limitsEnumLoading,
    numberOfBlockchainLimitEnum,
  ]);

  const numberOfBlockchainsLimitChecker = useCallback(
    (blockchainsLength: number) => {
      // exceeded or not
      if (numberOfBlockchainsLimit) {
        return blockchainsLength >= numberOfBlockchainsLimit.LimitationValue;
      }
      return false;
    },
    [numberOfBlockchainsLimit]
  );

  // amount of CPU settings
  const amountOfCpuLimitEnum = useMemo(() => {
    return limitsEnum.find((e) => e.Text === "Cpu");
  }, [limitsEnum]);

  const amountOfCpuLimit = useMemo(() => {
    if (!limitsEnumLoading && amountOfCpuLimitEnum && cases?.includes("Cpu")) {
      const accountCpuLimits = accountLimits.filter(
        (l) => l.LimitationType === amountOfCpuLimitEnum.Value
      );
      const envCpuLimits = envLimits.filter(
        (l) => l.LimitationType === amountOfCpuLimitEnum.Value
      );
      const amountOfCpuLimits = [...accountCpuLimits, ...envCpuLimits];

      if (amountOfCpuLimits.length === 0) {
        return null;
      }

      return amountOfCpuLimits.reduce((min, current) =>
        current.LimitationValue < min.LimitationValue ? current : min
      );
    }
    return null;
  }, [
    accountLimits,
    amountOfCpuLimitEnum,
    cases,
    envLimits,
    limitsEnumLoading,
  ]);

  const amountOfCpuLimitChecker = useCallback(
    (Cpu: string | number | undefined, replicaCounts: number = 1) => {
      if (Cpu === undefined) return false;
      const cpu = Number(Cpu);

      // exceeded or not
      if (amountOfCpuLimit) {
        return (
          prevServicesData.prevCpu + cpu * replicaCounts >
          amountOfCpuLimit.LimitationValue
        );
      }
      return false;
    },
    [amountOfCpuLimit, prevServicesData.prevCpu]
  );

  // amount of Memory settings
  const amountOfMemoryLimitEnum = useMemo(() => {
    return limitsEnum.find((e) => e.Text === "Memory");
  }, [limitsEnum]);

  const amountOfMemoryLimit = useMemo(() => {
    if (
      !limitsEnumLoading &&
      amountOfMemoryLimitEnum &&
      cases?.includes("Memory")
    ) {
      const accountMemoryLimits = accountLimits.filter(
        (l) => l.LimitationType === amountOfMemoryLimitEnum.Value
      );
      const envMemoryLimits = envLimits.filter(
        (l) => l.LimitationType === amountOfMemoryLimitEnum.Value
      );
      const amountOfMemoryLimits = [...accountMemoryLimits, ...envMemoryLimits];

      if (amountOfMemoryLimits.length === 0) {
        return null;
      }

      return amountOfMemoryLimits.reduce((min, current) =>
        current.LimitationValue < min.LimitationValue ? current : min
      );
    }
    return null;
  }, [
    accountLimits,
    amountOfMemoryLimitEnum,
    cases,
    envLimits,
    limitsEnumLoading,
  ]);

  const amountOfMemoryLimitChecker = useCallback(
    (Memory: string | undefined, replicaCounts: number = 1) => {
      if (Memory === undefined) return false;

      const memory = parseFloat(Memory.replace("Gi", ""));

      // exceeded or not
      if (amountOfMemoryLimit && memory) {
        return (
          prevServicesData.prevMemory + memory * replicaCounts >
          amountOfMemoryLimit.LimitationValue
        );
      }
      return false;
    },
    [amountOfMemoryLimit, prevServicesData.prevMemory]
  );

  return {
    loading,
    refreshLimits,

    // plan price
    planPriceLimit,
    planPriceLimitChecker,

    // number of envs
    numberOfEnvironmentLimit,
    numberOfEnvironmentLimitChecker,

    // number of web-apps
    numberOfWebAppsLimit,
    numberOfWebAppsLimitChecker,

    // number of blockchains
    numberOfBlockchainsLimit,
    numberOfBlockchainsLimitChecker,

    // amount of cpu
    amountOfCpuLimit,
    amountOfCpuLimitChecker,

    // amount of memory
    amountOfMemoryLimit,
    amountOfMemoryLimitChecker,
  };
};

export default useLimitations;
