import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { S3RetentionSchema } from "../../../../../utils/validations";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import {
  selectBucketDetails,
  selectShowRetentionEditor,
  selectBucketRetentionLoading,
  selectBucketRetention,
  handleHideRetentionEditor,
  setBucketRetentionAsync,
  getBucketRetentionAsync,
} from "../../../../../store/s3/buckets/bucketSlice";
import RadioGrouper, {
  RadioGroupeItem,
} from "../../../../general/RadioGrouper";
import { useCallback, useEffect, useState } from "react";
import {
  S3BucketRetentionMode,
  S3BucketRetentionUnit,
} from "../../../../../types/s3-bucket";
import { Button, Input, Modal, SelectOption, Typography } from "djuno-design";

export const validityOptions: RadioGroupeItem<S3BucketRetentionUnit>[] = [
  { label: "Days", value: "days" },
  { label: "Years", value: "years" },
];

export const modeOptions: RadioGroupeItem<S3BucketRetentionMode>[] = [
  { label: "Compliance", value: "compliance" },
  { label: "Governance", value: "governance" },
];

const S3RetentionEditorModal = () => {
  const isOpen = useAppSelector(selectShowRetentionEditor);
  const bucketRetention = useAppSelector(selectBucketRetention);
  const retentionLoading = useAppSelector(selectBucketRetentionLoading);
  const bucketDetails = useAppSelector(selectBucketDetails);

  const [selectedMode, setSelectedMode] = useState<
    RadioGroupeItem<S3BucketRetentionMode>
  >(modeOptions[0]);
  const [selectedValidityOption, setSelectedValidityOption] = useState<
    RadioGroupeItem<S3BucketRetentionUnit>
  >(validityOptions[0]);
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(S3RetentionSchema),
  });

  const handleClearComponent = useCallback(() => {
    setSelectedMode(modeOptions[0]);
    setSelectedValidityOption(validityOptions[0]);
    reset();
  }, [reset]);

  useEffect(() => {
    if (isOpen && bucketDetails && bucketRetention) {
      setValue("validity", bucketRetention.validity);
      const selectedModeOption = modeOptions.find(
        (mo) => mo.value === bucketRetention.mode
      );
      const selectedUnit = validityOptions.find(
        (vo) => vo.value === bucketRetention.unit
      );
      selectedModeOption && setSelectedMode(selectedModeOption);
      selectedUnit && setSelectedValidityOption(selectedUnit);
    }
  }, [bucketDetails, bucketRetention, isOpen, setValue]);

  const onSubmit = (data: any) => {
    //data:
    //  validity
    //others:
    // selectedMode
    // selectedValidityOption
    // console.log(data);
    if (bucketDetails) {
      dispatch(
        setBucketRetentionAsync({
          name: bucketDetails.name,
          formData: {
            mode: selectedMode.value,
            unit: selectedValidityOption.value,
            validity: data.validity,
          },
        })
      ).then((action) => {
        if (action.type === "bucket/retention/set/fulfilled") {
          handleClearComponent();
          dispatch(handleHideRetentionEditor());
          dispatch(getBucketRetentionAsync({ name: bucketDetails.name }));
        }
      });
    }
  };

  const handleOnClose = useCallback(() => {
    dispatch(handleHideRetentionEditor());
    handleClearComponent();
  }, [dispatch, handleClearComponent]);

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      contentClassName="max-w-lg"
      title={"Set Retention Configuration"}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-10 flex flex-col w-full gap-5">
          <div className="flex gap-5 justify-between items-center">
            <Typography.Text className="!text-sm font-semibold">
              Retention Mode
            </Typography.Text>
            <div className="">
              <RadioGrouper
                items={modeOptions}
                selected={selectedMode}
                setSelected={setSelectedMode}
              />
            </div>
          </div>

          <div className="flex gap-5 justify-between items-center">
            <Typography.Text className="!text-sm font-semibold">
              Retention Unit
            </Typography.Text>
            <div className="">
              <RadioGrouper
                items={validityOptions}
                selected={selectedValidityOption}
                setSelected={setSelectedValidityOption}
              />
            </div>
          </div>
          <div className="flex gap-5 justify-between items-center">
            <Typography.Text className="!text-sm font-semibold">
              Retention Validity
            </Typography.Text>
            <div className="flex-1">
              <Input
                {...register("validity")}
                error={errors.validity?.message}
              />
            </div>
          </div>
        </div>
        <div className="mt-4 flex justify-end gap-2">
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleClearComponent();
              dispatch(handleHideRetentionEditor());
            }}
            uiType="light"
          >
            Cancel
          </Button>
          <Button
            loading={retentionLoading}
            uiType="primary"
            disabled={retentionLoading}
            type="submit"
          >
            Set
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default S3RetentionEditorModal;
