import { Outlet, useNavigate, useLocation, useParams } from "react-router-dom";
import { ReactComponent as BellIcon } from "./../../assets/icons/bell.svg";
import { ReactComponent as CloseIcon } from "./../../assets/icons/close.svg";
import { ReactComponent as RightArrow } from "./../../assets/icons/arrow-right.svg";
import { S3EventAddUrl, S3EventsUrl } from "../../utils/urls";
import {
  EventDestinationNames,
  EventDestinationOption,
  eventDestinationNames,
} from "../../types/s3-events";
import { s3EventDestinations } from "../../components/s3/events/S3SelectDestination";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Typography } from "djuno-design";
import useServiceType from "../../hooks/useServiceType";

const S3EventAddPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { serviceType } = useServiceType(
    process.env.REACT_APP_SIMPLE_STORAGE_SERVICE_TYPE
  );

  const { destination } = useParams<{ destination: EventDestinationNames }>();

  const [selectedDestination, setSelectedDestination] =
    useState<EventDestinationOption>();

  useEffect(() => {
    if (destination) {
      const findSelectedDestination = (
        keyToFind: string
      ): EventDestinationOption | undefined => {
        for (const category of s3EventDestinations) {
          const foundObject = category.options.find(
            (option) => option.key === keyToFind
          );
          if (foundObject) {
            return foundObject;
          }
        }
        return undefined;
      };

      setSelectedDestination(findSelectedDestination(destination));
    }
  }, [destination]);

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | {serviceType?.ServiceName || ""}{" "}
          (create new event destination)
        </title>
        <meta
          name="description"
          content="Simple-storage technology refers to a straightforward method of storing data or information in a manner that is easy to implement and access"
        />
      </Helmet>
      <div className="flex items-center justify-between h-16 px-6 bg-white dark:bg-dark-1">
        <div className="items-center justify-between flex flex-1 transition duration-150">
          <div className="font-medium mr-2 text-standard dark:text-slate-100 flex items-center gap-1">
            {location.pathname !== S3EventAddUrl ? (
              <>
                <RightArrow
                  onClick={() => navigate(S3EventAddUrl)}
                  className="rotate-180 w-5 h-5 hover:scale-110 transition-all duration-500 text-slate-800 dark:text-slate-100 cursor-pointer"
                />
                {typeof destination !== "undefined" &&
                  eventDestinationNames.includes(destination) && (
                    <div className="flex items-center">
                      <div className="w-[50px] h-[50px] flex justify-start items-center p-2">
                        <img
                          className="h-full"
                          alt={selectedDestination?.name}
                          src={selectedDestination?.iconUrl}
                        />
                      </div>
                      <Typography.Text className="!text-sm">
                        {selectedDestination?.name} Event Destination
                      </Typography.Text>
                    </div>
                  )}
              </>
            ) : (
              <Typography.Text className="!text-sm font-medium flex items-center gap-1">
                <BellIcon className="w-5 h-5" />
                Event Destinations
              </Typography.Text>
            )}
          </div>
        </div>
        <div className="">
          <Button
            uiType="light"
            uiSize="small"
            onClick={() => navigate(S3EventsUrl)}
            className="group"
          >
            <CloseIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>

      <div className="mt-10 w-full overflow-x-auto px-6 min-h-[calc(100%-6rem)]">
        <Outlet />
      </div>
    </>
  );
};

export default S3EventAddPage;
