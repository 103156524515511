import MonacoEditor, { OnMount } from "@monaco-editor/react";
import { useDappFilesContext } from "./contexts/DappFilesContext";
import {
  Button,
  cn,
  Flex,
  Loading,
  Tooltip,
  Typography,
  useDjunoDesign,
} from "djuno-design";
import { ReactComponent as CloseIcon } from "./../../../../assets/icons/editor-files/x-mark.svg";
import { ReactComponent as FolderIcon } from "./../../../../assets/icons/editor-files/languages/folder.svg";
import { ReactComponent as ChatBubbleIcon } from "./../../../../assets/icons/editor-files/chat-bubble.svg";
import { ReactComponent as MinimizeIcon } from "./../../../../assets/icons/arrows-pointing-in.svg";
import { ReactComponent as MaximizeIcon } from "./../../../../assets/icons/arrows-pointing-out.svg";
import { getFileIcon, getLanguageFromFilename, reduceFileName } from "./utils";
import { useTour } from "../../../../providers/TourProvider";
import { ReactComponent as RunIcon } from "./../../../../assets/icons/play.svg";

const FileEditor = () => {
  const {
    setEditorRef,
    openFiles,
    updateFileContent,
    activeFile,
    handleSetActiveFile,
    closeFile,
    isDirtyFile,
    handleToggleChat,
    isFullScreen,
    handleToggleFullScreen,
  } = useDappFilesContext();

  const { theme } = useDjunoDesign();

  const handleEditorMount: OnMount = (editor) => {
    // editorRef.current = editor;
    setEditorRef(editor);
  };

  // Handle content changes in the editor
  const handleEditorChange = (newContent: string) => {
    if (activeFile) {
      updateFileContent(activeFile.path, newContent);
    }
  };
  const { run } = useTour("dapp-studio");

  return (
    <div className="flex flex-col h-full w-full">
      {/* Opened Files List */}
      <div
        className={cn("flex items-center justify-between h-10 w-full ", {
          "bg-gray-100 dark:bg-dark-1": openFiles.length > 0,
        })}
      >
        <div className="flex items-center gap-0 h-full overflow-x-auto">
          {openFiles.map((file) => (
            <div
              key={file.path}
              className={cn(
                "relative h-full pr-6 p-2 cursor-default border-x border-gray-100 hover:border-gray-200 first:hover:border-l-gray-100  dark:border-gray-900 hover:dark:border-gray-800 first:dark:hover:border-l-gray-900 group",
                {
                  "!bg-white !border-gray-200 first:!border-l-gray-100 dark:!bg-[#1e1e1e] dark:!border-gray-800 first:dark:!border-l-gray-900":
                    activeFile?.path === file.path,
                }
              )}
              onClick={() => handleSetActiveFile(file.path)}
            >
              <div className="flex items-center gap-1">
                {getFileIcon(file.path)}
                <Typography.Text
                  size="xs"
                  uiType={isDirtyFile(file.path) ? "danger" : undefined}
                  className="whitespace-nowrap max-w-sm truncate"
                >
                  {reduceFileName(file.name)}
                </Typography.Text>
              </div>
              <CloseIcon
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  closeFile(file.path);
                }}
                className={cn(
                  "absolute right-1 -translate-y-1/2 top-1/2 w-3 h-3 text-slate-400 hover:text-slate-900 dark:text-slate-300 hover:dark:text-slate-100 hidden group-hover:block transition-all duration-150 cursor-pointer flex-shrink-0",
                  { "!block": activeFile?.path === file.path }
                )}
              />
            </div>
          ))}
        </div>
        <div className="h-full px-5 flex items-center gap-2">
          <Tooltip className="!select-none !text-xs" content="Start Tour">
            <RunIcon
              className="w-5 h-5 flex-shrink-0 hover:cursor-pointer select-none text-slate-900 dark:text-slate-100"
              onClick={() => {
                run();
              }}
            />
          </Tooltip>
          {isFullScreen && (
            <Tooltip className="!select-none !text-xs" content="Minimize">
              <MinimizeIcon
                className="w-5 h-5 flex-shrink-0 hover:cursor-pointer select-none text-slate-900 dark:text-slate-100"
                onClick={handleToggleFullScreen}
              />
            </Tooltip>
          )}
          {!isFullScreen && (
            <Tooltip className="!select-none !text-xs" content="Maximize">
              <MaximizeIcon
                className="w-5 h-5 flex-shrink-0 hover:cursor-pointer select-none text-slate-900 dark:text-slate-100"
                onClick={handleToggleFullScreen}
              />
            </Tooltip>
          )}

          {/* <Popover
            anchor="bottom end"
            panelClassName="!z-[55]"
            content={<AiChat />}
          > */}
          <Tooltip className="!select-none !text-xs" content="Chat with AI">
            <ChatBubbleIcon
              className="w-5 h-5 flex-shrink-0 hover:cursor-pointer select-none text-slate-900 dark:text-slate-100"
              onClick={handleToggleChat}
            />
          </Tooltip>
          {/* </Popover> */}
        </div>
      </div>
      <div className="flex h-full w-full">
        <div className="flex h-full w-full">
          {activeFile ? (
            // h-[calc(100dvh)]
            <div className="flex flex-col w-full">
              <MonacoEditor
                language={getLanguageFromFilename(activeFile.path)}
                value={activeFile.newContent}
                onChange={(v) => handleEditorChange(v || "")}
                theme={theme.mode === "dark" ? "vs-dark" : "light"}
                width="100%"
                height="100%"
                onMount={handleEditorMount}
                loading={
                  <Flex items="center" className="gap-1">
                    <Loading borderSize={2} uiSize={14} />
                    <Typography.Text size="sm">Loading</Typography.Text>
                  </Flex>
                }
                options={{
                  wordWrap: "on",
                  scrollbar: {
                    horizontal: "visible",
                    horizontalScrollbarSize: 10,
                    vertical: "visible",
                    verticalScrollbarSize: 8,
                  },
                }}
              />
            </div>
          ) : (
            <div className="flex flex-col w-full h-full items-center justify-center file">
              <div className="flex flex-col items-center gap-3">
                <Typography.Text
                  size="4xl"
                  className="placeholder whitespace-normal"
                >
                  <FolderIcon className="w-10 h-10" />
                </Typography.Text>
                <Typography.Text
                  size="sm"
                  className="placeholder whitespace-normal"
                >
                  Select a file to start editing
                </Typography.Text>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FileEditor;
