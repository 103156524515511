import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  BlockchainMetricsUrl,
  BlockchainSettingsUrl,
  BlockchainUrl,
  BlockchainsUrl,
} from "../../utils/urls";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { ReactComponent as RightArrow } from "./../../assets/icons/arrow-right.svg";
import { Helmet } from "react-helmet";
import {
  BlockchainNotActiveTab,
  NotExist,
} from "../../components/layouts/NotData";
import { ReactComponent as ComputerIcon } from "./../../assets/icons/computer-desktop.svg";
import { ReactComponent as MetricsIcon } from "./../../assets/icons/adjustments-vertical.svg";
import { ReactComponent as SettingIcon } from "./../../assets/icons/setting.svg";
import { Skeleton, Typography, Tabs, TabOptions, Flex } from "djuno-design";
import { WebAppsRefreshStatus } from "../../store/web-app/webAppsSlice";
import {
  getBlockchainAsync,
  getBlockchainOverviewAsync,
  handleClearBlockchainSlice,
  selectBlockchain,
  selectBlockchainLoading,
} from "../../store/blockchain-studio/blockchainSlice";
import { getBlockchainsPlansAsync } from "../../store/blockchain-studio/blockchainsSlice";
import { WebAppStatusTag } from "../web-apps/WebAppsPage";

const BlockchainPage = () => {
  const { blockchainId } = useParams();

  const blockchain = useAppSelector(selectBlockchain);
  const blockchainLoading = useAppSelector(selectBlockchainLoading);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isExist, setIsExist] = useState<boolean>(true);

  // get blockchain data
  useEffect(() => {
    if (
      (blockchain === null || blockchain.Id.toString() !== blockchainId) &&
      blockchainId
    ) {
      dispatch(
        getBlockchainAsync({
          blockchainId,
        })
      ).then((action) => {
        if (action.type === "blockchain/rejected") {
          setIsExist(false);
        }
      });
    }
  }, [dispatch, blockchainId, blockchain]);

  const isActive = useMemo(() => {
    if (!blockchain) return false;
    return blockchain.ServiceStatus === 2;
  }, [blockchain]);

  // get overview data
  useEffect(() => {
    if (blockchain && isActive)
      dispatch(
        getBlockchainOverviewAsync({
          blockchainId: blockchain.Id.toString(),
          withoutLoading: false,
        })
      );
  }, [dispatch, isActive, blockchain]);

  // refresh data based on app status
  useEffect(() => {
    if (blockchain) {
      let interval: NodeJS.Timeout | undefined;
      let intervalTime = 8000;

      const shouldSetInterval = WebAppsRefreshStatus.includes(
        blockchain.ServiceStatus
      );

      if (shouldSetInterval) {
        interval = setInterval(() => {
          dispatch(
            getBlockchainAsync({
              blockchainId: blockchain.Id.toString(),
              withoutLoading: true,
            })
          );
        }, intervalTime);
      } else {
        if (interval) clearInterval(interval);
      }

      return () => {
        if (interval) {
          clearInterval(interval);
        }
      };
    }
  }, [dispatch, blockchainId, blockchain]);

  // cleanup
  useEffect(() => {
    return () => {
      dispatch(handleClearBlockchainSlice());
    };
  }, [dispatch]);

  // get plans
  useEffect(() => {
    dispatch(getBlockchainsPlansAsync({}));
  }, [dispatch]);

  const BlockchainPageMenus: TabOptions = useMemo(
    () => [
      {
        label: (
          <div className="flex items-center gap-1">
            <ComputerIcon className="w-3" />
            Overview
          </div>
        ),
        url: BlockchainUrl(blockchainId),
      },
      {
        label: (
          <div className="flex items-center gap-1 metrics">
            <MetricsIcon className="w-3" />
            Metrics
          </div>
        ),
        url: BlockchainMetricsUrl(blockchainId),
      },
      {
        label: (
          <div className="flex items-center gap-1 settings">
            <SettingIcon className="w-3" />
            Settings
          </div>
        ),
        url: BlockchainSettingsUrl(blockchainId),
      },
    ],
    [blockchainId]
  );

  const pageName = useMemo(
    () => location.pathname.split("/").filter(Boolean)[2] || "",
    [location.pathname]
  );

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | ({blockchain?.ServiceName || ""}){" "}
          {pageName}
        </title>
        <meta
          name="description"
          content="web3-auth refers to authentication mechanisms used within Web3 applications, which are decentralized applications built on blockchain technology. Web3-auth facilitates user authentication and authorization processes within these decentralized applications"
        />
      </Helmet>
      <Flex items="center" justify="between" className="h-20">
        <Flex items="center" justify="between" className="px-6 flex-1">
          <Flex items="center" className="gap-2">
            <RightArrow
              onClick={() => navigate(BlockchainsUrl)}
              className="rotate-180 w-5 h-5 hover:scale-110 transition-all duration-500 text-slate-800 dark:text-slate-100 cursor-pointer"
            />
            {blockchainLoading ? (
              <Skeleton shape="rectangle" className="!w-48" />
            ) : (
              <Flex items="center" className="gap-2">
                <Typography.Title
                  level={5}
                  className="!mb-0 !whitespace-nowrap !max-w-[200px] md:!max-w-[400px] lg:!max-w-[350px] xl:!max-w-[400px] !truncate"
                >
                  {blockchain?.ServiceName}
                </Typography.Title>
                <WebAppStatusTag ServiceStatus={blockchain?.ServiceStatus} />
              </Flex>
            )}
          </Flex>
        </Flex>
        <div className="mr-6 w-96"></div>
      </Flex>
      {isExist ? (
        <div className="mx-6">
          <Tabs
            options={BlockchainPageMenus.map((o) => ({
              label: o.label,
              url: o.url,
              disabled: o.disabled,
            }))}
          />
          {blockchain && isActive && (
            <div className="py-6">
              <Outlet />
            </div>
          )}
        </div>
      ) : (
        <NotExist url={() => navigate(BlockchainsUrl)} />
      )}
      {!blockchainLoading && blockchain && !isActive && (
        <BlockchainNotActiveTab />
      )}
    </>
  );
};
export default BlockchainPage;
