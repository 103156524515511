import { WorkingPage } from "../../../layouts/NotData";

const DomainAutoRenewalTab = () => {
  return (
    <>
      <WorkingPage />
    </>
  );
};

export default DomainAutoRenewalTab;
