import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { Button, Input, Modal } from "djuno-design";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  handleRemoveDirty,
  handleToggleWorkflowExportModal,
  selectWorkflowShowexportModal,
} from "../../../../store/workflows/workflowSlice";
import { WorkflowExportTempalteSchema } from "../../../../utils/validations";
import { selectWorkflowsActionLoading } from "../../../../store/workflows/workflowsSlice";

const WorkflowExportModal = ({
  onConfirm,
}: {
  onConfirm: (name: string, description: string) => void;
}) => {
  const isOpen = useAppSelector(selectWorkflowShowexportModal);
  const loading = useAppSelector(selectWorkflowsActionLoading);

  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(WorkflowExportTempalteSchema),
  });

  useEffect(() => {
    if (isOpen) {
      setValue("name", "");
      setValue("description", "");
    }
  }, [dispatch, isOpen, setValue]);

  const handleClearComponent = useCallback(() => {
    reset();
    setValue("name", "");
    setValue("description", "");
  }, [reset, setValue]);

  const onSubmit = (data: { name: string; description: string }) => {
    dispatch(handleRemoveDirty());
    onConfirm(data.name, data.description);
    handleClearComponent();
  };

  const handleOnClose = useCallback(() => {
    dispatch(handleToggleWorkflowExportModal(false));
    handleClearComponent();
  }, [dispatch, handleClearComponent]);

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      contentClassName="max-w-lg"
      title="Create template"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5 flex flex-col w-full gap-5">
          <>
            <Input
              label="Name"
              {...register("name")}
              error={errors.name?.message}
              placeholder=""
            />
            <Input
              label="Description"
              {...register("description")}
              error={errors.description?.message}
              placeholder=""
            />
          </>
        </div>
        <div className="mt-4 flex justify-end">
          <Button
            loading={loading}
            uiType="primary"
            disabled={loading}
            type="submit"
          >
            Submit
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default WorkflowExportModal;
