import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { Enum, IThunkRejectValue, RootState } from "../../types";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import { Blockchain, BlockchainsState } from "../../types/blockchain";
import {
  deleteBlockchainApi,
  getBlockchainsApi,
} from "../../apis/blockchainsAPI";
import { IPlanGroup } from "../../types/billing";
import { getPlansApi } from "../../apis/billingAPI";
import { getEnumsApi } from "../../apis/publicAPI";

export const BlockchainRefreshStatus = [0, 1, 4];

const initialState: BlockchainsState = {
  blockchains: [],
  loading: false,
  actionLoading: false,

  plans: [],
  plansLoading: false,

  ConsensusTypes: [],
  ConsensusTypesLoading: false,
};

export const getBlockchainsAsync = createAsyncThunk<
  { blockchains: Blockchain[] },
  { withoutLoading?: boolean },
  IThunkRejectValue
>(
  "blockchains",
  async (
    { withoutLoading },
    { rejectWithValue, fulfillWithValue, dispatch, requestId }
  ) => {
    try {
      dispatch(
        getBlockchainsAsync.pending(requestId, {
          withoutLoading,
        })
      );

      const response = await getBlockchainsApi();
      const blockchains = response.data.Result;

      return fulfillWithValue({ blockchains });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getBlockchainsPlansAsync = createAsyncThunk<
  { plans: IPlanGroup[] },
  { withoutLoading?: boolean },
  IThunkRejectValue
>(
  "blockchains/plans",
  async (
    { withoutLoading },
    { rejectWithValue, fulfillWithValue, dispatch, requestId }
  ) => {
    try {
      dispatch(
        getBlockchainsPlansAsync.pending(requestId, {
          withoutLoading,
        })
      );

      const response = await getPlansApi({
        serviceType: process.env.REACT_APP_BLOCKCHAIN_STUDIO_SERVICE_TYPE,
      });
      const plans = response.data.Result;

      return fulfillWithValue({ plans });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getBlockchainsConsensusAsync = createAsyncThunk<
  { consensus: Enum[] },
  undefined,
  IThunkRejectValue
>(
  "blockchains/consensus",
  async (_, { rejectWithValue, fulfillWithValue, dispatch, requestId }) => {
    try {
      const response = await getEnumsApi("consensus-type");
      const consensus = response.data.Result;

      return fulfillWithValue({ consensus });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const deleteBlockchainsAsync = createAsyncThunk<
  boolean,
  { id: number },
  IThunkRejectValue
>("blockchains/delete", async ({ id }, { rejectWithValue }) => {
  try {
    await deleteBlockchainApi(id);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const blockchainsSlice = createSlice({
  name: "blockchains",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBlockchainsAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.loading = true;
      })
      .addCase(getBlockchainsAsync.fulfilled, (state, action) => {
        state.blockchains = action.payload.blockchains;
        state.loading = false;
      })
      .addCase(getBlockchainsAsync.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getBlockchainsPlansAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.plansLoading = true;
      })
      .addCase(getBlockchainsPlansAsync.fulfilled, (state, action) => {
        state.plans = action.payload.plans[0].PlanDetail;
        state.plansLoading = false;
      })
      .addCase(getBlockchainsPlansAsync.rejected, (state, { payload }) => {
        state.plansLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getBlockchainsConsensusAsync.pending, (state) => {
        state.ConsensusTypesLoading = true;
      })
      .addCase(getBlockchainsConsensusAsync.fulfilled, (state, action) => {
        state.ConsensusTypes = action.payload.consensus;
        state.ConsensusTypesLoading = false;
      })
      .addCase(getBlockchainsConsensusAsync.rejected, (state, { payload }) => {
        state.ConsensusTypesLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteBlockchainsAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(deleteBlockchainsAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(deleteBlockchainsAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectBlockchains = (state: RootState) =>
  state.blockchains.blockchains;
export const selectBlockchainsLoading = (state: RootState) =>
  state.blockchains.loading;
export const selectBlockchainsActionLoading = (state: RootState) =>
  state.blockchains.actionLoading;

export const selectBlockchainsPlans = (state: RootState) =>
  state.blockchains.plans;
export const selectBlockchainsPlansLoading = (state: RootState) =>
  state.blockchains.plansLoading;

export const selectBlockchainsConsensusTypes = (state: RootState) =>
  state.blockchains.ConsensusTypes;
export const selectBlockchainsConsensusTypesLoading = (state: RootState) =>
  state.blockchains.ConsensusTypesLoading;
// export const {} = blockchainsSlice.actions;
export default blockchainsSlice.reducer;
