import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark } from "react-syntax-highlighter/dist/esm/styles/prism";

const MarkdownText: React.FC<{ text: string }> = ({ text }) => {
  return (
    <ReactMarkdown
      components={{
        code(props) {
          const { children, className, node, ref, ...rest } = props;
          const match = /language-(\w+)/.exec(className || "");
          return match ? (
            <SyntaxHighlighter
              {...rest}
              PreTag="div"
              children={String(children).replace(/\n$/, "")}
              language={match[1]}
              style={materialDark}
            />
          ) : (
            <code {...rest} className={className}>
              {children}
            </code>
          );
        },
        p({ children }) {
          return (
            <p style={{ marginTop: "1rem", lineHeight: "1.6" }}>{children}</p>
          );
        },
      }}
    >
      {text}
    </ReactMarkdown>
  );
};

export default MarkdownText;
