import AnimatedFeatures from "../../components/website/AnimatedFeaturesBox";
import {
  CounterParallaxBox,
  ContactUsParallaxBox,
} from "../../components/website/ParallaxBox";
import NewsBox from "../../components/website/NewsBox";
import ProductsBox from "../../components/website/ProductsBox";
import TrustedByBox from "../../components/website/TrustedByBox/TrustedByBox";
import UseCasesBox from "../../components/website/UseCasesBox";
import VideosBox from "../../components/website/VideosBox";
import IndexBanner from "../../components/website/IndexBanner";
import { Helmet } from "react-helmet";
// import BuyDomainBox from "../../components/website/BuyDomainBox";

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME || ""}</title>
        <meta
          name="description"
          content="Top projects & businesses trust DjunoCloud for fast, reliable Web3 infrastructure. Instantly access 24 chains across a global node network, plus tools and APIs to help you build & scale high-quality blockchain applications!"
        />
      </Helmet>
      <IndexBanner />
      <AnimatedFeatures />
      <ProductsBox />
      <CounterParallaxBox />
      <UseCasesBox />
      <TrustedByBox />
      <VideosBox />
      <ContactUsParallaxBox />
      {/* <BuyDomainBox /> */}
      <NewsBox />
    </>
  );
};

export default IndexPage;
