import { useEffect, useRef, useState } from "react";
import { useAiChatContext } from "../../../../providers/AiChatProvider";
import { useDappFilesContext } from "./contexts/DappFilesContext";
import { cn, Input, Button, Flex, Typography } from "djuno-design";
import { formatTimestamp } from "../../../../utils/date";
import { ReactComponent as DjunoLogo } from "../../../../assets/icons/logo.svg";
import { ReactComponent as AILogo } from "../../../../assets/icons/ai-platform.svg";
import { useAppSelector } from "../../../../hooks";
import { selectUser } from "../../../../store/auth/authSlice";
import { UserAvatar } from "../../../dropdowns/UserDropdown";
import MarkdownText from "../../../general/MarkdownText";

const AiExamples = [
  "How to create a token with 1000 total supply on Ethereum blockchain ?",
  "how to create .sol file?",
];

const AiChat = () => {
  const { messages, sendMessage, loading } = useAiChatContext();
  const { isFullScreen } = useDappFilesContext();
  const user = useAppSelector(selectUser);

  const [input, setInput] = useState("");
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const handleSend = async () => {
    if (input.trim() && !loading) {
      await sendMessage(input.trim());
      setInput("");
      scrollToBottom();
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div
      className={cn(
        // "flex flex-col w-[400px] h-[420px] border border-gray-200 dark:border-black bg-gray-50 dark:bg-dark-1 rounded-lg shadow-xl overflow-hidden",
        // {
        //   " md:!w-[500px] !h-[500px] md:!h-[600px]": isFullScreen,
        // },
        "flex flex-col w-[400px] lg:w-[500px] overflow-hidden bg-white dark:bg-dark-1",
        {
          "h-[500px] lg:h-[68dvh]": !isFullScreen,
          "md:!w-[500px]": isFullScreen,
        }
      )}
    >
      {/* Messages Container */}
      <div className="flex-grow overflow-y-auto p-4 space-y-3">
        {messages.length === 0 && (
          <div className="flex w-full h-full justify-center items-center">
            <Flex direction="col" items="center" className="gap-6 mt-5">
              <Flex direction="col" items="center" className="gap-2">
                <AILogo className="w-20 h-20 flex-shrink-0" />
                <Typography.Text size="base" className="text-center">
                  Your Djuno AI assistant ready to help you, ask me any thing.
                </Typography.Text>
              </Flex>
              {AiExamples.length > 0 && (
                <Flex direction="col" className="gap-2">
                  <Typography.Text size="sm">For example:</Typography.Text>
                  <div className="grid grid-cols-12 gap-3">
                    {AiExamples.map((example, i) => (
                      <div
                        key={i}
                        onClick={() => sendMessage(example.trim())}
                        className="col-span-6 bg-slate-100 dark:bg-dark-3 rounded-lg p-3 cursor-pointer border-2 border-slate-200 hover:border-primary-200 dark:border-dark-2 hover:dark:border-primary-500/70 transition-all duration-300"
                      >
                        <Typography.Text size="xs">{example}</Typography.Text>
                      </div>
                    ))}
                  </div>
                </Flex>
              )}
            </Flex>
          </div>
        )}
        {messages.map((msg) => (
          <div
            key={msg.id}
            className={`p-2 md:p-4 rounded-3xl !text-sm w-[95%] ${
              msg.role === "user"
                ? "bg-blue-500 text-white self-end ml-auto !rounded-br-none"
                : "bg-gray-200 text-gray-800 dark:bg-dark-2 dark:text-slate-100 self-start !rounded-bl-none"
            }`}
          >
            <Flex
              items="center"
              justify="between"
              className="w-full border-b mb-3 pb-1 border-slate-300 dark:border-slate-600"
            >
              <Typography.Text size="sm" uiType="transparent">
                {msg.role === "user" ? (
                  <Flex items="center" className="gap-1">
                    <div className="w-4 h-4 flex-shrink-0">
                      <UserAvatar />
                    </div>
                    {user?.FullName}
                  </Flex>
                ) : (
                  <Flex items="center" className="gap-1">
                    <DjunoLogo className="w-4 h-4 flex-shrink-0" />
                    Djuno AI
                  </Flex>
                )}
              </Typography.Text>
              <Typography.Text size="xs" uiType="transparent">
                {
                  formatTimestamp(msg.date, "HH:mm A", { isUTC: false })
                    .datetime
                }
              </Typography.Text>
            </Flex>
            <MarkdownText text={msg.content} />
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      {/* Input Section */}
      <div className="p-2 border-t border-gray-300 bg-white dark:border-dark-2 dark:bg-dark-1 flex items-center space-x-2">
        <Input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handleSend();
            }
          }}
          placeholder="Type a message..."
          className="focus:outline-none focus:ring-2 focus:!ring-blue-500"
          containerClassName="!flex-grow"
        />
        <Button onClick={handleSend} uiType="primary" loading={loading}>
          Send
        </Button>
      </div>
    </div>
  );
};
export default AiChat;
