// import Drawer from "./../../../modals/Drawer";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  selectShowObjectDrawer,
  selectSelectedObject,
  getObjectMetadataAsync,
  selectObjectMetadata,
  selectObjectMetadataLoading,
  selectObjectVersions,
  selectObjectVersionsLoading,
  getObjectVersionsAsync,
  handleShowObjectLegalHoldModal,
  handleShowObjectRetentionPolicyModal,
  handleShowObjectPreview,
  handleShowObjectTagsModal,
  handleShowObjectInspectModal,
  handleShowObjectShareModal,
  handleHideObjectDrawer,
  selectSelectedVersionOfObject,
  handleShowObjectVersionsModal,
  selectShowObjectVersions,
  handleHideObjectVersionsModal,
  getObjectDownloadAsync,
  selectObjectDeleteLoading,
  deleteObjectAsync,
} from "../../../store/s3/buckets/objectSlice";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { ReactComponent as CloseIcon } from "./../../../assets/icons/close.svg";
import { ReactComponent as DownloadIcon } from "./../../../assets/icons/arrow-down-tray.svg";
import { ReactComponent as ShareIcon } from "./../../../assets/icons/share.svg";
import { ReactComponent as EyeIcon } from "./../../../assets/icons/eye.svg";
import { ReactComponent as ScaleIcon } from "./../../../assets/icons/scale.svg";
import { ReactComponent as CalendarIcon } from "./../../../assets/icons/calendar.svg";
import { ReactComponent as TagIcon } from "./../../../assets/icons/tag.svg";
import { ReactComponent as MagnifyingGlassIcon } from "./../../../assets/icons/magnifying-glass.svg";
import { ReactComponent as BarsIcon } from "./../../../assets/icons/bars-3-bottom-left.svg";
import { ReactComponent as DeleteIcon } from "./../../../assets/icons/archive-box.svg";
import { humanizeSize } from "../../../utils/file";
import { dateConvertor } from "../../../utils/date";
import {
  getBucketObjectsAsync,
  selectBucketDetails,
  selectBucketDetailsLoading,
  selectBucketObjectLocking,
  selectBucketObjectLockingLoading,
  selectBucketVersioning,
  selectBucketVersioningLoading,
} from "../../../store/s3/buckets/bucketSlice";
import {
  getFileNameAndExt,
  getMainVersionOfObject,
  getObjectTotalVersionsSize,
} from "../../../utils/bucket";
import S3ObjectLegalHoldModal from "./modals/objects/S3ObjectLegalHoldModal";
import S3ObjectRetentionPolicyModal from "./modals/objects/S3ObjectRetentionPolicyModal";
import S3ObjectPreview from "./modals/objects/S3ObjectPreview";
import S3ObjectTagsModal from "./modals/objects/S3ObjectTagsModal";
import S3ObjectInspectModal from "./modals/objects/S3ObjectInspectModal";
import S3ObjectShareModal from "./modals/objects/S3ObjectShareModal";
import QuestionModal from "../../modals/QuestionModal";
import { S3BucketObjectInfo } from "../../../types/s3-bucket";
import { Button, cn, Skeleton, Switcher, Typography } from "djuno-design";

const S3ObjectDrawer = () => {
  const isOpen = useAppSelector(selectShowObjectDrawer);
  const selectedObject = useAppSelector(selectSelectedObject);
  const bucketDetails = useAppSelector(selectBucketDetails);
  const bucketDetailsLoading = useAppSelector(selectBucketDetailsLoading);

  const bucketVersioning = useAppSelector(selectBucketVersioning);
  const bucketVersioningLoading = useAppSelector(selectBucketVersioningLoading);

  const dispatch = useAppDispatch();

  const versions = useAppSelector(selectObjectVersions);
  const versionsLoading = useAppSelector(selectObjectVersionsLoading);

  const objectLoacking = useAppSelector(selectBucketObjectLocking);

  const mainVersion = getMainVersionOfObject(versions);
  const selectedVersion = useAppSelector(selectSelectedVersionOfObject);
  const isOpenVersions = useAppSelector(selectShowObjectVersions);

  const metadata = useAppSelector(selectObjectMetadata);
  const metadataLoading = useAppSelector(selectObjectMetadataLoading);

  const [deleteVersion, setDeleteVersion] = useState<S3BucketObjectInfo>();
  const [enableDeleteAllVersions, setEnableDeleteAllVersions] = useState(false);
  const deleteLoading = useAppSelector(selectObjectDeleteLoading);

  const handleCloseDrawer = () => {
    dispatch(handleHideObjectDrawer());
  };

  useEffect(() => {
    if (isOpen && selectedObject && bucketDetails) {
      dispatch(
        getObjectMetadataAsync({
          bucketName: bucketDetails.name,
          prefix: selectedObject.name,
        })
      );
    }
  }, [bucketDetails, dispatch, isOpen, selectedObject]);

  useEffect(() => {
    if (isOpen && selectedObject && bucketDetails) {
      dispatch(
        getObjectVersionsAsync({
          bucketName: bucketDetails.name,
          prefix: selectedObject.name,
        })
      );
    }
  }, [bucketDetails, dispatch, isOpen, selectedObject]);

  const handleDeleteVersion = () => {
    if (
      deleteVersion &&
      bucketDetails &&
      deleteVersion.version_id &&
      mainVersion
    ) {
      dispatch(
        deleteObjectAsync({
          bucketName: bucketDetails.name,
          prefix: deleteVersion.name,
          versionId: !deleteVersion.is_latest
            ? deleteVersion.version_id
            : undefined,
          all_versions: deleteVersion.is_latest
            ? enableDeleteAllVersions
            : false,
          recursive: false,
        })
      ).then((action) => {
        if (action.type === "object/delete/fulfilled") {
          if (deleteVersion.is_latest) {
            dispatch(handleHideObjectDrawer());
            dispatch(getBucketObjectsAsync({ name: bucketDetails.name }));
          } else {
            dispatch(
              getObjectVersionsAsync({
                bucketName: bucketDetails.name,
                prefix: mainVersion.name,
              })
            );
          }
          setDeleteVersion(undefined);
          setEnableDeleteAllVersions(false);
        }
      });
    }
  };

  const locked = useMemo(() => {
    if (objectLoacking === null) return true;
    if ("object_locking_enabled" in objectLoacking)
      return !objectLoacking.object_locking_enabled;
    return true;
  }, [objectLoacking]);

  return (
    <>
      {isOpen && (
        <div className="xl:border-l p-2 xl:p-3 mt-4 xl:mt-0 xl:w-1/4 dark:border-dark-2">
          <div className="">
            <div className="flex items-center justify-between ">
              <Typography.Title
                level={6}
                className="!mb-0 !max-w-[250px] md:!max-w-[400px] lg:!max-w-[350px] xl:!max-w-[400px] !truncate !whitespace-pre"
              >
                {getFileNameAndExt(selectedObject?.name)?.fullName}
              </Typography.Title>
              <CloseIcon
                className="w-5 text-slate-800 dark:text-slate-200 hover:scale-110 cursor-pointer"
                onClick={handleCloseDrawer}
              />
            </div>
            <div className="flex flex-col w-full rounded-lg bg-gray-100/80 dark:bg-dark-1 mt-2">
              <Typography.Title
                level={6}
                className="!mb-0 p-2 border-b dark:border-b-dark-2"
              >
                Actions:
              </Typography.Title>
              <ActionOption
                title="Download"
                icon={<DownloadIcon className="w-4" />}
                onClick={() =>
                  bucketDetails &&
                  selectedVersion &&
                  selectedVersion.version_id &&
                  !selectedVersion.is_delete_marker &&
                  dispatch(
                    getObjectDownloadAsync({
                      bucketName: bucketDetails.name,
                      prefix: selectedVersion.name,
                      versionId: selectedVersion.version_id,
                    })
                  )
                }
                disabled={
                  selectedVersion !== null && selectedVersion.is_delete_marker
                }
              />
              <ActionOption
                title="Share"
                icon={<ShareIcon className="w-4" />}
                onClick={() => dispatch(handleShowObjectShareModal({}))}
                disabled={
                  selectedVersion !== null && selectedVersion.is_delete_marker
                }
              />
              <ActionOption
                title="Preview"
                icon={<EyeIcon className="w-4" />}
                onClick={() => dispatch(handleShowObjectPreview({}))}
                disabled={
                  selectedVersion !== null && selectedVersion.is_delete_marker
                }
              />
              <ActionOption
                title="Legal Hold"
                icon={<ScaleIcon className="w-4" />}
                onClick={() => dispatch(handleShowObjectLegalHoldModal())}
                disabled={
                  selectedVersion?.version_id !== mainVersion?.version_id ||
                  locked
                }
              />
              <ActionOption
                title="Retention"
                icon={<CalendarIcon className="w-4" />}
                onClick={() => dispatch(handleShowObjectRetentionPolicyModal())}
                disabled={
                  selectedVersion?.version_id !== mainVersion?.version_id ||
                  locked
                }
              />
              <ActionOption
                title="Tags"
                icon={<TagIcon className="w-4" />}
                onClick={() => dispatch(handleShowObjectTagsModal())}
                disabled={
                  selectedVersion?.version_id !== mainVersion?.version_id
                }
              />
              <ActionOption
                title="Inspect"
                icon={<MagnifyingGlassIcon className="w-4" />}
                onClick={() => dispatch(handleShowObjectInspectModal())}
                disabled={
                  selectedVersion?.version_id !== mainVersion?.version_id
                }
              />
              <ActionOption
                title={`${isOpenVersions ? "Hide" : "Display"} Object Versions`}
                icon={<BarsIcon className="w-4" />}
                onClick={() =>
                  isOpenVersions
                    ? dispatch(handleHideObjectVersionsModal())
                    : dispatch(handleShowObjectVersionsModal())
                }
                disabled={bucketVersioning?.status !== "Enabled"}
              />
            </div>

            <div>
              <Button
                uiType="dangerLight"
                className="w-full mt-5"
                onClick={() =>
                  selectedVersion && setDeleteVersion(selectedVersion)
                }
                disabled={
                  bucketDetails === null ||
                  versionsLoading ||
                  !mainVersion ||
                  selectedVersion?.is_delete_marker
                }
              >
                <DeleteIcon className="w-4" />
                {selectedVersion?.version_id === mainVersion?.version_id
                  ? "Delete"
                  : "Delete version"}
              </Button>
            </div>
            <div className="flex flex-col w-full mt-5">
              <Typography.Title
                level={6}
                className="!mb-0 border-b pb-1 dark:border-dark-2"
              >
                Object Info
              </Typography.Title>
              <InfoOption
                title="Name:"
                content={
                  <div className="whitespace-pre">
                    {getFileNameAndExt(selectedVersion?.name)?.fullName}
                  </div>
                }
              />
              <InfoOption
                title="Size:"
                content={
                  selectedVersion && selectedVersion.size
                    ? humanizeSize(selectedVersion.size, {
                        fractionDigits: 2,
                      }).join("B")
                    : "0"
                }
              />
              <InfoOption
                title="Versions:"
                content={`${versions?.length} version${
                  versions && versions.length > 1 ? "s" : ""
                },  
          ${
            versions &&
            humanizeSize(getObjectTotalVersionsSize(versions), {
              fractionDigits: 2,
            }).join("B")
          }
          `}
                loading={versionsLoading}
              />
              <InfoOption
                title="Last Modified:"
                content={
                  selectedVersion &&
                  dateConvertor(selectedVersion.last_modified)
                }
              />
              <InfoOption title="ETAG:" content={selectedVersion?.etag} />
              <InfoOption
                loading={versionsLoading}
                title="Tags:"
                content={
                  selectedVersion?.tags
                    ? Object.entries(selectedVersion.tags)
                        .map(([key, val]) => `${key}:${val}`)
                        .join(" ,")
                    : "N/A"
                }
              />

              {selectedVersion?.legal_hold_status && (
                <InfoOption
                  loading={versionsLoading}
                  title="Legal Hold:"
                  content={
                    selectedVersion.legal_hold_status === "ON" ? "On" : "Off"
                  }
                />
              )}

              {selectedVersion?.retention_mode && (
                <InfoOption
                  loading={versionsLoading}
                  title="Retention Policy:"
                  content={
                    selectedVersion.retention_mode === "GOVERNANCE"
                      ? "Governance"
                      : "Compliance"
                  }
                />
              )}
            </div>

            <div className="flex flex-col w-full mt-5">
              <Typography.Title
                level={6}
                className="!mp-0 border-b pb-1 dark:border-dark-2"
              >
                Metadata
              </Typography.Title>
              {metadata?.["Content-Type"] && (
                <InfoOption
                  title="Content-Type"
                  content={metadata?.["Content-Type"][0]}
                  loading={bucketDetailsLoading || metadataLoading}
                />
              )}

              {metadata?.["X-Amz-Object-Lock-Legal-Hold"] && (
                <InfoOption
                  title="X-Amz-Object-Lock-Legal-Hold"
                  content={metadata?.["X-Amz-Object-Lock-Legal-Hold"][0]}
                  loading={bucketDetailsLoading || metadataLoading}
                />
              )}
              {metadata?.["X-Amz-Object-Lock-Mode"] && (
                <InfoOption
                  title="VX-Amz-Object-Lock-Mode"
                  content={metadata?.["X-Amz-Object-Lock-Mode"][0]}
                  loading={bucketDetailsLoading || metadataLoading}
                />
              )}

              {metadata?.["X-Amz-Object-Lock-Retain-Until-Date"] && (
                <InfoOption
                  title="X-Amz-Object-Lock-Retain-Until-Date"
                  content={metadata?.["X-Amz-Object-Lock-Retain-Until-Date"][0]}
                  loading={bucketDetailsLoading || metadataLoading}
                />
              )}
            </div>
          </div>
          <S3ObjectLegalHoldModal />
          <S3ObjectRetentionPolicyModal />
          <S3ObjectPreview />
          <S3ObjectTagsModal />
          <S3ObjectInspectModal />
          <S3ObjectShareModal />
          <QuestionModal
            isOpen={!!deleteVersion}
            onClose={() => {
              setDeleteVersion(undefined);
              setEnableDeleteAllVersions(false);
            }}
            title="Delete Object"
            confirmButtonType="danger"
            onConfirm={handleDeleteVersion}
            loading={deleteLoading}
          >
            <div className="flex flex-col gap-2 mt-5">
              <div>
                <Typography.Text className="!text-sm">
                  Are you sure you want to delete:
                </Typography.Text>
                <Typography.Title level={6} className="!mb-0 whitespace-pre">
                  {deleteVersion
                    ? getFileNameAndExt(deleteVersion.name)?.fullName
                    : ""}
                </Typography.Title>
              </div>
              {!deleteVersion?.is_latest && (
                <div>
                  <Typography.Text className="!text-sm">
                    Version ID:
                  </Typography.Text>
                  <Typography.Title level={6} className="!mb-0">
                    {deleteVersion ? deleteVersion.version_id : ""}
                  </Typography.Title>
                </div>
              )}

              {deleteVersion?.is_latest && (
                <div className="flex items-center justify-between">
                  <Typography.Text className="!text-sm">
                    Delete All Versions
                  </Typography.Text>
                  <Switcher
                    value={enableDeleteAllVersions}
                    onChange={setEnableDeleteAllVersions}
                  />
                </div>
              )}
            </div>
          </QuestionModal>
        </div>
      )}
    </>
  );
};

export const ActionOption = ({
  title,
  icon,
  onClick,
  disabled,
}: {
  title: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
}) => {
  return (
    <div
      className={cn(
        "flex items-center px-2 py-2 border-b last:border-b-0 dark:border-b-dark-2 group gap-1.5",
        {
          " cursor-pointer": !disabled,
          " cursor-not-allowed": disabled,
        }
      )}
      onClick={() => (onClick && !disabled ? onClick() : {})}
    >
      <div
        className={cn("", {
          "text-slate-600 dark:!text-slate-300 group-hover:!text-slate-900 dark:group-hover:!text-slate-100":
            !disabled,
          "text-slate-300 dark:text-slate-600": disabled,
        })}
      >
        {icon}
      </div>
      <Typography.Text
        uiType="transparent"
        className={cn("!text-sm", {
          "!text-slate-600 dark:!text-slate-300 group-hover:!text-slate-900 dark:group-hover:!text-slate-100":
            !disabled,
          "!text-slate-300 dark:!text-slate-600": disabled,
        })}
      >
        {title}
      </Typography.Text>
    </div>
  );
};

export const InfoOption = ({
  title,
  content,
  loading,
}: {
  title: string;
  content?: string | null | ReactNode;
  loading?: boolean;
}) => {
  return (
    <div className="flex flex-col px-2 py-1.5">
      <Typography.Title level={6} className="!mb-0">
        {title}
      </Typography.Title>

      {loading && <Skeleton style={{ width: "100%", height: 26 }} />}
      {!loading && (
        <Typography.Text className="!text-sm !overflow-hidden !truncate">
          {content}
        </Typography.Text>
      )}
    </div>
  );
};
export default S3ObjectDrawer;
