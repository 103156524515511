import { Button, Checkbox, Flex, Input, Typography } from "djuno-design";
import { useForm } from "react-hook-form";

const DomainTransferTab = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();

  const submitForm = (data: any) => {};

  return (
    <Flex direction="col" className="mt-6">
      <Typography.Text size="base">
        Transfer domain to another address
      </Typography.Text>
      <Typography.Text uiType="secondary" size="sm" className="mt-2">
        You must have the private key in order to manage your domain. If you
        transfer this domain to an exchange or any other custodial account where
        you do not control the private key, you will not be able to access your
        domain. Not your keys, not your domain.
      </Typography.Text>

      <form onSubmit={handleSubmit(submitForm)}>
        <Flex direction="col" className="mt-8 gap-5 lg:w-2/3">
          <Input
            label="Recipient address"
            placeholder="Enter recipient address"
            required
          />
          <Flex direction="col" className="mt-2 gap-3">
            <Checkbox label="I understand that I am transferring ownership of this domain and this action is irreversible" />
            <Checkbox label="I’m not transferring to an exchange address" />
            <Checkbox label="I’m transferring to a Base wallet address" />
            <Checkbox label="Clear records upon transfer (Optional)" />
          </Flex>
          <Button disabled={!isValid} uiType="primary" type="submit">
            Transfer Domain
          </Button>
        </Flex>
      </form>
    </Flex>
  );
};

export default DomainTransferTab;
