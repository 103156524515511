import { useAppDispatch, useAppSelector } from "./../../hooks";
import { Alert, Flex, Loading, Select, Typography } from "djuno-design";
import { useEffect, useState } from "react";
import {
  grafanaMetricChartSetting,
  KuberoMetricChart,
  kuberoMetricChartName,
  kuberoMetricChartYName,
} from "./../general/charts/KuberoMetricChart";
import { WebAppsRefreshStatus } from "./../../store/web-app/webAppsSlice";
import {
  getBlockchainMetricsAsync,
  selectBlockchain,
  selectBlockchainLoading,
  selectBlockchainMetrics,
  selectBlockchainMetricsLoading,
} from "../../store/blockchain-studio/blockchainSlice";
import { metricsTimesOptions } from "../web-apps/single-page/WebAppMetricsTab";

const BlockchainMetricsTab = () => {
  const blockchain = useAppSelector(selectBlockchain);
  const blockchainLoading = useAppSelector(selectBlockchainLoading);

  const blockchainMetrics = useAppSelector(selectBlockchainMetrics);
  const blockchainMetricsLoading = useAppSelector(
    selectBlockchainMetricsLoading
  );

  // console.log("blockchainMetrics", blockchainMetrics);

  const dispatch = useAppDispatch();

  const [metricTime, setMetricTime] = useState<string>("0");

  useEffect(() => {
    if (blockchain) {
      dispatch(
        getBlockchainMetricsAsync({
          blockchainId: blockchain.Id.toString(),
          time: Number(metricTime),
          withoutLoading: WebAppsRefreshStatus.includes(
            blockchain.ServiceStatus
          ),
        })
      );
    }
  }, [dispatch, blockchain, metricTime]);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    interval = setInterval(() => {
      if (
        blockchain &&
        !WebAppsRefreshStatus.includes(blockchain.ServiceStatus)
      ) {
        dispatch(
          getBlockchainMetricsAsync({
            blockchainId: blockchain?.Id.toString(),
            withoutLoading: true,
            time: Number(metricTime),
          })
        );
      }
    }, 10000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, blockchain, metricTime]);

  return (
    <div className="flex flex-col gap-8">
      <div className="max-lg:order-2 max-lg:grow lg:mx-0 mx-2"></div>
      <div className="flex justify-end">
        <Select
          options={metricsTimesOptions}
          value={metricTime.toString()}
          onChange={(v) => setMetricTime(v || "0")}
          buttonClassName="!border-slate-200 dark:!border-secondary-700 !pr-7"
          optionsClassName=" !max-h-none"
          className="!w-[175px]"
        />
      </div>

      {blockchainMetricsLoading && (
        <Flex items="center" justify="center" className="w-full h-[300px]">
          <Loading borderSize={2} />
        </Flex>
      )}

      {!blockchainMetricsLoading &&
        blockchainMetrics &&
        Object.keys(blockchainMetrics).length > 0 && (
          <Flex direction="col" className="w-full gap-14">
            {Object.keys(blockchainMetrics).map((key) => {
              const data =
                blockchainMetrics[key as keyof typeof blockchainMetrics];

              return (
                <div className="h-[200px]" key={key}>
                  <Flex justify="center" className="w-full mt-5">
                    <Typography.Text className="!font-semibold !text-xs">
                      {grafanaMetricChartSetting(key).name}
                    </Typography.Text>
                  </Flex>
                  <KuberoMetricChart
                    data={data.Data.Result}
                    // yName={grafanaMetricChartSetting(key).unit}
                    chartType="grafana"
                    chartKey={key}
                  />
                </div>
              );
            })}
          </Flex>
        )}
    </div>
  );
};

export default BlockchainMetricsTab;
