import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DBSPoolSchema } from "../../../utils/validations";
import { useCallback, useEffect, useMemo } from "react";
import {
  createDBSPoolAsync,
  getDBSDatabasesAsync,
  getDBSPoolsAsync,
  getDBSUsersAsync,
  handleDBSPoolEditor,
  selectDBSDatabases,
  selectDBSDatabasesLoading,
  selectDBSPoolsActionLoading,
  selectDBSPoolsShowEditor,
  selectDBSSelectedPool,
  selectDatabaseService,
  selectDatabaseServiceLoading,
  selectDatabaseServiceUsers,
  selectDatabaseServiceUsersLoading,
  updateDBSPoolAsync,
} from "../../../store/database/serviceSlice";
import { Button, Input, Modal, Select, SelectOption } from "djuno-design";

export const modeOptions: SelectOption[] = [
  { label: "session", value: "session" },
  { label: "statement", value: "statement" },
  { label: "transaction", value: "transaction" },
];

const DBSPoolCreateModal = () => {
  const dispatch = useAppDispatch();

  const selectedPool = useAppSelector(selectDBSSelectedPool);

  const isOpen = useAppSelector(selectDBSPoolsShowEditor);
  const actionLoading = useAppSelector(selectDBSPoolsActionLoading);

  const service = useAppSelector(selectDatabaseService);
  const serviceLoading = useAppSelector(selectDatabaseServiceLoading);

  const users = useAppSelector(selectDatabaseServiceUsers);
  const usersLoading = useAppSelector(selectDatabaseServiceUsersLoading);

  const databases = useAppSelector(selectDBSDatabases);
  const databasesLoading = useAppSelector(selectDBSDatabasesLoading);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    resolver: yupResolver(DBSPoolSchema),
    shouldUnregister: true,
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (isOpen && service && databases.length === 0) {
      dispatch(
        getDBSDatabasesAsync({ engine: service.engine, clusterId: service.id })
      );
    }
  }, [databases.length, dispatch, isOpen, service]);

  useEffect(() => {
    if (isOpen && service && users.length === 0) {
      dispatch(getDBSUsersAsync({ engine: service.engine, id: service.id }));
    }
  }, [dispatch, isOpen, service, users.length]);

  useEffect(() => {
    if (selectedPool) {
      const { name, mode, databaseId, userId, size } = selectedPool;
      setValue("name", name);
      setValue("mode", mode);
      setValue("databaseId", databaseId);
      setValue("userId", userId);
      setValue("size", size);
    }
  }, [selectedPool, setValue]);

  const onSubmit = (data: any) => {
    if (service && !actionLoading) {
      if (selectedPool) {
        const apiData = {
          databaseId: data.databaseId,
          mode: data.mode,
          size: data.size,
          userId: data.userId || null,
        };
        dispatch(
          updateDBSPoolAsync({
            clusterId: service.id,
            engine: service.engine,
            poolId: selectedPool.id,
            data: apiData,
          })
        ).then((action) => {
          if (action.type === "service/pools/update/fulfilled") {
            handleOnClose();
            dispatch(
              getDBSPoolsAsync({
                engine: service.engine,
                clusterId: service.id,
              })
            );
          }
        });
      } else {
        dispatch(
          createDBSPoolAsync({
            clusterId: service.id,
            engine: service.engine,
            data: data,
          })
        ).then((action) => {
          if (action.type === "service/pools/create/fulfilled") {
            handleOnClose();
            dispatch(
              getDBSPoolsAsync({
                engine: service.engine,
                clusterId: service.id,
              })
            );
          }
        });
      }
    }
  };

  const databasesOptions = useMemo(() => {
    return databases.map((db) => ({ label: db.name, value: db.id }));
  }, [databases]);

  const usersOptions = useMemo(() => {
    const usersOptions = users.map((u) => ({ label: u.username, value: u.id }));
    return usersOptions;
    // return [{ label: "-", value: "-" }, ...usersOptions];
  }, [users]);

  const handleOnClose = useCallback(() => {
    reset();
    dispatch(handleDBSPoolEditor({ status: false, pool: null }));
  }, [dispatch, reset]);

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      contentClassName="max-w-lg"
      title={selectedPool ? "Edit pool" : `Add a pool`}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5 flex flex-col gap-3">
          <Input
            {...register("name")}
            disabled={!!selectedPool}
            error={errors.name?.message}
            placeholder=""
            label="Name"
            required
          />

          <Controller
            control={control}
            name="databaseId"
            render={({ field: { value, onChange } }) => (
              <Select
                label="Database"
                value={value?.toString()}
                onChange={onChange}
                options={databasesOptions}
                error={errors.databaseId?.message}
                required
              />
            )}
          />

          <Controller
            control={control}
            name="mode"
            render={({ field: { value, onChange } }) => (
              <Select
                label="Mode"
                value={value?.toString()}
                onChange={onChange}
                options={modeOptions}
                error={errors.mode?.message}
                required
              />
            )}
          />

          <Input
            {...register("size")}
            type="number"
            error={errors.size?.message}
            placeholder=""
            label="Size"
            required
          />

          <Controller
            control={control}
            name="userId"
            render={({ field: { value, onChange } }) => (
              <Select
                label="Username"
                value={value !== null ? value?.toString() : ""}
                onChange={onChange}
                options={usersOptions}
                error={errors.userId?.message}
                required={false}
                clearable
              />
            )}
          />
        </div>

        <div className="mt-4 flex justify-end gap-2">
          <Button uiType="light" onClick={handleOnClose}>
            Cancel
          </Button>

          <Button
            uiType="primary"
            type="submit"
            loading={
              actionLoading ||
              serviceLoading ||
              databasesLoading ||
              usersLoading
            }
          >
            {selectedPool ? "Edit" : "Add"}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default DBSPoolCreateModal;
