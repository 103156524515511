import { useEffect } from "react";

const useConfigIntercomeSetting = (
  vertical_padding: number = 70,
  hideButton: boolean = false
) => {
  useEffect(() => {
    if (window.intercomSettings) {
      window.intercomSettings = {
        ...window.intercomSettings,
        vertical_padding,
        hide_default_launcher: hideButton,
      };
    }

    return () => {
      if (window.intercomSettings) {
        window.intercomSettings = {
          ...window.intercomSettings,
          vertical_padding: 20,
          hide_default_launcher: false,
        };
      }
    };
  }, [hideButton, vertical_padding]);
  return null;
};

export default useConfigIntercomeSetting;
