import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useSearch } from "../../../providers/SearchProvider";
import {
  getWeb3AuthEndpointUsersAsync,
  handleChangeUsersPagination,
  handleUserDataInfoModal,
  selectWeb3AuthEndpintUsersPagination,
  selectWeb3AuthEndpoint,
  selectWeb3AuthEndpointLoading,
  selectWeb3AuthEndpointUsers,
  selectWeb3AuthEndpointUsersLoading,
} from "../../../store/web3-auth/web3AuthEndpointSlice";
import { Web3AuthEndpointUser } from "../../../types/web3-auth";
import { timeAgo } from "../../../utils/date";
import { ReactComponent as EyeIcon } from "./../../../assets/icons/eye.svg";
import Web3UserDataInfoModal from "./Web3UserDataInfoModal";
import BlankAvatarImg from "./../../../assets/images/blank-avatar.png";
import {
  Button,
  SimpleTable,
  Typography,
  Input,
  EmptyState,
  Flex,
  Pagination,
} from "djuno-design";
import { getIpfsApiKeysAysnc } from "../../../store/ipfs/apiKeysSlice";
import { useTour } from "../../../providers/TourProvider";

const Web3AuthEndpointUsersTab = () => {
  const endpoint = useAppSelector(selectWeb3AuthEndpoint);
  const endpointLoading = useAppSelector(selectWeb3AuthEndpointLoading);
  const users = useAppSelector(selectWeb3AuthEndpointUsers);
  const usersPagination = useAppSelector(selectWeb3AuthEndpintUsersPagination);
  const usersLoading = useAppSelector(selectWeb3AuthEndpointUsersLoading);
  const [filteredUsers, setFilteredUsers] = useState<Web3AuthEndpointUser[]>(
    []
  );

  const dispatch = useAppDispatch();
  const { run } = useTour("web3-auth-page");

  const { value: searchValue } = useSearch();

  useEffect(() => {
    if (endpoint) {
      dispatch(getWeb3AuthEndpointUsersAsync(endpoint.Id.toString()));
    }
  }, [dispatch, endpoint, usersPagination.PageNumber]);

  useEffect(() => {
    dispatch(getIpfsApiKeysAysnc());
  }, [dispatch]);

  useEffect(() => {
    const lookedUpFiles = users?.filter((user) =>
      user.WalletAddress?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredUsers(lookedUpFiles);
  }, [dispatch, searchValue, users]);

  // const fetchUserAvatar = useCallback(
  //   async (user: Web3AuthEndpointUser) => {
  //     const _axios = axios.create({
  //       baseURL: env?.IpfsAddress,
  //       headers: {
  //         // Authorization: "Bearer " + getStorage("token"),
  //         "x-api-key": apiKeys[0].KeyValue,
  //       },
  //     });
  //     const { data } = await _axios.get("/web3-auth/profile/avatar", {
  //       responseType: "blob",
  //     });
  //     return URL.createObjectURL(data);
  //   },
  //   [apiKeys, env?.IpfsAddress]
  // );

  return (
    <>
      <div className="flex justify-end ">
        {" "}
        <Button
          onClick={() => {
            run();
          }}
        >
          Start Tour
        </Button>
      </div>
      <div className="mt-5 w-full user-table">
        {" "}
        <SimpleTable
          loading={usersLoading || endpointLoading}
          className="table-auto"
          containerClassName="min-h-[240px]"
        >
          <SimpleTable.Head>
            <SimpleTable.Row>
              <SimpleTable.TH lable="Profile ID" />
              <SimpleTable.TH lable="Address" />
              <SimpleTable.TH lable="Chain" />
              <SimpleTable.TH lable="Last Loging" />
              <SimpleTable.TH lable="User data" />
            </SimpleTable.Row>
          </SimpleTable.Head>
          <SimpleTable.Body>
            {filteredUsers.map((user, index) => (
              <SimpleTable.Row key={index}>
                <SimpleTable.TD>
                  <div className="flex items-center gap-2">
                    <img
                      className="object-cover w-10 h-10 rounded-full"
                      src={
                        user.AvatarFileContent
                          ? `data:image/png;base64,${user.AvatarFileContent}`
                          : BlankAvatarImg
                      }
                      alt="Avatar"
                    />
                    {/* <HighlighterText
                    searchValue={searchValue}
                    textToHighlight={user.Id}
                  /> */}
                  </div>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Input value={user.WalletAddress} copyable readOnly />
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm">
                    {user.Chain}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm">
                    {timeAgo(user.LastLogin)}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD className="w-20">
                  <Flex items="center" justify="end" className="w-full">
                    <Button
                      uiType="icon"
                      uiSize="small"
                      className="!p-2"
                      onClick={() =>
                        dispatch(
                          handleUserDataInfoModal({ status: true, user })
                        )
                      }
                    >
                      <EyeIcon className="w-5 h-5" />
                    </Button>
                  </Flex>
                </SimpleTable.TD>
              </SimpleTable.Row>
            ))}
            {filteredUsers.length === 0 && (
              <SimpleTable.Row withoutHoverStyle={true} className="h-[200px]">
                <SimpleTable.TD colSpan={5} className="!border-0">
                  <EmptyState text="No users" />
                </SimpleTable.TD>
              </SimpleTable.Row>
            )}
          </SimpleTable.Body>
        </SimpleTable>
      </div>

      <div className="flex justify-end mt-3">
        <Pagination
          siblingCount={1}
          loading={usersLoading}
          limit={usersPagination.PageSize}
          offset={(usersPagination.PageNumber - 1) * usersPagination.PageSize}
          total={usersPagination.Total !== null ? usersPagination.Total : 5}
          onPageChange={(offset: number, limit: number) => {
            const newPageNumber = Math.floor(offset / limit) + 1;
            dispatch(
              handleChangeUsersPagination({
                pageNumber: newPageNumber,
              })
            );
          }}
        />
      </div>
      <Web3UserDataInfoModal />
    </>
  );
};

export default Web3AuthEndpointUsersTab;
