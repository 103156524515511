import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { S3QuotaSchema } from "../../../../../utils/validations";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { useCallback, useEffect, useState } from "react";
import {
  selectBucketQuotaLoading,
  selectShowQuotaEditor,
  selectBucketDetails,
  handleHideQuotaEditor,
  setBucketQuotaAsync,
  getBucketQuotaAsync,
  selectBucketQuota,
} from "../../../../../store/s3/buckets/bucketSlice";
import S3QuotaForm, {
  capacityOptions,
  getQuataSize,
} from "../../forms/S3QuotaForm";
import { binarySize } from "../../../../../utils/file";
import { Button, Modal } from "djuno-design";

const S3QuotaEditorModal = () => {
  const isOpen = useAppSelector(selectShowQuotaEditor);
  const loading = useAppSelector(selectBucketQuotaLoading);
  const bucketDetails = useAppSelector(selectBucketDetails);
  const bucketQuota = useAppSelector(selectBucketQuota);

  const [enabledQuota, setEnabledQuota] = useState<boolean>(false);
  const [selectedCapacityOption, setSelectedCapacityOption] = useState<string>(
    capacityOptions[0].value
  );

  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(S3QuotaSchema(enabledQuota)),
  });

  //get bucket quota details if it is equal to null
  useEffect(() => {
    if (isOpen && bucketDetails && bucketQuota === null)
      dispatch(getBucketQuotaAsync({ name: bucketDetails.name }));
  }, [bucketDetails, bucketQuota, dispatch, isOpen]);

  //set form data with quota details
  useEffect(() => {
    if (isOpen && bucketDetails && bucketQuota !== null) {
      if (typeof bucketQuota === "object" && "quota" in bucketQuota) {
        const { number, selectedOption } = getQuataSize(bucketQuota.quota);
        setValue("capacity", number);
        setEnabledQuota(true);
        selectedOption && setSelectedCapacityOption(selectedOption.value);
      } else {
        setValue("capacity", 1);
        setEnabledQuota(false);
        setSelectedCapacityOption(capacityOptions[0].value);
      }
    }
  }, [isOpen, bucketDetails, setValue, bucketQuota]);

  const handleClearComponent = useCallback(() => {
    reset();
    setEnabledQuota(false);
    setSelectedCapacityOption(capacityOptions[0].value);
    setValue("capacity", 1);
  }, [reset, setValue]);

  const onSubmit = (data: any) => {
    // data:
    //capacity -> number or ''
    //--- others:
    // enabledQuota
    // selectedCapacityOption
    // console.log(data);
    if (bucketDetails) {
      dispatch(
        setBucketQuotaAsync({
          name: bucketDetails.name,
          formData: {
            amount: binarySize(data.capacity, selectedCapacityOption),
            enabled: enabledQuota,
            quota_type: "hard",
          },
        })
      ).then((action) => {
        if (action.type === "bucket/quota/set/fulfilled") {
          handleClearComponent();
          dispatch(handleHideQuotaEditor());
          dispatch(getBucketQuotaAsync({ name: bucketDetails.name }));
        }
      });
    }
  };

  const handleOnClose = useCallback(() => {
    dispatch(handleHideQuotaEditor());
    handleClearComponent();
  }, [dispatch, handleClearComponent]);

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      contentClassName="max-w-lg"
      title={"Update Quota"}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5 flex flex-col w-full gap-5">
          <S3QuotaForm
            enabledQuota={enabledQuota}
            setQuotaStatus={setEnabledQuota}
            capacityInputProps={{ ...register("capacity") }}
            capacityErrorMessage={errors.capacity?.message}
            selectedCapacityOption={selectedCapacityOption}
            setSelectedCapacityOption={setSelectedCapacityOption}
          />
        </div>
        <div className="mt-4 flex justify-end gap-2">
          <Button
            onClick={(e) => {
              e.preventDefault();
              dispatch(handleHideQuotaEditor());
            }}
            uiType="light"
            // buttonClassName="w-[70px]"
          >
            Cancel
          </Button>
          <Button
            loading={loading}
            uiType="primary"
            disabled={loading}
            type="submit"
            // buttonClassName="w-[70px]"
          >
            Set
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default S3QuotaEditorModal;
