import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getInstanceAsync } from "../../../store/instances/instanceSlice";
import {
  getInstancesAsync,
  selectInstances,
  selectInstancesLoading,
} from "../../../store/instances/instancesSlice";
import {
  getInstancesVolumesAsync,
  handleInstanceDetachHideModal,
  instancesDetachVolumeAsync,
  selectInstancesSelectedVolume,
  selectInstancesVolumesActionLoading,
  selectShowInstanceDetachModal,
} from "../../../store/instances/instancesVolumesSlice";
import { Button, Modal, Typography } from "djuno-design";

const InstanceDetachModal = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectShowInstanceDetachModal);
  const instances = useAppSelector(selectInstances);
  const instancesLoading = useAppSelector(selectInstancesLoading);
  const volume = useAppSelector(selectInstancesSelectedVolume);
  const actionLoading = useAppSelector(selectInstancesVolumesActionLoading);

  const { handleSubmit, reset } = useForm({
    shouldUnregister: true,
    reValidateMode: "onChange",
  });
  // useEffect(() => {
  //   if (isOpen) {
  //     dispatch(getInstancesVolumesSnapshotAsync());
  //   }
  // }, [isOpen, dispatch]);

  const attachedInstance = useMemo(() => {
    return instances.find((instance) => instance.id === volume?.attachedTo[0]);
  }, [instances, volume?.attachedTo]);

  useEffect(() => {
    if (instances.length === 0 && isOpen)
      dispatch(getInstancesAsync({ withoutLoading: true }));
  }, [instances, dispatch, isOpen]);

  const onSubmit = (data: any) => {
    if (attachedInstance && volume && !instancesLoading) {
      const formData = { instanceId: attachedInstance.id };
      dispatch(
        instancesDetachVolumeAsync({
          volumeId: volume?.id,
          data: formData,
        })
      ).then((action) => {
        if (action.type === "instances/volume/detach/fulfilled") {
          dispatch(getInstancesAsync({ withoutLoading: true }));
          dispatch(getInstanceAsync({ instanceId: attachedInstance.id }));
          dispatch(getInstancesVolumesAsync({}));
          handleOnClose();
        }
      });
    }
  };

  const handleOnClose = useCallback(() => {
    reset();
    dispatch(handleInstanceDetachHideModal());
  }, [dispatch, reset]);

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      contentClassName="max-w-lg"
      title="Detach volume from instance"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5">
          {instances.length > 0 && volume && (
            <Typography.Text className="!text-sm !pt-3 !mt-5">
              Detach the {volume.name} volume from the{" "}
              <strong>"{attachedInstance?.name}" </strong>
              instance
            </Typography.Text>
          )}
        </div>
        <div className="mt-4 flex justify-end gap-2">
          <Button uiType="light" type="button" onClick={handleOnClose}>
            Cancel
          </Button>
          <Button
            uiType="primary"
            type="submit"
            disabled={instancesLoading}
            loading={actionLoading || instancesLoading}
          >
            Confirm
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default InstanceDetachModal;
