import {
  Button,
  cn,
  Dropdown,
  Flex,
  Input,
  Modal,
  ModalProps,
  Tag,
  Tooltip,
  Typography,
} from "djuno-design";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { ScrollArea } from "../../../../general/ScrollArea";
import { ReactComponent as ArrowdownIcon } from "./../../../../../assets/icons/arrow-down.svg";
import { ReactComponent as CheckIcon } from "./../../../../../assets/icons/check.svg";
import { ReactComponent as ArrowPathIcon } from "./../../../../../assets/icons/arrow-path.svg";
import { ReactComponent as ArrowUpIcon } from "./../../../../../assets/icons/arrow-up.svg";
import { ReactComponent as GitHubIcon } from "./../../../../../assets/icons/socials/github.svg";
import { ReactComponent as EditIcon } from "./../../../../../assets/icons/pencil-square.svg";
import { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { TreeViewProps } from "./FileExplorerSection";
import { getFileIcon, getFileName } from "../utils";
import { useDappGit, IGitFile, GitFlowType } from "../contexts/DappGitContext";
import { useDappFilesContext } from "../contexts/DappFilesContext";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DappStudioRemoteDataSchema } from "../../../../../utils/validations";

const SourceControlSection = () => {
  const { loadPath } = useDappFilesContext();

  const {
    gitHasInit,
    gitHasRemote,
    gitStatus,

    gitInitLoading,
    handleGitInit,

    gitStatusLoading,
    handleGitStatus,

    handleGitLog,

    gitAddLoading,
    handleGitAdd,

    gitCommitLoading,
    handleGitCommit,

    handleGitDiff,

    gitPushLoading,
    handleGitPush,

    gitPullLoading,
    handleGitPull,

    addRemoteLoading,
    handleGitGetRemote,

    changedFiles,
    stagedChangedFiles,
  } = useDappGit();

  const [expandedSections, setExpandedSections] = useState(["Changes"]);
  const [commitMessage, setCommitMessage] = useState("");
  const [gitFlowType, setGitFlowType] = useState<GitFlowType | null>(null);
  const [noStagedChangeModal, setNoStagedChangeModal] = useState(false);
  const [remoteModal, setRemoteModal] = useState(false);

  const handleToggleExpand = useCallback((sectionName: string) => {
    setExpandedSections((prev) => {
      if (prev.find((e) => e === sectionName)) {
        return prev.filter((e) => e !== sectionName);
      } else {
        return [...prev, sectionName];
      }
    });
  }, []);

  useEffect(() => {
    if (stagedChangedFiles.length) {
      setExpandedSections((prev) => [...prev, "Staged Changes"]);
    }
  }, [stagedChangedFiles]);

  const allChangedFilesLen = useMemo(
    () => changedFiles.length + stagedChangedFiles.length,
    [changedFiles.length, stagedChangedFiles.length]
  );

  const handleInit = useCallback(async () => {
    await handleGitInit();
    await handleGitGetRemote();
    await loadPath("");
  }, [handleGitGetRemote, handleGitInit, loadPath]);

  const handleRunGitCommand = useCallback(
    async (strategy: GitFlowType, hasRemote: boolean) => {
      try {
        if (commitMessage) {
          if (strategy === "Commit") {
            await handleGitCommit(commitMessage);
          }

          if (strategy === "AddAndCommit") {
            await handleGitAdd(".");
            await handleGitCommit(commitMessage);
          }

          if (strategy === "CommitAndPush") {
            await handleGitCommit(commitMessage);
            if (hasRemote) {
              await handleGitPush();
              setGitFlowType(null);
            } else {
              setRemoteModal(true);
            }
          }

          if (strategy === "AddAndCommitAndPush") {
            await handleGitAdd(".");
            await handleGitCommit(commitMessage);
            if (hasRemote) {
              await handleGitPush();
              setGitFlowType(null);
            } else {
              setRemoteModal(true);
            }
          }

          if (strategy === "CommitAndSync") {
            await handleGitCommit(commitMessage);
            await handleGitPush();
            if (hasRemote) {
              await handleGitPush();
              setGitFlowType(null);
            } else {
              setRemoteModal(true);
            }
          }

          if (strategy === "AddAndCommitAndSync") {
            await handleGitAdd(".");
            await handleGitCommit(commitMessage);
            if (hasRemote) {
              await handleGitPush();
              await handleGitPull();
              setGitFlowType(null);
            } else {
              setRemoteModal(true);
            }
          }

          setCommitMessage("");
          setNoStagedChangeModal(false);
        }

        if (strategy === "Push") {
          if (hasRemote) {
            await handleGitPush();
            setGitFlowType(null);
          } else {
            setRemoteModal(true);
          }
        }

        if (strategy === "Sync") {
          if (gitStatus) {
            if (hasRemote) {
              if (gitStatus.ahead) await handleGitPush();
              if (gitStatus.behind) await handleGitPull();
              setGitFlowType(null);
            } else {
              setRemoteModal(true);
            }
          }
        }

        await handleGitStatus();
      } catch (e) {}
    },
    [
      commitMessage,
      gitStatus,
      handleGitAdd,
      handleGitCommit,
      handleGitPull,
      handleGitPush,
      handleGitStatus,
    ]
  );

  const handleCommit = useCallback(async () => {
    if (gitHasInit && gitStatus && commitMessage !== "") {
      if (stagedChangedFiles.length === 0 && changedFiles.length > 0) {
        setGitFlowType("AddAndCommit");
        setNoStagedChangeModal(true);
      } else {
        setGitFlowType("Commit");
        await handleRunGitCommand("Commit", gitHasRemote);
      }
    }
  }, [
    changedFiles.length,
    commitMessage,
    gitHasInit,
    gitHasRemote,
    gitStatus,
    handleRunGitCommand,
    stagedChangedFiles.length,
  ]);

  const handleCommitAndPush = useCallback(async () => {
    if (gitHasInit && gitStatus && commitMessage !== "") {
      if (stagedChangedFiles.length === 0 && changedFiles.length > 0) {
        setGitFlowType("AddAndCommitAndPush");
        setNoStagedChangeModal(true);
      } else {
        setGitFlowType("CommitAndPush");
        await handleRunGitCommand("CommitAndPush", gitHasRemote);
      }
    }
  }, [
    changedFiles.length,
    commitMessage,
    gitHasInit,
    gitHasRemote,
    gitStatus,
    handleRunGitCommand,
    stagedChangedFiles.length,
  ]);

  const handleCommitAndSync = useCallback(async () => {
    if (gitHasInit && gitStatus && commitMessage !== "") {
      if (stagedChangedFiles.length === 0 && changedFiles.length > 0) {
        setGitFlowType("AddAndCommitAndSync");
        setNoStagedChangeModal(true);
      } else {
        setGitFlowType("CommitAndSync");
        await handleRunGitCommand("CommitAndSync", gitHasRemote);
      }
    }
  }, [
    changedFiles.length,
    commitMessage,
    gitHasInit,
    gitHasRemote,
    gitStatus,
    handleRunGitCommand,
    stagedChangedFiles.length,
  ]);

  const handleSync = useCallback(async () => {
    if (gitHasInit && gitStatus) {
      if (allChangedFilesLen === 0) {
        setGitFlowType("Sync");
        await handleRunGitCommand("Sync", gitHasRemote);
      }
    }
  }, [
    allChangedFilesLen,
    gitHasInit,
    gitHasRemote,
    gitStatus,
    handleRunGitCommand,
  ]);

  const handleCloseNoStagedChangeModal = () => {
    setNoStagedChangeModal(false);
    setGitFlowType(null);
  };

  const handdleNeddedCommandAfterAddingRemote = useCallback(() => {
    console.log("handleRenNeddedCommandAfterAddingRemote:", gitFlowType);
    if (gitFlowType) {
      if (
        gitFlowType === "AddAndCommitAndPush" ||
        gitFlowType === "CommitAndPush" ||
        gitFlowType === "Push"
      ) {
        handleRunGitCommand("Push", true);
      }
      if (
        gitFlowType === "AddAndCommitAndSync" ||
        gitFlowType === "CommitAndSync" ||
        gitFlowType === "Sync"
      ) {
        handleRunGitCommand("Sync", true);
      }

      setGitFlowType(null);
    }
  }, [gitFlowType, handleRunGitCommand]);

  return (
    <>
      <Typography.Text size="sm" className="whitespace-nowrap px-3 mt-5">
        Source Control
      </Typography.Text>
      {!gitHasInit && (
        <Flex direction="col" className="gap-2 px-3 my-4 mt-5">
          <Typography.Text size="xs">
            Your dAPP studio doesn't have a Git repository. You can initialize a
            repository which will enable source control features powered by Git.
          </Typography.Text>
          <Button
            uiType="primary"
            uiSize="small"
            className="!w-full !justify-center"
            onClick={handleInit}
          >
            Initialize Repository
          </Button>
        </Flex>
      )}

      {gitHasInit && (
        <>
          {allChangedFilesLen > 0 && (
            <>
              <Flex direction="col" className="gap-2 px-3 my-4 mt-5">
                <Input
                  placeholder="Message"
                  className="placeholder:!text-xs !text-xs"
                  value={commitMessage}
                  onChange={(e) => setCommitMessage(e.target.value)}
                />
                <Flex>
                  <Button
                    uiType="primary"
                    uiSize="small"
                    className={cn(
                      "!rounded-r-none w-full flex justify-center flex-1",
                      {
                        "!cursor-not-allowed": false,
                      }
                    )}
                    onClick={handleCommit}
                    loading={
                      gitAddLoading || gitCommitLoading || gitPushLoading
                    }
                    disabled={commitMessage === ""}
                  >
                    <CheckIcon className="w-3 h-3 flex-shrink-0" />
                    Commit
                  </Button>
                  <Flex>
                    <Dropdown
                      anchor="bottom end"
                      itemsClassName="!w-auto"
                      menu={[
                        {
                          key: "end",
                          label: (
                            <div className="flex items-center gap-1">
                              <Typography.Text size="xs">
                                Commit
                              </Typography.Text>
                            </div>
                          ),
                          onClick: (_, close) => {
                            close();
                            handleCommit();
                          },
                        },
                        {
                          key: "end",
                          label: (
                            <div className="flex items-center gap-1">
                              <Typography.Text size="xs">
                                Commit & Push
                              </Typography.Text>
                            </div>
                          ),
                          onClick: (_, close) => {
                            close();
                            handleCommitAndPush();
                          },
                        },
                        {
                          key: "end",
                          label: (
                            <div className="flex items-center gap-1">
                              <Typography.Text size="xs">
                                Commit & Sync
                              </Typography.Text>
                            </div>
                          ),
                          onClick: (_, close) => {
                            close();
                            handleCommitAndSync();
                          },
                        },
                      ]}
                    >
                      <Button
                        uiType="primary"
                        uiSize="small"
                        className="!px-1 !rounded-l-none !bg-primary-500 disabled:!bg-secondary-200 !border-l-0"
                        disabled={commitMessage === ""}
                      >
                        <ArrowdownIcon className="w-4" />
                      </Button>
                    </Dropdown>
                  </Flex>
                </Flex>
                <GitRemoteStatusBox handleEdit={() => setRemoteModal(true)} />
              </Flex>
              <GitTree expandedSections={expandedSections}>
                {stagedChangedFiles.length > 0 && (
                  <GitFolder
                    handleToggleExpand={handleToggleExpand}
                    expanded={expandedSections.includes("Staged Changes")}
                    sectionName="Staged Changes"
                  >
                    {stagedChangedFiles.map((f, i) => (
                      <GitFile key={i} file={f} />
                    ))}
                  </GitFolder>
                )}

                <GitFolder
                  handleToggleExpand={handleToggleExpand}
                  expanded={expandedSections.includes("Changes")}
                  sectionName="Changes"
                >
                  {changedFiles.map((f, i) => (
                    <GitFile key={i} file={f} />
                  ))}
                </GitFolder>
              </GitTree>
            </>
          )}
          {allChangedFilesLen === 0 && (
            <>
              {gitHasRemote && (
                <Flex direction="col" className="gap-2 px-3 my-4 mt-5">
                  <Button
                    uiType="primary"
                    uiSize="small"
                    className={cn(
                      "!w-full flex items-center justify-center !gap-2",
                      {
                        "!cursor-not-allowed": false,
                      }
                    )}
                    onClick={handleSync}
                    disabled={
                      !!!gitStatus ||
                      (gitStatus.ahead === 0 && gitStatus.behind === 0) ||
                      gitPushLoading ||
                      gitPullLoading
                    }
                  >
                    <Flex items="center" className="gap-0.5">
                      <ArrowPathIcon
                        className={cn("w-3 h-3 flex-shrink-0", {
                          "animate-spin": gitPushLoading || gitPullLoading,
                        })}
                      />
                      Sync
                    </Flex>
                    <Flex items="center" className="gap-0.5">
                      {gitStatus && gitStatus.ahead > 0 && (
                        <Flex items="center" className="-tracking-widest">
                          <Typography.Text size="xs" uiType="transparent">
                            {gitStatus.ahead}
                          </Typography.Text>
                          <ArrowUpIcon className="w-3 h-4 flex-shrink-0" />
                        </Flex>
                      )}
                      {gitStatus && gitStatus.behind > 0 && (
                        <Flex items="center" className="-tracking-widest">
                          <Typography.Text size="xs" uiType="transparent">
                            {gitStatus.ahead}
                          </Typography.Text>
                          <ArrowUpIcon className="w-3 h-4 flex-shrink-0 rotate-180" />
                        </Flex>
                      )}
                    </Flex>
                  </Button>
                  <GitRemoteStatusBox handleEdit={() => setRemoteModal(true)} />
                </Flex>
              )}

              {!gitHasRemote && (
                <Flex direction="col" className="gap-2 px-3 my-4 mt-5">
                  <Button
                    uiType="primary"
                    uiSize="small"
                    className="!w-full flex items-center justify-center !gap-2"
                    onClick={() => setRemoteModal(true)}
                    loading={addRemoteLoading}
                  >
                    <Flex items="center" className="gap-0.5">
                      <GitHubIcon className="w-3 h-3 flex-shrink-0" />
                      Publish
                    </Flex>
                  </Button>
                </Flex>
              )}
            </>
          )}
        </>
      )}

      <Modal
        isOpen={noStagedChangeModal}
        onClose={handleCloseNoStagedChangeModal}
        contentClassName="max-w-lg"
        title="Attention"
      >
        <Flex direction="col" className="my-5">
          <Typography.Text size="sm">
            There are no staged changes to commit
          </Typography.Text>
          <Typography.Text size="sm">
            Would you like to stage all your changes and commit them directly?
          </Typography.Text>
        </Flex>
        <Flex items="center" justify="end" className="gap-2">
          <Button uiSize="small" onClick={handleCloseNoStagedChangeModal}>
            Cancel
          </Button>
          <Button
            uiSize="small"
            uiType="primary"
            onClick={() =>
              gitFlowType && handleRunGitCommand(gitFlowType, gitHasRemote)
            }
            loading={gitAddLoading || gitCommitLoading || gitPushLoading}
          >
            Yes
          </Button>
        </Flex>
      </Modal>
      <GitRemoteConfigModal
        isOpen={remoteModal}
        onConfirm={handdleNeddedCommandAfterAddingRemote}
        onClose={() => setRemoteModal(false)}
      />

      <Flex direction={"col"} className="gap-2 mt-2 px-3">
        {/* <Button
          uiType="primary"
          uiSize="small"
          className="!w-full !justify-center"
          onClick={() => handleGitStatus()}
        >
          Status
        </Button> */}
        {/* <Button
          uiType="primary"
          uiSize="small"
          className="!w-full !justify-center"
          onClick={() => handleGitLog()}
        >
          Log
        </Button> */}

        {/* <Button
          uiType="primary"
          uiSize="small"
          className="!w-full !justify-center"
          onClick={() => handleGitAdd(".")}
        >
          Add
        </Button> */}

        {/* <Button
          uiType="primary"
          uiSize="small"
          className="!w-full !justify-center"
          onClick={() => handleGitAddRemote()}
        >
          Add Remote
        </Button> */}

        <Button
          uiType="primary"
          uiSize="small"
          className="!w-full !justify-center"
          onClick={() => handleGitDiff()}
        >
          Diff
        </Button>
      </Flex>
    </>
  );
};

export const GitTree = forwardRef<
  HTMLDivElement,
  TreeViewProps & { expandedSections: string[] }
>(({ className, children, expandedSections, ...props }, ref) => {
  return (
    <ScrollArea
      ref={ref}
      className={cn(
        "tree w-full flex-1 border border-transparent transition-colors duration-300",
        className
      )}
      scrollbarClassName="!w-1.5"
    >
      <AccordionPrimitive.Root
        {...props}
        type="multiple"
        dir="ltr"
        defaultValue={expandedSections}
        value={expandedSections}
        className="flex flex-col max-h-full pb-10"
      >
        {children}
      </AccordionPrimitive.Root>
    </ScrollArea>
  );
});

const GitFolder = forwardRef<
  HTMLDivElement,
  {
    sectionName: string;
    expanded: boolean;
    handleToggleExpand: (sectionName: string) => void;
  } & React.HTMLAttributes<HTMLDivElement>
>(
  (
    {
      className,
      sectionName,
      expanded,
      handleToggleExpand,
      children,
      ...props
    },
    ref
  ) => {
    // const { show } = useContextMenu({
    //   id: CONTEXT_MENU_ID,
    // });

    return (
      <AccordionPrimitive.Item
        {...props}
        value={sectionName}
        className="relative h-full overflow-hidden"
      >
        <AccordionPrimitive.Trigger
          className={cn(
            `flex items-center gap-1 w-full select-none py-1 cursor-pointer hover:bg-gray-100 dark:hover:bg-dark-2`,
            className
          )}
          onClick={(e) => {
            e.stopPropagation();
            handleToggleExpand(sectionName);
          }}
          // onContextMenu={(e) => {
          //   show({
          //     event: e,
          //     props: {
          //       file,
          //     },
          //   });
          // }}
        >
          <ArrowdownIcon
            className={cn(
              "w-4 h-4 flex-shrink-0 text-slate-700 dark:text-slate-200 transition-all duration-200 -rotate-90",
              { "rotate-0": expanded }
            )}
          />

          <Typography.Text size="xs">{sectionName}</Typography.Text>
        </AccordionPrimitive.Trigger>
        <AccordionPrimitive.Content className="relative h-full overflow-hidden text-sm data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down group">
          <AccordionPrimitive.Root
            dir={"ltr"}
            type="multiple"
            className="pl-3 flex flex-col"
            // defaultValue={["Changes"]}
            // value={["Changes"]}
          >
            {children}
          </AccordionPrimitive.Root>
        </AccordionPrimitive.Content>
      </AccordionPrimitive.Item>
    );
  }
);

const GitFile = forwardRef<
  HTMLButtonElement,
  {
    file: IGitFile | undefined;
    isSelectable?: boolean;
  } & React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ file, className, isSelectable = true, children, ...props }, ref) => {
  // const { show } = useContextMenu({
  //   id: CONTEXT_MENU_ID,
  // });

  if (!file) return null;

  const fileStatus =
    file.working_dir === "?"
      ? "U"
      : file.working_dir === " "
      ? file.index
      : file.working_dir;

  return (
    <button
      ref={ref}
      type="button"
      disabled={!isSelectable}
      className={cn(
        "w-full select-none py-1",
        {
          // "bg-blue-500/20 dark:bg-blue-500/80": isSelected,
          "hover:bg-gray-100 dark:hover:bg-dark-2": true,
        },
        isSelectable ? "cursor-pointer" : "cursor-not-allowed opacity-50",
        className
      )}
      onClick={(e) => {
        e.stopPropagation();
        // handleFileClick(file);
      }}
      // onContextMenu={(e) => {
      //   show({
      //     event: e,
      //     props: {
      //       file,
      //     },
      //   });
      // }}
      {...props}
    >
      <div className="flex items-center justify-between gap-1 w-full pr-1 max-w-full">
        <div className="flex items-center gap-1 flex-1 min-w-0">
          {getFileIcon(file.path)}
          <Typography.Text
            size="xs"
            // uiType={isDirtyFile(file.path) ? "danger" : undefined}
            className="whitespace-nowrap overflow-hidden text-ellipsis"
          >
            {getFileName(file.path)}
          </Typography.Text>
        </div>

        <Typography.Text
          className={cn("!text-xs", {
            "!text-green-500": fileStatus === "U",
            "!text-green-700": fileStatus === "A",
            "!text-orange-500": fileStatus === "M",
          })}
        >
          {fileStatus}
        </Typography.Text>
      </div>
    </button>
  );
});

const GitRemoteConfigModal: React.FC<
  ModalProps & { onConfirm: () => void }
> = ({ onConfirm, onClose, ...props }) => {
  const [updateRemote, setUpdateRemote] = useState(false);
  const { gitHasRemote, handleGitAddRemote, addRemoteLoading } = useDappGit();

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(DappStudioRemoteDataSchema),
  });

  const handleSubmitAddRemote = async (data: any) => {
    if (!addRemoteLoading) {
      try {
        await handleGitAddRemote(data);
        onConfirm();
        handleClose();
      } catch (e) {}
    }
  };

  const handleClose = () => {
    setUpdateRemote(false);
    reset();
    if (onClose) onClose();
  };

  return (
    <Modal
      contentClassName="max-w-lg"
      title="Remote Data"
      onClose={handleClose}
      {...props}
    >
      <Flex direction="col" className="mt-5">
        {gitHasRemote && !updateRemote && (
          <div className="flex flex-col gap-3">
            <Typography.Text size="sm">
              Remote added successfully
            </Typography.Text>
            <Typography.Text size="sm">
              Remote Name: <Tag>origin</Tag>
            </Typography.Text>
            <Typography.Text size="sm">
              Branch: <Tag>main</Tag>
            </Typography.Text>

            <Flex items="center" justify="end" className="gap-2">
              <Button
                uiSize="small"
                uiType="primary"
                onClick={() => setUpdateRemote(true)}
              >
                Add New Remote
              </Button>
            </Flex>
          </div>
        )}

        {(!gitHasRemote || updateRemote) && (
          <form onSubmit={handleSubmit(handleSubmitAddRemote)}>
            <div className="mt-5 flex flex-col w-full gap-4">
              <Input
                label="GitHub Username"
                {...register("username")}
                error={errors.username?.message}
                placeholder=""
                uiSize="small"
              />

              <Input
                label="Token"
                tooltip={{
                  content: "You can generate a token from your GitHub account",
                  className: "!text-xs",
                }}
                {...register("token")}
                error={errors.token?.message}
                placeholder=""
                uiSize="small"
              />

              <Input
                label="Repo URL"
                {...register("repoUrl")}
                error={errors.repoUrl?.message}
                placeholder=""
                uiSize="small"
              />

              <Flex items="center" justify="end" className="gap-2">
                <Button uiSize="small" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  uiSize="small"
                  uiType="primary"
                  type="submit"
                  loading={addRemoteLoading}
                >
                  Add
                </Button>
              </Flex>
            </div>
          </form>
        )}
      </Flex>
    </Modal>
  );
};

const GitRemoteStatusBox: React.FC<{ handleEdit?: () => void }> = ({
  handleEdit,
}) => {
  return (
    <div className="bg-slate-200 dark:bg-dark-2 flex flex-col rounded-md p-2 gap-1">
      <Flex items="center" justify="between">
        <Typography.Text size="xs">
          Remote: <Tag>origin</Tag>
        </Typography.Text>

        {handleEdit && (
          <Tooltip content="Edit Remote" className="!text-xs">
            <EditIcon
              className="w-4 h-4 flex-shrink-0 text-slate-600 hover:text-slate-800 dark:text-slate-300 dark:hover:text-slate-100 cursor-pointer"
              onClick={handleEdit}
            />
          </Tooltip>
        )}
      </Flex>
    </div>
  );
};
export default SourceControlSection;
