import { useEffect, useMemo, useState } from "react";
import { ReactComponent as MoreIcon } from "./../../../../assets/icons/more.svg";
import { ReactComponent as ArchiveIcon } from "./../../../../assets/icons/archive-box.svg";
import {
  selectBucketDetails,
  selectBucketDetailsLoading,
} from "../../../../store/s3/buckets/bucketSlice";
import {
  deleteBucketAnonymousAccessAsync,
  getBucketAnonymousesAsync,
  handleShowAnonymousModal,
  selectBucketAnonymouses,
  selectBucketAnonymousesActionLoading,
  selectBucketAnonymousesLoading,
} from "../../../../store/s3/buckets/setting/anonymousSlice";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { S3NotActiveTab } from "../../../layouts/NotData";
import { DeleteModal } from "../../../modals/QuestionModal";
import S3AnonymousAccessModal from "../modals/buckets/S3AnonymousAccessModal";
import { selectS3Status } from "../../../../store/s3/s3PublicSlice";
import { S3Anonymous, S3AnonymousDelete } from "../../../../types/s3-anonymous";
import { ReactComponent as EditIcon } from "./../../../../assets/icons/pencil-square.svg";
import {
  Button,
  Dropdown,
  EmptyState,
  SimpleTable,
  Typography,
} from "djuno-design";

const S3BucketAnonymousTab = () => {
  const s3Status = useAppSelector(selectS3Status);
  const bucketDetails = useAppSelector(selectBucketDetails);
  const bucketDetailsLoading = useAppSelector(selectBucketDetailsLoading);

  const anonymouses = useAppSelector(selectBucketAnonymouses);
  const loading = useAppSelector(selectBucketAnonymousesLoading);
  const actionLoading = useAppSelector(selectBucketAnonymousesActionLoading);

  const [deleteAnonymous, setDeleteAnonymous] = useState<S3Anonymous | null>(
    null
  );
  const dispatch = useAppDispatch();

  const sortedAnonymouses = useMemo(() => {
    return [...anonymouses].sort((a, b) => {
      const prefixA = a.prefix || ""; // Default to empty string if prefix is undefined
      const prefixB = b.prefix || ""; // Default to empty string if prefix is undefined
      return prefixA.localeCompare(prefixB); // String comparison
    });
  }, [anonymouses]);

  useEffect(() => {
    if (bucketDetails && !bucketDetailsLoading) {
      dispatch(getBucketAnonymousesAsync({ name: bucketDetails.name }));
    }
  }, [bucketDetails, dispatch, bucketDetailsLoading]);

  const handleDelete = () => {
    if (deleteAnonymous && bucketDetails) {
      const deleteData: S3AnonymousDelete = {
        prefix: deleteAnonymous.prefix || "/",
      };
      dispatch(
        deleteBucketAnonymousAccessAsync({
          bucketName: bucketDetails.name,
          data: deleteData,
        })
      ).then((action) => {
        if (action.type === "bucket/anonymous-access/delete/fulfilled") {
          dispatch(getBucketAnonymousesAsync({ name: bucketDetails.name }));
          setDeleteAnonymous(null);
        }
      });
    }
  };

  if (!s3Status) {
    return <S3NotActiveTab />;
  }
  return (
    <>
      <div className="flex transition-all duration-500 items-center justify-between gap-3">
        <Typography.Title
          level={5}
          className="!mb-0 whitespace-nowrap font-medium"
        >
          Anonymous Access
        </Typography.Title>
        <div className="flex items-center justify-end gap-1 md:gap-2 ">
          <Button
            uiType="primary"
            onClick={() => dispatch(handleShowAnonymousModal({}))}
          >
            Add Access Rule
          </Button>
        </div>
      </div>
      <div className="mt-5 w-full">
        <SimpleTable loading={loading} containerClassName="min-h-[240px]">
          <SimpleTable.Head>
            <SimpleTable.Row>
              <SimpleTable.TH lable="Prefix" />
              <SimpleTable.TH lable="Access" />
              <SimpleTable.TH lable="" />
            </SimpleTable.Row>
          </SimpleTable.Head>
          <SimpleTable.Body>
            {sortedAnonymouses?.map((anonymouse, i) => (
              <SimpleTable.Row key={i}>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm !font-semibold whitespace-nowrap">
                    {anonymouse.prefix || "/"}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm whitespace-nowrap">
                    {anonymouse.access}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD className="w-32 gap-1">
                  <div className="h-full w-full inline-flex items-center justify-end gap-1">
                    <div className="w-8 flex justify-center items-center">
                      <Dropdown
                        itemsClassName="!w-40"
                        anchor="bottom end"
                        menu={[
                          {
                            key: "end",
                            label: (
                              <div className="flex items-center gap-1">
                                <EditIcon className="w-4" />
                                Edit
                              </div>
                            ),
                            onClick: (_, close) => {
                              close();
                              dispatch(
                                handleShowAnonymousModal({ anonymouse })
                              );
                            },
                          },
                          {
                            key: "end",
                            label: (
                              <div className="flex items-center gap-1">
                                <ArchiveIcon className="w-4" />
                                Delete
                              </div>
                            ),
                            danger: true,
                            onClick: (_, close) => {
                              close();
                              setDeleteAnonymous(anonymouse);
                            },
                          },
                        ]}
                      >
                        <Button uiType="icon" uiSize="small" className="!px-2">
                          <MoreIcon className="w-4 h-4" />
                        </Button>
                      </Dropdown>
                    </div>
                  </div>
                </SimpleTable.TD>
              </SimpleTable.Row>
            ))}

            {anonymouses?.length === 0 && (
              <SimpleTable.Row withoutHoverStyle={true} className="h-[200px]">
                <SimpleTable.TD colSpan={3} className="!border-0">
                  <EmptyState />
                </SimpleTable.TD>
              </SimpleTable.Row>
            )}
          </SimpleTable.Body>
        </SimpleTable>
      </div>
      <S3AnonymousAccessModal />

      <DeleteModal
        title="Delete Anonymous Access"
        isOpen={deleteAnonymous !== null}
        onClose={() => setDeleteAnonymous(null)}
        onConfirm={handleDelete}
        confirmButtonType="danger"
        loading={actionLoading || bucketDetailsLoading}
        // confirmString={
        //   anonymouses.find((a) => a.prefix === deleteAnonymous?.prefix)?.prefix
        // }
      />
    </>
  );
};

export default S3BucketAnonymousTab;
