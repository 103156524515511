import React, { useCallback, useEffect, useMemo } from "react";
import {
  TourProvider as ReactTourProvider,
  StepType,
  useTour as useReactTour,
} from "@reactour/tour";
import { useDjunoDesign } from "djuno-design";

export type TourProviderProps = {
  children: React.ReactNode;
};

function TourProvider({ children }: TourProviderProps) {
  const { theme } = useDjunoDesign();
  const { mode } = theme;
  return (
    <ReactTourProvider
      steps={[]}
      scrollSmooth
      styles={{
        popover: (base) => ({
          ...base,
          borderRadius: "10px",
          background: mode === "dark" ? "#202425" : "#fff",
          color: mode === "dark" ? "#eee" : "#202425",
        }),
      }}
    >
      {children}
    </ReactTourProvider>
  );
}

export const useTour = (tour_name: string) => {
  const { setIsOpen, setMeta, setSteps, setCurrentStep } = useReactTour();

  const new_steps: StepType[] = useMemo(() => {
    return getTourSteps(tour_name);
  }, [tour_name]);

  useEffect(() => {
    if (setSteps) setSteps(new_steps);
    if (setMeta) setMeta(tour_name);
  }, [new_steps, setMeta, setSteps, tour_name]);

  const run = useCallback(() => {
    setCurrentStep(0);
    setIsOpen(true);
  }, [setCurrentStep, setIsOpen]);

  return { run };
};

const getTourSteps = (name: string): StepType[] => {
  switch (name) {
    case "sign-up":
      return [];
    case "instances":
      return [
        {
          selector: ".instances-header",
          content:
            "Here you can manage your instances. You can refresh the list or create new instances.",
        },
        {
          selector: ".refresh-button",
          content: "Click here to refresh the list of instances.",
        },
        {
          selector: ".create-instance-button",
          content: "Click here to create a new instance.",
        },
        {
          selector: ".ssh-key",
          content:
            "In the SSH Keys tab, you can manage and create SSH keys for secure instance access, With the option to Create New keys as needed or remove it.",
        },
        {
          selector: ".volumes",
          content:
            "In cloud computing, volumes refer to virtual storage units that can be attached to instances for data storage and management. They provide additional storage capacity beyond what is typically offered by the instance itself. ",
        },
        {
          selector: ".backups",
          content:
            "In the Backups tab, users are informed that backups are automatic copies of the server's disk. ",
        },
        {
          selector: ".instance-table",
          content: "This table shows all your instances and their details.",
        },
      ];
    case "instances-ssh-keys":
      return [
        {
          selector: "#create-ssh-key-button",
          content: "Click here to create a new SSH key.",
        },
        {
          selector: "#ssh-keys-table",
          content: "This table shows all your SSH keys and their details.",
        },
      ];
    case "databases":
      return [
        {
          selector: ".databases-header",
          content:
            "Here you can manage your databases. You can refresh the list or create new databases.",
        },
        {
          selector: ".refresh-button",
          content: "Click here to refresh the list of databases.",
        },
        {
          selector: ".create-database-button",
          content: "Click here to create a new database.",
        },
        // {
        //   selector: ".ssh-key",
        //   content:
        //     "In the SSH Keys tab, you can manage and create SSH keys for secure instance access, With the option to Create New keys as needed or remove it.",
        // },
        // {
        //   selector: ".volumes",
        //   content:
        //     "In cloud computing, volumes refer to virtual storage units that can be attached to instances for data storage and management. They provide additional storage capacity beyond what is typically offered by the instance itself. ",
        // },
        // {
        //   selector: ".backups",
        //   content:
        //     "In the Backups tab, users are informed that backups are automatic copies of the server's disk. ",
        // },
        {
          selector: ".database-table",
          content: "This table shows all your databases and their details.",
        },
      ];
    case "block-events":
      return [
        {
          selector: ".block-events-header",
          content:
            "Here you can manage your block events. You can  create new block eevent.",
        },

        {
          selector: ".create-block-event-button",
          content: "Click here to create a new block event.",
        },
        {
          selector: ".block-event-table",
          content: "This table shows all your block events and their details.",
        },
        {
          selector: ".destinations",
          content:
            "In the Destinations tab,you can set up Webhook notifications by providing a name, URL, and request type.",
        },
        {
          selector: ".plans",
          content: "In the Plans tab you can see flexible pricing.",
        },
      ];
    case "rpcs":
      return [
        {
          selector: ".rpcs-header",
          content:
            "Here you can manage your rpc. You can refresh the list or create new rpcs.",
        },
        {
          selector: ".refresh-button",
          content: "Click here to refresh the list of rpcs.",
        },
        {
          selector: ".create-rpc-button",
          content: "Click here to create a new rpc.",
        },
        {
          selector: ".plans",
          content: "In the Plans tab you can see flexible pricing.",
        },
        {
          selector: ".rpc-table",
          content: "This table shows all your rpcs and their details.",
        },
      ];
    case "rps-get-started":
      return [
        {
          selector: ".rpc-card",
          content:
            "Here are your HTTP and WSS providers for the RPC node. Use HTTP for REST communication and WSS for WebSocket interactions. These endpoints support read and write operations on the configured blockchain network. Copy the URLs to start using them.",
        },

        {
          selector: ".metrics",
          content:
            "In the Metrics tab, you can monitor traffic, throughput rate, response time, and request throughput.",
        },
        {
          selector: ".security",
          content:
            "In the Security tab, you'll find that Djuno offers various security features for your endpoint, which caters to a wide range of use cases.",
        },
      ];
    case "webapps":
      return [
        {
          selector: ".webapps-header",
          content:
            "Here you can manage your web apps. You can refresh the list or create new web app.",
        },
        {
          selector: ".refresh-button",
          content: "Click here to refresh the list of web apps.",
        },
        {
          selector: ".create-webapp-button",
          content: "Click here to create a new web app.",
        },
        {
          selector: ".webapp-table",
          content: "This table shows all your web apps and their details.",
        },
      ];
    case "webapp-page":
      return [
        {
          selector: ".webapp-page-header",
          content:
            "Here you can test,upgrade your instance, or restart the web app.",
        },
        {
          selector: ".upgrade-tab",
          content: "Here you can upgrade your instance.",
        },
        {
          selector: ".restart-button",
          content: "Here you can restart your web app.",
        },
        {
          selector: ".webapp-page-url",
          content: "Click here to copy the URL",
        },
        {
          selector: ".new-tab-button",
          content: "Click here to open the URL in the new tab",
        },
        {
          selector: ".webapp-page-actions",
          content:
            "Here you can view various actions performed, along with their timestamps and messages, ",
        },
        {
          selector: ".logs",
          content: "In the Logs tab you can access the application logs. ",
        },
        {
          selector: ".environment",
          content:
            "In the Environment tab you can modify and set environments variable necessary for running applications.",
        },
        {
          selector: ".sell",
          content:
            "In the Sell tab you can access your running application via shell, press connect a terminal will be prompted.",
        },
        {
          selector: ".metrics",
          content:
            "In the Metrics tab you can get an overview of traffic, access, response time and cpu and ram alocation.",
        },
        {
          selector: ".scaling",
          content:
            "This tab offers you horizantal and vertical scaling capability.",
        },
        {
          selector: ".events",
          content:
            "Events tab gives you full history of events for deployment of the application, you can find many information here.",
        },
        {
          selector: ".settings",
          content:
            "In the Settings tab you can modify port, change your instance type, change your image, and lastly set a custom domain name ",
        },
      ];
    case "workflows":
      return [
        {
          selector: ".workflows-header",
          content:
            "Here you can manage your workflows. You can refresh the list or create new workflow.",
        },
        {
          selector: ".flow-direction-button",
          content: "Click here to ....",
        },
        {
          selector: ".create-workflow-button",
          content: "Click here to create a new workflow.",
        },
        {
          selector: ".workflows-table",
          content: "This table shows all your workflows and their details.",
        },
        {
          selector: ".contracts",
          content: "In the Contracts tab, ....",
        },
        {
          selector: ".wallets",
          content: "In the Wallets tab, ....",
        },
        {
          selector: ".accesskeys",
          content: "In the Accesskey tab, ....",
        },
        {
          selector: ".plans",
          content: "In the Plans tab you can see flexible pricing.",
        },
      ];
    case "blockchains":
      return [
        {
          selector: ".blockchain-header",
          content:
            "Here you can manage your blockchains. You can refresh the list or create new blockchain.",
        },
        {
          selector: ".refresh-button",
          content: "Click here to refresh the list of blockchains.",
        },
        {
          selector: ".create-blockchain-button",
          content: "Click here to create a new blockchain.",
        },
        {
          selector: ".blockchain-table",
          content: "This table shows all your blockchains and their details.",
        },
      ];
    case "blockchain-page":
      return [
        {
          selector: ".blockchain-page-header",
          content: "Here you can ",
        },
        {
          selector: ".upgrade-tab",
          content: "Here you can upgrade your instance.",
        },
        {
          selector: ".metmask-button",
          content: "Here you can ....",
        },
        {
          selector: ".blockchain-card",
          content: "...",
        },
        {
          selector: ".metrics",
          content: "...",
        },
        {
          selector: ".settings",
          content: "....",
        },
      ];
    case "ipfs":
      return [
        {
          selector: ".ipfs-header",
          content:
            "Here you can manage your ipfs. You can refresh the list or create new ipfs.",
        },
        {
          selector: ".new-folder-button",
          content: "Click here to create a new folder.",
        },
        {
          selector: ".upload-file-button",
          content: "Click here to upload a file.",
        },
        {
          selector: ".files-table",
          content: "This table shows all your files and their details.",
        },
        {
          selector: ".overview",
          content:
            "In the Overview tab you can access some metric and usage of your IPFS node.",
        },
        {
          selector: ".settings",
          content:
            "In the Settings tab you can create API keys in order to interact with your IPFS node.",
        },
        {
          selector: ".plans",
          content: "In the Plans tab you can see flexible pricing.",
        },
      ];
    case "buckets":
      return [
        {
          selector: ".bucket-header",
          content:
            "Here you can manage your buckets. You can refresh the list or create new bucket.",
        },
        {
          selector: ".refresh-button",
          content: "Click here to refresh the list of buckets.",
        },
        {
          selector: ".lifecycle-button",
          content:
            "Click here to create lifecycle rules for managing the lifecycle of objects in your bucket. Set automated rules for expiry, transitioning storage classes, and more.",
        },
        {
          selector: ".replication-button",
          content:
            "Click here to create multiple bucket replication. Configure the replication of multiple buckets to a remote storage endpoint to enhance data redundancy and backup capabilities.",
        },
        {
          selector: ".create-bucket-button",
          content: "Click here to create a new bucket.",
        },
        {
          selector: ".bucket-table",
          content: "This table shows all your bucketss and their details.",
        },
        {
          selector: ".overview",
          content:
            "In the Overview tab, view key metrics like buckets, objects, usage, and detailed traffic, throughput, and performance data.",
        },
        {
          selector: ".access-keys",
          content:
            "In the Access Keys tab, manage keys for secure API access, enabling authentication, automation, and service integration.",
        },
        {
          selector: ".events",
          content:
            "In the Events tab, set up integrations for real-time notifications and data processing, automating workflows, alerts, or synchronization based on bucket events like uploads or deletions.",
        },
        {
          selector: ".logs",
          content:
            "In the Logs tab, view recent actions on the bucket, including DELETE, PUT, and POST actions, along with timestamps and affected entities for tracking and troubleshooting.",
        },
        {
          selector: ".tier",
          content:
            "In the Tiers tab, manage storage tiers for your bucket, optimizing storage and costs by distributing data across services like MinIO, Google Cloud, Amazon S3, and Azure.",
        },
        {
          selector: ".plans",
          content: "In the Plans tab you can see flexible pricing.",
        },
      ];
    case "bucket-page":
      return [
        {
          selector: ".bucket-header",
          content:
            "Here you can manage your bucket, refresh, rewind, create new paths, and upload files.",
        },
        {
          selector: ".rewind-button",
          content:
            "Click here to restore a bucket to a previous state by selecting a specific date and time..",
        },
        {
          selector: ".refresh-button",
          content: "Click here to refresh the list of objects.",
        },
        {
          selector: ".new-path-button",
          content: "Click here to create new path.",
        },
        {
          selector: ".upload-button",
          content: "Click here to upload file",
        },

        {
          selector: ".bucket-table",
          content: "This table shows all your bojects and their details.",
        },
        {
          selector: ".setting-button",
          content:
            "In the Settings tab, manage bucket configurations like deletion, event notifications, replication, lifecycle policies, access policies, encryption, tags, quotas, versioning, and retention.",
        },
      ];
    case "bucket-setting":
      return [
        {
          selector: ".bucket-setting-header",
          content:
            "Here you can manage your bucket's settings, browse contents, refresh object lists, and delete the bucket.",
        },
        {
          selector: ".browse-button",
          content:
            "Click here to browse a bucket's contents, create folders, upload files, and view or manage file details, including downloads, sharing, tags, and retention policies.",
        },
        {
          selector: ".refresh-button",
          content:
            "Click here to refresh bucket details, including access policy, encryption status, usage, object locking, quotas, tags, and versioning status.",
        },
        {
          selector: ".delete-button",
          content: "Click here to delete bucket.",
        },
        {
          selector: ".events",
          content:
            "In the Events tab you can stay informed about changes and manage the bucket effectively.",
        },
        {
          selector: ".replications",
          content:
            "In the Replications tab you can manage and configure replication settings for the selected bucket and add new replication rules.",
        },
        {
          selector: ".lifecycle",
          content:
            "In the Lifecycle tab you can manage and create lifecycle rules for objects in the selected bucket. You can view existing rules and create new ones that automate data management tasks.",
        },
        {
          selector: ".access-logs",
          content:
            "In the Access logs tab you can manage predefined access policies such as consoleAdmin, diagnostics, readonly, readwrite, and writeonly to control user permissions and interactions with the storage system.",
        },
        {
          selector: ".anonymose",
          content:
            "In the Anonymose tab you can manage access rules for unauthenticated users by setting prefixes and defining access levels like readonly, ensuring controlled public access to specific data.",
        },
      ];
    case "dapp-studio":
      return [
        {
          selector: ".file-explorer-tab",
          content: "In the File Explore tab you can...",
        },
        {
          selector: ".solidity-compiler-tab",
          content: "In the Solidity Compiler tab you can...",
        },
        {
          selector: ".deploy-tab",
          content: "In the Deploy & Run transaction tab you can...",
        },
        {
          selector: ".file",
          content: "You can see your file details here",
        },
        {
          selector: ".plans",
          content: "In the Plans tab you can see flexible pricing.",
        },
      ];
    case "web3-auth":
      return [
        {
          selector: ".create-web3-auth-button",
          content: "Click here to create a web3-auth.",
        },
        {
          selector: ".web3-auth-table",
          content: "This table shows all your web3 auth and their details.",
        },
        {
          selector: ".plans",
          content: "In the Plans tab you can see flexible pricing.",
        },
      ];
    case "web3-auth-page":
      return [
        {
          selector: ".user-table",
          content:
            "This table lists all users and their details. Customize fields or enable avatars in the User Data settings.",
        },
        {
          selector: ".settings",
          content:
            "In the Settings tab, you can configure the JWT lifespan, manage the encryption key (keep it confidential), and update supported networks for your app.",
        },
        {
          selector: ".access-keys",
          content:
            "In the Access Keys tab, manage keys for secure API access, enabling authentication, automation, and service integration.",
        },
      ];
    default:
      return [];
  }
};
export default TourProvider;
