import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getCloudCreditAsync,
  handleShowTopupWithPromoModal,
  invoicesAsync,
  selectCloudCredit,
  selectCloudCreditLoading,
  selectShowTopupWithPromoModal,
  selectTopupLoading,
  topupWithPromoAsync,
} from "../../../store/billing/billingSlice";
import { ReactComponent as TopupIcon } from "./../../../assets/icons/topup.svg";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { CustomToast } from "../../general/Toast";
import { currency } from "../../../utils/currency";
import { yupResolver } from "@hookform/resolvers/yup";
import { TopupWithPromoSchema } from "../../../utils/validations";
import { Button, Modal, Typography, Input, Skeleton } from "djuno-design";
import { handleStarsConfetti } from "../../../utils";

const TopupPromoModal = () => {
  const isOpen = useAppSelector(selectShowTopupWithPromoModal);
  const topupLoading = useAppSelector(selectTopupLoading);

  const cloudCredit = useAppSelector(selectCloudCredit);
  const cloudCreditLoading = useAppSelector(selectCloudCreditLoading);

  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    setValue,
    formState: { errors, isValid },
    register,
  } = useForm({
    resolver: yupResolver(TopupWithPromoSchema),
    mode: "all",
  });

  useEffect(() => {
    if (isOpen) {
      setValue("PromoCode", "");
    }
  }, [isOpen, setValue]);

  const handleSubmitForm = (data: any) => {
    if (!topupLoading) {
      dispatch(topupWithPromoAsync({ code: data.PromoCode })).then((action) => {
        if (action.type === "billing/top-up-with-promo/fulfilled") {
          // toast.success(() =>
          //   CustomToast("Your account has been charged successfully")
          // );
          dispatch(invoicesAsync());
          dispatch(getCloudCreditAsync({ withoutLoading: true }));
          handleOnClose();
          handleStarsConfetti();
        }
      });
    }
  };

  const handleOnClose = useCallback(() => {
    dispatch(handleShowTopupWithPromoModal(false));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  return (
    <Modal
      isOpen={isOpen}
      title={"Top up with promotion code"}
      contentClassName="max-w-lg"
      onClose={handleOnClose}
    >
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <div className="flex justify-center py-4 text-primary-400">
          <TopupIcon width={100} height={100} />
        </div>
        <div>
          <Typography.Text className="!text-sm">
            Your Credit shows{" "}
            {cloudCreditLoading && (
              <Skeleton style={{ width: 50, height: 24 }} />
            )}
            {!cloudCreditLoading && (
              <span className="font-medium">
                {currency(cloudCredit?.Balance)}
              </span>
            )}{" "}
          </Typography.Text>
        </div>
        <div className="gap-5 mt-5">
          <Input
            label="Promotion Code"
            {...register("PromoCode")}
            error={errors.PromoCode?.message}
          />
        </div>
        <div className="flex justify-end mt-5">
          <Button
            uiType="primary"
            type="submit"
            disabled={!isValid}
            loading={topupLoading}
          >
            Top up
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default TopupPromoModal;
