import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  applicationAccessInstanceAsync,
  handleToggleInstanceApplicationLoginModal,
  selectInstance,
  selectInstanceLoginInformation,
  selectInstanceLoginInformationLoading,
  selectShowApplicationLoginModal,
} from "../../../../store/instances/instanceSlice";
import { useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import { Flex, Modal, SecureCopy, Skeleton, Typography } from "djuno-design";

const InstanceLoginInformationModal = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectShowApplicationLoginModal);
  const instance = useAppSelector(selectInstance);
  const loginInformation = useAppSelector(selectInstanceLoginInformation);
  const loading = useAppSelector(selectInstanceLoginInformationLoading);

  useEffect(() => {
    if (isOpen && instance) {
      dispatch(
        applicationAccessInstanceAsync({
          instanceId: instance.id,
          data: { instanceId: instance.id },
        })
      );
    }
  }, [isOpen, instance, dispatch]);

  const handleOnClose = useCallback(() => {
    dispatch(handleToggleInstanceApplicationLoginModal(false));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      contentClassName="max-w-lg"
      // containerClassName="!items-start"
      title="Application login"
    >
      <Flex direction="col" items="center" className="mt-9 mx-2 gap-4">
        <Flex items="center" className="gap-1 w-full">
          <Typography.Text className="!text-sm font-medium">
            Type:
          </Typography.Text>
          {loading && <Skeleton style={{ width: 100, height: 24 }} />}
          {!loading && (
            <Typography.Text className="!text-sm min-w-[150px]">
              {loginInformation?.accesses[0].type}
            </Typography.Text>
          )}
        </Flex>
        <Flex items="center" className="gap-1 w-full">
          <Typography.Text className="!text-sm font-medium">
            URL:
          </Typography.Text>
          {loading && <Skeleton style={{ width: 150, height: 24 }} />}
          {!loading && loginInformation && loginInformation.accesses.length && (
            <Typography.Text className="min-w-[150px] !text-sm">
              <Link
                to={loginInformation.accesses[0].url}
                className="!text-sm text-sky-500 hover:text-sky-600"
                target="_blank"
              >
                {loginInformation.accesses[0].url}
              </Link>
            </Typography.Text>
          )}
        </Flex>
        <Flex items="center" className="gap-1 w-full">
          <Typography.Text className="text-sm font-medium">ID:</Typography.Text>
          {loading && <Skeleton style={{ width: 100, height: 24 }} />}
          {!loading && (
            <Typography.Text className="!text-sm min-w-[150px]">
              {loginInformation?.accesses[0].login}
            </Typography.Text>
          )}
        </Flex>
        <Flex items="center" className="gap-1 w-full">
          <Typography.Text className="!text-sm font-medium">
            Password
          </Typography.Text>
          <SecureCopy
            className="w-full flex-1"
            type="copy"
            text={loginInformation?.accesses[0].password || ""}
          />
        </Flex>
      </Flex>
    </Modal>
  );
};

export default InstanceLoginInformationModal;
