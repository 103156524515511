import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as ArrowRightIcon } from "./../../../assets/icons/arrow-right.svg";
import { ReactComponent as DetailsIcon } from "./../../../assets/icons/bars-3-bottom-left.svg";
import { DeleteModal } from "../../modals/QuestionModal";
import { BlockEvent } from "../../../types/block-events";
import {
  getBlockEventsAsync,
  deleteBlockEventAsync,
  selectBlockEventsActionLoading,
  selectBlockEventsLoading,
  selectBlockEvents,
  setBlockEvent,
} from "../../../store/block-event/blockEventsSlice";
import { BlockEventCreateUrl, BlockEventUrl } from "../../../utils/urls";
import { useNavigate } from "react-router-dom";
import Highlighter from "react-highlight-words";
import { useSearch } from "../../../providers/SearchProvider";
import { Helmet } from "react-helmet";
import { timeAgo } from "../../../utils/date";
import { capitalizeFirstLetter } from "../../../utils";
import {
  Button,
  Typography,
  SimpleTable,
  Dropdown,
  EmptyState,
  Tag,
} from "djuno-design";
import useServiceType from "../../../hooks/useServiceType";
import { useTour } from "../../../providers/TourProvider";

const BlockEventNotificationsTab = () => {
  const blockEvents = useAppSelector(selectBlockEvents);
  const loading = useAppSelector(selectBlockEventsLoading);
  const [filteredEvents, setFilteredEvents] = useState<BlockEvent[]>([]);
  const actionLoading = useAppSelector(selectBlockEventsActionLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { value: searchValue } = useSearch();
  const [deleteBlockEvent, setDeleteBlockEvent] = useState<BlockEvent | null>(
    null
  );
  const { run } = useTour("block-events");
  const { serviceType } = useServiceType(
    process.env.REACT_APP_BLOCK_EVENT_SERVICE_TYPE
  );

  useEffect(() => {
    // if (blockEvents.length === 0) {
    dispatch(getBlockEventsAsync());
    // }
  }, [dispatch]);

  useEffect(() => {
    const lookedUp = blockEvents.filter((event) =>
      event.Name?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredEvents(lookedUp);
  }, [dispatch, searchValue, blockEvents]);

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | {serviceType?.ServiceName || ""}
        </title>
        <meta name="description" content="" />
      </Helmet>
      <div className="flex transition-all duration-500 items-center justify-between gap-3 block-events-header">
        <Typography.Text className="!whitespace-nowrap !font-medium"></Typography.Text>
        <div className="flex items-center justify-end gap-1 md:gap-2 ">
          <Button
            onClick={() => {
              run();
            }}
          >
            Start Tour
          </Button>
          <Button
            uiType="primary"
            onClick={() => navigate(BlockEventCreateUrl)}
            className="group create-block-event-button"
          >
            Create Event
            <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>
      <div className="mt-5 w-full block-event-table">
        <SimpleTable loading={loading} containerClassName="min-h-[240px]">
          <SimpleTable.Head>
            <SimpleTable.Row>
              <SimpleTable.TH lable="Name" />
              <SimpleTable.TH lable="Network" />
              <SimpleTable.TH lable="Destinations" />
              <SimpleTable.TH lable="Last delivery" />
              <SimpleTable.TH lable="Status" />
              <SimpleTable.TH lable="" />
            </SimpleTable.Row>
          </SimpleTable.Head>
          <SimpleTable.Body>
            {filteredEvents.map((event, index) => {
              const network = event.Network.split("-");
              return (
                <SimpleTable.Row
                  key={index}
                  onClick={() => {
                    dispatch(setBlockEvent(event));
                    navigate(BlockEventUrl(event.BlockEventId));
                  }}
                  className="cursor-pointer"
                >
                  <SimpleTable.TD className="w-36">
                    <Highlighter
                      highlightClassName="bg-primary-100 dark:bg-primary-300 rounded-sm"
                      className="whitespace-nowrap max-w-[110px] md:max-w-none text-ellipsis overflow-hidden text-sm font-medium"
                      searchWords={[searchValue]}
                      autoEscape={true}
                      textToHighlight={event.Name}
                    />
                  </SimpleTable.TD>
                  <SimpleTable.TD>
                    <Typography.Text className="!text-sm">{`${capitalizeFirstLetter(
                      network[0]
                    )} ${capitalizeFirstLetter(network[1])}`}</Typography.Text>
                  </SimpleTable.TD>
                  <SimpleTable.TD>
                    <Typography.Text className="!text-sm">
                      {event.Destinations.length}
                    </Typography.Text>
                  </SimpleTable.TD>
                  <SimpleTable.TD>
                    <Typography.Text className="!text-sm">
                      {timeAgo(event.Updated_at)}
                    </Typography.Text>
                  </SimpleTable.TD>
                  <SimpleTable.TD>
                    {event.Enabled ? (
                      <Tag color="success">Enable</Tag>
                    ) : (
                      <Tag color="warning">Disable</Tag>
                    )}
                  </SimpleTable.TD>
                  <SimpleTable.TD className="w-10">
                    <div className="h-full w-full inline-flex items-center justify-end gap-1 px-4">
                      <div className="w-8 flex justify-center items-center">
                        <Dropdown
                          anchor="bottom end"
                          itemsClassName="!w-40"
                          menu={[
                            {
                              key: "details",
                              label: (
                                <div className="flex items-center gap-1">
                                  <DetailsIcon className="w-4" />
                                  Get details
                                </div>
                              ),
                              onClick: () => {
                                dispatch(setBlockEvent(event));
                                navigate(BlockEventUrl(event.BlockEventId));
                              },

                              disabled: loading,
                            },
                            {
                              type: "divider",
                            },
                            {
                              key: "end",
                              label: (
                                <div className="flex items-center gap-1">
                                  <ArchiveIcon className="w-4" />
                                  Delete
                                </div>
                              ),
                              danger: true,
                              onClick: () => {
                                setDeleteBlockEvent(event);
                              },
                            },
                          ]}
                        >
                          <Button
                            uiSize="small"
                            uiType="icon"
                            className="!px-2"
                          >
                            <MoreIcon className="w-4 h-4" />
                          </Button>
                        </Dropdown>
                      </div>
                      <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" />
                    </div>
                  </SimpleTable.TD>
                </SimpleTable.Row>
              );
            })}
            {blockEvents.length === 0 && (
              <SimpleTable.Row withoutHoverStyle={true} className="h-[200px]">
                <SimpleTable.TD colSpan={6} className="!border-0">
                  <EmptyState text="You have not created any block event yet" />
                </SimpleTable.TD>
              </SimpleTable.Row>
            )}
          </SimpleTable.Body>
        </SimpleTable>
      </div>

      <DeleteModal
        title="Delete Block Event"
        isOpen={deleteBlockEvent !== null}
        onClose={() => setDeleteBlockEvent(null)}
        confirmButtonText="Confirm"
        confirmButtonType="danger"
        confirmButtonClassName="w-full"
        onConfirm={() => {
          deleteBlockEvent &&
            dispatch(
              deleteBlockEventAsync({
                blockEventId: deleteBlockEvent.BlockEventId,
              })
            ).then((action) => {
              if (action.type === "block-events/delete/fulfilled") {
                dispatch(getBlockEventsAsync());
              }
              setDeleteBlockEvent(null);
            });
        }}
        loading={actionLoading}
        confirmString={deleteBlockEvent?.Name}
      />
    </>
  );
};

export default BlockEventNotificationsTab;
