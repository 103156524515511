import jwtAxios from ".";

export function getNotificationsApi() {
  return jwtAxios.get(`/notifications`);
}

export function markAsReadNotificationApi(notificationId: number | undefined) {
  return jwtAxios.put(
    `/notifications/read${
      notificationId ? `?notificationId=${notificationId}` : ""
    }`
  );
}
