import { jwtEnvAxios } from ".";

export function getDomainsApi() {
  return jwtEnvAxios().get(`/domains`);
}

export function searchDomainsApi(query: string) {
  return jwtEnvAxios().get(`/domains`);
}

export function getDomainApi(domainId: number) {
  return jwtEnvAxios().get(`/domains/${domainId}`);
}
