import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { EnvAddFromFileSchema } from "../../../utils/validations";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  createMultipleEnvVariableAsync,
  getEnvVariablesAysnc,
  handleHideImportFromEnvFileModal,
  selectEnvVariablesActionLoading,
  selectShowImportFromEnvFileModal,
} from "../../../store/settings/env-variables/envVariablesSlice";
import { Button, Flex, Modal, Textarea, Typography } from "djuno-design";
import { ReactComponent as ImportIcon } from "./../../../assets/icons/arrow-down-tray.svg";
import { useCallback, useEffect, useRef } from "react";
import toast from "react-hot-toast";

const ImportFromEnvFileModal: React.FC<{ callback?: any }> = ({ callback }) => {
  const isOpen = useAppSelector(selectShowImportFromEnvFileModal);
  const loading = useAppSelector(selectEnvVariablesActionLoading);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(EnvAddFromFileSchema),
  });

  const onSubmit = (data: any) => {
    const envs = data.Value as string;
    const envsArr = envs
      .split("\n")
      .filter(Boolean)
      .filter((d) => d.includes("="))
      .map((item) => {
        const [Name, Value] = item.split("=");
        return { Name, Value };
      });

    if (callback) {
      callback(envsArr);
      dispatch(handleHideImportFromEnvFileModal());
      reset();
    } else {
      dispatch(createMultipleEnvVariableAsync(data)).then((action) => {
        if (action.type === "envVariables/create/multiple/fulfilled") {
          reset();
          dispatch(handleHideImportFromEnvFileModal());
          dispatch(getEnvVariablesAysnc());
        }
      });
    }
  };

  const handleUploadClick = useCallback(() => {
    if (fileInputRef.current) fileInputRef.current.click();
  }, []);

  const handleUploadFiles = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      if (files && files[0]) {
        const file = files[0];

        if (!file.name.endsWith(".env")) {
          toast.error("Please select a valid .env file.");
          return;
        }

        // Read file content
        const text = await file.text();
        setValue("Value", text);
      }
    },
    [setValue]
  );

  const handleOnClose = useCallback(() => {
    reset();
    dispatch(handleHideImportFromEnvFileModal());
  }, [dispatch, reset]);

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      contentClassName="max-w-lg"
      title="Add from .env"
    >
      <form>
        <Flex direction="col" className="mt-5 w-full gap-5">
          <Typography.Text uiType="secondary" size="sm">
            Paste your .env contents to add multiple environment variables at
            onece
          </Typography.Text>
          <Textarea
            rows={7}
            {...register("Value")}
            error={errors.Value?.message}
            placeholder={`KEY_1=VALUE_1 \nKEY_2=VALUE_2 \nKEY_3=VALUE_3`}
          />
          <input
            type="file"
            hidden={true}
            ref={fileInputRef}
            multiple={false}
            // accept="text/plain"
            onChange={handleUploadFiles}
          />
        </Flex>
        <Flex justify="between" className="mt-4 w-full">
          <Button uiType="icon" onClick={handleUploadClick} className="!px-1">
            <ImportIcon className="w-4 text-slate-700 dark:text-slate-200" />
            Import from{" "}
            <Typography.Text size="sm" className="font-semibold">
              .env
            </Typography.Text>{" "}
            file
          </Button>

          <Button
            loading={loading}
            uiType="primary"
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              handleSubmit(onSubmit)();
            }}
            className="w-[160px]"
          >
            Add Variables
          </Button>
        </Flex>
      </form>
    </Modal>
  );
};

export default ImportFromEnvFileModal;
