import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "./../../hooks";
import { useNavigate } from "react-router-dom";
import { BlockchainsUrl } from "./../../utils/urls";
import {
  AnimatedFormError,
  Button,
  Card,
  Flex,
  Loading,
  Typography,
} from "djuno-design";
import QuestionModal, { DeleteModal } from "./../modals/QuestionModal";
import {
  getBlockchainAsync,
  selectBlockchain,
  selectBlockchainLoading,
  selectBlockchainOverviewLoading,
  selectBlockchainUpdateLoading,
  updateBlockchainAsync,
} from "../../store/blockchain-studio/blockchainSlice";
import {
  deleteBlockchainsAsync,
  getBlockchainsPlansAsync,
  selectBlockchainsActionLoading,
} from "../../store/blockchain-studio/blockchainsSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import { BlockchainInstanceTypeSchema } from "../../utils/validations";
import { BlockchainPlansGroup } from "../../pages/blockchain-studio/BlockchainCreatePage";

const BlockchainSettingsTab = () => {
  return (
    <div className="flex flex-col gap-10">
      <BlockchainInstanceType />
      <DangerZone />
    </div>
  );
};

const BlockchainInstanceType = () => {
  const blockchain = useAppSelector(selectBlockchain);
  const blockchainLoading = useAppSelector(selectBlockchainLoading);

  const updateLoading = useAppSelector(selectBlockchainUpdateLoading);
  const overviewLoading = useAppSelector(selectBlockchainOverviewLoading);

  const dispatch = useAppDispatch();

  const [nextPlan, setNextPlan] = useState<number | null>();

  const {
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm({
    resolver: yupResolver(BlockchainInstanceTypeSchema),
  });

  const planId = watch("PlanId");

  // get plans
  useEffect(() => {
    dispatch(getBlockchainsPlansAsync({}));
  }, [dispatch]);

  useEffect(() => {
    if (blockchain && blockchain.PlanId) {
      setValue("PlanId", blockchain.PlanId);
    }
  }, [dispatch, setValue, blockchain]);

  const handleSubmitForm = () => {
    if (
      blockchain &&
      (!updateLoading || !blockchainLoading || !overviewLoading) &&
      nextPlan
    )
      dispatch(
        updateBlockchainAsync({
          blockchainId: blockchain.Id.toString(),
          data: {
            PlanId: nextPlan,
          },
        })
      ).then((action) => {
        if (action.type === "blockchain/update/fulfilled") {
          setNextPlan(null);
          toast.success("The Plan successfully changed");
          dispatch(
            getBlockchainAsync({
              blockchainId: blockchain.Id.toString(),
              withoutLoading: true,
            })
          );
        }
      });
  };

  const isActiveBlockchain = useMemo(() => {
    if (!blockchain) return false;
    return ![0, 1].includes(blockchain.ServiceStatus);
  }, [blockchain]);

  const isPlanChanged = useMemo(() => {
    return blockchain?.PlanId !== planId;
  }, [blockchain?.PlanId, planId]);

  return (
    <div className="flex flex-col gap-6">
      {/* <form onSubmit={handleSubmit(handleSubmitForm)}> */}
      <Card title="Pick an Instance Type">
        {blockchainLoading && (
          <Flex items="center" justify="center" className="min-h-[200px]">
            <Loading borderSize={2} />
          </Flex>
        )}
        {/* suspended={blockchain?.IsSuspended} */}
        <BlockchainPlansGroup control={control}>
          <AnimatedFormError error={errors.PlanId?.message} />
          <div className="flex items-center justify-start gap-2 mt-4 mr-0  ">
            <Button
              uiType="primary"
              disabled={!isPlanChanged || !isActiveBlockchain}
              type="submit"
              loading={updateLoading || blockchainLoading}
              onClick={() => setNextPlan(planId)}
            >
              Save Changes
            </Button>
          </div>
        </BlockchainPlansGroup>
      </Card>
      {/* </form> */}
      <QuestionModal
        isOpen={!!nextPlan}
        title="Change instance type"
        description="Are you sure you want to change the blockchain instance type?"
        onConfirm={handleSubmitForm}
        onClose={() => setNextPlan(null)}
        confirmButtonText="Yes, Change it"
        loading={updateLoading || blockchainLoading}
      />
    </div>
  );
};

const DangerZone = () => {
  const blockchain = useAppSelector(selectBlockchain);
  const blockchainLoading = useAppSelector(selectBlockchainLoading);

  const actionLoading = useAppSelector(selectBlockchainsActionLoading);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleDelete = () => {
    if (blockchain)
      dispatch(deleteBlockchainsAsync({ id: blockchain.Id })).then((action) => {
        if (action.type === "blockchains/delete/fulfilled") {
          navigate(BlockchainsUrl);
        }
      });
  };

  return (
    <div className="flex gap-3">
      <Button
        uiType="danger"
        disabled={blockchainLoading}
        onClick={() => setShowDeleteModal(true)}
      >
        Delete Blockchain
      </Button>

      <DeleteModal
        title="Delete Blockchain"
        description={
          <Flex direction="col" className="!mt-6">
            <Typography.Text size="sm">
              All resources for {blockchain?.ServiceName} will stop working
              immediately. This action cannot be undone.
            </Typography.Text>
            <Typography.Text size="sm" className="mt-2 text-sm">
              Are you sure you want to delete this blockchain service?
            </Typography.Text>
          </Flex>
        }
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        confirmString={blockchain?.ServiceName}
        confirmButtonType="danger"
        confirmButtonText="Delete it!"
        onConfirm={handleDelete}
        loading={actionLoading}
      />
    </div>
  );
};

export default BlockchainSettingsTab;
