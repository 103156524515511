import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IThunkRejectValue, RootState } from "../../types";
import toast from "react-hot-toast";
import { createBlockchainApi } from "../../apis/blockchainsAPI";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import { BlockchainCreateState } from "../../types/blockchain";

const initialState: BlockchainCreateState = {
  createLoading: false,
};

export const createBlockchainAsync = createAsyncThunk<
  boolean,
  { data: any },
  IThunkRejectValue
>("blockchains/create", async ({ data }, { rejectWithValue }) => {
  try {
    await createBlockchainApi(data);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const blockchainCreateSlice = createSlice({
  name: "blockchainCreate",
  initialState,
  reducers: {
    handleClearBlockchainCreationSlice: (state) => {
      state.createLoading = initialState.createLoading;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createBlockchainAsync.pending, (state) => {
        state.createLoading = true;
      })
      .addCase(createBlockchainAsync.fulfilled, (state) => {
        state.createLoading = false;
      })
      .addCase(createBlockchainAsync.rejected, (state, { payload }) => {
        state.createLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectBlockchainCreateLoading = (state: RootState) =>
  state.blockchainCreate.createLoading;

export const { handleClearBlockchainCreationSlice } =
  blockchainCreateSlice.actions;
export default blockchainCreateSlice.reducer;
