import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { useCallback, useEffect, useState } from "react";
import { ReactComponent as ArrowRightIcon } from "./../../../../../assets/icons/arrow-right.svg";
import {
  createBucketReplicationAsync,
  handleHideReplicationEditor,
  selectBucketReplicationActionLoading,
  selectShowBucketReplicationEditor,
} from "../../../../../store/s3/buckets/setting/replicationsSlice";
import {
  ReplicationCreateLevels,
  S3CreatedReplication,
  bucketsRelation,
} from "../../../../../types/s3-replication";
import { S3MultiReplicationSchema } from "../../../../../utils/validations";
import { S3Bucket } from "../../../../../types/s3-bucket";
import {
  getBucketsAsync,
  getExternalBucketsAsync,
  selectCheckedBuckets,
  selectExternalBuckets,
} from "../../../../../store/s3/buckets/bucketsSlice";
import { bandWidthOptions, replicationModes } from "./S3ReplicationEditorModal";
import { binarySize } from "../../../../../utils/file";
import {
  Button,
  Input,
  Modal,
  Select,
  SelectOption,
  Steps,
  Switcher,
} from "djuno-design";

export interface ReplicationError {
  errorString?: string;
  originBucket: string;
  targetBucket: string;
}

const S3MultipleBucketEditorModal = () => {
  const isOpen = useAppSelector(selectShowBucketReplicationEditor);
  const loading = useAppSelector(selectBucketReplicationActionLoading);
  const dispatch = useAppDispatch();
  const externalBuckets = useAppSelector(selectExternalBuckets);

  //filter buckets and errors
  const externalBucketOptions: SelectOption[] = externalBuckets.map((eb) => ({
    label: eb.name,
    value: eb.name,
  }));
  const [filterCheckedBuckets, setFilterCheckedBuckets] = useState<S3Bucket[]>(
    []
  );
  const checkedBuckets = useAppSelector(selectCheckedBuckets);

  useEffect(() => {
    setFilterCheckedBuckets(checkedBuckets);
  }, [checkedBuckets]);

  //manage steps
  const [step, setStep] = useState<ReplicationCreateLevels>(
    "RemoteConfiguration"
  );
  //first steps
  const [useTLS, setUseTLS] = useState<boolean>(false);
  const [selectedExternalBuckets, setSelectedExternalBuckets] = useState<
    SelectOption[]
  >(externalBucketOptions);
  const [replicationError, setReplicationError] = useState<ReplicationError[]>(
    []
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    control,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(S3MultiReplicationSchema()),
  });

  const selectedMode = watch("syncMode");

  const handleClearComponent = useCallback(() => {
    reset();
    setSelectedExternalBuckets([]);
    setStep("RemoteConfiguration");
    setReplicationError([]);
    setValue("bandwidth", 100);
    setValue("healthCheckPeriod", 60);
    setValue("syncMode", replicationModes[0].value);
    setValue("bandwidthOption", bandWidthOptions[0].value);
    setFilterCheckedBuckets([]);
  }, [reset, setValue]);

  useEffect(() => {
    if (isOpen) {
      setValue("region", "");
      setValue("bandwidth", 100);
      setValue("healthCheckPeriod", 60);
      setUseTLS(true);
      setValue("syncMode", replicationModes[0].value);
      setValue("bandwidthOption", bandWidthOptions[0].value);
    }
  }, [isOpen, setValue]);

  const onSubmit = async (data: any) => {
    const externalData: any = {
      accessKey: data.accessKey,
      secretKey: data.secretKey,
      targetURL: data.targetURL,
      useTLS: useTLS,
    };
    dispatch(getExternalBucketsAsync({ data: externalData })).then((action) => {
      if (action.type === "externalbuckets/fulfilled") {
        setStep("BucketAssignments");
      }
    });
  };

  const createMultipleReplication = async () => {
    const bucketsRelationArray: bucketsRelation[] = filterCheckedBuckets.map(
      (check, index) => ({
        originBucket: check.name,
        destinationBucket:
          selectedExternalBuckets[index]?.label?.toString() || "",
      })
    );
    const protocol = useTLS ? "https://" : "http://";
    const bandwidth = getValues("bandwidth") || 0;
    const healthCheckPeriodValue = getValues("healthCheckPeriod");
    const healthCheckPeriod = parseInt(healthCheckPeriodValue!.toString());
    const formData = getValues();
    const createdData: S3CreatedReplication = {
      ...formData,
      targetURL: `${protocol}${getValues("targetURL")}`,
      bucketsRelation: bucketsRelationArray,
      bandwidth:
        formData.syncMode === "sync"
          ? 0
          : formData.bandwidthOption
          ? binarySize(bandwidth, formData.bandwidthOption)
          : 0,
      healthCheckPeriod: healthCheckPeriod,
    };
    dispatch(createBucketReplicationAsync({ data: createdData })).then(
      (action: any) => {
        if (action.type === "bucket/replications/create/fulfilled") {
          const errors = action.payload.replication;
          console.log("errors", errors);
          console.log(
            "errors",
            errors.map((err: any) => err.errorString)
          );
          if (
            errors &&
            errors.length > 0 &&
            errors.some((err: any) => err.errorString)
          ) {
            setReplicationError(errors);
            const filteredBucketsWithErrors = checkedBuckets
              .filter((bucket) => {
                const matchingError = errors.find(
                  (error: any) =>
                    error.originBucket === bucket.name && error.errorString
                );

                return matchingError;
              })
              .map((bucket) => {
                const matchingError = errors.find(
                  (error: any) =>
                    error.originBucket === bucket.name && error.errorString
                );

                return {
                  ...bucket,
                  errorString: matchingError.errorString,
                };
              });
            setFilterCheckedBuckets(filteredBucketsWithErrors);
          } else {
            dispatch(getBucketsAsync());
            dispatch(handleHideReplicationEditor());
            handleClearComponent();
          }
        }
      }
    );
  };

  const handleOnClose = useCallback(() => {
    dispatch(handleHideReplicationEditor());
    handleClearComponent();
  }, [dispatch, handleClearComponent]);

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      contentClassName="max-w-xl"
      title={
        <div className="justify-center w-full flex flex-col">
          <div>Set Multiple Bucket Replication</div>
        </div>
      }
    >
      <hr className="border-t-b border-gray-100 mt-5" />
      <Steps
        step={step}
        steps={[
          { label: "Remote Configuration", value: "RemoteConfiguration" },
          { label: "Bucket Assignments", value: "BucketAssignments" },
        ]}
      />

      <div className="mt-5 w-full overflow-x-auto px-5">
        <div className="mx-auto  pb-14 ">
          {step === "RemoteConfiguration" && (
            <form>
              <div className="mt-5 flex flex-col w-full gap-5">
                <div className="flex items-center justify-between w-full">
                  <div className="flex gap-2 items-center">
                    <div className="text-sm font-medium text-slate-800  dark:text-slate-200 mb-1">
                      Local Buckets to replicate
                    </div>
                  </div>
                  <div className="text-sm font-medium text-slate-800  dark:text-slate-200 mb-1 border p-2 rounded-lg">
                    {filterCheckedBuckets.map((check, index) => (
                      <span key={check.name}>
                        {check.name}
                        {index !== filterCheckedBuckets.length - 1 ? ", " : ""}
                      </span>
                    ))}
                  </div>
                </div>
                <div className="flex items-center justify-between w-full">
                  <div className="flex gap-2 items-center">
                    <div className="text-sm font-medium text-slate-800  dark:text-slate-200 mb-1">
                      Remote Endpoint Configuration
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-between w-full">
                  <div className="flex gap-2 items-center">
                    <div className="text-sm font-medium text-slate-500 dark:text-slate-200 mb-1">
                      Please avoid the use of root credentials for this feature
                    </div>
                  </div>
                </div>
                <Input
                  label="Access Key"
                  {...register("accessKey")}
                  type="string"
                  error={errors.accessKey?.message}
                  placeholder=""
                  required
                />
                <Input
                  label="Secret Key"
                  {...register("secretKey")}
                  type="string"
                  error={errors.secretKey?.message}
                  placeholder=""
                  required
                />

                <Input
                  label="Target URL"
                  {...register("targetURL")}
                  type="string"
                  error={errors.targetURL?.message}
                  placeholder="play.min.io"
                  required
                  hint={useTLS ? "https://" : "http://"}
                />

                <div className="flex items-center justify-between w-full">
                  <div className="flex gap-2 items-center">
                    <div className="text-sm font-medium text-slate-800  dark:text-slate-200 mb-1">
                      Use TLS
                    </div>
                  </div>
                  <Switcher onChange={setUseTLS} value={useTLS} />
                </div>

                <Input
                  label="Region"
                  {...register("region")}
                  type="string"
                  error={errors.region?.message}
                  placeholder=""
                />

                <Controller
                  control={control}
                  name="syncMode"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      label="Replication Mode"
                      value={value?.toString()}
                      onChange={onChange}
                      options={replicationModes}
                      className="w-full"
                    />
                  )}
                />

                {selectedMode !== "sync" && (
                  <div className="mt-5">
                    <div className="text-sm font-medium text-slate-800 dark:text-slate-200 mb-1">
                      Bandwidth
                    </div>
                    <div className="flex gap-1">
                      <div className="flex-1">
                        <Input
                          {...register("bandwidth")}
                          type="number"
                          error={errors.bandwidth?.message}
                          placeholder=""
                        />
                      </div>
                      <div className="w-20">
                        <Controller
                          control={control}
                          name="bandwidthOption"
                          render={({ field: { onChange, value } }) => (
                            <Select
                              value={value?.toString() || "0"}
                              onChange={onChange}
                              options={bandWidthOptions}
                              className="w-full"
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <Input
                  label="Health Check Duration"
                  {...register("healthCheckPeriod")}
                  type="number"
                  error={errors.healthCheckPeriod?.message}
                  placeholder=""
                />
              </div>
            </form>
          )}
          {step === "BucketAssignments" && (
            <div className="mt-5 flex flex-col w-full gap-5">
              <div className="flex items-center justify-between w-full">
                <div className="flex gap-2 items-center">
                  <div className="text-sm font-medium text-slate-800 dark:text-slate-200 mb-1">
                    Remote Bucket Assignments
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between w-full">
                <div className="flex gap-2 items-center">
                  <div className="text-sm font-medium text-slate-500 dark:text-slate-200 mb-1">
                    Please select/type the desired remote bucket where you want
                    the local data to be replicated.
                  </div>
                </div>
              </div>

              {filterCheckedBuckets &&
                filterCheckedBuckets.map((check, index) => {
                  const error = replicationError.find(
                    (err) => err.originBucket === check.name
                  );

                  return (
                    <div
                      className="flex flex-row w-full justify-between"
                      key={index}
                    >
                      <div className="text-sm font-medium text-slate-800 dark:text-slate-200 mb-1">
                        {check.name}
                      </div>

                      <div className="flex gap-1">
                        <div className="w-52">
                          <Select
                            error={error && error.errorString}
                            value={selectedExternalBuckets[index]?.value}
                            onChange={(v) => {
                              if (v) {
                                const updatedSelections = [
                                  ...selectedExternalBuckets,
                                ];
                                const selectedOption =
                                  externalBucketOptions.find(
                                    (option) => option.value === v
                                  );
                                if (selectedOption) {
                                  updatedSelections[index] = selectedOption;
                                  setSelectedExternalBuckets(updatedSelections);
                                }
                              }
                            }}
                            options={externalBucketOptions}
                            className="w-full"
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>
      <div className="fixed bottom-0 right-0 left-0 flex items-center w-full h-16 border-t bg-white dark:bg-dark-1 dark:border-dark-2 px-6  rounded-b-xl">
        {step === "RemoteConfiguration" && (
          <div className="flex justify-end w-full mb-4">
            <div className="mt-4 flex justify-end">
              <Button
                loading={loading}
                uiType="primary"
                onClick={handleSubmit(onSubmit)}
                // type="submit"
              >
                Next
                <ArrowRightIcon className="w-4 h-4 group-hover:scale-110 group-hover:translate-x-1 transition-all duration-300" />
              </Button>
            </div>
          </div>
        )}

        {step === "BucketAssignments" && (
          <div className="flex items-center justify-between w-full">
            <Button
              uiType="light"
              onClick={() => {
                setStep("RemoteConfiguration");
                setUseTLS(true);
              }}
            >
              back
            </Button>

            <Button
              loading={loading}
              uiType="primary"
              disabled={loading}
              type="submit"
              onClick={() => {
                createMultipleReplication();
              }}
            >
              Save
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default S3MultipleBucketEditorModal;
