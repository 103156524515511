import { Button, Flex, Typography } from "djuno-design";
import { ReactComponent as EmailIcon } from "../../../../assets/icons/email.svg";
import { ReactComponent as ArrowUpIcon } from "../../../../assets/icons/arrow-up.svg";
import { useAppSelector } from "../../../../hooks";
import { selectUser } from "../../../../store/auth/authSlice";

const DomainEmailTab = () => {
  const user = useAppSelector(selectUser);
  return (
    <Flex
      direction="col"
      className="mt-6 max-w-lg border rounded-lg mx-auto p-6 items-center"
    >
      <EmailIcon className="w-10 h-10 text-primary-400" />
      <Typography.Text size="base">Send and receive messages</Typography.Text>
      <Flex items="center" justify="center" className="w-full mt-8">
        <Flex direction="col" items="center" className="border rounded-lg p-5">
          <Typography.Text size="sm">They send to</Typography.Text>
          <Typography.Text size="sm" className="font-semibold">
            djuno.u@ud.me
          </Typography.Text>
        </Flex>
        <ArrowUpIcon className="rotate-90 w-5 h-5 mx-8" />
        <Flex direction="col" items="center" className="border rounded-lg p-5">
          <Typography.Text size="sm">You receive from</Typography.Text>
          <Typography.Text size="sm" className="font-semibold">
            {user?.Email}
          </Typography.Text>
        </Flex>
      </Flex>
      <Button uiType="primary" className="mt-10">
        Enable Email
      </Button>
    </Flex>
  );
};

export default DomainEmailTab;
