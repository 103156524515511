import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "./../../../hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { BlockEventDestinationSchema } from "./../../../utils/validations";
import {
  createDestinationAsync,
  getDestinationPayloadTypesAsync,
  getDestinationWebhookTypesAsync,
  getDestinationsAsync,
  handleHideDestinationEditor,
  selectDestinationsActionLoading,
  selectDestinationsPayloadTypes,
  selectDestinationsPayloadTypesLoading,
  selectDestinationsWebhookTypes,
  selectDestinationsWebhookTypesLoading,
  selectSelectedDestination,
  selectShowDestinationEditor,
  updateDestinationAsync,
} from "../../../store/block-event/destinationsSlice";
import {
  JsonViewer,
  Select,
  SelectOption,
  Button,
  Input,
  Modal,
} from "djuno-design";

const DestinationEditorModal = () => {
  const isOpen = useAppSelector(selectShowDestinationEditor);
  const loading = useAppSelector(selectDestinationsActionLoading);

  const dispatch = useAppDispatch();
  const selectedDestination = useAppSelector(selectSelectedDestination);

  const webhookTypes = useAppSelector(selectDestinationsWebhookTypes);
  const webhookTypesLoading = useAppSelector(
    selectDestinationsWebhookTypesLoading
  );

  const payloadTypes = useAppSelector(selectDestinationsPayloadTypes);
  const payloadTypesLoading = useAppSelector(
    selectDestinationsPayloadTypesLoading
  );

  const [selectedWebhookTypeOption, setSelectedWebhookTypeOption] = useState<
    SelectOption<string> | undefined
  >(() => {
    if (webhookTypes.length > 0)
      return { label: webhookTypes[0].Text, value: webhookTypes[0].Text };
  });

  const [selectedPayloadTypeOption, setSelectedPayloadTypeOption] = useState<
    SelectOption<string> | undefined
  >(() => {
    if (payloadTypes.length > 0)
      return {
        label: `${payloadTypes[0].Id} - ${payloadTypes[0].Name}`,
        value: `${payloadTypes[0].Id}`,
      };
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(BlockEventDestinationSchema),
    shouldUnregister: true,
  });

  useEffect(() => {
    if (isOpen) {
      if (selectedDestination) {
        setValue("name", selectedDestination.Name);
        setValue("to_url", selectedDestination.To_url);
        //TODO select webhook and payload
      }
      if (webhookTypes.length > 0) {
        if (selectedDestination) {
          const selectedWeebhook = webhookTypes.find(
            (wt) => wt.Text === selectedDestination.Webhook_type
          );
          if (selectedWeebhook) {
            setSelectedWebhookTypeOption({
              label: selectedWeebhook.Text,
              value: selectedWeebhook.Text,
            });
          }
        } else {
          setSelectedWebhookTypeOption({
            label: webhookTypes[0].Text,
            value: webhookTypes[0].Text,
          });
        }
      }
      if (payloadTypes.length > 0) {
        if (selectedDestination) {
          const selectedPayloadType = payloadTypes.find(
            (pt) => pt.Id === selectedDestination.Payload_type
          );
          if (selectedPayloadType) {
            setSelectedPayloadTypeOption({
              label: selectedPayloadType.Id + " - " + selectedPayloadType.Name,
              value: `${selectedPayloadType.Id}`,
            });
          }
        } else {
          setSelectedPayloadTypeOption({
            label: `${payloadTypes[0].Id} - ${payloadTypes[0].Name}`,
            value: `${payloadTypes[0].Id}`,
          });
        }
      }
    }
  }, [isOpen, payloadTypes, selectedDestination, setValue, webhookTypes]);

  const handleClearComponent = useCallback(() => {
    reset();
    setValue("name", "");
    setValue("to_url", "");
    setSelectedWebhookTypeOption(undefined);
    setSelectedPayloadTypeOption(undefined);
  }, [reset, setValue]);

  useEffect(() => {
    if (isOpen && webhookTypes.length === 0) {
      dispatch(getDestinationWebhookTypesAsync());
    }
  }, [dispatch, isOpen, webhookTypes.length]);

  useEffect(() => {
    if (isOpen && payloadTypes.length === 0) {
      dispatch(getDestinationPayloadTypesAsync());
    }
  }, [dispatch, isOpen, payloadTypes.length]);

  const onSubmit = (data: any) => {
    const apiData = {
      name: data.name,
      to_url: data.to_url,
      webhook_type: selectedWebhookTypeOption?.value,
      payload_type: Number(selectedPayloadTypeOption?.value),
      service: "webhook",
    };
    if (selectedDestination) {
      dispatch(
        updateDestinationAsync({
          data: apiData,
          destId: selectedDestination.DestId,
        })
      ).then((action) => {
        if (action.type === "block-event/destinations/update/fulfilled") {
          handleClearComponent();
          dispatch(handleHideDestinationEditor());
          dispatch(getDestinationsAsync());
        }
      });
    } else {
      dispatch(createDestinationAsync({ data: apiData })).then((action) => {
        if (action.type === "block-event/destinations/create/fulfilled") {
          handleClearComponent();
          dispatch(handleHideDestinationEditor());
          dispatch(getDestinationsAsync());
        }
      });
    }
  };

  const selectedPayload = useMemo(
    () =>
      selectedPayloadTypeOption &&
      payloadTypes.find((pt) => `${pt.Id}` === selectedPayloadTypeOption.value),
    [payloadTypes, selectedPayloadTypeOption]
  );

  const handleOnClose = useCallback(() => {
    dispatch(handleHideDestinationEditor());
    handleClearComponent();
  }, [dispatch, handleClearComponent]);

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      contentClassName="max-w-lg overflow-hidden"
      title={selectedDestination ? "Update Destination" : "Create Destination"}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5 flex flex-col w-full gap-5 min-h-[500px] max-h-[70vh] overflow-y-auto">
          <Input
            label="Webhook name"
            {...register("name")}
            type="text"
            error={errors.name?.message}
            placeholder=""
          />

          <div className="">
            <div className="text-sm text-slate-800  dark:text-slate-200 mb-1">
              URL and Request type
            </div>
            <div className="flex gap-1">
              <div className="flex-1">
                <Input
                  {...register("to_url")}
                  error={errors.to_url?.message}
                  placeholder=""
                />
              </div>
              <div className="w-20">
                <Select
                  options={webhookTypes.map((type) => ({
                    value: type.Text,
                    label: type.Text,
                  }))}
                  value={selectedWebhookTypeOption?.value}
                  onChange={(newValue) => {
                    const selectedOption = webhookTypes.find(
                      (option) => option.Text === newValue
                    );
                    setSelectedWebhookTypeOption(
                      selectedOption
                        ? {
                            label: selectedOption.Text,
                            value: selectedOption.Text,
                          }
                        : undefined
                    );
                  }}
                  loading={webhookTypesLoading}
                />
              </div>
            </div>
          </div>

          <Select
            label="Payload type"
            options={payloadTypes.map((type) => ({
              value: type.Id.toString(),
              label: `${type.Id} - ${type.Name}`,
            }))}
            value={selectedPayloadTypeOption?.value}
            onChange={(newValue) => {
              const selectedOption = payloadTypes.find(
                (type) => type.Id.toString() === newValue
              );
              if (selectedOption) {
                setSelectedPayloadTypeOption({
                  label: `${selectedOption.Id} - ${selectedOption.Name}`,
                  value: selectedOption.Id.toString(),
                });
              }
            }}
            loading={payloadTypesLoading}
          />
          {selectedPayloadTypeOption && selectedPayload && (
            <JsonViewer value={JSON.parse(selectedPayload.Example)} />
          )}
        </div>
        <div className="mt-4 flex justify-end">
          <Button
            loading={loading || webhookTypesLoading || payloadTypesLoading}
            uiType="primary"
            // disabled: loading || webhookTypesLoading || payloadTypesLoading,
            type="submit"
            className="w-[100px]"
          >
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default DestinationEditorModal;
