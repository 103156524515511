import Sidebar from "./Sidebar";
import FileEditor from "./FileEditor";
import Sections from "./Sections";
import { cn } from "djuno-design";
import { useDappFilesContext } from "./contexts/DappFilesContext";
import AiChat from "./AiChat";

const DappStudioIDE = () => {
  const { isFullScreen, isOpenAiChat } = useDappFilesContext();

  return (
    <div
      className={cn("flex w-full", {
        // "h-[calc(100dvh-15rem)] border rounded-lg dark:border-dark-2":
        //   !isFullScreen,
        "fixed inset-0 z-[49]": isFullScreen,
      })}
    >
      <div
        className={cn("flex w-full overflow-hidden", {
          "h-[calc(100dvh-15rem)] border rounded-lg dark:border-dark-2":
            !isFullScreen,
          // "fixed inset-0 z-[51]": isFullScreen,
        })}
      >
        {/* Sidebar */}
        <div className="sidebar flex flex-col w-[40px] flex-[0_0_40px] lg:w-[50px] lg:flex-[0_0_50px] overflow-y-auto">
          <Sidebar />
        </div>

        {/* Sections */}
        <div className="sections flex flex-col flex-[0_0_160px] max-w-[160px] md:flex-[0_0_190px] md:max-w-[190px] lg:flex-[0_0_200px] lg:max-w-[200px] bg-slate-50 dark:bg-dark-1">
          <Sections />
        </div>

        {/* File Editor (with integrated AI Chat) */}
        <div className="file-editor flex flex-col flex-1 overflow-hidden bg-white dark:bg-black">
          <FileEditor />
        </div>
      </div>
      {isOpenAiChat && <AiChat />}
    </div>
  );
};
export default DappStudioIDE;
