import { WorkingPage } from "../../../layouts/NotData";

const DomainTokenizationTab = () => {
  return (
    <>
      <WorkingPage />
    </>
  );
};

export default DomainTokenizationTab;
