import { jwtEnvAxios } from ".";

//web-apps
export function getBlockchainsApi() {
  return jwtEnvAxios().get(`/blockchain-studio`);
}

export function createBlockchainApi(data: any) {
  return jwtEnvAxios().post(`/blockchain-studio`, data);
}

export function deleteBlockchainApi(id: number) {
  return jwtEnvAxios().delete(`/blockchain-studio/${id}`);
}

//blockchain
export function getBlockchainApi(id: string) {
  return jwtEnvAxios().get(`/blockchain-studio/${id}`);
}

export function updateBlockchainApi(id: string, data: any) {
  return jwtEnvAxios().put(`blockchain-studio/${id}`, data);
}

export function getBlockchainOverviewApi(id: string) {
  return jwtEnvAxios().get(`/blockchain-studio/${id}/overview`);
}

export function getBlockchainMetricsApi(id: string, time: number) {
  return jwtEnvAxios().get(
    `/blockchain-studio/${id}/metrics?metricTime=${time}`
  );
}
