import {
  cn,
  Flex,
  Loading,
  Tooltip,
  TooltipProps,
  Typography,
} from "djuno-design";
import { forwardRef, useCallback, useRef } from "react";
import { AnimatedBeam } from "./AnimatedBeam";
import UserDefaultAvatar from "./../../assets/images/blank-avatar.png";
import { ReactComponent as DjonuIcon } from "./../../assets/icons/logo.svg";

// import { ReactComponent as S3Icon } from "./../../assets/icons/services/amazon-s3.svg";
import { ReactComponent as IPFSIcon } from "./../../assets/icons/cube.svg";
import { ReactComponent as WorkflowIcon } from "./../../assets/icons/services/workflow.svg";
import { ReactComponent as ArchiveIcon } from "./../../assets/icons/archive-box2.svg";
// import RPCIcon from "./../../assets/icons/services/rpc.png";
import { ReactComponent as DatabasesIcon } from "./../../assets/icons/circle-stack.svg";
import { ReactComponent as InstancesIcon } from "./../../assets/icons/driver.svg";
import { ReactComponent as BlockEventsIcon } from "./../../assets/icons/chain.svg";
import { ReactComponent as Web3AuthIcon } from "./../../assets/icons/shield-check.svg";
import { ReactComponent as WalletApiIcon } from "./../../assets/icons/services/crypto-wallet-api.svg";
// import NFTIcon from "./../../assets/icons/services/nft-api.png";
import { ReactComponent as MarketApiIcon } from "./../../assets/icons/megaphone.svg";
import { ReactComponent as BlockApiIcon } from "./../../assets/icons/squares-2x2.svg";

import { useAppSelector } from "../../hooks";
import { selectUser, selectUserLoading } from "../../store/auth/authSlice";
import { BASE_URL } from "../../apis";
import { Link } from "react-router-dom";
import {
  BlockApiUrl,
  BlockEventNotificationsUrl,
  DatabaseServicesUrl,
  InstancesUrl,
  IpfsFilesUrl,
  MarketApiUrl,
  NftApiUrl,
  RpcEndpointsUrl,
  S3BucketsUrl,
  WalletApiUrl,
  Web3AuthEndpointsUrl,
  WorkflowsUrl,
} from "../../utils/urls";
import {
  selectServiceTypes,
  selectServiceTypesLoading,
} from "../../store/publicSlice";

//Instances, Databases, s3, IPFS, Block Events,
const CloudFeatures = ({ className }: { className?: string }) => {
  const authUser = useAppSelector(selectUser);
  const authUserLoading = useAppSelector(selectUserLoading);

  const serviceTypes = useAppSelector(selectServiceTypes);
  const serviceTypesLoading = useAppSelector(selectServiceTypesLoading);

  const containerRef = useRef<HTMLDivElement>(null);

  const rpcRef = useRef<HTMLDivElement>(null);
  const workflowRef = useRef<HTMLDivElement>(null);
  const databasesRef = useRef<HTMLDivElement>(null);
  const instancesRef = useRef<HTMLDivElement>(null);
  const blockEventsRef = useRef<HTMLDivElement>(null);
  const web3AuthRef = useRef<HTMLDivElement>(null);

  const s3Ref = useRef<HTMLDivElement>(null);
  const ipfsRef = useRef<HTMLDivElement>(null);
  const walletApiRef = useRef<HTMLDivElement>(null);
  const nftApiRef = useRef<HTMLDivElement>(null);
  const marketApiRef = useRef<HTMLDivElement>(null);
  const blockApiRef = useRef<HTMLDivElement>(null);

  const djunoRef = useRef<HTMLDivElement>(null);
  const userRef = useRef<HTMLDivElement>(null);

  const isActive = useCallback(
    (serviceId: number) => {
      const serviceType = serviceTypes.find((st) => st.Id === serviceId);
      if (serviceType) return serviceType.IsActive && serviceType.Enabled;
      return false;
    },
    [serviceTypes]
  );

  return (
    <div
      className={cn(
        "relative flex w-full items-center justify-center overflow-hidden p-0",
        className
      )}
      ref={containerRef}
    >
      {(authUserLoading || serviceTypesLoading) && (
        <Flex items="center" justify="center" className="min-h-[250px]">
          <Loading borderSize={2} />
        </Flex>
      )}
      {!authUserLoading && !serviceTypesLoading && (
        <>
          <div className="flex w-full h-full flex-row items-stretch justify-between gap-14 max-w-lg">
            <div className="flex gap-10">
              <div className="flex flex-col justify-center gap-7 mt-20">
                {isActive(1) && (
                  <BeamNode ref={rpcRef}>
                    <Link to={RpcEndpointsUrl}>
                      <Circle
                        tooltip={{
                          content: "RPC Endpoints",
                          className: "!text-xs !z-50",
                          theme: "black",
                        }}
                      >
                        <Typography.Text
                          size="sm"
                          className="!text-primary-400 font-semibold"
                        >
                          rpc
                        </Typography.Text>
                      </Circle>
                    </Link>
                  </BeamNode>
                )}

                {isActive(3) && (
                  <BeamNode ref={workflowRef}>
                    <Link to={WorkflowsUrl}>
                      <Circle
                        tooltip={{
                          content: "Workflow Studio",
                          className: "!text-xs !z-50",
                          theme: "black",
                        }}
                      >
                        <WorkflowIcon className="w-[90%] h-[90%] text-primary-400" />
                      </Circle>
                    </Link>
                  </BeamNode>
                )}

                {isActive(14) && (
                  <BeamNode ref={databasesRef}>
                    <Link to={DatabaseServicesUrl}>
                      <Circle
                        tooltip={{
                          content: "Databases",
                          className: "!text-xs !z-50",
                          theme: "black",
                        }}
                      >
                        <DatabasesIcon className="w-full h-full text-primary-400" />
                      </Circle>
                    </Link>
                  </BeamNode>
                )}

                {isActive(15) && (
                  <BeamNode ref={instancesRef}>
                    <Link to={InstancesUrl}>
                      <Circle
                        tooltip={{
                          content: "Instances",
                          className: "!text-xs !z-50",
                          theme: "black",
                        }}
                      >
                        <InstancesIcon className="w-full h-full text-primary-400" />
                      </Circle>
                    </Link>
                  </BeamNode>
                )}

                {isActive(11) && (
                  <BeamNode ref={blockEventsRef}>
                    <Link to={BlockEventNotificationsUrl}>
                      <Circle
                        tooltip={{
                          content: "Block Events",
                          className: "!text-xs !z-50",
                          theme: "black",
                        }}
                      >
                        <BlockEventsIcon className="w-[90%] h-[90%] text-primary-400" />
                      </Circle>
                    </Link>
                  </BeamNode>
                )}

                {isActive(6) && (
                  <BeamNode ref={web3AuthRef}>
                    <Link to={Web3AuthEndpointsUrl}>
                      <Circle
                        tooltip={{
                          content: "Web3 Auth",
                          className: "!text-xs !z-50",
                          theme: "black",
                        }}
                      >
                        <Web3AuthIcon className="w-full h-full text-primary-400" />
                      </Circle>
                    </Link>
                  </BeamNode>
                )}
              </div>
              {/* // */}
              <div className="flex flex-col justify-center gap-5">
                {isActive(13) && (
                  <BeamNode ref={s3Ref}>
                    <Link to={S3BucketsUrl}>
                      <Circle
                        tooltip={{
                          content: "Object Storage",
                          className: "!text-xs !z-50",
                          theme: "black",
                        }}
                      >
                        <ArchiveIcon className="w-full h-full text-primary-400" />
                      </Circle>
                    </Link>
                  </BeamNode>
                )}

                {isActive(12) && (
                  <BeamNode ref={ipfsRef}>
                    <Link to={IpfsFilesUrl}>
                      <Circle
                        tooltip={{
                          content: "IPFS Storage",
                          className: "!text-xs !z-50",
                          theme: "black",
                        }}
                      >
                        <IPFSIcon className="w-full h-full text-primary-400" />
                      </Circle>
                    </Link>
                  </BeamNode>
                )}

                {isActive(7) && (
                  <BeamNode ref={walletApiRef}>
                    <Link to={WalletApiUrl}>
                      <Circle
                        tooltip={{
                          content: "Wallet API",
                          className: "!text-xs !z-50",
                          theme: "black",
                        }}
                      >
                        <WalletApiIcon className="w-full h-full text-primary-400" />
                      </Circle>
                    </Link>
                  </BeamNode>
                )}

                {isActive(8) && (
                  <BeamNode ref={nftApiRef}>
                    <Link to={NftApiUrl}>
                      <Circle
                        tooltip={{
                          content: "NFT API",
                          className: "!text-xs !z-50",
                          theme: "black",
                        }}
                      >
                        {/* <img src={NFTIcon} alt="" className="w-full h-full" /> */}
                        <Typography.Text
                          size="sm"
                          className="!text-primary-400 font-semibold"
                        >
                          NFT
                        </Typography.Text>
                      </Circle>
                    </Link>
                  </BeamNode>
                )}

                {isActive(9) && (
                  <BeamNode ref={marketApiRef}>
                    <Link to={MarketApiUrl}>
                      <Circle
                        tooltip={{
                          content: "Market API",
                          className: "!text-xs !z-50",
                          theme: "black",
                        }}
                      >
                        <MarketApiIcon className="w-[90%] h-[90%] text-primary-400" />
                      </Circle>
                    </Link>
                  </BeamNode>
                )}

                {isActive(10) && (
                  <BeamNode ref={blockApiRef}>
                    <Link to={BlockApiUrl}>
                      <Circle
                        tooltip={{
                          content: "Block API",
                          className: "!text-xs !z-50",
                          theme: "black",
                        }}
                      >
                        <BlockApiIcon className="w-[90%] h-[90%] text-primary-400" />
                      </Circle>
                    </Link>
                  </BeamNode>
                )}
              </div>
            </div>
            <div className="flex gap-12 md:gap-20">
              <div className="flex flex-col justify-center">
                <BeamNode ref={djunoRef}>
                  <Circle
                    className="w-12 h-12"
                    tooltip={{
                      content: "Djuno Cloud Panel",
                      className: "!text-xs",
                    }}
                  >
                    <DjonuIcon className="w-7 h-7" />
                  </Circle>
                </BeamNode>
              </div>
              <div className="flex flex-col justify-center">
                <BeamNode ref={userRef}>
                  <Circle
                    className="!p-0 overflow-hidden"
                    tooltip={{
                      content: authUser?.FullName,
                      className: "!text-xs",
                      theme: "black",
                    }}
                  >
                    <img
                      src={
                        authUser?.AvatarLink !== null
                          ? BASE_URL + authUser?.AvatarLink
                          : UserDefaultAvatar
                      }
                      alt={authUser?.FullName}
                      className="w-full h-full cursor-pointer"
                    />
                  </Circle>
                </BeamNode>
              </div>
            </div>
          </div>

          {/* // */}
          <AnimatedBeam
            containerRef={containerRef}
            fromRef={rpcRef}
            toRef={djunoRef}
            className={cn("")}
          />
          <AnimatedBeam
            containerRef={containerRef}
            fromRef={workflowRef}
            toRef={djunoRef}
            className={cn("")}
          />
          <AnimatedBeam
            containerRef={containerRef}
            fromRef={databasesRef}
            toRef={djunoRef}
            className={cn("")}
          />
          <AnimatedBeam
            containerRef={containerRef}
            fromRef={instancesRef}
            toRef={djunoRef}
            className={cn("")}
          />
          <AnimatedBeam
            containerRef={containerRef}
            fromRef={blockEventsRef}
            toRef={djunoRef}
            className={cn("")}
          />
          <AnimatedBeam
            containerRef={containerRef}
            fromRef={web3AuthRef}
            toRef={djunoRef}
            className={cn("")}
          />

          {/* // */}
          <AnimatedBeam
            containerRef={containerRef}
            fromRef={s3Ref}
            toRef={djunoRef}
            reverse={false}
            className={cn("")}
          />
          <AnimatedBeam
            containerRef={containerRef}
            fromRef={ipfsRef}
            toRef={djunoRef}
            reverse={false}
            className={cn("")}
          />
          <AnimatedBeam
            containerRef={containerRef}
            fromRef={walletApiRef}
            toRef={djunoRef}
            reverse={false}
            className={cn("")}
          />
          <AnimatedBeam
            containerRef={containerRef}
            fromRef={nftApiRef}
            toRef={djunoRef}
            reverse={false}
            className={cn("")}
          />
          <AnimatedBeam
            containerRef={containerRef}
            fromRef={marketApiRef}
            toRef={djunoRef}
            reverse={false}
            className={cn("")}
          />
          <AnimatedBeam
            containerRef={containerRef}
            fromRef={blockApiRef}
            toRef={djunoRef}
            reverse={false}
            className={cn("")}
          />

          {/* // */}
          <AnimatedBeam
            containerRef={containerRef}
            fromRef={djunoRef}
            toRef={userRef}
            className={cn("")}
          />
        </>
      )}
    </div>
  );
};

const BeamNode = forwardRef<
  HTMLDivElement,
  { className?: string; children?: React.ReactNode }
>(({ className, children }, ref) => {
  return (
    <div ref={ref} className={cn("z-10", className)}>
      {children}
    </div>
  );
});

const Circle = ({
  className,
  children,
  tooltip,
}: {
  className?: string;
  children?: React.ReactNode;
  tooltip?: TooltipProps;
}) => {
  return (
    <Tooltip {...tooltip}>
      <div
        className={cn(
          "z-10 flex w-11 h-11 items-center justify-center rounded-full border-2 bg-white dark:bg-dark-2 dark:border-dark-1 p-1 shadow-[0_0_20px_-12px_rgba(0,0,0,0.8)]",
          className
        )}
      >
        {children}
      </div>
    </Tooltip>
  );
};

Circle.displayName = "Circle";
export default CloudFeatures;
