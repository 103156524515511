import { jwtEnvAxios } from ".";

//workflows-apis
export function getDappStudioApi() {
  return jwtEnvAxios().get("/dapp-studio");
}

export function activateDappStudioApi() {
  return jwtEnvAxios().post("/dapp-studio/activate");
}

export function deactivateDappStudioApi() {
  return jwtEnvAxios().post("/dapp-studio/deactivate");
}
