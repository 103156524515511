import { Controller, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { InstanceSshCreateSchema } from "../../../utils/validations";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createInstancesSshKeysAsync,
  getInstancesSshKeysAsync,
  handleInstancesSshKeyEditor,
  selectInstancesSshKeysActionLoading,
  selectShowInstancesSshKeysEditor,
} from "../../../store/instances/instancesSSHkeysSlice";
import { Button, Input, Modal, Textarea, Typography } from "djuno-design";
import { useCallback, useEffect } from "react";

export const InstancesSskKeyEditorModal: React.FC = () => {
  const isOpen = useAppSelector(selectShowInstancesSshKeysEditor);

  const dispatch = useAppDispatch();

  const sshKeysActionLoading = useAppSelector(
    selectInstancesSshKeysActionLoading
  );
  const methods = useForm({
    resolver: yupResolver(InstanceSshCreateSchema()),
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
  } = methods;

  const handleSubmitForm = (data: any) => {
    if (!sshKeysActionLoading) {
      dispatch(createInstancesSshKeysAsync({ data })).then((action) => {
        if (action.type === "instances/ssh-keys/create/fulfilled") {
          dispatch(getInstancesSshKeysAsync());
          handleOnClose();
        }
      });
    }
  };

  const handleOnClose = useCallback(() => {
    reset();
    dispatch(handleInstancesSshKeyEditor({ status: false }));
  }, [dispatch, reset]);

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      contentClassName="!max-w-xl overflow-hidden"
      title={"Add SSH key"}
    >
      <form className="mt-5" onSubmit={handleSubmit(handleSubmitForm)}>
        <div className="flex flex-col gap-3">
          <Input
            label="SSH key name"
            {...register("name")}
            error={errors.name?.message}
          />
          <Controller
            control={control}
            name="publicKey"
            render={({ field: { name, onChange } }) => (
              <Textarea
                label="SSH key"
                name={name}
                onChange={onChange}
                // {...register("publicKey")}
                rows={6}
                error={errors.publicKey?.message}
              />
            )}
          />

          <Typography.Text className="!text-xs my-2">
            SSH keys are required to connect to your service. Please refer to
            our <Typography.Link className="!text-xs">guide</Typography.Link> to
            find out more. Your SSH key will be available for all regions and
            OVHcloud datacenters. Only RSA and ECDSA SSH keys are accepted. You
            cannot use ED25519 SSH keys.
          </Typography.Text>
          <div className="flex justify-end gap-2">
            <Button uiType="simple" onClick={handleOnClose}>
              Cancel
            </Button>
            <Button
              uiType="primary"
              type="submit"
              loading={sshKeysActionLoading}
            >
              Add a key
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};
