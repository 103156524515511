import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "./../../../../hooks";
import {
  getWebAppAsync,
  getWebAppOverviewAsync,
  saveWebAppSecretFilesAsync,
  selectWebApp,
  selectWebAppLoading,
  selectWebAppOverview,
  selectWebAppOverviewLoading,
  selectWebAppSecretFiles,
  selectWebAppSecretFilesActionLoading,
  selectWebAppSecretFilesLoading,
  selectWebAppUpdateLoading,
  updateWebAppAsync,
} from "../../../../store/web-app/webAppSlice";
import { useFieldArray, useForm } from "react-hook-form";
import { ReactComponent as ArchiveIcon } from "./../../../../assets/icons/archive-box.svg";
import { ReactComponent as PlusIcon } from "./../../../../assets/icons/plus.svg";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  WebAppEnvironmentsSchema,
  WebAppSecretFilesSchema,
} from "../../../../utils/validations";
import ImportFromEnvFileModal from "../../../settings/env-variables/ImportFromEnvFileModal";
import { handleShowImportFromEnvFileModal } from "../../../../store/settings/env-variables/envVariablesSlice";
import { SecretFileModal } from "../../create-page-steps/CreateFormStep";
import { WebAppSecretFile } from "../../../../types/web-app";
import {
  AnimatedFormError,
  Button,
  Card,
  Flex,
  Input,
  Loading,
  Select,
  Typography,
} from "djuno-design";

const WebAppEnvironmentTab = () => {
  const webApp = useAppSelector(selectWebApp);
  const webAppLoading = useAppSelector(selectWebAppLoading);

  const webAppOverview = useAppSelector(selectWebAppOverview);
  const webAppOverviewLoading = useAppSelector(selectWebAppOverviewLoading);

  const updateLoading = useAppSelector(selectWebAppUpdateLoading);

  const dispatch = useAppDispatch();

  const {
    register,
    formState: { errors, isValidating, isValid, isDirty },
    control,
    setValue,
    handleSubmit,
    trigger,
  } = useForm({
    mode: "all",
    resolver: yupResolver(WebAppEnvironmentsSchema()),
  });

  const {
    fields: ENVsFields,
    append: ENVappend,
    remove: ENVremove,
  } = useFieldArray({
    control,
    name: "EvironmentVariables",
  });

  useEffect(() => {
    if (isValidating) {
      trigger("EvironmentVariables");
    }
  }, [isValidating, trigger]);

  const envs = useMemo(() => {
    return webAppOverview?.Production?.Spec?.EnvVars || [];
  }, [webAppOverview?.Production?.Spec?.EnvVars]);

  const isActiveWebApp = useMemo(() => {
    if (!webApp) return false;
    return ![0, 1].includes(webApp.ServiceStatus);
  }, [webApp]);

  useEffect(() => {
    if (!webAppOverview && webApp?.Id) {
      dispatch(getWebAppOverviewAsync({ webAppId: webApp.Id.toString() }));
    }
  }, [webAppOverview, webApp, dispatch]);

  useEffect(() => {
    setValue("EvironmentVariables", envs);
  }, [setValue, envs]);

  const handleSubmitForm = (data: any) => {
    if (webApp && !updateLoading && !webAppOverviewLoading && !webAppLoading)
      dispatch(
        updateWebAppAsync({
          webAppId: webApp.Id.toString(),
          data: {
            ...webApp,
            EvironmentVariables: data.EvironmentVariables,
          },
        })
      ).then((action) => {
        if (action.type === "web-app/update/fulfilled") {
          dispatch(getWebAppAsync({ webAppId: webApp.Id.toString() }));
          dispatch(getWebAppOverviewAsync({ webAppId: webApp.Id.toString() }));
        }
      });
  };

  return (
    <div className="flex flex-col gap-6">
      <Card
        title="Environment Variables"
        description="Set environment-specific config and secrets (such as API keys), then read those values from your code"
      >
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Flex direction="col" className="w-full mt-3 mb-1">
            {ENVsFields.map((_, index) => (
              <div key={index} className="grid gap-3 grid-cols-12 pb-2 w-full">
                <div className="col-span-4">
                  <Input
                    label=""
                    {...register(`EvironmentVariables.${index}.Name` as const)}
                    error={
                      errors.EvironmentVariables &&
                      errors.EvironmentVariables[index] &&
                      errors.EvironmentVariables[index]?.Name &&
                      errors.EvironmentVariables[index]?.Name?.message
                    }
                    placeholder="Name"
                    disabled={!isActiveWebApp}
                  />
                </div>
                <div className="col-span-8">
                  <div className="flex gap-2">
                    <div className="flex-1">
                      <Input
                        label=""
                        {...register(
                          `EvironmentVariables.${index}.Value` as const
                        )}
                        error={
                          errors.EvironmentVariables &&
                          errors.EvironmentVariables[index] &&
                          errors.EvironmentVariables[index]?.Value &&
                          errors.EvironmentVariables[index]?.Value?.message
                        }
                        placeholder="Value"
                        className="flex-1"
                        disabled={!isActiveWebApp}
                      />
                    </div>
                    <Button
                      uiType="icon"
                      className="!px-2 group"
                      onClick={(e) => {
                        if (isActiveWebApp) {
                          e.preventDefault();
                          ENVremove(index);
                        }
                      }}
                      disabled={!isActiveWebApp}
                    >
                      <ArchiveIcon className="w-5 text-slate-700 dark:text-slate-300 group-hover:text-red-500 group-hover:dark:text-red-400" />
                    </Button>
                  </div>
                </div>
              </div>
            ))}
            <AnimatedFormError
              error={errors.EvironmentVariables?.root?.message}
            />
            <AnimatedFormError error={errors.EvironmentVariables?.message} />
            <Flex
              items="center"
              justify="end"
              className="mt-3 gap-2 w-full flex-wrap"
            >
              <Button
                uiType="light"
                onClick={(e) => {
                  e.preventDefault();
                  ENVappend({
                    Name: "",
                    Value: "",
                  });
                }}
              >
                <PlusIcon className="w-4" />
                Add Environment Variable
              </Button>
              <Button
                uiType="light"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(handleShowImportFromEnvFileModal());
                }}
              >
                Add from .env
              </Button>
              <Button
                uiType="primary"
                type="submit"
                loading={
                  updateLoading || webAppOverviewLoading || webAppLoading
                }
                disabled={
                  !isActiveWebApp ||
                  !isValid ||
                  !isDirty ||
                  (envs.length === 0 && ENVsFields.length === 0)
                }
              >
                Save Changes
              </Button>
            </Flex>
          </Flex>
        </form>
        <ImportFromEnvFileModal callback={ENVappend} />
      </Card>
      {/* <SecretFiles /> */}
      {/* <LinkedEnvironmentGroups /> */}
    </div>
  );
};

const SecretFiles = () => {
  const webApp = useAppSelector(selectWebApp);
  const webAppLoading = useAppSelector(selectWebAppLoading);

  const secretFiles = useAppSelector(selectWebAppSecretFiles);
  const secretFilesLoading = useAppSelector(selectWebAppSecretFilesLoading);
  const secretFilesActionLoading = useAppSelector(
    selectWebAppSecretFilesActionLoading
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (webApp) {
      // dispatch(getWebAppSecretFilesAsync({ webAppId: webApp.Id.toString() }));
    }
  }, [dispatch, webApp]);

  const [secretFileModal, setSecretFileModal] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<
    (WebAppSecretFile & { i: number }) | undefined
  >(undefined);

  const {
    register,
    formState: { errors },
    control,
    setValue,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(WebAppSecretFilesSchema()),
  });

  const {
    fields: secretFileFields,
    append,
    update,
    remove,
  } = useFieldArray({
    control,
    name: "SecretFiles",
  });

  useEffect(() => {
    if (secretFiles) {
      setValue("SecretFiles", secretFiles);
    }
  }, [secretFiles, setValue]);

  const handleSubmitForm = (data: any) => {
    console.log(data);
    if (webApp)
      dispatch(
        saveWebAppSecretFilesAsync({
          webAppId: webApp.Id.toString(),
          secretFiles: data.SecretFiles,
        })
      );
  };

  return (
    <Card
      title="Secret Files"
      description={
        <Flex direction="col">
          <Typography.Text size="sm" uiType="secondary">
            Store plaintext files containing secret data (such as a
            <span className="text-primary-300 ml-1">.env</span> file or a
            private key)
          </Typography.Text>
          <Typography.Text size="sm" uiType="secondary">
            Read these files during builds by absolute path at{" "}
            <span className="text-primary-300 ml-1">{`/etc/secrets/<filename>`}</span>
            .
          </Typography.Text>
        </Flex>
      }
    >
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        {secretFilesLoading && (
          <Flex
            items="center"
            justify="center"
            className="h-full w-full min-h-[200px]"
          >
            <Loading borderSize={2} />
          </Flex>
        )}
        {!secretFilesLoading &&
          secretFileFields.map((secretFile, index) => (
            <div key={index} className="grid gap-3 grid-cols-12 pb-2">
              <div className="col-span-4">
                <Input
                  label=""
                  {...register(`SecretFiles.${index}.Filename` as const)}
                  readOnly={true}
                  onClick={() => {
                    setSelectedFile({ ...secretFile, i: index });
                    setSecretFileModal(true);
                  }}
                  error={
                    errors.SecretFiles &&
                    errors.SecretFiles[index] &&
                    errors.SecretFiles[index]?.Filename &&
                    errors.SecretFiles[index]?.Filename?.message
                  }
                  placeholder="file name"
                />
              </div>
              <div className="col-span-8">
                <Flex items="center" className="gap-2">
                  <div className="flex-1">
                    <Input
                      label=""
                      {...register(
                        `SecretFiles.${index}.FileContents` as const
                      )}
                      readOnly
                      //type="password" //TODO
                      onClick={() => {
                        setSelectedFile({ ...secretFile, i: index });
                        setSecretFileModal(true);
                      }}
                      error={
                        errors.SecretFiles &&
                        errors.SecretFiles[index] &&
                        errors.SecretFiles[index]?.FileContents &&
                        errors.SecretFiles[index]?.FileContents?.message
                      }
                      placeholder="file contents"
                      className="flex-1"
                    />
                  </div>
                  <Button
                    uiType="light"
                    onClick={(e) => {
                      e.preventDefault();
                      remove(index);
                    }}
                    disabled={false}
                  >
                    <ArchiveIcon className="w-5 text-slate-700 dark:text-slate-300 hover:text-red-500 hover:dark:text-red-400" />
                  </Button>
                </Flex>
              </div>
            </div>
          ))}
        <Flex items="center" justify="between" className="gap-2 mt-3">
          <Button
            uiType="light"
            onClick={(e) => {
              e.preventDefault();
              setSecretFileModal(true);
            }}
          >
            <PlusIcon className="w-4" />
            Add Secret File
          </Button>
          {secretFileFields.length > 0 && (
            <Button
              uiType="primary"
              type="submit"
              disabled={secretFilesLoading}
              loading={secretFilesActionLoading}
            >
              Save Changes
            </Button>
          )}
        </Flex>
      </form>
      <SecretFileModal
        isOpen={secretFileModal}
        onClose={() => {
          setSecretFileModal(false);
          setSelectedFile(undefined);
        }}
        callback={(data: any) => {
          if (selectedFile) {
            setValue(`SecretFiles.${selectedFile.i}`, data, {
              shouldDirty: true,
              shouldTouch: true,
              shouldValidate: true,
            });
            update(selectedFile.i, data);
          } else {
            append(data);
          }
        }}
        selectedFile={selectedFile}
      />
    </Card>
  );
};

const LinkedEnvironmentGroups = () => {
  const [selectedGroup, setSelectedGroup] = useState(undefined);
  return (
    <Card title="Linked Environment Groups">
      <div className="grid grid-cols-5 gap-x-10 my-6 w-full">
        <Flex direction="col" className="col-span-2">
          <Typography.Text size="sm">Link Environment Group</Typography.Text>
          <Typography.Text size="sm" className="mt-2" uiType="secondary">
            Environment groups are collections of environment variables and
            secret files that you can share across multiple services.
          </Typography.Text>
        </Flex>
        <div className="col-span-3">
          <Flex items="center" className="gap-4">
            <Select
              emptyString="Select an environment group..."
              options={[]}
              value={selectedGroup}
              className="flex-1"
            />
            <Button disabled={true}>Link</Button>
          </Flex>
        </div>
      </div>
    </Card>
  );
};
export default WebAppEnvironmentTab;
