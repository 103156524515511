import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import {
  selectBucketDetails,
  handleHideCreateFolderModal,
  selectShowCreateFolderModal,
  setBreadCrumb,
  selectBucketDetailsLoading,
  getBucketObjectsAsync,
  selectBucketBreadCrumbItems,
} from "../../../../../store/s3/buckets/bucketSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CreateS3FolderSchema } from "../../../../../utils/validations";
import { FileBreadCrumbItem } from "../../../../../types/ipfs-file";
import { handleHideObjectDrawer } from "../../../../../store/s3/buckets/objectSlice";
import { Button, Input, Modal, uuid } from "djuno-design";
import { useCallback, useEffect } from "react";

const BucketNewFolderModal = () => {
  const bucketDetails = useAppSelector(selectBucketDetails);
  const bucketDetailsLoading = useAppSelector(selectBucketDetailsLoading);
  const isOpen = useAppSelector(selectShowCreateFolderModal);
  const dispatch = useAppDispatch();

  const currentBreadcrumb = useAppSelector(selectBucketBreadCrumbItems);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CreateS3FolderSchema),
  });

  const onSubmit = (data: any) => {
    // if (bucketDetails) {
    //   dispatch(
    //     createFolderAsync({
    //       name: data.name,
    //       path: path,
    //     })
    //   ).then((action) => {
    //     if (action.type === "bucket/folder/create/fulfilled") {
    //       reset();
    //       dispatch(handleHideCreateFolderModal());
    //       dispatch(getBucketObjectsAsync({ name: bucketDetails.name }));
    //     }
    //   });
    // }
    const pathArrray: string[] = data.name.split("/").filter(Boolean);
    const breadcrumbItems = pathArrray.map(
      (item) => ({ id: uuid(), title: item } as FileBreadCrumbItem)
    );

    dispatch(
      setBreadCrumb({
        breadCrumbItems: [...currentBreadcrumb, ...breadcrumbItems],
      })
    );
    if (bucketDetails) {
      dispatch(getBucketObjectsAsync({ name: bucketDetails.name }));
    }
    reset();
    dispatch(handleHideCreateFolderModal());
    dispatch(handleHideObjectDrawer());
  };

  const handleOnClose = useCallback(() => {
    reset();
    dispatch(handleHideCreateFolderModal());
  }, [dispatch, reset]);

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  return (
    <Modal
      title="Create new path"
      contentClassName="max-w-lg"
      isOpen={isOpen}
      onClose={handleOnClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5">
          <Input
            {...register("name")}
            error={errors.name?.message}
            placeholder=""
          />
        </div>

        <div className="mt-4 flex justify-end">
          <Button
            uiType="primary"
            disabled={bucketDetails === null}
            type="submit"
            loading={bucketDetailsLoading}
          >
            Create
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default BucketNewFolderModal;
