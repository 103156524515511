import { useNavigate } from "react-router-dom";
// import { ReactComponent as BellAlertIcon } from "./../../assets/icons/bell-alert.svg";
import { ReactComponent as CloseIcon } from "./../../assets/icons/close.svg";
import { ReactComponent as ArrowRightIcon } from "./../../assets/icons/arrow-right.svg";
import { WebAppsUrl } from "../../utils/urls";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { WebAppCreateLevels } from "../../types/web-app";
import {
  selectWebAppSelectedDeployType,
  handleClearWebAppCreationSlice,
  selectWebAppCreateLoading,
  selectWebAppHasDisk,
  selectWebAppImageValue,
  selectWebAppImageCredentialId,
  createWebAppsAsync,
  selectWebAppTagQuery,
  selectWebAppImageQuery,
  selectWebAppNameValidationLoading,
  selectWebAppIsValidName,
} from "../../store/web-app/webAppCreateSlice";
import SelectDeployingTypeStep from "../../components/web-apps/create-page-steps/SelectDeployingTypeStep";
import SelectDeployingSourceStep from "../../components/web-apps/create-page-steps/SelectDeployingSourceStep";
import CreateFormStep from "../../components/web-apps/create-page-steps/CreateFormStep";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { WebAppCreateSchema } from "../../utils/validations";
import { Button, Steps, Typography } from "djuno-design";
import useConfigIntercomeSetting from "../../hooks/useConfigIntercomeSetting";
import {
  getWebAppsAsync,
  getWebAppsPlansAsync,
  selectWebApps,
} from "../../store/web-app/webAppsSlice";
import useLimitations from "../../hooks/useLimitations";

const WebAppCreatePage = () => {
  const webApps = useAppSelector(selectWebApps);

  useConfigIntercomeSetting();
  const navigate = useNavigate();
  const [step, setStep] = useState<WebAppCreateLevels>("SelectDeployingType");

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (webApps.length === 0) {
      dispatch(getWebAppsAsync({}));
    }
  }, [dispatch, webApps.length]);

  const { numberOfWebAppsLimitChecker } = useLimitations({
    cases: ["NumberOfWebApps"],
  });
  const isValidToCreateNewWebApp = useMemo(
    () => !numberOfWebAppsLimitChecker(webApps.length),
    [numberOfWebAppsLimitChecker, webApps.length]
  );

  if (!isValidToCreateNewWebApp) {
    navigate(WebAppsUrl);
  }

  const nameValidationLoading = useAppSelector(
    selectWebAppNameValidationLoading
  );
  const isValidName = useAppSelector(selectWebAppIsValidName);
  const selectedDeployType = useAppSelector(selectWebAppSelectedDeployType);
  const hasDisk = useAppSelector(selectWebAppHasDisk);
  const createLoading = useAppSelector(selectWebAppCreateLoading);
  const imageValue = useAppSelector(selectWebAppImageValue);
  const imageQuery = useAppSelector(selectWebAppImageQuery);
  const imageCredentialId = useAppSelector(selectWebAppImageCredentialId);
  const tagQuery = useAppSelector(selectWebAppTagQuery);

  const form = useForm({
    resolver: yupResolver(WebAppCreateSchema(hasDisk)),
    mode: "all",
    defaultValues: {
      Port: "80",
      // PlanId: 17,
    },
  });

  useEffect(() => {
    form.setValue("AppName", "");
    // form.setValue("Origin", webAppRegions[0].value);
    // form.setValue("PlanId", webAppInstances[0].id);

    form.setValue("EvironmentVariables", []);
    // form.setValue("SecretFiles", []);
    // form.setValue("DiskName", "");
    // form.setValue("DiskMountPath", "");
    // form.setValue("DiskSize", null);
    // form.setValue("HealthCheckPath", "");
    // form.setValue("DockerCommand", "");
    // form.setValue("PreDeployCommand", "");
    return () => {
      dispatch(handleClearWebAppCreationSlice());
    };
  }, [dispatch, form]);

  useEffect(() => {
    dispatch(getWebAppsPlansAsync({}));
  }, [dispatch]);

  const handleCreateWebApp = (data: any) => {
    // console.log(data);
    if (!createLoading) {
      const formData = {
        AppName: data.AppName,
        PlanId: data.PlanId,
        EvironmentVariables: data.EvironmentVariables,
        ImageName: imageValue,
        ImageTag: tagQuery,
        Autodeploy: true,
        Port: data.Port || 80,
        ReplicaCounts: 1,
        RegistryId:
          imageCredentialId !== null ? Number(imageCredentialId) : null,
      };
      dispatch(createWebAppsAsync({ data: formData })).then((action) => {
        if (action.type === "web-apps/create/fulfilled") {
          dispatch(getWebAppsAsync({}));
          navigate(WebAppsUrl);
        }
      });
    }
  };

  return (
    <>
      <div className="flex items-center justify-between h-16 px-6 sticky top-0 z-20 bg-white dark:bg-dark-1 border-b dark:border-dark-2">
        <div className="items-center justify-between flex flex-1 transition duration-150">
          <Typography.Text className="font-medium">
            Create Web App
          </Typography.Text>
        </div>
        <div className="">
          <Button
            uiType="light"
            uiSize="small"
            onClick={() => navigate(WebAppsUrl)}
            className="group"
          >
            <CloseIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>

      <Steps
        step={step}
        steps={[
          {
            label: (
              <Typography.Text
                uiType="transparent"
                className="!text-xs md:!text-sm"
              >
                Deploying Type
              </Typography.Text>
            ),
            value: "SelectDeployingType",
            callback: () => setStep("SelectDeployingSourceStep"),
          },
          {
            label: (
              <Typography.Text
                uiType="transparent"
                className="!text-xs md:!text-sm"
              >
                Deploying Source
              </Typography.Text>
            ),
            value: "SelectDeployingSourceStep",
            callback: () => setStep("CreateFormStep"),
          },
          {
            label: (
              <Typography.Text
                uiType="transparent"
                className="!text-xs md:!text-sm"
              >
                App Data
              </Typography.Text>
            ),
            value: "CreateFormStep",
          },
        ]}
        className="px-6"
      />
      <form onSubmit={form.handleSubmit(handleCreateWebApp)}>
        <div className="mt-5 w-full px-6">
          <div className="mx-auto flex-1 flex justify-start pb-14 w-full">
            {step === "SelectDeployingType" && <SelectDeployingTypeStep />}
            {step === "SelectDeployingSourceStep" && (
              <SelectDeployingSourceStep />
            )}
            {step === "CreateFormStep" && <CreateFormStep form={form} />}
          </div>
        </div>
        <div className="fixed bottom-0 right-0 left-0 flex items-center w-full h-16 border-t bg-white dark:bg-dark-1 dark:border-dark-2 px-6 lg:pl-80">
          {step === "SelectDeployingType" && (
            <div className="flex justify-end w-full">
              <Button
                uiType="primary"
                disabled={!selectedDeployType}
                onClick={() => setStep("SelectDeployingSourceStep")}
                className="group  w-[110px]"
              >
                Continue
                <ArrowRightIcon className="w-4 h-4 group-hover:scale-110 group-hover:translate-x-1 transition-all duration-300" />
              </Button>
            </div>
          )}
          {step === "SelectDeployingSourceStep" && (
            <div className="flex items-center justify-between w-full">
              <Button
                uiType="light"
                onClick={() => {
                  setStep("SelectDeployingType");
                }}
              >
                back
              </Button>
              <Button
                uiType="primary"
                disabled={
                  imageValue === "" ||
                  imageValue !== imageQuery ||
                  tagQuery === ""
                }
                onClick={() => setStep("CreateFormStep")}
                className="group  w-[110px]"
              >
                Continue
                <ArrowRightIcon className="w-4 h-4 group-hover:scale-110 group-hover:translate-x-1 transition-all duration-300" />
              </Button>
            </div>
          )}
          {step === "CreateFormStep" && (
            <div className="flex items-center justify-between w-full">
              <Button
                uiType="light"
                onClick={() => {
                  setStep("SelectDeployingSourceStep");
                }}
              >
                back
              </Button>
              <Button
                uiType="primary"
                type="submit"
                loading={createLoading || nameValidationLoading}
                disabled={
                  !form.formState.isValid ||
                  !isValidName ||
                  nameValidationLoading
                }
              >
                Create Web App
              </Button>
            </div>
          )}
        </div>
      </form>
    </>
  );
};

export default WebAppCreatePage;
