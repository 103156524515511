import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { S3AccessKeySchemaTiers } from "../../../utils/validations";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getS3TiersAsync,
  handleHideEditor,
  selectS3TierActionLoading,
  selectS3Tiers,
  selectSelectedS3Tier,
  selectShowS3TierEditor,
  updateS3TierCredentioalAsync,
} from "../../../store/s3/tiersSlice";
import { Button, Input, Modal } from "djuno-design";
import { useCallback, useEffect } from "react";

const UpdateCredentialsModal = () => {
  const isOpen = useAppSelector(selectShowS3TierEditor);
  const actionLoading = useAppSelector(selectS3TierActionLoading);
  const tiers = useAppSelector(selectS3Tiers);
  const tire = useAppSelector(selectSelectedS3Tier);
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(S3AccessKeySchemaTiers),
  });

  const handleClearComponent = useCallback(() => {
    reset();
    setValue("accessKey", "");
    setValue("secretKey", "");
  }, [reset, setValue]);

  const onSubmit = (data: any) => {
    dispatch(
      updateS3TierCredentioalAsync({
        data,
        tiers,
      })
    ).then((action) => {
      if (action.type === "s3-tiers/update/fulfilled") {
        handleClearComponent();
        dispatch(handleHideEditor());
        dispatch(getS3TiersAsync());
      }
    });
  };

  const handleOnClose = useCallback(() => {
    dispatch(handleHideEditor());
    handleClearComponent();
  }, [dispatch, handleClearComponent]);

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      contentClassName="max-w-lg"
      containerClassName="!items-start"
      title={`Update Credentials ${tire ? tire.type : ""} / ${
        tire ? tire[tire.type]?.name : ""
      }`}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5 flex flex-col w-full gap-5">
          {tire && (
            <>
              <Input
                label="Access key"
                {...register("accessKey")}
                error={errors.accessKey?.message}
                placeholder=""
              />
              <Input
                label="Secret key"
                {...register("secretKey")}
                error={errors.secretKey?.message}
                placeholder=""
              />
            </>
          )}
        </div>
        <div className="mt-4 flex justify-end">
          <Button
            loading={actionLoading}
            uiType="primary"
            disabled={actionLoading}
            type="submit"
          >
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default UpdateCredentialsModal;
