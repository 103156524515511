import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  WebAppEnvUrl,
  WebAppEventsUrl,
  WebAppLogsUrl,
  WebAppMetricsUrl,
  WebAppScalingUrl,
  WebAppSettingsUrl,
  WebAppShellUrl,
  WebAppUrl,
  WebAppsUrl,
} from "../../utils/urls";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { ReactComponent as RightArrow } from "./../../assets/icons/arrow-right.svg";
import { Helmet } from "react-helmet";
import {
  getWebAppAsync,
  getWebAppOverviewAsync,
  handleClearWebAppSlice,
  selectWebApp,
  selectWebAppLoading,
} from "../../store/web-app/webAppSlice";
import { NotExist, WebAppNotActiveTab } from "../../components/layouts/NotData";
import { ReactComponent as ComputerIcon } from "./../../assets/icons/computer-desktop.svg";
// import { ReactComponent as DiskIcon } from "./../../assets/icons/driver.svg";
import { ReactComponent as EnviromentIcon } from "./../../assets/icons/globe-alt.svg";
import { ReactComponent as ShellIcon } from "./../../assets/icons/shell.svg";
// import { ReactComponent as PreviewIcon } from "./../../assets/icons/eye.svg";
// import { ReactComponent as JobIcon } from "./../../assets/icons/job.svg";
// import { ReactComponent as ShareIcone } from "./../../assets/icons/share.svg";
import { ReactComponent as MetricsIcon } from "./../../assets/icons/adjustments-vertical.svg";
import { ReactComponent as ScaleIcon } from "./../../assets/icons/scale.svg";
import { ReactComponent as SettingIcon } from "./../../assets/icons/setting.svg";
import { ReactComponent as BeatIcon } from "./../../assets/icons/beat.svg";
import { ReactComponent as Calender } from "./../../assets/icons/calendar.svg";
import { Skeleton, Typography, Tabs, TabOptions, Flex } from "djuno-design";
import {
  getWebAppsPlansAsync,
  WebAppsRefreshStatus,
} from "../../store/web-app/webAppsSlice";
import { WebAppStatusTag } from "./WebAppsPage";

const WebAppSinglePage = () => {
  const { webAppId } = useParams();
  const webApp = useAppSelector(selectWebApp);
  const webAppLoading = useAppSelector(selectWebAppLoading);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isExist, setIsExist] = useState<boolean>(true);

  // get web app data
  useEffect(() => {
    if ((webApp === null || webApp.Id.toString() !== webAppId) && webAppId) {
      dispatch(
        getWebAppAsync({
          webAppId,
        })
      ).then((action) => {
        if (action.type === "web-app/rejected") {
          setIsExist(false);
        }
      });
    }
  }, [dispatch, webAppId, webApp]);

  const isActive = useMemo(() => {
    if (!webApp) return true;
    return webApp.ServiceStatus > 1;
  }, [webApp]);

  // get web app overview data
  useEffect(() => {
    if (webApp && isActive)
      dispatch(
        getWebAppOverviewAsync({
          webAppId: webApp.Id.toString(),
          withoutLoading: false,
        })
      );
  }, [dispatch, isActive, webApp]);

  // refresh web app data based on app status
  useEffect(() => {
    if (webApp) {
      let interval: NodeJS.Timeout | undefined;
      let intervalTime = 8000;

      const shouldSetInterval = WebAppsRefreshStatus.includes(
        webApp.ServiceStatus
      );

      if (shouldSetInterval) {
        interval = setInterval(() => {
          dispatch(
            getWebAppAsync({
              webAppId: webApp.Id.toString(),
              withoutLoading: true,
            })
          );
        }, intervalTime);
      } else {
        if (interval) clearInterval(interval);
      }

      return () => {
        if (interval) {
          clearInterval(interval);
        }
      };
    }
  }, [dispatch, webAppId, webApp]);

  // cleanup
  useEffect(() => {
    return () => {
      dispatch(handleClearWebAppSlice());
    };
  }, [dispatch]);

  // get web app plans
  useEffect(() => {
    dispatch(getWebAppsPlansAsync({}));
  }, [dispatch]);

  const Web3EndpointPageMenus: TabOptions = useMemo(
    () => [
      {
        label: (
          <div className="flex items-center gap-1 ">
            <ComputerIcon className="w-3" />
            Overview
          </div>
        ),
        url: WebAppUrl(webAppId),
      },
      {
        label: (
          <div className="flex items-center gap-1 logs">
            <BeatIcon className="w-3" />
            Logs
          </div>
        ),
        url: WebAppLogsUrl(webAppId),
      },
      {
        label: (
          <div className="flex items-center gap-1 environment">
            <EnviromentIcon className="w-3" />
            Environment
          </div>
        ),

        url: WebAppEnvUrl(webAppId),
      },
      {
        label: (
          <div className="flex items-center gap-1 sell">
            <ShellIcon className="w-3" />
            Shell
          </div>
        ),

        url: WebAppShellUrl(webAppId),
      },
      {
        label: (
          <div className="flex items-center gap-1 metrics">
            <MetricsIcon className="w-3" />
            Metrics
          </div>
        ),
        url: WebAppMetricsUrl(webAppId),
      },
      {
        label: (
          <div className="flex items-center gap-1 scaling">
            <ScaleIcon className="w-3" />
            Scaling
          </div>
        ),
        url: WebAppScalingUrl(webAppId),
      },
      {
        label: (
          <div className="flex items-center gap-1 events">
            <Calender className="w-3" />
            Events
          </div>
        ),
        url: WebAppEventsUrl(webAppId),
      },
      {
        label: (
          <div className="flex items-center gap-1 settings">
            <SettingIcon className="w-3" />
            Settings
          </div>
        ),
        url: WebAppSettingsUrl(webAppId),
      },

      // {
      //   label: (
      //     <div className="flex items-center gap-1">
      //       <DiskIcon className="w-3" />
      //       Disks
      //     </div>
      //   ),
      //   url: WebAppDisksUrl(webAppId),
      //   disabled: true,
      // },
      // {
      //   label: (
      //     <div className="flex items-center gap-1">
      //       <JobIcon className="w-3" />
      //       Jobs
      //     </div>
      //   ),
      //   url: WebAppJobsUrl(webAppId),
      //   disabled: true,
      // },
      // {
      //   label: (
      //     <div className="flex items-center gap-1">
      //       <PreviewIcon className="w-3" />
      //       Previews
      //     </div>
      //   ),
      //   url: WebAppPreviewsUrl(webAppId),
      // },
      // {
      //   label: (
      //     <div className="flex items-center gap-1">
      //       <ShareIcone className="w-3" />
      //       Sharing
      //     </div>
      //   ),
      //   url: WebAppSharingUrl(webAppId),
      // },
    ],
    [webAppId]
  );

  const pageName = useMemo(
    () => location.pathname.split("/").filter(Boolean)[2] || "",
    [location.pathname]
  );

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | Web App ({webApp?.ServiceName || ""}){" "}
          {pageName}
        </title>
        <meta
          name="description"
          content="web3-auth refers to authentication mechanisms used within Web3 applications, which are decentralized applications built on blockchain technology. Web3-auth facilitates user authentication and authorization processes within these decentralized applications"
        />
      </Helmet>
      <Flex items="center" justify="between" className="h-20">
        <Flex items="center" justify="between" className="px-6 flex-1">
          <Flex items="center" className="gap-2">
            <RightArrow
              onClick={() => navigate(WebAppsUrl)}
              className="rotate-180 w-5 h-5 hover:scale-110 transition-all duration-500 text-slate-800 dark:text-slate-100 cursor-pointer"
            />
            {webAppLoading ? (
              <Skeleton shape="rectangle" className="!w-48" />
            ) : (
              <Flex items="center" className="gap-2">
                <Typography.Title
                  level={5}
                  className="!mb-0 !whitespace-nowrap !max-w-[200px] md:!max-w-[400px] lg:!max-w-[350px] xl:!max-w-[400px] !truncate"
                >
                  {webApp?.ServiceName}
                </Typography.Title>
                <WebAppStatusTag
                  ServiceStatus={webApp?.ServiceStatus}
                  IsSuspended={webApp?.IsSuspended}
                />
              </Flex>
            )}
          </Flex>
        </Flex>
        <div className="mr-6 w-96"></div>
      </Flex>
      {isExist ? (
        <div className="mx-6">
          <Tabs
            options={Web3EndpointPageMenus.map((o) => ({
              label: o.label,
              url: o.url,
              disabled: o.disabled,
            }))}
          />
          {webApp && isActive && (
            <div className="py-6">
              <Outlet />
            </div>
          )}
        </div>
      ) : (
        <NotExist url={() => navigate(WebAppsUrl)} />
      )}
      {!webAppLoading && webApp && !isActive && <WebAppNotActiveTab />}
    </>
  );
};
export default WebAppSinglePage;
