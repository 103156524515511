import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { editor } from "monaco-editor";
import { jwtEnvAxios } from "../../../../../apis";
import { getDirectoryPath } from "../utils";
import { useDappGit } from "./DappGitContext";

export type EditorSection =
  | "editor"
  | "compiler"
  | "deploy"
  | "source-controll";

export interface DappFile {
  name: string;
  isDirectory: boolean;
  path: string;
  gitStatus?: string;

  isSelectable?: boolean;

  children?: DappFile[]; // Nested files
  content?: string; // Original content of the file
  newContent?: string; // Updated content from the editor
  isDirty?: boolean; // Indicates if content has changed
}

export type NewFileType = "file" | "folder";
export type NewFileDataType = {
  type: null | NewFileType;
  dir: string;
  name: string;
};
export type RenameFileDataType = {
  type: null | NewFileType;
  renamingFile: null | DappFile;
  newName: string;
};

interface OutputMessage {
  type: "error" | "success";
  message: string;
}

interface FileContextType {
  // global variables
  activeSection: EditorSection;
  changeActiveSection: (section: EditorSection) => void;

  // file explorer section
  files: DappFile[];
  loadingFilesPath: string[] | undefined;
  openFiles: DappFile[];
  selectedFile: DappFile | null;
  expandedFilesPath: string[] | undefined;
  activeFile: DappFile | null;
  clearSelectedFile: () => void;
  dirtyFiles: DappFile[];
  dirtySolFiles: DappFile[];
  loadPath: (path: string | undefined | null) => Promise<void>;
  getFolderContents: (dirPath: string) => DappFile[];
  handleSetActiveFile: (filePath: string) => void;
  closeFile: (filePath: string) => void;
  handleFileClick: (file: DappFile) => void;
  updateFileContent: (filePath: string, newContent: string) => void;
  isDirtyFile: (filePath: string) => boolean;
  saveFile: (file: DappFile) => void;
  saveAllDirtyFiles: () => Promise<void>;
  compoleFile: (file?: DappFile) => void;
  actionLoading: boolean;
  compileLoading: boolean;
  compileOutput: OutputMessage | undefined;
  compiledContracts: DappFile[];
  getFileContentToDeploy: (file: DappFile) => Promise<string | undefined>;

  newFileData: NewFileDataType;
  setNewFileData: React.Dispatch<React.SetStateAction<NewFileDataType>>;
  handleCreateNewFile: () => Promise<boolean>;

  handleFileUpload: (
    file: globalThis.File,
    parentFile: DappFile | undefined | null
  ) => Promise<void>;
  deleteFile: (filePath: string) => Promise<boolean>;

  renameFileData: RenameFileDataType;
  setRenameFileData: React.Dispatch<React.SetStateAction<RenameFileDataType>>;
  handleRenameFile: () => Promise<boolean>;

  // ai chat
  isOpenAiChat: boolean;
  handleToggleChat: () => void;

  isFullScreen: boolean;
  handleToggleFullScreen: () => void;

  // deploy
  wallectAccount: string;
  handleSetWalletAccount: (account: string) => void;

  // editor
  editorRef: React.MutableRefObject<editor.IStandaloneCodeEditor | null>;
  setEditorRef: (editor: editor.IStandaloneCodeEditor) => void;
  editorIsAvailable: boolean;
  openCommandPalette: () => void;

  expandSpecificTargetedFiles: (
    files?: DappFile[],
    selectPath?: string
  ) => void;

  handleRightClick: (file: DappFile) => void;
  contextMenuFile: DappFile | null;

  clearDappStudio: () => void;
}

const DappFilesContext = createContext<FileContextType | null>(null);

export const DappFilesProvider: React.FC<{
  children: React.ReactNode;
  isActive?: boolean;
}> = ({ children, isActive }) => {
  const [editorIsAvailable, setEditorIsAvailable] = useState(false);
  const editorRef = useRef<editor.IStandaloneCodeEditor | null>(null);

  const [activeSection, setActiveSection] = useState<EditorSection>("editor");
  const [selectedFile, setSelectedFile] = useState<DappFile | null>(null);
  const [contextMenuFile, setContextMenuFile] = useState<DappFile | null>(null);
  const [expandedFilesPath, setExpandedFilesPath] = useState<
    string[] | undefined
  >();
  const [loadingFilesPath, setLoadingFilesPath] = useState<
    string[] | undefined
  >();

  const [files, setFiles] = useState<DappFile[]>([]);
  const [openFiles, setOpenFiles] = useState<DappFile[]>([]);
  const [activeFile, setActiveFile] = useState<DappFile | null>(null);

  const [actionLoading, setActionLoading] = useState(false);

  const [compiledContracts, setCompiledContracts] = useState<DappFile[]>([]);
  const [compileLoading, setCompileLoading] = useState(false);
  const [compileOutput, setCompileOutput] = useState<OutputMessage>();

  const [isOpenAiChat, setIsOpenChat] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const [wallectAccount, setWalletAccount] = useState("");

  const [newFileData, setNewFileData] = useState<NewFileDataType>({
    type: null,
    dir: "",
    name: "",
  });
  const [renameFileData, setRenameFileData] = useState<RenameFileDataType>({
    type: null,
    renamingFile: null,
    newName: "",
  });

  const { handleGitStatus, handleGitGetRemote } = useDappGit();

  const changeActiveSection = (section: EditorSection) => {
    setActiveSection(section);
  };

  const normalizePath = (p: string) => p.replace(/^\/+/, ""); // Remove leading slashes

  // Find a file by its path
  const findFileByPath = useCallback(
    (path: string, fileList?: DappFile[]): DappFile | null => {
      const _files = fileList || files;
      for (const file of _files) {
        if (file.path === path) return file;
        if (file.isDirectory && file.children) {
          const nestedFile = findFileByPath(path, file.children);
          if (nestedFile) return nestedFile;
        }
      }
      return null;
    },
    [files]
  );

  const getFolderContents = useCallback(
    (folderPath: string): DappFile[] => {
      const findContents = (currentFiles: DappFile[]): DappFile[] | null => {
        for (const file of currentFiles) {
          // If the current file matches the folderPath, return its children
          if (file.isDirectory && file.path === folderPath) {
            return file.children || [];
          }
          // If the current file is a directory, traverse deeper
          if (file.isDirectory && file.children) {
            const result = findContents(file.children);
            if (result) return result;
          }
        }
        return null;
      };

      // Root-level folder contents if folderPath is empty or root-level folder
      if (!folderPath || folderPath === "/") {
        return files;
      }

      return findContents(files) || [];
    },
    [files]
  );

  // Update a file in the files state
  const updateFileInFiles = useCallback(
    (
      fileList: DappFile[],
      filePath: string,
      updater: (file: DappFile) => DappFile
    ): DappFile[] => {
      return fileList.map((file) => {
        if (file.path === filePath) {
          return updater(file);
        }
        if (file.isDirectory && file.children) {
          return {
            ...file,
            children: updateFileInFiles(file.children, filePath, updater),
          };
        }
        return file;
      });
    },
    []
  );

  // Update the file tree after compiling
  const updateFileTree = (
    prevFiles: DappFile[],
    newFiles: DappFile[],
    targetPath: string
  ): DappFile[] => {
    const findOrCreateDirectory = (
      parent: DappFile[],
      pathParts: string[],
      basePath: string
    ): DappFile[] => {
      if (pathParts.length === 0) return parent;

      const [head, ...rest] = pathParts;
      let directory = parent.find(
        (file) => file.isDirectory && file.name === head
      );

      if (!directory) {
        directory = {
          name: head,
          isDirectory: true,
          path: normalizePath(`${basePath}/${head}`),
          children: [],
        };
        parent.push(directory);
      }

      if (!directory.children) directory.children = [];
      directory.children = findOrCreateDirectory(
        directory.children,
        rest,
        directory.path
      );

      return parent;
    };

    const normalizedPathParts = normalizePath(targetPath)
      .split("/")
      .filter(Boolean);
    const updatedFiles = findOrCreateDirectory(
      [...prevFiles],
      normalizedPathParts,
      ""
    );

    const targetDirectory = normalizedPathParts.reduce<DappFile | null>(
      (dir, part) => {
        if (!dir || !dir.children) return null;
        return (
          dir.children.find(
            (child) => child.isDirectory && child.name === part
          ) || null
        );
      },
      { children: updatedFiles } as DappFile
    );

    if (targetDirectory && targetDirectory.children) {
      newFiles.forEach((item) => {
        const normalizedItemPath = normalizePath(item.path);
        const existingItem = targetDirectory.children?.find(
          (child) => child.path === normalizedItemPath
        );

        if (existingItem) {
          Object.assign(existingItem, { ...item, path: normalizedItemPath }); // Update existing item
        } else {
          targetDirectory.children?.push({
            ...item,
            path: normalizePath(`${normalizePath(targetPath)}/${item.name}`), // Build path relative to normalized path
          });
        }
      });
    }

    return updatedFiles;
  };

  // Load files from the backend
  const loadFiles = useCallback(
    async (path = "", hardLoad = false) => {
      // Look for children of the specified path, avoid directly using state here
      const parentDir = path ? findFileByPath(path, files) : null;

      // Skip loading if children are already present in the path
      if (parentDir?.isDirectory && parentDir.children && !hardLoad) {
        return;
      }

      let loadRoot = false;

      // Fetch files from the backend if children are not loaded
      try {
        if (path === "") {
          setActionLoading(true);
          loadRoot = true;
        }
        setLoadingFilesPath((prev) => {
          return [...(prev ?? []), path];
        });
        const response = await jwtEnvAxios("dapp").get(`/files`, {
          params: { path },
        });
        const loadedFiles =
          process.env.REACT_APP_USE_LOCAL_DAPP === "true"
            ? response.data.files
            : response.data.Result.files;

        // Update state depending on whether we're at root level or within a directory
        if (path === "") {
          // If at root level, replace the entire file list
          setFiles(loadedFiles || []);
        } else {
          // Update the specific directory's children (ensure this update is efficient)
          setFiles((prevFiles) => {
            const updatedFiles = [...prevFiles];
            const parentDirToUpdate = findFileByPath(path, updatedFiles);
            if (parentDirToUpdate && parentDirToUpdate.isDirectory) {
              parentDirToUpdate.children = loadedFiles || [];
            }
            return updatedFiles;
          });
        }
      } catch (error) {
        console.error("Failed to load files:", error);
      } finally {
        if (path === "" && loadRoot) {
          setActionLoading(false);
          loadRoot = false;
          setExpandedFilesPath([]);
        }
        setLoadingFilesPath((prev) => {
          if (prev?.includes(path)) {
            return prev.filter((item) => item !== path);
          }
          return prev;
        });
      }
    },
    [files, findFileByPath] // Avoiding infinite loops by limiting dependencies
  );

  const loadPath = useCallback(
    async (path: string | undefined | null) => {
      await loadFiles(path || "", true);
      await handleGitStatus();
    },
    [loadFiles, handleGitStatus]
  );

  // Open a file and set its content
  const openFile = (file: DappFile, content: string) => {
    // Update file content in the files state
    setFiles((prevFiles) => {
      const updatedFiles = updateFileInFiles(prevFiles, file.path, (f) => ({
        ...f,
        content,
        newContent: f.newContent !== undefined ? f.newContent : content,
        isDirty: f.isDirty !== undefined ? f.isDirty : false,
      }));

      // Update the active file using the newly updated files
      const updatedActiveFile = findFileByPath(file.path, updatedFiles);
      setActiveFile(updatedActiveFile);

      return updatedFiles;
    });

    // Add to openFiles only if not already present
    if (!openFiles.find((f) => f.path === file.path)) {
      setOpenFiles([...openFiles, { ...file }]);
    }
  };

  // Close a file
  const closeFile = useCallback(
    (filePath: string) => {
      setOpenFiles((prevFiles) => {
        const closingFileIndex = prevFiles.map((f) => f.path).indexOf(filePath);

        const updatedOpenFiles = prevFiles.filter((f) => f.path !== filePath);
        if (updatedOpenFiles.length > 0) {
          if (closingFileIndex !== undefined) {
            const newOpenFile =
              updatedOpenFiles[closingFileIndex] ||
              updatedOpenFiles[closingFileIndex - 1] ||
              updatedOpenFiles[closingFileIndex + 1];

            const updatedActiveFile = findFileByPath(newOpenFile.path);
            setActiveFile(updatedActiveFile);
            setSelectedFile(updatedActiveFile);
          } else {
            setActiveFile(null);
            setSelectedFile(null);
          }
        } else {
          setActiveFile(null);
          setSelectedFile(null);
        }

        return updatedOpenFiles;
      });
    },
    [findFileByPath]
  );

  // Handle file click in the explorer
  const handleFileClick = async (file: DappFile) => {
    const filePath = normalizePath(file.path);
    setSelectedFile(file);
    if (!file.isDirectory) {
      // Check if the file content is already in files (no need to make a new request)
      const existingFile = findFileByPath(filePath, files);

      if (existingFile && existingFile.content !== undefined) {
        // If content already exists, just open it
        openFile(existingFile, existingFile.content);
      } else {
        // If content doesn't exist, fetch it from the backend
        try {
          setActionLoading(true);
          const response = await jwtEnvAxios("dapp").get(`/read/${filePath}`);
          const content =
            process.env.REACT_APP_USE_LOCAL_DAPP === "true"
              ? response.data.content
              : response.data.Result.content;
          openFile(file, content);
        } catch (error) {
          console.error("Failed to load file content:", error);
        } finally {
          setActionLoading(false);
        }
      }
    } else {
      setExpandedFilesPath((prev) => {
        if (prev?.includes(filePath)) {
          return prev.filter((item) => item !== filePath);
        }
        return [...(prev ?? []), filePath];
      });
      // If it's a directory, load the files
      loadFiles(filePath);
    }
  };

  // Set the active file when a file is clicked
  const handleSetActiveFile = (filePath: string) => {
    const _selectedFile = findFileByPath(filePath, files);
    setSelectedFile(_selectedFile);
    setActiveFile(_selectedFile || null);
    expandSpecificTargetedFiles(files, filePath);
  };

  const clearSelectedFile = () => {
    setSelectedFile(null);
  };

  // Update the content of an open file
  const updateFileContent = (filePath: string, newContent: string) => {
    // Update file content and isDirty status in the files state
    setFiles((prevFiles) =>
      updateFileInFiles(prevFiles, filePath, (f) => ({
        ...f,
        newContent,
        isDirty: f.content !== newContent,
      }))
    );

    // Update active file if it's the file being modified
    setActiveFile((prevFile) => {
      if (prevFile && prevFile.path === filePath) {
        return {
          ...prevFile,
          newContent,
          isDirty: prevFile.content !== newContent,
        };
      } else {
        return prevFile;
      }
    });
  };

  // Check if a file is dirty
  const isDirtyFile = (filePath: string) => {
    const fileInFiles = findFileByPath(filePath);
    if (fileInFiles) return fileInFiles.isDirty || false;
    return false;
  };

  // Get all dirty files
  const dirtyFiles: DappFile[] = useMemo(() => {
    const dirtyFiles: DappFile[] = [];
    // console.log("dirty files", dirtyFiles, files);

    const traverseAndCollect = (file: DappFile): void => {
      if (file.isDirty) {
        // console.log("dirty file", file);
        dirtyFiles.push(file);
      }

      // Recursively process children if the file is a directory
      if (file.isDirectory && file.children) {
        file.children.forEach(traverseAndCollect);
      }
    };

    if (files.length > 0) files.forEach(traverseAndCollect);

    return dirtyFiles;
  }, [files]);

  // Get all dirty solidity files
  const dirtySolFiles: DappFile[] = useMemo(() => {
    return dirtyFiles.filter((file) => file.path.endsWith(".sol"));
  }, [dirtyFiles]);

  // create new file or folder
  const handleCreateNewFile = async (): Promise<boolean> => {
    try {
      const { name, type, dir } = newFileData;
      if (newFileData) setActionLoading(true);
      const fileClearName = name.replace(/\.\./g, "").replace(/\//g, "").trim();
      let response;
      if (type === "file") {
        response = await jwtEnvAxios("dapp").post(`/create`, {
          currentPath: dir,
          filename: fileClearName,
        });
      } else if (type === "folder") {
        response = await jwtEnvAxios("dapp").post(`/create-folder`, {
          currentPath: dir,
          foldername: fileClearName,
        });
      }

      if (response?.status === 200) {
        // Add the new file or folder to the `files` state
        setFiles((prevFiles) => {
          const updatedFiles = [...prevFiles];

          const parentDir = findFileByPath(dir, updatedFiles);

          const newItem: DappFile = {
            name: fileClearName,
            isDirectory: type === "folder",
            path: dir ? `${dir}/${fileClearName}` : fileClearName,
          };

          if (parentDir && parentDir.isDirectory) {
            parentDir.children = parentDir.children
              ? [...parentDir.children, newItem]
              : [newItem];
          } else if (dir === "") {
            // If at the root level, directly add the new item
            updatedFiles.push(newItem);
          }

          return updatedFiles;
        });

        setNewFileData({
          dir: "",
          type: null,
          name: "",
        });

        handleGitStatus();
        return true;
      }
    } catch (error) {
      console.error("Failed to create new file or folder:", error);
    } finally {
      setActionLoading(false);
    }
    return false;
  };

  const handleFileUpload = async (
    nativeFile: globalThis.File,
    parentFile: DappFile | undefined | null
  ) => {
    try {
      setActionLoading(true);
      const path = getDirectoryPath(parentFile);
      const formData = new FormData();
      formData.append("file", nativeFile);
      formData.append("currentPath", path);

      const response = await jwtEnvAxios("dapp").post("/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        setFiles((prevFiles) => {
          const updatedFiles = [...prevFiles];

          const parentDir = findFileByPath(path, updatedFiles);
          if (parentDir && parentDir.isDirectory) {
            const newFile: DappFile = {
              name: nativeFile.name,
              isDirectory: false,
              path: path ? `${path}/${nativeFile.name}` : nativeFile.name,
            };

            parentDir.children = parentDir.children
              ? [...parentDir.children, newFile]
              : [newFile];
          } else if (path === "") {
            updatedFiles.push({
              name: nativeFile.name,
              isDirectory: false,
              path: path ? `${path}/${nativeFile.name}` : nativeFile.name,
            });
          }

          return updatedFiles;
        });
        handleGitStatus();
      } else {
        console.error("File upload failed:", response.data.Result.message);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setActionLoading(false);
    }
  };

  // Save the current file's content
  const saveFile = (file: DappFile) => {
    if (file.newContent && file.isDirty) {
      setActionLoading(true);

      // const filePath = file.path.startsWith("/")
      //   ? file.path.slice(0)
      //   : `${file.path}`;
      jwtEnvAxios("dapp")
        .post(`/save/${file.path}`, { content: file.newContent })
        .then(() => {
          // Update the file's state after a successful save
          setFiles((prevFiles) =>
            updateFileInFiles(prevFiles, file.path, (f) => ({
              ...f,
              content: file.newContent, // Update the original content to match the new content
              isDirty: false, // Mark as not dirty
            }))
          );
          handleGitStatus();
        })
        .catch((error) => {
          console.error("Failed to save file:", error);
        })
        .finally(() => {
          setActionLoading(false);
        });
    }
  };

  // Save all dirty files in the files state
  const saveAllDirtyFiles = async () => {
    try {
      setActionLoading(true);
      await Promise.all(
        dirtyFiles.map((file) =>
          jwtEnvAxios("dapp")
            .post(`/save/${file.path}`, { content: file.newContent })
            .then(() => {
              // Update each file after it's saved
              setFiles((prevFiles) =>
                updateFileInFiles(prevFiles, file.path, (f) => ({
                  ...f,
                  content: f.newContent, // Update original content
                  isDirty: false, // Mark as not dirty
                }))
              );
            })
        )
      );

      handleGitStatus();
    } catch (error) {
      console.error("Failed to save all dirty files:", error);
    } finally {
      setActionLoading(false);
    }
  };

  // Utility function to recursively remove the file/folder from the files tree
  const removeFileFromTree = useCallback(
    (files: DappFile[], filePath: string): DappFile[] => {
      return files
        .map((file) => {
          // If the file matches the path, exclude it from the result
          if (file.path === filePath) {
            return null;
          }

          // If it's a folder, recursively update its children
          if (file.children && file.children.length > 0) {
            return {
              ...file,
              children: removeFileFromTree(file.children, filePath),
            };
          }

          // Otherwise, return the file unchanged
          return file;
        })
        .filter(Boolean) as DappFile[]; // Remove any null entries
    },
    []
  );

  // Delete a file or folder
  const deleteFile = useCallback(
    async (filePath: string) => {
      try {
        setActionLoading(true);
        // Call the delete API
        await jwtEnvAxios("dapp").delete(`/delete/${filePath}`);

        // Update the files tree
        setFiles((prevFiles) => {
          const updatedFiles = removeFileFromTree(prevFiles, filePath);
          return updatedFiles;
        });

        closeFile(filePath);
        handleGitStatus();
        return true;
      } catch (error) {
        console.error(`Failed to delete ${filePath}:`, error);
        return false;
      } finally {
        setActionLoading(false);
      }
    },
    [closeFile, removeFileFromTree]
  );

  // Function to rename a file or folder
  const handleRenameFile = async () => {
    try {
      const { newName, renamingFile } = renameFileData;

      const oldPath = renamingFile?.path || "";
      const oldName = renamingFile?.name || "";

      // Extract the folder path and the new full path
      const folderPath = oldPath.substring(0, oldPath.lastIndexOf("/"));
      const newPath = folderPath
        ? `${folderPath}/${newName}`
        : normalizePath(newName);

      // Call the rename API
      await jwtEnvAxios("dapp").post(`/rename`, {
        currentPath: folderPath,
        oldName,
        newName,
      });

      // Update the files state
      setFiles((prevFiles) => {
        return updateFileInFiles(prevFiles, oldPath, (file) => ({
          ...file,
          name: newName,
          path: newPath,
        }));
      });

      handleGitStatus();

      // If the renamed file is open, update its path in the openFiles state
      setOpenFiles((prevOpenFiles) =>
        prevOpenFiles.map((file) =>
          file.path === oldPath
            ? { ...file, name: newName, path: newPath }
            : file
        )
      );

      // Update active file if it matches the renamed file
      setActiveFile((prevActiveFile) =>
        prevActiveFile?.path === oldPath
          ? { ...prevActiveFile, name: newName, path: newPath }
          : prevActiveFile
      );

      // Update the selected file if it matches the renamed file
      setSelectedFile((prevSelectedFile) =>
        prevSelectedFile?.path === oldPath
          ? { ...prevSelectedFile, name: newName, path: newPath }
          : prevSelectedFile
      );

      setRenameFileData({
        renamingFile: null,
        type: null,
        newName: "",
      });
      return true;
    } catch (error) {
      console.error("Failed to rename file or folder:", error);
      return false;
    }
  };

  // Compile the current file
  const compoleFile = async (file?: DappFile): Promise<void> => {
    setCompileLoading(true);
    await saveAllDirtyFiles();

    jwtEnvAxios("dapp")
      .post(`/compile?path=/build/contracts`) //${file.path}
      .then((res) => {
        const response =
          process.env.REACT_APP_USE_LOCAL_DAPP === "true"
            ? res.data
            : res.data.Result;
        const { items, compileOutput, currentPath } = response as {
          items: DappFile[];
          compileOutput: string;
          currentPath: string;
        };
        const normalizeItems = items.map((item) => ({
          ...item,
          path: item.path.replace(/^\/+/, ""), // Remove leading slashes from the path
        }));
        setCompileOutput({ type: "success", message: compileOutput });
        setCompiledContracts(normalizeItems);
        setFiles((prevFiles) =>
          updateFileTree(prevFiles, normalizeItems, currentPath)
        );
      })
      .catch((error) => {
        console.error("Failed to compile file:", error.response.data.message);
        if (error.response.data.message) {
          setCompileOutput({
            type: "error",
            message: error.response.data.message,
          });
        }
      })
      .finally(() => {
        setCompileLoading(false);
      });
  };

  const getFileContentToDeploy = async (
    file: DappFile
  ): Promise<string | undefined> => {
    try {
      const response = await jwtEnvAxios("dapp").get(`/read/${file.path}`);
      return process.env.REACT_APP_USE_LOCAL_DAPP === "true"
        ? response.data.content
        : response.data.Result.content;
    } catch (e) {
    } finally {
      setActionLoading(false);
    }
  };

  // ai chat
  const handleToggleChat = () => {
    setIsOpenChat((prev) => !prev);
  };

  // deploy
  const handleSetWalletAccount = (account: string) => {
    setWalletAccount(account);
  };

  //utils
  const handleToggleFullScreen = () => {
    setIsFullScreen((prev) => !prev);
  };

  // editor configurations
  const setEditorRef = useCallback(
    (editor: editor.IStandaloneCodeEditor) => {
      editorRef.current = editor;
      setEditorIsAvailable(openFiles.length > 0);
    },
    [openFiles]
  );

  const openCommandPalette = () => {
    if (editorRef.current) {
      // Ensure the editor is focused
      editorRef.current.focus();

      const quickCommand = editorRef.current.getAction(
        "editor.action.quickCommand"
      );

      if (!quickCommand) {
        console.error(
          "The 'editor.action.quickCommand' action is not available."
        );
        return;
      }

      quickCommand
        .run()
        .then(() => {
          console.log("Command palette opened successfully.");
        })
        .catch((error) => {
          console.error("Failed to open command palette:", error);
        });
    } else {
      console.error("Editor instance is not initialized.");
    }
  };

  const expandSpecificTargetedFiles = useCallback(
    (files?: DappFile[], selectPath?: string) => {
      if (!files || !selectPath) return;
      const findParent = (
        currentFile: DappFile,
        currentPath: string[] = []
      ) => {
        const isSelectable = currentFile.isSelectable ?? true;
        const newPath = [...currentPath, currentFile.path];
        if (currentFile.path === selectPath) {
          if (isSelectable) {
            setExpandedFilesPath((prev) => [...(prev ?? []), ...newPath]);
          } else {
            if (newPath.includes(currentFile.path)) {
              newPath.pop();
              setExpandedFilesPath((prev) => [...(prev ?? []), ...newPath]);
            }
          }
          return;
        }
        if (
          isSelectable &&
          currentFile.children &&
          currentFile.children.length > 0
        ) {
          currentFile.children.forEach((child) => {
            findParent(child, newPath);
          });
        }
      };
      files.forEach((f) => {
        findParent(f);
      });
    },
    []
  );

  const handleRightClick = useCallback((file: DappFile) => {
    setContextMenuFile(file);
  }, []);

  const clearDappStudio = useCallback(() => {
    setFiles([]);
    setActiveFile(null);
    setActiveSection("editor");
    setCompileOutput(undefined);
    setCompiledContracts([]);
    setExpandedFilesPath([]);
    setIsOpenChat(false);
    setLoadingFilesPath([]);
    setOpenFiles([]);
    setSelectedFile(null);
    setContextMenuFile(null);
    setWalletAccount("");
    setEditorIsAvailable(false);
    setActionLoading(false);
    setCompileLoading(false);
  }, []);

  // Load files on initial render
  useEffect(() => {
    const initDappIde = async () => {
      await loadFiles("");
      await handleGitStatus(true);
      await handleGitGetRemote();
    };

    setSelectedFile(null);
    setContextMenuFile(null);
    if (isActive) {
      initDappIde();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  useEffect(() => {
    const available = editorRef.current && openFiles.length > 0;
    setEditorIsAvailable(available || false);
  }, [openFiles.length]);

  useEffect(() => {
    if (newFileData.type) {
      expandSpecificTargetedFiles(files, newFileData.dir);
    }
  }, [expandSpecificTargetedFiles, files, newFileData.dir, newFileData.type]);

  return (
    <DappFilesContext.Provider
      value={{
        activeSection,
        changeActiveSection,
        files,
        loadingFilesPath,
        selectedFile,
        clearSelectedFile,
        expandedFilesPath,
        handleFileClick, // <----
        closeFile,
        handleSetActiveFile,
        updateFileContent,
        openFiles,
        activeFile,
        dirtyFiles,
        dirtySolFiles,
        isDirtyFile,
        loadPath,
        getFolderContents,

        handleRightClick,
        contextMenuFile,

        newFileData,
        setNewFileData,
        handleCreateNewFile,
        handleFileUpload,
        saveFile,
        saveAllDirtyFiles,
        deleteFile,
        handleRenameFile,
        renameFileData,
        setRenameFileData,
        actionLoading,

        compoleFile,
        compileLoading,
        compileOutput,
        compiledContracts,
        getFileContentToDeploy,

        isOpenAiChat,
        handleToggleChat,

        isFullScreen,
        handleToggleFullScreen,

        wallectAccount,
        handleSetWalletAccount,

        editorRef,
        setEditorRef,
        editorIsAvailable,
        openCommandPalette,

        expandSpecificTargetedFiles,

        clearDappStudio,
      }}
    >
      {children}
    </DappFilesContext.Provider>
  );
};

export const useDappFilesContext = () => {
  const context = useContext(DappFilesContext);
  if (!context)
    throw new Error(
      "useDappFilesContext must be used within DappFilesProvider"
    );
  return context;
};
