import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import {
  handleHideRewindModal,
  selectBucketDetails,
  selectBucketRewindLoading,
  selectShowRewindModal,
  setBucketRewindAsync,
} from "../../../../../store/s3/buckets/bucketSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import { S3RewindSchema } from "../../../../../utils/validations";
import { useForm } from "react-hook-form";
import { Button, Input, Modal } from "djuno-design";
import { useCallback, useEffect } from "react";

const S3RewindModal = () => {
  const isOpen = useAppSelector(selectShowRewindModal);
  const loading = useAppSelector(selectBucketRewindLoading);
  const bucketDetails = useAppSelector(selectBucketDetails);

  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(S3RewindSchema),
  });

  const handleClearComponent = useCallback(() => {
    reset();
  }, [reset]);

  const onSubmit = (data: any) => {
    // console.log(data);
    if (bucketDetails) {
      dispatch(
        setBucketRewindAsync({
          name: bucketDetails.name,
          date: data.time,
        })
      ).then((action) => {
        if (action.type === "bucket/rewind/set/fulfilled") {
          handleClearComponent();
          dispatch(handleHideRewindModal());
        }
      });
    }
  };

  const handleOnClose = useCallback(() => {
    dispatch(handleHideRewindModal());
    handleClearComponent();
  }, [dispatch, handleClearComponent]);

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      contentClassName="max-w-lg"
      title={`Rewind - ${bucketDetails?.name}`}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5 flex flex-col w-full gap-5">
          <Input
            label="Rewind to"
            {...register("time")}
            type="datetime-local"
            error={errors.time?.message}
          />
        </div>
        <div className="mt-4 flex justify-end gap-2">
          <Button
            onClick={(e) => {
              handleClearComponent();
              dispatch(handleHideRewindModal());
            }}
            uiType="light"
          >
            Cancel
          </Button>
          <Button
            loading={loading}
            uiType="primary"
            disabled={loading}
            type="submit"
          >
            Show Rewind Data
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default S3RewindModal;
