import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  selectSelectedBlockEventHistory,
  handleHideBlockEventHistoryModal,
} from "../../../store/block-event/blockEventsSlice";
import { formatTimestamp } from "../../../utils/date";
import { Typography, Modal } from "djuno-design";

const BlockEventHistoryModal = () => {
  const blockEventHistory = useAppSelector(selectSelectedBlockEventHistory);
  const dispatch = useAppDispatch();

  const handleOnClose = useCallback(() => {
    dispatch(handleHideBlockEventHistoryModal());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  return (
    <Modal
      isOpen={blockEventHistory !== null}
      onClose={handleOnClose}
      contentClassName="max-w-lg overflow-hidden"
      containerClassName=""
      title={"Event details"}
    >
      <div className="mt-5 flex flex-col w-full gap-5 min-h-[300px]">
        <div className="flex items-center justify-between">
          <Typography.Text className="!text-sm">Timestamp</Typography.Text>
          <Typography.Text className="!text-sm">
            {blockEventHistory &&
              formatTimestamp(blockEventHistory.created_at).datetime}
          </Typography.Text>
        </div>
        <div className="flex items-center justify-between">
          <Typography.Text className="!text-sm">Block number</Typography.Text>
          <Typography.Text className="!text-sm">
            {blockEventHistory?.block_number}
          </Typography.Text>
        </div>
        <div className="flex items-center justify-between">
          <Typography.Text className="!text-sm">Status</Typography.Text>
          <Typography.Text
            className="!text-sm"
            uiType={
              blockEventHistory
                ? blockEventHistory.delivered
                  ? "success"
                  : "danger"
                : "warning"
            }
          >
            {blockEventHistory
              ? blockEventHistory.delivered
                ? "Delivered"
                : "Failed"
              : ""}
          </Typography.Text>
        </div>
        <div className="flex items-center justify-between">
          {blockEventHistory?.delivered_error && (
            <Typography.Text className="!text-sm !p-4" uiType="danger">
              All attempts fail: #1: Get "https://test.con": dial tcp: lookup
              test.con on 10.96.5.5:53: no such host #2: Get "https://test.con":
              dial tcp: lookup test.con on 10.96.5.5:53: no such host #3: Get
              "https://test.con": dial tcp: lookup test.con on 10.96.5.5:53: no
              such host
            </Typography.Text>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default BlockEventHistoryModal;
