import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  selectShowBlockEventExpressionModal,
  handleCloseExpressionEditor,
  selectBlockEventExpressionEditLoading,
  editExpressionAsync,
} from "../../../store/block-event/blockEventCreateSlice";
import CreateExpressionStep from "./create-page-steps/CreateExpressionStep";
import {
  getBlockEventAsync,
  selectBlockEvent,
} from "../../../store/block-event/blockEventsSlice";
import { Modal, Button } from "djuno-design";
import { useCallback, useEffect } from "react";

const BlockEventExprissionModal = () => {
  const blockEvent = useAppSelector(selectBlockEvent);
  const isOpen = useAppSelector(selectShowBlockEventExpressionModal);
  const loading = useAppSelector(selectBlockEventExpressionEditLoading);
  const dispatch = useAppDispatch();

  const handleOnClose = useCallback(() => {
    dispatch(handleCloseExpressionEditor());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      contentClassName="!max-w-4xl overflow-hidden"
      containerClassName="!items-start"
      title={"Edit Expression"}
    >
      <div className="max-h-[70vh] overflow-y-auto mt-5">
        <CreateExpressionStep />
      </div>
      <div className="mt-4 flex justify-end">
        <Button
          loading={loading}
          uiType="primary"
          onClick={() => {
            blockEvent &&
              dispatch(editExpressionAsync({ blockEvent })).then((action) => {
                if (action.type === "block-event/expression/edit/fulfilled") {
                  dispatch(getBlockEventAsync(blockEvent.BlockEventId));
                  dispatch(handleCloseExpressionEditor());
                }
              });
          }}
          className="w-[100px]"
        >
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default BlockEventExprissionModal;
