import * as yup from "yup";
import { AccessPolicyTypes } from "../types/s3-bucket";
import { PasswordErrors } from "../types";
import { Inputs } from "../components/databases/users/UserCreateTab";
import { isAddress } from "ethers";

const userFullnameRegex = /^[a-zA-Z]{2,}((\s+){1,}([a-zA-Z]+){2,}){1,}$/;
//----------------- users and environments ---------------------
const passwordRules = () =>
  yup
    .string()
    .min(8, "The password must be at least 8 characters long")
    .max(64, "The password must be no more than 64 characters long")
    .matches(/[a-z]/, "The password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "The password must contain at least one uppercase letter")
    .matches(/[0-9]/, "The password must contain at least one number")
    .matches(
      // eslint-disable-next-line no-useless-escape
      /[\!\@\#\$\%\^\&\*\(\)\-\_\=\+\[\]\{\}\|\;\:\'\"\<\>\,\.\?\/\\\~\`]/,
      "The password must contain at least one special character"
    )
    .required("The password is required");

export const validatePassword = (password: string): PasswordErrors => {
  const schema = passwordRules();
  let errors: PasswordErrors = {
    length: false,
    lowercase: false,
    uppercase: false,
    number: false,
    special_char: false,
  };

  try {
    schema.validateSync(password, { abortEarly: false });
  } catch (error: any) {
    error.inner.forEach((err: yup.ValidationError) => {
      const errorMessage = err.message;
      if (
        errorMessage.includes("The password must be at least 8 characters long")
      ) {
        errors.length = true;
      } else if (
        errorMessage.includes(
          "The password must be no more than 64 characters long"
        )
      ) {
        errors.length = true;
      } else if (
        errorMessage.includes(
          "The password must contain at least one lowercase letter"
        )
      ) {
        errors.lowercase = true;
      } else if (
        errorMessage.includes(
          "The password must contain at least one uppercase letter"
        )
      ) {
        errors.uppercase = true;
      } else if (
        errorMessage.includes("The password must contain at least one number")
      ) {
        errors.number = true;
      } else if (
        errorMessage.includes(
          "The password must contain at least one special character"
        )
      ) {
        errors.special_char = true;
      }
    });
  }

  return errors;
};

export const SignUpSchema = (invited: boolean = false) =>
  yup
    .object({
      fullName: yup
        .string()
        .min(4, "The full name must be at least 4 characters long")
        .max(250, "The full name must be at most 250 characters long")
        .matches(
          userFullnameRegex,
          "The full name must be at least 2 parts and contain alphabetical characters only"
        )
        .required(),
      organizationName: invited
        ? yup.string().nullable()
        : yup
            .string()
            .min(4, "The organization must be at least 4 characters long")
            .required(),
      email: invited
        ? yup.string().nullable()
        : yup.string().email().required("Email is a required field"),
      password: passwordRules(),
      termsOfService: yup
        .bool()
        .oneOf([true], "The terms of service must be checked")
        .required("The terms of service is required"),
    })
    .required();

export const SignInSchema = yup
  .object({
    Email: yup.string().email().required(),
    Password: yup.string().required(),
  })
  .required();

export const ForgotPasswordSchema = yup
  .object({
    email: yup.string().email().required(),
  })
  .required();

export const ResetPasswordSchema = yup
  .object({
    password: passwordRules(),
    passwordConfirmation: yup.string().required(),
  })
  .required();

export const UserProfileSchema = yup
  .object({
    name: yup
      .string()
      .min(4, "The full name must be at least 4 characters long")
      .max(250, "The full name must be at most 250 characters long")
      .matches(userFullnameRegex, "The full name muse be at least 2 part")
      .required(),
  })
  .required();

export const ChangePasswordSchema = yup
  .object({
    password: yup.string().required("Current password is a required field"),
    newPassword: passwordRules(),
    newPasswordConfirmation: yup
      .string()
      .required("Password confirm is a required field"),
  })
  .required();

export const CreateEnvSchema = yup
  .object({
    name: yup
      .string()
      .required()
      .matches(/^\S.*$/, "Name should not start with a space"),
  })
  .required();

export const InviteUserSchema = yup
  .object({
    // name: yup.string().required(),
    email: yup.string().email().required(),
  })
  .required();

export const Enable2FASchema = yup
  .object({
    code: yup.string().required("Code is a required field"),
  })
  .required();

export const AddCredentialSchema = yup
  .object({
    Name: yup.string().required(),
    RegistryType: yup.number().required("Registry type is a required field!"),
    UserName: yup.string().required("Username is a required field!"),
    Sec_Key: yup.string().required("Password is a required field!"),
  })
  .required();

//IPFS
export const CreateIpfsFolderSchema = yup
  .object({
    name: yup
      .string()
      .required("Path is a required field!")
      .test("no-leading-space", "Path cannot start with a space", (value) => {
        if (value && value[0] === " ") {
          return false;
        }
        return true;
      })
      .test(
        "no-slash",
        'Path should not contain a slash ("/")',
        (value) => !value || !value.includes("/")
      )
      .test(
        "no-plus",
        'Path should not contain the "+" character',
        (value) => !value || !value.includes("+")
      )
      .test(
        "no-dot",
        'Path should not contain the "." character',
        (value) => !value || !value.includes(".")
      )
      .test(
        "no-double-dot",
        'Path should not contain consecutive dots ("..")',
        (value) => !value || !value.includes("..")
      ),
  })
  .required();

export const IpfsApiKeySchema = yup
  .object({
    label: yup.string().required(),
  })
  .required();

// export const IpfsGatewaySchema = yup
//   .object({
//     Name: yup.string().required(),
//     IpfsWhiteListRequest: yup.array().of(
//       yup.object({
//         WhiteListType: yup
//           .number()
//           .typeError("Please enter a valid number")
//           .required("Type is a required"),
//         WhiteList: yup.string().required("Value is a required"),
//       })
//     ),
//   })
//   .required();

export const IpfsGatewaySchema = yup
  .object({
    WhiteListType: yup.string().required("Type is a required"),
    WhiteList: yup
      .string()
      .required("Value is a required")
      .test(
        "is-ipv4-or-cidr",
        "Must be an IPv4 (e.g. 192.168.0.1) or an IP address block (e.g. 192.168.0.1/24)",
        (value) => {
          const ipv4Regex =
            /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
          const cidrRegex =
            /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/([0-9]|[1-2][0-9]|3[0-2]))$/;
          return ipv4Regex.test(value) || cidrRegex.test(value);
        }
      ),
  })
  .required();

//RPC
export const RpcJwtSchema = yup
  .object({
    name: yup.string().required(),
    publicKey: yup.string().required(),
  })
  .required();

//s3
export const S3bucketNameSchema = (
  name: string | undefined,
  existingPartitions: string[] | undefined
): { type: string; message: string }[] => {
  const errors: { type: string; message: string }[] = [];

  if (!name || typeof name !== "string") {
    errors.push({
      type: "string",
      message: "Bucket name is required and must be a string",
    });
  } else {
    if (name.length < 3) {
      errors.push({
        type: "least3characters",
        message: "Name must be at least 3 characters long",
      });
    }

    if (name.length > 63) {
      errors.push({
        type: "most63characters",
        message: "Name must be at most 63 characters long",
      });
    }

    if (!/^[a-z0-9.-]+$/.test(name)) {
      errors.push({
        type: "InvalidCharacters",
        message: "Invalid characters in the name",
      });
    }

    if (/[.-]$/.test(name)) {
      errors.push({
        type: "finishWithDot",
        message: "Name can't finish with a dot (.) or hyphen (-)",
      });
    }

    if (/\.\.|-\.|\.-/.test(name)) {
      errors.push({
        type: "twoAdjacentPeriods",
        message:
          "Name must not contain two adjacent periods or a period adjacent to a hyphen",
      });
    }

    if (/^\d+\.\d+\.\d+\.\d+$/.test(name)) {
      errors.push({
        type: "IPaddress",
        message: "Name must not be formatted as an IP address",
      });
    }

    if (/^xn--/.test(name)) {
      errors.push({
        type: "prefixXn",
        message: "Name must not start with the prefix xn--",
      });
    }

    if (/-s3alias$/.test(name)) {
      errors.push({
        type: "suffixS3alias",
        message: "Name must not end with the suffix -s3alias",
      });
    }

    if (existingPartitions && existingPartitions.includes(name)) {
      errors.push({
        type: "partition",
        message: "Name must be unique within a partition",
      });
    }
  }

  return errors;
};

export const S3bucketSchema = (
  versioning: boolean,
  objectLocking: boolean,
  quota: boolean,
  retention: boolean,
  existingPartitions: string[]
) =>
  yup
    .object()
    .shape({
      name: yup
        .string()
        .required("Bucket name is required")
        .min(3, "Name must be at least 3 characters long")
        .max(63, "Name must be at most 63 characters long")
        .matches(/^[a-z0-9.-]+$/, "Invalid characters in the name")
        .test(
          "noTrailingDotOrHyphen",
          "Name can't finish with a dot (.) or hyphen (-)",
          (value) => !/[.-]$/.test(value)
        )
        .test(
          "noAdjacentPeriodOrHyphen",
          "Name must not contain two adjacent periods or a period adjacent to a hyphen",
          (value) => !/\.\.|-\.|\.-/.test(value)
        )
        .test(
          "notIpAddressFormat",
          "Name must not be formatted as an IP address",
          (value) => !/^\d+\.\d+\.\d+\.\d+$/.test(value)
        )
        .test(
          "notStartWithXn",
          "Name must not start with the prefix xn--",
          (value) => !/^xn--/.test(value)
        )
        .test(
          "notEndWithS3Alias",
          "Name must not end with the suffix -s3alias",
          (value) => !/-s3alias$/.test(value)
        )
        .test(
          "isUniqueWithinPartition",
          "Name must be unique within a partition",
          (value) =>
            !existingPartitions ||
            !existingPartitions.some((partition) => partition === value)
        ),
      excludePrefixes:
        versioning && !objectLocking
          ? yup.array().of(
              yup.object({
                prefix: yup.string().required("Prefix is a required"),
              })
            )
          : yup.array().nullable(),
      capacity: quota
        ? yup
            .number()
            .typeError("Please enter a valid number")
            .moreThan(0, "Capacity must be greater than 0")
            .notRequired()
        : yup.string().nullable(),
      validity: retention
        ? yup
            .number()
            .typeError("Please enter a valid number")
            .moreThan(0)
            .notRequired()
        : yup.string().nullable(),
    })
    .required();

export const CreateS3FolderSchema = yup
  .object({
    name: yup
      .string()
      .required("Path is a required field!")
      .test("no-leading-space", "Path cannot start with a space", (value) => {
        console.log("value:", value[0]);
        if (value && value[0] === " ") {
          return false;
        }
        return true;
      }),
  })
  .required();

export const S3KmsSchema = yup
  .object({
    name: yup.string().required(),
  })
  .required();

export const S3RewindSchema = yup
  .object({
    time: yup.string().required("Please select a valid date"),
  })
  .required();

export const S3QuotaSchema = (quota: boolean) =>
  yup
    .object({
      capacity: quota
        ? yup
            .number()
            .typeError("Please enter a valid number")
            .moreThan(0, "Capacity must be greater than 0")
            .notRequired()
        : yup.string().nullable(),
    })
    .required();

export const S3AccessPolicySchema = (type: AccessPolicyTypes | undefined) =>
  yup
    .object({
      access: yup.string().required("Acccess is a required field!"),
      definition:
        type === "CUSTOM"
          ? yup.string().required("Policy is a required field!")
          : yup.string().nullable(),
    })
    .required();

export const S3TagSchema = yup
  .object({
    key: yup.string().required(),
    value: yup.string().required(),
  })
  .required();

export const S3RetentionSchema = yup
  .object({
    validity: yup.number().typeError("Please enter a valid number").required(),
  })
  .required();

export const S3VersioningSchema = (versioning: boolean) =>
  yup
    .object({
      // Name: yup.string().required(),
      excludePrefixes: yup.array().of(
        yup.object({
          prefix: yup.string().required("Prefix is a required"),
        })
      ),
    })
    .required();

export const S3AccessKeySchema = (update: boolean) =>
  yup
    .object({
      accessKey: update
        ? yup.string().nullable()
        : yup.string().required("Access key is a required field"),
      secretKey: update
        ? yup.string().nullable()
        : yup.string().required("Secret key is a required field"),
      policy: yup.string().nullable(),
      expiration: yup.string().nullable(),
      name: yup.string().nullable(),
      description: yup.string().nullable(),
      comments: yup.string().nullable(),
    })
    .required();

export const S3AccessKeySchemaTiers = yup
  .object({
    accessKey: yup.string().required("Access key is a required field"),
    secretKey: yup.string().required("Secret key is a required field"),
  })
  .required();

export const S3LifecycleSchema = (selectedType: string) =>
  yup
    .object({
      expiry_days: yup.string().required("expiry days is a required field"),
      prefix: yup.string().nullable(),
      tags: yup.array().of(
        yup.object({
          key: yup.string().nullable(),
          value: yup.string().nullable(),
        })
      ),
    })
    .required();

export const S3AnonymousAccessSchema = yup
  .object({
    prefix: yup.string().nullable(),
  })
  .required();

export const S3BucketEventSchema = yup
  .object({
    prefix: yup.string().nullable(),
    suffix: yup.string().nullable(),
    put: yup.boolean().nullable(),
    get: yup.boolean().nullable(),
    delete: yup.boolean().nullable(),
    replica: yup.boolean().nullable(),
    ilm: yup.boolean().nullable(),
    scanner: yup.boolean().nullable(),
  })
  .required();

export const S3KafkaEventSchema = yup
  .object({
    identifier: yup.string().required(),
    brokers: yup.string().nullable(),
    topic: yup.string().nullable(),
    sasl_username: yup.string().nullable(),
    sasl_password: yup.string().nullable(),
    sasl_mechanism: yup.string().nullable(),
    tls_client_auth: yup.string().nullable(),
    client_tls_cert: yup.string().nullable(),
    client_tls_key: yup.string().nullable(),
    version: yup.string().nullable(),
    queue_dir: yup.string().nullable(),
    queue_limit: yup
      .number()
      .typeError("Please enter a valid number")
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .nullable(),
    comment: yup.string().nullable(),
  })
  .required();

export const S3AmqpEventSchema = yup
  .object({
    identifier: yup.string().required(),
    url: yup.string().nullable(),
    exchange: yup.string().nullable(),
    exchange_type: yup.string().nullable(),
    routing_key: yup.string().nullable(),
    delivery_mode: yup.string().nullable(),
    queue_dir: yup.string().nullable(),
    queue_limit: yup
      .number()
      .typeError("Please enter a valid number")
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .nullable(),
    comment: yup.string().nullable(),
  })
  .required();

export const S3MqttEventSchema = yup
  .object({
    identifier: yup.string().required(),
    broker: yup.string().nullable(),
    topic: yup.string().nullable(),
    username: yup.string().nullable(),
    password: yup.string().nullable(),
    qos: yup.string().nullable(),
    keep_alive_interval: yup.string().nullable(),
    reconnect_interval: yup.string().nullable(),
    queue_dir: yup.string().nullable(),
    queue_limit: yup
      .number()
      .typeError("Please enter a valid number")
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .nullable(),
    comment: yup.string().nullable(),
  })
  .required();

export const S3RedisEventSchema = yup
  .object({
    identifier: yup.string().required(),
    address: yup.string().nullable(),
    key: yup.string().nullable(),
    password: yup.string().nullable(),
    queue_dir: yup.string().nullable(),
    queue_limit: yup
      .number()
      .typeError("Please enter a valid number")
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .nullable(),
    comment: yup.string().nullable(),
  })
  .required();

export const S3NatsEventSchema = yup
  .object({
    identifier: yup.string().required(),
    address: yup.string().nullable(),
    subject: yup.string().nullable(),
    username: yup.string().nullable(),
    password: yup.string().nullable(),
    token: yup.string().nullable(),
    ping_interval: yup.string().nullable(),
    streaming_max_pub_acks_in_flight: yup.string().nullable(),
    streaming_cluster_id: yup.string().nullable(),
    cert_authority: yup.string().nullable(),
    client_cert: yup.string().nullable(),
    client_key: yup.string().nullable(),
    queue_dir: yup.string().nullable(),
    queue_limit: yup
      .number()
      .typeError("Please enter a valid number")
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .nullable(),
    comment: yup.string().nullable(),
  })
  .required();

export const S3NsqEventSchema = yup
  .object({
    identifier: yup.string().required(),
    nsqd_address: yup.string().nullable(),
    topic: yup.string().nullable(),
    queue_dir: yup.string().nullable(),
    queue_limit: yup
      .number()
      .typeError("Please enter a valid number")
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .nullable(),
    comment: yup.string().nullable(),
  })
  .required();

export const S3PostgresEventSchema = (useManualString: boolean) =>
  yup
    .object({
      identifier: yup.string().required(),
      connection_string: useManualString
        ? yup.string().required()
        : yup.string().nullable(),
      host: !useManualString
        ? yup.string().required()
        : yup.string().nullable(),
      db_name: !useManualString
        ? yup.string().required("DB name is a required field")
        : yup.string().nullable(),
      port: !useManualString
        ? yup.string().required()
        : yup.string().nullable(),
      user: !useManualString
        ? yup.string().required()
        : yup.string().nullable(),
      password: !useManualString
        ? yup.string().required()
        : yup.string().nullable(),
      table: yup.string().required(),
      queue_dir: yup.string().nullable(),
      queue_limit: yup
        .number()
        .typeError("Please enter a valid number")
        .transform((value, originalValue) =>
          originalValue === "" ? null : value
        )
        .nullable(),
      comment: yup.string().nullable(),
    })
    .required();

export const S3MySqlEventSchema = (useDnsString: boolean) =>
  yup
    .object({
      identifier: yup.string().required(),
      dsn_string: useDnsString
        ? yup.string().required()
        : yup.string().nullable(),
      host: !useDnsString ? yup.string().required() : yup.string().nullable(),
      db_name: !useDnsString
        ? yup.string().required("DB name is a required field")
        : yup.string().nullable(),
      port: !useDnsString ? yup.string().required() : yup.string().nullable(),
      user: !useDnsString ? yup.string().required() : yup.string().nullable(),
      password: !useDnsString
        ? yup.string().required()
        : yup.string().nullable(),
      table: yup.string().required(),
      queue_dir: yup.string().nullable(),
      queue_limit: yup
        .number()
        .typeError("Please enter a valid number")
        .transform((value, originalValue) =>
          originalValue === "" ? null : value
        )
        .nullable(),
      comment: yup.string().nullable(),
    })
    .required();

export const S3ElasticSearchEventSchema = yup
  .object({
    identifier: yup.string().required(),
    url: yup.string().nullable(),
    index: yup.string().nullable(),
    format: yup.string().nullable(),
    queue_dir: yup.string().nullable(),
    queue_limit: yup
      .number()
      .typeError("Please enter a valid number")
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .nullable(),
    comment: yup.string().nullable(),
  })
  .required();

export const S3WeebhookEventSchema = yup
  .object({
    identifier: yup.string().required(),
    endpoint: yup.string().required(),
    auth_token: yup.string().required("Auth Token is a required field"),
    queue_dir: yup.string().nullable(),
    queue_limit: yup
      .number()
      .typeError("Please enter a valid number")
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .nullable(),
    comment: yup.string().nullable(),
  })
  .required();

export const S3NetworkSchema = yup
  .object({
    NetworkName: yup.string().required("Network name is a required field"),
    RpcUrl: yup.string().required("Rpc url is a required field"),
    ChainID: yup
      .number()
      .typeError("Please enter a valid number")
      .required("Chain ID is a required field"),
    CurrencySymbol: yup
      .string()
      .required("Currency symbol is a required field"),
    ExplorerURL: yup.string().nullable(),
  })
  .required();

export const S3TierMinIoSchema = yup
  .object({
    name: yup
      .string()
      .required("Name is a required field")
      .matches(
        /^[A-Za-z0-9-_]+$/,
        "Please verify that string is uppercase only and contains valid characters (numbers, dashes & underscores)."
      ),
    endpoint: yup.string().required("Endpoint is a required field"),
    bucket: yup.string().required("Bucket is a required field"),
    prefix: yup.string().required("Prefix is a required field"),
    region: yup.string().nullable(),
    accesskey: yup.string().required("Access Key is a required field"),
    secretkey: yup.string().required("Secret Key is a required field"),
  })
  .required();

export const S3TierGoogleCloudSchema = yup
  .object({
    name: yup
      .string()
      .required("Name is a required field")
      .matches(
        /^[A-Za-z0-9-_]+$/,
        "Please verify that string is uppercase only and contains valid characters (numbers, dashes & underscores)."
      ),

    endpoint: yup.string().required("Endpoint is a required field"),
    bucket: yup.string().required("Bucket is a required field"),
    prefix: yup.string().required("Prefix is a required field"),
    region: yup.string().nullable(),
    creds: yup
      .mixed()
      .test("is-file", "Invalid file format", (value) => {
        if (value instanceof FileList) {
          return value.length > 0; // Ensure at least one file is present
        }
        return false;
      })
      .required("Credentials is a required field"),
  })
  .required();

export const S3TierAmazonSchema = yup
  .object({
    name: yup
      .string()
      .required("Name is a required field")
      .matches(
        /^[A-Za-z0-9-_]+$/,

        "Please verify that string is uppercase only and contains valid characters (numbers, dashes & underscores)."
      ),

    endpoint: yup.string().required("Endpoint is a required field"),
    bucket: yup.string().required("Bucket is a required field"),
    prefix: yup.string().required("Prefix is a required field"),
    region: yup.string().nullable(),
    accesskey: yup.string().required("Access Key is a required field"),
    secretkey: yup.string().required("Secret Key is a required field"),
    storageclass: yup.string().nullable(),
  })
  .required();

export const S3TierAzureSchema = yup
  .object({
    name: yup
      .string()
      .required("Name is a required field")
      .matches(
        /^[A-Za-z0-9-_]+$/,

        "Please verify that string is uppercase only and contains valid characters (numbers, dashes & underscores)."
      ),

    endpoint: yup.string().required("Endpoint is a required field"),
    bucket: yup.string().required("Bucket is a required field"),
    prefix: yup.string().required("Prefix is a required field"),
    region: yup.string().nullable(),
    accountname: yup.string().required("Account Name is a required field"),
    accountkey: yup.string().required("Account Key is a required field"),
  })
  .required();

export const S3ReplicationSchema = (update: boolean) =>
  yup
    .object({
      accessKey: update
        ? yup.string().nullable()
        : yup.string().required("Access key is a required field"),
      secretKey: update
        ? yup.string().nullable()
        : yup.string().required("secretKey is a required field"),
      targetURL: update
        ? yup.string().nullable()
        : yup.string().required("target URL is a required field"),
      targetBucket: update
        ? yup.string().nullable()
        : yup.string().required("Target bucket is a required field"),
      bandwidth: update
        ? yup.number().nullable()
        : yup.number().required("region is a required field"),
      healthCheckPeriod: update
        ? yup.number().nullable()
        : yup.number().required("region is a required field"),
      priority: yup.number().nullable(),
      region: yup.string().nullable(),
      storageClass: yup.string().nullable(),
      prefix: yup.string().nullable(),
      tags: yup.array().of(
        yup.object({
          key: yup.string().nullable(),
          value: yup.string().nullable(),
        })
      ),
      arn: update
        ? yup.string().required("arn is a required field")
        : yup.string().nullable(),
    })
    .required();

export const S3MultipleLifecycleSchema = (selectedType: string) =>
  yup
    .object({
      expiry_days: yup.string().nullable(),
      transition_days: yup.string().nullable(),
      noncurrentversion_expiration_days: yup.string().nullable(),
      noncurrentversion_transition_days: yup.string().nullable(),
      noncurrentversion_transition_storage_class: yup.string().nullable(),
      prefix: yup.string().nullable(),
      tier: yup.string().nullable(),
      tags: yup.array().of(
        yup.object({
          key: yup.string().nullable(),
          value: yup.string().nullable(),
        })
      ),
      storage_class:
        selectedType === "transition"
          ? yup.string().required("Storage class is a required field")
          : yup.string().nullable(""),
    })
    .required();

// export const S3MultiReplicationSchema = (selectedMode: string) =>
//   yup
//     .object({
//       accessKey: yup
//         .string()
//         .min(3, "The password must be at least 3 characters long")
//         .required("accessKey in body should be at least 3 chars long"),
//       secretKey: yup
//         .string()
//         .min(8, "The password must be at least 8 characters long")
//         .required("secretKey in body should be at least 8 chars long"),
//       targetURL: yup
//         .string()
//         .matches(
//           /^(?!(https:\/\/|http:\/\/)).*$/,
//           "Endpoint url cannot have fully qualified paths."
//         )
//         .required(
//           "Endpoint: does not follow ip address or domain name standards"
//         ),
//       bandwidth: yup.number().nullable(),
//       healthCheckPeriod: yup.number().nullable(),
//       region: yup.string().nullable(),
//       syncMode: yup.string().required(),
//       bandwidthOption:
//         selectedMode !== "sync"
//           ? yup.string().required()
//           : yup.string().nullable(),
//     })
//     .required();
export const S3MultiReplicationSchema = () =>
  yup
    .object({
      accessKey: yup
        .string()
        .min(3, "The password must be at least 3 characters long")
        .required("accessKey in body should be at least 3 chars long"),
      secretKey: yup
        .string()
        .min(8, "The password must be at least 8 characters long")
        .required("secretKey in body should be at least 8 chars long"),
      targetURL: yup
        .string()
        .matches(
          /^(?!(https:\/\/|http:\/\/)).*$/,
          "Endpoint url cannot have fully qualified paths."
        )
        .required(
          "Endpoint: does not follow ip address or domain name standards"
        ),
      bandwidth: yup.number().nullable(),
      healthCheckPeriod: yup.number().nullable(),
      region: yup.string().nullable(),
      syncMode: yup.string().required(),
      bandwidthOption: yup.string().required().default(""),
    })
    .required();

//web3-endpoints
export const Web3EndpointSchema = (existingPartitions?: string[]) =>
  yup
    .object({
      Name: yup
        .string()
        .required()
        .min(3, "Name must be at least 3 characters long")
        .max(60, "Name must be at most 60 characters long")
        .test("isUniqueWithinPartition", "Name must be unique", (value) => {
          return (
            !existingPartitions ||
            !existingPartitions.some((partition) => partition === value)
          );
        }),
    })
    .required();

export const Web3EndpointUpdateSchema = yup
  .object({
    Name: yup
      .string()
      .required()
      .min(3, "Name must be at least 3 characters long")
      .max(60, "Name must be at most 60 characters long"),
    EnabledAvatar: yup.boolean().required(),
    TokenLifeTime: yup.number().required(),
    // JWTEncryptionKey: yup.string().required(),
  })
  .required();

export const Web3EndpointUserDataSchema = (existingPartitions?: string[]) =>
  yup
    .object({
      FieldType: yup.string().required(),
      FieldName: yup
        .string()
        .required()
        .test(
          "isUniqueWithinPartition",
          "Field name must be unique",
          (value, obj) => {
            // if (obj.parent.IsUnique) {
            return (
              !existingPartitions ||
              !existingPartitions.some((partition) => partition === value)
            );
            // } else {
            //   return true;
            // }
          }
        ),
      IsRequired: yup.boolean().nullable(),
      IsUnique: yup.boolean().nullable(),
    })
    .required();

//block-events
export const BlockEventDestinationSchema = yup
  .object({
    name: yup.string().required("Name is a required field"),
    to_url: yup.string().required("URL is a required field"),
  })
  .required();

export const BlockEventDetailSchema = yup
  .object({
    name: yup.string().required("Name is a required field"),
  })
  .required();

//-------------------- web-apps ------------------
//
// {
//   Name: yup
//     .string()
//     .matches(
//       /^(?!\d)[A-Za-z0-9_.-]+$/,
//       "Name must not start with a digit and can only contain letters, numbers, underscores, periods, or dashes."
//     )
//     .required("Name is required"),
//   Value: yup.string().required("Value is required"),
// }
export const WebAppCreateSchema = (hasDisk: boolean = false) =>
  yup
    .object({
      AppName: yup
        .string()
        .min(3, "Name must be at least 3 characters long")
        .max(55, "Name must be at most 55 characters long")
        .matches(/^[^0-9]/, "App name cannot start with a number")
        .matches(/[a-z]/, "App name must contain at least one letter")
        .matches(
          /^[a-z0-9-]+$/,
          "App name can only contain lowercase letters, numbers, or hyphens"
        )
        .matches(
          /^(?!-)(?!.*-$).*/,
          "App name cannot start or end with a hyphen (-)"
        )
        .required("App name is a required field"),
      Port: yup
        .string()
        .nullable()
        .matches(
          /^(6553[0-5]|655[0-2]\d|65[0-4]\d{2}|6[0-4]\d{3}|[1-5]?\d{1,4}|0)$/,
          "Port must be a number between 0 and 65536"
        ),
      // Origin: yup.string().required(),
      PlanId: yup.number().required(),
      EvironmentVariables: yup
        .array()
        .of(
          yup.object({
            Name: yup
              .string()
              .matches(
                /^(?!\d)[A-Za-z0-9_.-]+$/,
                "Name must not start with a digit and can only contain letters, numbers, underscores, periods, or dashes."
              )
              .required("Name is required"),
            Value: yup.string().nullable(), //.required("Value is required")
          })
        )
        // .test(
        //   "has-more-than-one",
        //   "Both Name and Value are required if there are more than one environment variables.",
        //   function (value) {
        //     if (this.parent.EvironmentVariables.length > 1) {
        //       return !!value && value.every((env) => !!env.Name && !!env.Value);
        //     }
        //     return true;
        //   }
        // )
        .test(
          "no-duplicate-keys",
          "Duplicate names are not allowed",
          function (value) {
            if (!value || value.length <= 1) {
              return true;
            }
            const keys = new Set();
            for (const env of value) {
              if (keys.has(env.Name)) {
                return false;
              }
              keys.add(env.Name);
            }
            return true;
          }
        ),
      // SecretFiles: yup.array().of(
      //   yup.object({
      //     Filename: yup.string(),
      //     FileContents: yup.string(),
      //   })
      // ),
      // DiskName: hasDisk
      //   ? yup.string().required("Required")
      //   : yup.string().nullable(),
      // DiskMountPath: hasDisk
      //   ? yup.string().required("Required")
      //   : yup.string().nullable(),
      // DiskSize: hasDisk
      //   ? yup.number().required("Required")
      //   : yup.string().nullable(),
      // HealthCheckPath: yup.string().nullable(),
      // DockerCommand: yup.string().nullable(),
      // PreDeployCommand: yup.string().nullable(),
    })
    .required();

export const WebAppDiskSchema = yup
  .object({
    DiskName: yup.string().required("Required"),
    DiskMountPath: yup.string().required("Required"),
    DiskSize: yup.number().required("Required"),
  })
  .required();

export const WebAppSecretFileSchema = yup
  .object({
    Filename: yup.string().required("Required"),
    FileContents: yup.string().required("Required"),
  })
  .required();

export const WebAppEnvironmentsSchema = () =>
  yup
    .object({
      EvironmentVariables: yup
        .array()
        .of(
          yup.object({
            Name: yup
              .string()
              .matches(
                /^(?!\d)[A-Za-z0-9_.-]+$/,
                "Name must not start with a digit and can only contain letters, numbers, underscores, periods, or dashes."
              )
              .required("Name is required"),
            Value: yup.string().nullable(), //.required("Value is required")
          })
        )
        // .test(
        //   "has-more-than-one",
        //   "Both Name and Value are required if there are more than one environment variables.",
        //   function (value) {
        //     if (this.parent.EvironmentVariables.length > 1) {
        //       return !!value && value.every((env) => !!env.Name && !!env.Value);
        //     }
        //     return true;
        //   }
        // )
        .test(
          "no-duplicate-keys",
          "Duplicate names are not allowed",
          function (value) {
            if (!value || value.length <= 1) {
              return true;
            }
            const keys = new Set();
            for (const env of value) {
              if (keys.has(env.Name)) {
                return false;
              }
              keys.add(env.Name);
            }
            return true;
          }
        ),
    })
    .required();

export const WebAppSecretFilesSchema = () =>
  yup
    .object({
      SecretFiles: yup
        .array()
        .of(
          yup.object({
            Filename: yup.string(),
            FileContents: yup.string(),
          })
        )
        .test(
          "has-more-than-one",
          "Both name and content are required if there are more than one secret file.",
          function (value) {
            if (this.parent.SecretFiles.length > 1) {
              return (
                !!value &&
                value.every((env) => !!env.Filename && !!env.FileContents)
              );
            }
            return true;
          }
        )
        .test(
          "no-duplicate-keys",
          "Duplicate name are not allowed",
          function (value) {
            if (!value || value.length <= 1) {
              return true;
            }
            const keys = new Set();
            for (const SecretFile of value) {
              if (keys.has(SecretFile.Filename)) {
                return false;
              }
              keys.add(SecretFile.Filename);
            }
            return true;
          }
        ),
    })
    .required();

export const WebAppSaringSchema = yup
  .object({
    Email: yup.string().email().required(),
  })
  .required();

export const WebAppNameSchema = yup
  .object({
    Name: yup
      .string()
      .min(3, "Name must be at least 3 characters long")
      .max(55, "Name must be at most 55 characters long")
      .matches(/^[^0-9]/, "App name cannot start with a number")
      .matches(/[a-z]/, "App name must contain at least one letter")
      .matches(
        /^[a-z0-9-]+$/,
        "App name can only contain lowercase letters, numbers, or hyphens"
      )
      .matches(
        /^(?!-)(?!.*-$).*/,
        "App name cannot start or end with a hyphen (-)"
      )
      .required("App name is a required field"),
  })
  .required();

export const WebAppPortSchema = yup
  .object({
    Port: yup
      .string()
      .matches(
        /^(6553[0-5]|655[0-2]\d|65[0-4]\d{2}|6[0-4]\d{3}|[1-5]?\d{1,4}|0)$/,
        "Port must be a number between 0 and 65536"
      ),
  })
  .required();

export const WebAppDockerCommandSchema = yup
  .object({
    DockerCommand: yup.string().nullable(),
  })
  .required();

export const WebAppPreDeployCommandSchema = yup
  .object({
    PreDeployCommand: yup.string().nullable(),
  })
  .required();

export const WebAppNameHealthChecksSchema = yup
  .object({
    HealthCheckPath: yup.string().required(),
  })
  .required();

export const WebAppInstanceTypeSchema = yup
  .object({
    PlanId: yup.number().required(),
  })
  .required();

// --------------------- market-api --------------------
export const MarketApiApiKeySchema = yup
  .object({
    label: yup.string().required(),
  })
  .required();

// --------------------- wallet-api --------------------
//
export const WalletApiApiKeySchema = yup
  .object({
    label: yup.string().required(),
  })
  .required();

// --------------------- block-api --------------------
//
export const BlockApiApiKeySchema = yup
  .object({
    label: yup.string().required(),
  })
  .required();

// --------------------- nft-api --------------------
//
export const NftApiApiKeySchema = yup
  .object({
    label: yup.string().required(),
  })
  .required();

// ------------------------- databases --------------------
export const DatabaseServiceNameSchema = yup
  .object({
    description: yup
      .string()
      .min(3, "Minimum 3 character")
      .max(64, "Maximum 40 characters")
      .matches(
        /^[a-z0-9_.-]+$/i,
        "May contain numbers, letters, underscores, dashes and full stops only"
      )
      .required(),
  })
  .required();

export const DatabaseServiceCreateSchema = () =>
  yup
    .object({
      SelectedEngine: yup.string().required(),
      SelectedEngineVersion: yup.string().required(),
      SelectedPlan: yup.string().required(),
      SelectedRegion: yup.string().required(),
      SelectedNodeType: yup.string().required(),

      NumberOfNodes: yup
        .number()
        .typeError("Please enter a valid number")
        .required(),
      AdditionalStorage: yup.number().nullable(),
      SelectedBackup: yup.string().nullable(),
    })
    .required();

export const DatabaseServiceUpdateNodeSchema = () =>
  yup
    .object({
      SelectedNodeType: yup.string().required(),
    })
    .required();

export const DatabaseServiceUpdateNumberOfNodesSchema = () =>
  yup
    .object({
      NumberOfNodes: yup
        .number()
        .typeError("Please enter a valid number")
        .required(),
    })
    .required();

export const DatabaseServiceUpdatePlanSchema = () =>
  yup
    .object({
      SelectedPlan: yup.string().required(),
      // SelectedNodeType: yup.string().required(),
    })
    .required();

export const DatabaseServiceUpdateStorageSchema = () =>
  yup
    .object({
      AdditionalStorage: yup.number().nullable(),
    })
    .required();

export const WorkflowApiKeySchema = yup
  .object({
    keyName: yup.string().required(),
  })
  .required();

export const DBSIPsSchema = yup
  .object({
    ip: yup
      .string()
      .required("Required")
      .test(
        "is-ipv4-or-cidr",
        "Must be an IPv4 (e.g. 192.168.0.1) or an IP address block (e.g. 192.168.0.1/24)",
        (value) => {
          const ipv4Regex =
            /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
          const cidrRegex =
            /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/([0-9]|[1-2][0-9]|3[0-2]))$/;
          return ipv4Regex.test(value) || cidrRegex.test(value);
        }
      ),
    description: yup.string().nullable(),
  })
  .required();

export const DBSUserSchema = (
  // existingNames: string[],
  existingPartitions: Inputs
) =>
  yup
    .object({
      name: yup
        .string()
        .required("Username is required")
        .min(3, "Username must be at least 3 characters")
        .max(32, "Username must be at most 32 characters"),
      // .test(
      //   "isUniqueWithinPartition",
      //   "Name must be unique within a partition",
      //   (value) =>
      //     !existingNames ||
      //     !existingNames.some((partition) => partition === value)
      // ),
      group: yup
        .string()
        .nullable()
        .max(16, "Group must be at most 16 characters")
        .matches(
          /^[A-Za-z0-9_]*$/,
          "Group must include alphanumeric characters, only upper-case letters, and underscores"
        ),
      key: yup
        .string()
        .nullable()
        .test(
          "unique-key",
          "Key already exists.",
          (value) =>
            !existingPartitions?.keys ||
            !existingPartitions.keys.includes(value || "")
        ),
      category: yup
        .string()
        .nullable()
        .matches(
          /^(?:\+@|\-@)[A-Za-z0-9_]+$/,
          'Category must be prefixed with "+@" or "-@" and contain alphanumeric characters'
        )
        .test(
          "unique-category",
          "Category already exists.",
          (value) =>
            !existingPartitions?.categories ||
            !existingPartitions.categories.includes(value || "")
        ),
      command: yup
        .string()
        .nullable()
        .matches(
          /^[+-][A-Za-z0-9_]+$/,
          'Command must be prefixed with "+" or "-" and contain alphanumeric characters'
        )
        .test(
          "unique-command",
          "Command already exists.",
          (value) =>
            !existingPartitions?.commands ||
            !existingPartitions.commands.includes(value || "")
        ),
      channel: yup
        .string()
        .nullable()
        .test(
          "unique-channel",
          "Channel already exists.",
          (value) =>
            !existingPartitions?.channels ||
            !existingPartitions.channels.includes(value || "")
        ),
    })
    .required();

export const DBSDatabaseSchema = yup
  .object({
    name: yup
      .string()
      .required("Database name is required")
      .min(1, "Minimum 1 character")
      .max(40, "Maximum 40 characters")
      .matches(/^[a-z0-9]+$/i, "Must only contain letters and numbers.")
      .required(),
  })
  .required();

export const DBSIntegrationSchema = () =>
  yup
    .object({
      type: yup.string().required("Type name is required"),

      sourceServiceId: yup.string().required("Source is required"),
      destinationServiceId: yup.string().required("Traget is required"),

      topic: yup.string().test("topic", "Topic is required", function (value) {
        if (this.parent.type === "kafkaLogs") {
          return value !== undefined && value.trim().length > 0;
        }
        return true;
      }),

      indexPrefix: yup
        .string()
        .test("indexPrefix", "Index prefix is required", function (value) {
          if (this.parent.type === "opensearchLogs") {
            return value !== undefined && value.trim().length > 0;
          }
          return true;
        }),

      indexDaysMax: yup
        .number()
        .test("indexDaysMax", "Days is required", function (value) {
          if (this.parent.type === "opensearchLogs") {
            return value !== undefined && value > 0;
          }
          return true;
        }),
    })
    .required();

export const DBSPoolSchema = yup
  .object({
    name: yup.string().required("Pool name is required"),
    size: yup.number().required("Size name is required"),
    databaseId: yup.string().required("Database is required"),
    mode: yup.string().required("Mode is required"),
    userId: yup.string().nullable(),
  })
  .required();

export const NamespacesSchema = yup
  .object({
    name: yup.string().required("Please fill in this field."),
    resolution: yup
      .string()
      .required("Please fill in this field.")
      .matches(
        /^[0-9]+[smhd]$/,
        "Invalid time format. Examples: 20s, 10m, 24h, 1d."
      ),
    periodDuration: yup
      .string()
      .required("Please fill in this field.")
      .matches(
        /^[0-9]+[smhd]$/,
        "Invalid time format. Examples: 20s, 10m, 24h, 1d."
      ),
    blockSizeDuration: yup
      .string()
      .nullable()
      .matches(/^[0-9]+[smhd]$/, {
        excludeEmptyString: true,
        message: "Invalid time format. Examples: 20s, 10m, 24h, 1d.",
      }),
    bufferFutureDuration: yup
      .string()
      .nullable()
      .matches(/^[0-9]+[smhd]$/, {
        excludeEmptyString: true,
        message: "Invalid time format. Examples: 20s, 10m, 24h, 1d.",
      }),
    bufferPastDuration: yup
      .string()
      .nullable()
      .matches(/^[0-9]+[smhd]$/, {
        excludeEmptyString: true,
        message: "Invalid time format. Examples: 20s, 10m, 24h, 1d.",
      }),
    blockDataExpirationDuration: yup
      .string()
      .nullable()
      .matches(/^[0-9]+[smhd]$/, {
        excludeEmptyString: true,
        message: "Invalid time format. Examples: 20s, 10m, 24h, 1d.",
      }),
  })
  .required();

//------------------------ instances ------------------------
export const InstanceCreateSchema = yup
  .object({
    selectedFlavor: yup.string().required("Plan is required!"),
    selectedRegion: yup.string().required("Region is required!"),
    selectedImageGroupId: yup.string().required("Image is required!"),
    selectedImageId: yup.string().required("Version is required!"),
    selectedSshKeyId: yup.string().nullable(),
    numberOfInstances: yup
      .number()
      .min(1)
      .required("Number of instances is required!"),
    isFlexible: yup.boolean().required(),
    instanceName: yup
      .string()
      .matches(
        /^[A-Za-z0-9-_]+$/,
        "Please verify that string is uppercase only and contains valid characters (numbers, dashes & underscores)."
      )
      .min(2, "Name must be at least 2 characters")
      .required("Name is required!"),
    postInstallation: yup.string().nullable(),
    autobackup: yup
      .object({
        cron: yup.string().required(),
        rotation: yup.number().required(),
      })
      .nullable(),
    billingPeriod: yup.string().required("Billing period is required!"),
  })
  .required();

export const InstanceSshCreateSchema = () =>
  yup
    .object({
      name: yup.string().required("Name is required!"),
      publicKey: yup.string().required("Key is required!"),
    })
    .required();

export const InstanceNameSchema = yup
  .object({
    instanceName: yup.string().required("Name is required!"),
  })
  .required();

export const InstanceModelSchema = yup
  .object({
    selectedFlavor: yup.string().required("Plan is required!"),
  })
  .required();

export const InstanceImageSchema = yup
  .object({
    selectedImageGroupId: yup
      .string()
      .required("Selected image group ID is required"),
    selectedImageId: yup.string().required("Selected image ID is required"),
  })
  .required();

export const InstanceBillingSchema = yup
  .object({
    // isFlexible: yup.boolean().required(),
    billingPeriod: yup.string().required("Billing period is required!"),
    // numberOfInstances: yup
    //   .number()
    //   .min(1)
    //   .required("Number of instances is required!"),
    // selectedSshKeyId: yup.string().nullable(),
    // selectedFlavor: yup.string().required("Plan is required!"),
  })
  .required();

export const InstanceAutomaticBackupSchema = () =>
  yup
    .object({
      autobackup: yup
        .object({
          cron: yup.string().required(),
          rotation: yup.number().required(),
          maxExecutionCount: yup.number().nullable(),
        })
        .nullable(),
      scheduleName: yup.string().required("Schedule name is required!"),
    })
    .required();

export const InstanceTypeDeleteSchema = yup
  .object({
    typeDelete: yup.string().required().oneOf(["DELETE"], "Type 'DELETE'!"),
  })
  .required();

export const InstanceVolumeCreateSchema = yup
  .object({
    selectedRegion: yup.string().required("Region is required!"),
    selectedType: yup.string().required("Type is required!"),
    capacity: yup.number().min(10).required(),
    volumeName: yup
      .string()
      .matches(
        /^[A-Za-z0-9-_]+$/,
        "Please verify that string is uppercase only and contains valid characters (numbers, dashes & underscores)."
      )
      .min(2, "Name must be at least 2 characters")
      .required("Name is required!"),
  })
  .required();

export const InstanceVolumeEditSchema = yup
  .object({
    capacity: yup.number().min(10).required(),
    volumeName: yup
      .string()
      .matches(
        /^[A-Za-z0-9-_]+$/,
        "Please verify that string is uppercase only and contains valid characters (numbers, dashes & underscores)."
      )
      .min(2, "Name must be at least 2 characters")
      .required("Name is required!"),
  })
  .required();

export const InstanceVolumeBackupSchema = yup
  .object({
    selectedType: yup.string().required("Type is required!"),
    name: yup
      .string()
      .matches(
        /^[A-Za-z0-9-_]+$/,
        "Please verify that string is uppercase only and contains valid characters (numbers, dashes & underscores)."
      )
      .min(2, "Name must be at least 2 characters")
      .required("Name is required!"),
  })
  .required();

export const InstanceAttachSchema = yup
  .object({
    instanceId: yup.string().required("Instance is required"),
  })
  .required();

export const InstanceAttachVolumeSchema = yup
  .object({
    instanceId: yup.string().required("Volume is required"),
  })
  .required();

export const DBSUserDetailsSchema = yup
  .object({
    username: yup.string().required(),
  })
  .required();

//--------------------- settings --------------------
export const BillingInformationSchema = () =>
  yup
    .object({
      CountryId: yup.string().required("Country is a required field"),
      State: yup.string().required(),
      City: yup.string().required(),
      ZipCode: yup.string().required(),
      AddressLine1: yup.string().required("Address is a required field"),
      AddressLine2: yup.string().nullable(),
      VatNumber: yup.string().nullable(),
    })
    .required();

export const EnvVariableSchema = yup
  .object({
    Key: yup.string().required(),
    Value: yup.string().required(),
  })
  .required();

export const EnvAddFromFileSchema = yup
  .object({
    Value: yup.string().required(),
  })
  .required();

// export const TopupSchema = yup
//   .object({
//     Value: yup
//       .number()
//       .min(1)
//       .required()
//       .test(
//         "max-decimals",
//         "More than two decimal digits are not allowed.",
//         (value) => {
//           if (value && !isNaN(value)) {
//             return value === Number(value.toFixed(2));
//           }
//           return true;
//         }
//       ),
//   })
//   .required();
export const TopupSchema = yup
  .object({
    Value: yup
      .number()
      .typeError("Value must be a number between 1 and 5,000,000.")
      .min(1, "Value must be at least 1.")
      .max(5000000, "Value must be at most 5,000,000.")
      .required("Value is required.")
      .test(
        "max-decimals",
        "Value can have at most two decimal digits.",
        (value, context) => {
          if (typeof value !== "number" || isNaN(value)) {
            return context.createError({
              message: "Value must be a valid number.",
            });
          }
          const preciseValue = context.originalValue?.toString();
          if (!preciseValue) return false;
          const [_, decimalPart] = preciseValue.split(".");
          return !decimalPart || decimalPart.length <= 2;
        }
      ),
  })
  .required();

export const TopupWithPromoSchema = yup
  .object({
    PromoCode: yup.string().required("Code is required."),
  })
  .required();

export const WorkflowNameSchema = yup
  .object({
    name: yup
      .string()
      .required("Name is required.")
      .min(3, "Name must be at least 3 characters long.")
      .matches(/^[^\s]+$/, "Name cannot contain spaces."),
  })
  .required();

export const ContactUsSchema = yup
  .object({
    fullname: yup.string().required("Fullname is required!"),
    email: yup.string().required("Email is required!"),
    message: yup.string().required("Message is required!"),
  })
  .required();

export const WorkflowExportTempalteSchema = yup
  .object({
    name: yup.string().required("Name is required!"),
    description: yup.string().required("Description is required!"),
    // content: yup.string().required("Content is required!"),
  })
  .required();

export const WebAppTemplateschema = yup.object().shape({
  AppName: yup
    .string()
    .min(3, "Name must be at least 3 characters long")
    .max(55, "Name must be at most 55 characters long")
    .matches(/^[^0-9]/, "App name cannot start with a number")
    .matches(/[a-z]/, "App name must contain at least one letter")
    .matches(
      /^[a-z0-9-]+$/,
      "App name can only contain lowercase letters, numbers, or hyphens"
    )
    .matches(/^(?!.*-$)/, "App name cannot end with a hyphen (-)")
    .required("App name is a required field"),

  PlanId: yup.number().required(),
});

export const BlockchainInstanceTypeSchema = yup
  .object({
    PlanId: yup.number().required(),
  })
  .required();

export const BlockchainCreateSchema = () =>
  yup
    .object({
      ChainId: yup
        .number()
        .typeError("ChainId must be a number")
        .min(1)
        .required(),
      ConsensusType: yup.string().required(),
      PlanId: yup.number().required(),
      WalletRequests: yup
        .array()
        .of(
          yup.object({
            Allocation: yup
              .number()
              .typeError("Allocation must be a number")
              .required(),
            WalletAddress: yup
              .string()
              .required("Address is required")
              .test(
                "is-ethereum-address",
                "Wallet address must be a valid Ethereum address",
                (value) => isAddress(value)
              ),
          })
        )
        .test(
          "no-duplicate-keys",
          "Duplicate wallet are not allowed",
          function (value) {
            if (!value || value.length <= 1) {
              return true;
            }
            const keys = new Set();
            for (const env of value) {
              if (keys.has(env.WalletAddress)) {
                return false;
              }
              keys.add(env.WalletAddress);
            }
            return true;
          }
        )
        .test(
          "at-least-one-allocation",
          "At least one wallet must have an allocation greater than 0",
          function (value) {
            if (!value || value.length === 0) {
              return true;
            }
            return value.some((wallet) => wallet.Allocation > 0);
          }
        ),
    })
    .required();

export const DappStudioRemoteDataSchema = yup
  .object({
    username: yup.string().required(),
    token: yup.string().required(),
    repoUrl: yup.string().required(),
  })
  .required();
