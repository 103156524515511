import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IThunkRejectValue, RootState } from "../../types";
import toast from "react-hot-toast";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import { DomainsStateType, Domain, DomainProduct } from "./../../types/domains";
import { getDomainsApi, searchDomainsApi } from "../../apis/domainsAPI";
import { getLocalStorage, setLocalStorage } from "djuno-design";

const dummyDomains: Domain[] = [
  {
    Name: "test.x",
    Id: 1,
    ForSale: false,
    Expiry: "",
    Purchase: "",
    Wallet: "qweisdfjsdfsdfjsdfj",
    Tags: ["test", "domain"],
  },
];

const dummySearchItems: DomainProduct[] = [
  {
    productId: 332331179,
    productType: "DomainProduct",
    productCode: "ansar.pw",
    domain: {
      name: "ansar.pw",
      label: "ansar",
      sld: null,
      extension: "pw",
    },
    status: "available",
    availability: true,
    quantity: 31536000,
    price: 1500,
    tags: [],
    forSalePrice: null,
    profaneDomain: false,
  },
  {
    productId: null,
    productType: "DomainProduct",
    productCode: "ansar.com",
    domain: {
      name: "ansar.com",
      label: "ansar",
      sld: null,
      extension: "com",
    },
    status: "registered",
    availability: false,
    quantity: 31536000,
    price: -1,
    tags: [],
    forSalePrice: null,
    profaneDomain: false,
  },
  {
    productId: null,
    productType: "DomainProduct",
    productCode: "ansar.net",
    domain: {
      name: "ansar.net",
      label: "ansar",
      sld: null,
      extension: "net",
    },
    status: "registered",
    availability: false,
    quantity: 31536000,
    price: -1,
    tags: [],
    forSalePrice: null,
    profaneDomain: false,
  },
  {
    productId: null,
    productType: "DomainProduct",
    productCode: "ansar.org",
    domain: {
      name: "ansar.org",
      label: "ansar",
      sld: null,
      extension: "org",
    },
    status: "registered",
    availability: false,
    quantity: 31536000,
    price: -1,
    tags: [],
    forSalePrice: null,
    profaneDomain: false,
  },
  {
    productId: null,
    productType: "DomainProduct",
    productCode: "ansar.io",
    domain: {
      name: "ansar.io",
      label: "ansar",
      sld: null,
      extension: "io",
    },
    status: "registered",
    availability: false,
    quantity: 31536000,
    price: -1,
    tags: [],
    forSalePrice: null,
    profaneDomain: false,
  },
  {
    productId: 318731984,
    productType: "DomainProduct",
    productCode: "ansar.x",
    domain: {
      name: "ansar.x",
      label: "ansar",
      sld: null,
      extension: "x",
    },
    status: "available",
    availability: true,
    price: 30000,
    tags: [],
    forSalePrice: null,
    profaneDomain: false,
  },
  {
    productId: 942130,
    productType: "DomainProduct",
    productCode: "ansar.crypto",
    domain: {
      name: "ansar.crypto",
      label: "ansar",
      sld: null,
      extension: "crypto",
    },
    status: "available",
    availability: true,
    price: 30000,
    tags: [],
    forSalePrice: null,
    profaneDomain: false,
  },
  {
    productId: 318731986,
    productType: "DomainProduct",
    productCode: "ansar.nft",
    domain: {
      name: "ansar.nft",
      label: "ansar",
      sld: null,
      extension: "nft",
    },
    status: "available",
    availability: true,
    price: 15000,
    tags: [],
    forSalePrice: null,
    profaneDomain: false,
  },
  {
    productId: 318731982,
    productType: "DomainProduct",
    productCode: "ansar.wallet",
    domain: {
      name: "ansar.wallet",
      label: "ansar",
      sld: null,
      extension: "wallet",
    },
    status: "available",
    availability: true,
    price: 15000,
    tags: [],
    forSalePrice: null,
    profaneDomain: false,
  },
  {
    productId: null,
    productType: "DomainProduct",
    productCode: "ansar.unstoppable",
    domain: {
      name: "ansar.unstoppable",
      label: "ansar",
      sld: null,
      extension: "unstoppable",
    },
    status: "available",
    availability: true,
    price: 1500,
    tags: [],
    forSalePrice: null,
    profaneDomain: false,
  },
  {
    productId: 330111956,
    productType: "DomainProduct",
    productCode: "ansar.eth",
    domain: {
      name: "ansar.eth",
      label: "ansar",
      sld: null,
      extension: "eth",
    },
    status: "registered",
    availability: false,
    quantity: 31536000,
    price: -1,
    tags: [],
    forSalePrice: null,
    profaneDomain: false,
  },
];

const initialState: DomainsStateType = {
  loading: false,
  domains: [...dummyDomains],
  actionLoading: false,

  searchDomains: [],
  searchLoading: false,

  basket: getLocalStorage("domains-basket", []),
};

export const getDomainsAysnc = createAsyncThunk<
  {
    domains: Array<Domain>;
  },
  undefined,
  IThunkRejectValue
>("domains", async (_, { fulfillWithValue, rejectWithValue }) => {
  try {
    const response = await getDomainsApi();
    const domains = response.data.Result;
    return fulfillWithValue({ domains });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const searchDomainsAysnc = createAsyncThunk<
  {
    domains: Array<DomainProduct>;
  },
  { query: string },
  IThunkRejectValue
>(
  "domains/search",
  async ({ query }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await searchDomainsApi(query);
      const domains = response.data.Result;
      return fulfillWithValue({ domains });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

const domainsSlice = createSlice({
  name: "domains",
  initialState: initialState,
  reducers: {
    handleAddDomainInBasket: (state, action: PayloadAction<DomainProduct>) => {
      const newBasket = [...state.basket, action.payload];
      state.basket = newBasket;
      setLocalStorage("domains-basket", newBasket);
    },
    handleRemoveDomaFromInBasket: (
      state,
      action: PayloadAction<{ productCode: string }>
    ) => {
      const newBasket = [
        ...state.basket.filter(
          (d) => d.productCode !== action.payload.productCode
        ),
      ];
      state.basket = newBasket;
      setLocalStorage("domains-basket", newBasket);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDomainsAysnc.fulfilled, (state, action) => {
        state.domains = action.payload.domains;
        state.loading = false;
      })
      .addCase(getDomainsAysnc.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDomainsAysnc.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(searchDomainsAysnc.fulfilled, (state, action) => {
        state.searchDomains = action.payload.domains;
        state.searchLoading = false;
      })
      .addCase(searchDomainsAysnc.pending, (state) => {
        state.searchLoading = true;
      })
      .addCase(searchDomainsAysnc.rejected, (state, { payload }) => {
        state.searchDomains = [...dummySearchItems, ...dummySearchItems];
        state.searchLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectDomains = (state: RootState) => state.domains.domains;
export const selectDomainsLoading = (state: RootState) => state.domains.loading;

export const selectSearchedDomains = (state: RootState) =>
  state.domains.searchDomains;
export const selectDomainsSearchLoading = (state: RootState) =>
  state.domains.searchLoading;

export const selectDomainsBasket = (state: RootState) => state.domains.basket;

export const { handleAddDomainInBasket, handleRemoveDomaFromInBasket } =
  domainsSlice.actions;

export default domainsSlice.reducer;
