import { useAppSelector } from "../../../hooks";
import { S3NotActiveTab } from "../../layouts/NotData";

import { selectS3Status } from "../../../store/s3/s3PublicSlice";
import { PlansTab } from "../../plans/UniversalPlansBox";

const S3PlansTab = () => {
  const s3ServiceType =
    Number(process.env.REACT_APP_SIMPLE_STORAGE_SERVICE_TYPE) || 13;
  const s3Status = useAppSelector(selectS3Status);

  if (!s3Status) {
    return <S3NotActiveTab />;
  }
  return (
    <>
      <PlansTab serviceType={s3ServiceType} />
    </>
  );
};

export default S3PlansTab;
