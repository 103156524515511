import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { IThunkRejectValue, RootState } from "../../types";
import { getExtractErrorCode, getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import { Domain, DomainStateType } from "../../types/domains";
import { getDomainApi } from "../../apis/domainsAPI";

const initialState: DomainStateType = {
  domain: null,
  loading: false,
  actionLoading: false,
};

export const getDomainAsync = createAsyncThunk<
  { domain: Domain },
  { id: number; withoutLoading?: boolean },
  IThunkRejectValue
>(
  "domain",
  async (
    { id, withoutLoading = false },
    { rejectWithValue, fulfillWithValue, dispatch, requestId }
  ) => {
    try {
      dispatch(
        getDomainAsync.pending(requestId, {
          id,
          withoutLoading,
        })
      );

      const response = await getDomainApi(id);
      const domain = response.data.Result;

      return fulfillWithValue({ domain });
    } catch (e) {
      return rejectWithValue({
        message: getExtractErrors(e),
        code: getExtractErrorCode(e),
      });
    }
  }
);

export const domainSlice = createSlice({
  name: "domain",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDomainAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.loading = true;
      })
      .addCase(getDomainAsync.fulfilled, (state, action) => {
        const { domain } = action.payload;
        state.domain = domain;
        state.loading = false;
      })
      .addCase(getDomainAsync.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload?.code !== 404)
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectDomain = (state: RootState) => state.domain.domain;
export const selectDomainLoading = (state: RootState) => state.domain.loading;

export const selectDomainActionLoading = (state: RootState) =>
  state.domain.actionLoading;

// export const {  } = domainSlice.actions;
export default domainSlice.reducer;
