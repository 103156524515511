import { ReactComponent as CheckIcon } from "./../../assets/icons/check.svg";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getPlansAsync,
  getSubscriptionsAsync,
  handleClearPlans,
  selectPlans,
  selectPlansLoading,
  selectSubscriptionActionLoading,
  selectSubscriptions,
  selectSubscriptionsLoading,
  updateSubscriptionsAsync,
} from "../../store/billing/billingSlice";
import { useEffect, useMemo, useState } from "react";
import { IPlan } from "../../types/billing";
import { selectOnStageEnv } from "../../store/auth/authSlice";
import { Loading, Button, Typography, Tag, cn, Flex } from "djuno-design";
import QuestionModal from "../modals/QuestionModal";
import useLimitations from "../../hooks/useLimitations";

export const UniversalPlansBox: React.FC<{
  serviceType?: number;
  planGroupId?: number;
  className?: string;
  onBuySuccessfull?: () => void;
}> = ({ serviceType, planGroupId, className, onBuySuccessfull }) => {
  const env = useAppSelector(selectOnStageEnv);

  const subscriptions = useAppSelector(selectSubscriptions);
  const subscriptionsLoading = useAppSelector(selectSubscriptionsLoading);
  const subscriptionActionLoading = useAppSelector(
    selectSubscriptionActionLoading
  );

  const [selectedPlanId, setSelectedPlanId] = useState<null | number>(null);

  const plans = useAppSelector(selectPlans);
  const plansLoading = useAppSelector(selectPlansLoading);

  const dispatch = useAppDispatch();

  const {
    refreshLimits,
    loading: limitCalcLoading,
    planPriceLimit,
    planPriceLimitChecker,
  } = useLimitations({ cases: ["PlanPrice"] });

  const hasCategoryId = useMemo(() => {
    return !!(serviceType || planGroupId);
  }, [planGroupId, serviceType]);

  const subscriptionsPlanIds = useMemo(() => {
    return subscriptions.map((s) => s.PlanId);
  }, [subscriptions]);

  useEffect(() => {
    dispatch(getSubscriptionsAsync({ includePlans: true }));
  }, [dispatch, subscriptions.length]);

  //get plans
  useEffect(() => {
    if (hasCategoryId) dispatch(getPlansAsync({ serviceType, planGroupId }));
    return () => {
      dispatch(handleClearPlans());
    };
  }, [dispatch, serviceType, planGroupId, hasCategoryId]);

  const handleBuyPlan = () => {
    if (selectedPlanId)
      dispatch(
        updateSubscriptionsAsync({ data: { PlanId: selectedPlanId } })
      ).then((action) => {
        if (action.type === "billing/update-subscriptions/fulfilled") {
          setSelectedPlanId(null);
          if (onBuySuccessfull) onBuySuccessfull();
          dispatch(getSubscriptionsAsync({ includePlans: true }));
          refreshLimits();
        }
      });
  };

  return (
    <>
      {(subscriptionsLoading ||
        plansLoading ||
        subscriptionActionLoading ||
        !hasCategoryId ||
        limitCalcLoading) && (
        <div className="h-full w-full flex items-center justify-center min-h-[300px]">
          <Loading borderSize={2} />
        </div>
      )}
      {!subscriptionsLoading &&
        !plansLoading &&
        !subscriptionActionLoading &&
        !limitCalcLoading &&
        hasCategoryId && (
          <div className={className}>
            {plans.map((plan, i) => {
              const isExceededLimits = planPriceLimitChecker(plan.Price);
              return (
                <PlanItem
                  key={i}
                  {...plan}
                  handleBuy={
                    env?.IsAccountOwner ? setSelectedPlanId : undefined
                  }
                  selected={subscriptionsPlanIds.includes(plan.Id)}
                  disabled={isExceededLimits}
                  disabledReson={
                    isExceededLimits ? planPriceLimit?.LimitationMessage : ""
                  }
                />
              );
            })}
            <PlanItem
              Id={0}
              PlanGroupId={1}
              PlanGroupName=""
              Default={false}
              Name="Enterprise"
              // PlanDescription={`{\n"Included Credit Monthly":"floating",\n"rate limit":"floating",\n"Additional Calls":"floating"\n}`}
              PlanDescription={`{}`}
              selected={false}
            />
          </div>
        )}
      <QuestionModal
        isOpen={selectedPlanId !== null}
        title="Change the active plan"
        description={
          <Typography.Text size="sm" uiType="secondary" className="mt-3 block">
            Are you sure you want to change your active plan?
          </Typography.Text>
        }
        confirmButtonText="Yes, Change it"
        confirmButtonType="primary"
        loading={subscriptionActionLoading}
        onClose={() => setSelectedPlanId(null)}
        onConfirm={handleBuyPlan}
      />
    </>
  );
};

export const PlansTab: React.FC<{ serviceType: number }> = ({
  serviceType,
}) => {
  return (
    <>
      <UniversalPlansBox
        serviceType={serviceType}
        className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-5 gap-5"
      />
    </>
  );
};

export const PlanItem: React.FC<
  IPlan & {
    selected?: boolean;
    handleBuy?: (planId: number) => void;
    disabled?: boolean;
    disabledReson?: string;
  }
> = ({
  Id,
  Name,
  Price,
  PlanDescription,
  selected,
  handleBuy,
  disabled,
  disabledReson,
}) => {
  const ActionButton = () => {
    if (Price === undefined) {
      const salesEmail = process.env.REACT_APP_SALES_EMAIL;
      return (
        <a
          href={`mailto:${salesEmail}?subject=enterprise-subscription`}
          // target="_blank"
          // rel="noreferrer"
        >
          <Button uiType="light" className="!w-full !justify-center">
            Talk to an Expert
          </Button>
        </a>
      );
    }

    return (
      <Button
        onClick={() => (handleBuy ? handleBuy(Id) : null)}
        disabled={disabled || selected || handleBuy === undefined}
        className="!w-full !justify-center"
        uiType="light"
        tooltip={{ content: disabledReson, className: "!text-xs" }}
      >
        Buy plan
      </Button>
    );
  };
  return (
    <div
      className={cn(
        "col-span-1 rounded-2xl border-2 dark:bg-dark-3 mx-auto flex flex-col gap-5 p-4 w-full hover:shadow-lg transition-shadow duration-200",
        {
          "border-slate-200 dark:border-gray-800": !selected,
          "border-primary-400": selected,
        }
      )}
    >
      <Flex direction="col" className="gap-5">
        <Flex items="center" className="flex items-center gap-2">
          <Typography.Text className="text-base font-medium">
            {Name}{" "}
          </Typography.Text>
          {selected && <Tag color="processing"> Active plan</Tag>}
        </Flex>
        <Flex direction="col" className="gap-2">
          <Flex items="center" className="gap-1 h-8">
            {Price !== undefined ? (
              <>
                <Typography.Text className="!text-2xl !font-semibold">
                  {Price === 0 ? "Pay as go" : `$${Price}`}
                </Typography.Text>
                {Price > 0 && (
                  <Typography.Text className="!text-xs !font-medium">
                    / month
                  </Typography.Text>
                )}
              </>
            ) : (
              <>
                <Typography.Text className="!text-sm !font-medium">
                  Custom Pricing
                </Typography.Text>
              </>
            )}
          </Flex>
          {/* <Text className="text-sm font-light">
            $12 per month if paid annually
          </Text> */}
        </Flex>
        <ActionButton />
      </Flex>

      {PlanDescription && (
        <Flex direction="col" className="gap-3">
          <Flex direction="col" className="gap-1">
            {Object.keys(JSON.parse(PlanDescription)).map((key, j) => {
              const value = JSON.parse(PlanDescription)[key];
              return <OptionRow key={j} text={`${key}: ${value}`} />;
            })}
          </Flex>
        </Flex>
      )}
    </div>
  );
};

const OptionRow: React.FC<{ text: string | React.ReactNode }> = ({ text }) => {
  return (
    <Flex items="center" className="gap-2">
      <Flex
        items="center"
        justify="center"
        className="w-[14px] h-[14px] rounded-full bg-primary-400 flex-shrink-0"
      >
        <CheckIcon className="w-3 h-3 text-white flex-shrink-0" />
      </Flex>
      <Typography.Text className="!text-xs !font-light">{text}</Typography.Text>
    </Flex>
  );
};
