import { useNavigate } from "react-router-dom";
import { ReactComponent as CloseIcon } from "./../../assets/icons/close.svg";
import { ReactComponent as PlusIcon } from "./../../assets/icons/plus.svg";
import { ReactComponent as TrashIcon } from "./../../assets/icons/trash.svg";
import { BlockchainsUrl } from "../../utils/urls";
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Control, Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { BlockchainCreateSchema } from "../../utils/validations";
import {
  Alert,
  AnimatedFormError,
  Button,
  Flex,
  Input,
  Loading,
  Select,
  SelectOption,
  Typography,
} from "djuno-design";
import useConfigIntercomeSetting from "../../hooks/useConfigIntercomeSetting";
import {
  getBlockchainsAsync,
  getBlockchainsConsensusAsync,
  getBlockchainsPlansAsync,
  selectBlockchains,
  selectBlockchainsConsensusTypes,
  selectBlockchainsConsensusTypesLoading,
  selectBlockchainsLoading,
  selectBlockchainsPlans,
  selectBlockchainsPlansLoading,
} from "../../store/blockchain-studio/blockchainsSlice";
import { RadioGroup } from "@headlessui/react";
import { WebAppInstanceCard } from "../../components/web-apps/single-page/WebAppPlanTab";
import {
  createBlockchainAsync,
  handleClearBlockchainCreationSlice,
  selectBlockchainCreateLoading,
} from "../../store/blockchain-studio/blockchainCreateSlice";
import axios from "axios";
import useLimitations, {
  ServiceReplicaWithPlan,
} from "../../hooks/useLimitations";
import { IPlan } from "../../types/billing";

const BlockchainCreatePage = () => {
  const blockchains = useAppSelector(selectBlockchains);

  useConfigIntercomeSetting();
  const navigate = useNavigate();

  const Consensus = useAppSelector(selectBlockchainsConsensusTypes);
  const ConsensusLoading = useAppSelector(
    selectBlockchainsConsensusTypesLoading
  );

  const [chainIdLoading, setChainIdLoading] = useState(false);
  const [existChainName, setExistChainName] = useState<string>();
  const createLoading = useAppSelector(selectBlockchainCreateLoading);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (blockchains.length === 0) {
      dispatch(getBlockchainsAsync({}));
    }
  }, [dispatch, blockchains.length]);

  const { numberOfBlockchainsLimitChecker } = useLimitations({
    cases: ["NumberOfBlockChains"],
  });
  const isValidToCreateNewBC = useMemo(
    () => !numberOfBlockchainsLimitChecker(blockchains.length),
    [numberOfBlockchainsLimitChecker, blockchains.length]
  );

  if (!isValidToCreateNewBC) {
    navigate(BlockchainsUrl);
  }

  const form = useForm({
    resolver: yupResolver(BlockchainCreateSchema()),
    mode: "all",
    defaultValues: {
      // PlanId: 18,
      WalletRequests: [
        {
          WalletAddress: "",
          Allocation: 1,
        },
      ],
    },
  });

  const {
    register,
    trigger,
    formState: { errors, isValidating },
    control,
  } = form;

  const {
    fields: walletFields,
    append: walletAppend,
    remove: walletRemove,
  } = useFieldArray({
    control,
    name: "WalletRequests",
  });

  useEffect(() => {
    return () => {
      dispatch(handleClearBlockchainCreationSlice());
    };
  }, [dispatch, form]);

  // get plans
  useEffect(() => {
    dispatch(getBlockchainsPlansAsync({}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getBlockchainsConsensusAsync());
  }, [dispatch]);

  useEffect(() => {
    if (isValidating) {
      trigger("WalletRequests");
    }
  }, [isValidating, trigger]);

  const handleCreate = (data: any) => {
    // console.log(data);
    if (!createLoading) {
      const formData = {
        ChainId: data.ChainId,
        ConsensusType: Number(data.ConsensusType),
        PlanId: data.PlanId,
        WalletRequests: data.WalletRequests.map((r: any) => ({
          Allocation: r.Allocation.toString(),
          WalletAddress: r.WalletAddress,
        })),
      };
      dispatch(createBlockchainAsync({ data: formData })).then((action) => {
        if (action.type === "blockchains/create/fulfilled") {
          dispatch(getBlockchainsAsync({}));
          navigate(BlockchainsUrl);
        }
      });
    }
  };

  const ConsensusOptions: SelectOption[] = useMemo(() => {
    return Consensus.map((c) => ({
      label: c.Text,
      value: c.Value.toString(),
    }));
  }, [Consensus]);

  const handleChainIdRequest = async (chainId: string) => {
    try {
      setChainIdLoading(true);
      const res = await axios.get(
        `https://chainid.network/page-data/chain/${chainId}/page-data.json`
      );
      const chainName = res.data.result.data.chain.name;
      setExistChainName(chainName);
    } catch (e) {
      setExistChainName(undefined);
    } finally {
      setChainIdLoading(false);
    }
  };

  const timeout = useRef<NodeJS.Timeout | null>(null);
  const handleCheckChainId = useCallback((value: string) => {
    setExistChainName(undefined);
    timeout.current && clearTimeout(timeout.current);
    if (value && value !== "") {
      timeout.current = setTimeout(() => handleChainIdRequest(value), 1000);
    } else {
      setExistChainName(undefined);
    }
  }, []);

  const handleChangeChainId = useCallback(
    (value: string) => {
      handleCheckChainId(value);
    },
    [handleCheckChainId]
  );

  return (
    <>
      <div className="flex items-center justify-between h-16 px-6 sticky top-0 z-20 bg-white dark:bg-dark-1 border-b dark:border-dark-2">
        <div className="items-center justify-between flex flex-1 transition duration-150">
          <Typography.Text className="font-medium">
            Create Blockchain
          </Typography.Text>
        </div>
        <div className="">
          <Button
            uiType="light"
            uiSize="small"
            onClick={() => navigate(BlockchainsUrl)}
            className="group"
          >
            <CloseIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>

      <form onSubmit={form.handleSubmit(handleCreate)}>
        <div className="mt-5 w-full px-6">
          <div className="mx-auto flex-1 flex justify-start pb-20 w-full">
            <Flex direction="col" className="w-full gap-10">
              <div className="grid grid-cols-3 gap-x-10">
                <Flex
                  direction="col"
                  className="col-span-3 md:col-span-1 text-sm"
                >
                  <Typography.Text size="sm" className="font-medium mb-1">
                    Chain Id
                  </Typography.Text>
                  <Typography.Text
                    uiType="secondary"
                    className="!text-xs md:!text-sm "
                  >
                    A unique id for your blockchain.
                  </Typography.Text>
                </Flex>
                <div className="col-span-3 md:col-span-2">
                  <Controller
                    control={control}
                    name="ChainId"
                    render={({ field: { value, onChange } }) => (
                      <Input
                        type="number"
                        onChange={(e) => {
                          onChange(e);
                          handleChangeChainId(e.target.value);
                        }}
                        value={value}
                        placeholder=""
                        error={errors.ChainId?.message}
                        loading={chainIdLoading}
                      />
                    )}
                  />
                  {existChainName && (
                    <Alert uiType="warning" className="mt-2">
                      <Typography.Text size="sm">
                        chain Id is already in use by{" "}
                        <Typography.Text size="sm" className="!font-semibold">
                          {existChainName}
                        </Typography.Text>{" "}
                        as long as you are not using the blockchain for
                        enterprise reasons or privately, it will not have any
                        impact on you
                      </Typography.Text>
                    </Alert>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-3 gap-x-10">
                <Flex
                  direction="col"
                  className="col-span-3 md:col-span-1 text-sm"
                >
                  <Typography.Text size="sm" className="font-medium mb-1">
                    Consensus
                  </Typography.Text>
                  {/* <Typography.Text
                    uiType="secondary"
                    className="!text-xs md:!text-sm "
                  >
                    A unique id for your blockchain.
                  </Typography.Text> */}
                </Flex>
                <div className="col-span-3 md:col-span-2">
                  <Controller
                    control={control}
                    name="ConsensusType"
                    render={({ field: { value, onChange } }) => (
                      <Select
                        value={value}
                        onChange={onChange}
                        options={ConsensusOptions}
                        loading={ConsensusLoading}
                      />
                    )}
                  />
                </div>
              </div>

              <BlockchainPlansGroup control={control} />

              <div className="grid grid-cols-3 gap-x-10 mb-10">
                <div className="col-span-3 md:col-span-1 text-sm">
                  <Typography.Text size="sm" className="font-medium mb-1">
                    Wallets
                  </Typography.Text>
                </div>
                <div className="col-span-3 md:col-span-2">
                  <div className="flex flex-col w-full">
                    {walletFields.map((_, index) => (
                      <div key={index} className="grid gap-3 grid-cols-12 pb-2">
                        <div className="col-span-7">
                          <Input
                            label=""
                            {...register(
                              `WalletRequests.${index}.WalletAddress` as const
                            )}
                            error={
                              errors.WalletRequests &&
                              errors.WalletRequests[index] &&
                              errors.WalletRequests[index]?.WalletAddress &&
                              errors.WalletRequests[index]?.WalletAddress
                                ?.message
                            }
                            placeholder="Wallet Address"
                          />
                        </div>
                        <div className="col-span-4">
                          <div className="flex gap-2">
                            <div className="flex-1">
                              <Input
                                label=""
                                {...register(
                                  `WalletRequests.${index}.Allocation` as const
                                )}
                                error={
                                  errors.WalletRequests &&
                                  errors.WalletRequests[index] &&
                                  errors.WalletRequests[index]?.Allocation &&
                                  errors.WalletRequests[index]?.Allocation
                                    ?.message
                                }
                                placeholder="Allocation"
                                className="flex-1"
                                type="number"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-span-1 flex items-start justify-center">
                          <Button
                            uiType="icon"
                            className="!px-2 group"
                            disabled={walletFields.length === 1}
                            onClick={(e) => {
                              e.preventDefault();
                              if (walletFields.length > 1) walletRemove(index);
                            }}
                          >
                            <TrashIcon className="w-5 text-slate-700 dark:text-slate-300 group-hover:text-red-500 group-hover:dark:text-red-400" />
                          </Button>
                        </div>
                      </div>
                    ))}
                    <AnimatedFormError
                      error={errors.WalletRequests?.root?.message}
                    />
                    <AnimatedFormError error={errors.WalletRequests?.message} />
                    <div className="flex justify-start gap-2 mt-2">
                      <Button
                        uiType="light"
                        onClick={(e) => {
                          e.preventDefault();
                          walletAppend({
                            WalletAddress: "",
                            Allocation: 0,
                          });
                        }}
                      >
                        <PlusIcon className="w-4" />
                        <Typography.Text
                          size="sm"
                          uiType="transparent"
                          className="hidden md:block"
                        >
                          Add Wallet
                        </Typography.Text>
                        <Typography.Text
                          size="sm"
                          uiType="transparent"
                          className="md:hidden"
                        >
                          Add
                        </Typography.Text>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Flex>
          </div>
        </div>
        <div className="fixed bottom-0 right-0 left-0 flex items-center w-full h-16 border-t bg-white dark:bg-dark-1 dark:border-dark-2 px-6 lg:pl-80">
          <div className="flex items-center justify-between w-full">
            <Button
              uiType="light"
              onClick={() => {
                navigate(BlockchainsUrl);
              }}
            >
              Cancel
            </Button>
            <Button
              uiType="primary"
              type="submit"
              loading={createLoading}
              disabled={!form.formState.isValid}
            >
              Create Blockchain
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export const BlockchainPlansGroup: React.FC<
  PropsWithChildren<{
    control: Control<any>;
  }>
> = ({ control, children }) => {
  const dispatch = useAppDispatch();

  const blockchains = useAppSelector(selectBlockchains);
  const blockchainsLoading = useAppSelector(selectBlockchainsLoading);

  const plans = useAppSelector(selectBlockchainsPlans);
  const plansLoading = useAppSelector(selectBlockchainsPlansLoading);

  useEffect(() => {
    if (blockchains.length === 0) {
      dispatch(getBlockchainsAsync({}));
    }
  }, [dispatch, blockchains.length]);

  const existingBlockchains = useMemo(() => {
    const existItems: ServiceReplicaWithPlan[] = [];
    for (const blockchain of blockchains) {
      const plan = plans.find((p) => p.Id === blockchain.PlanId);
      if (plan)
        existItems.push({ replicaCounts: blockchain.ReplicaCounts, plan });
    }
    return existItems;
  }, [plans, blockchains]);

  const {
    loading: limitCalcLoading,
    planPriceLimit,
    planPriceLimitChecker,
    amountOfCpuLimit,
    amountOfCpuLimitChecker,
    amountOfMemoryLimit,
    amountOfMemoryLimitChecker,
  } = useLimitations({
    cases: ["PlanPrice", "Cpu", "Memory"],
    existingServices: existingBlockchains,
  });

  if (plansLoading || blockchainsLoading || limitCalcLoading)
    return (
      <Flex items="center" justify="center" className="min-h-[200px]">
        <Loading borderSize={2} />
      </Flex>
    );
  return (
    <Controller
      name="PlanId"
      control={control}
      render={({ field: { value, onChange } }) => (
        <RadioGroup value={value || null} onChange={onChange}>
          <div className="mt-6">
            <div className="block md:grid grid-cols-3 gap-10">
              <Flex direction="col" className="mb-6">
                <Typography.Text size="sm" className="font-medium mb-1">
                  Select a plan
                </Typography.Text>
                <Typography.Text size="sm" className=" mb-4" uiType="secondary">
                  Using one of our paid instance types. All paid instances
                  support:
                </Typography.Text>
              </Flex>
              <div className="col-span-2">
                <div className="grid gap-4 grid-cols-1 md:grid-cols-2 mb-5">
                  {plans.slice(1).map((instance, i) => {
                    const price = instance.Price || 0;
                    const { CPU, RAM } = JSON.parse(
                      instance.PlanDescription || "{}"
                    );
                    const isExceedPrice = planPriceLimitChecker(price, 4);
                    const isExceedCpu = amountOfCpuLimitChecker(CPU, 4);
                    const isExceedMemory = amountOfMemoryLimitChecker(RAM, 4);

                    return (
                      <RadioGroup.Option
                        key={i}
                        value={instance.Id}
                        disabled={isExceedPrice || isExceedCpu}
                      >
                        {({ checked, disabled }) => (
                          <WebAppInstanceCard
                            title={instance.Name}
                            description={{ CPU, RAM }}
                            price={instance.Price || 0}
                            selected={checked}
                            disabled={disabled}
                            disabledReason={
                              isExceedPrice
                                ? planPriceLimit?.LimitationMessage
                                : isExceedCpu
                                ? amountOfCpuLimit?.LimitationMessage
                                : isExceedMemory
                                ? amountOfMemoryLimit?.LimitationMessage
                                : ""
                            }
                          />
                        )}
                      </RadioGroup.Option>
                    );
                  })}
                </div>
                {children}
              </div>
            </div>
          </div>
        </RadioGroup>
      )}
    />
  );
};

export default BlockchainCreatePage;
