import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import {
  getObjectShareAsync,
  getObjectVersionsAsync,
  handleHideObjectShareModal,
  selectObjectShareLink,
  selectObjectShareLoading,
  selectObjectVersionsLoading,
  selectSelectedObject,
  selectSelectedVersionOfObject,
  selectShowObjectShareModal,
} from "../../../../../store/s3/buckets/objectSlice";
import { selectBucketDetails } from "../../../../../store/s3/buckets/bucketSlice";
import { Input, Modal, Typography } from "djuno-design";
import {
  getBucketsMaxShareExpAsync,
  selectBucketsMaxShareExp,
  selectBucketsMaxShareExpLoading,
} from "../../../../../store/s3/s3PublicSlice";

const S3ObjectShareModal = () => {
  const isOpen = useAppSelector(selectShowObjectShareModal);
  const bucketDetails = useAppSelector(selectBucketDetails);
  const selectedVersion = useAppSelector(selectSelectedVersionOfObject);
  const selectedObject = useAppSelector(selectSelectedObject);
  const versionsLoading = useAppSelector(selectObjectVersionsLoading);
  const shareLoading = useAppSelector(selectObjectShareLoading);
  const shareLink = useAppSelector(selectObjectShareLink);

  const maxShareExp = useAppSelector(selectBucketsMaxShareExp);
  const maxShareExpLoading = useAppSelector(selectBucketsMaxShareExpLoading);

  const dispatch = useAppDispatch();

  const [durationError, setDurationError] = useState<boolean>(false);

  const [maxDays, setMaxDays] = useState<number>(0);
  const [maxHours, setMaxHours] = useState<number>(0);
  const [maxMinutes, setMaxMinutes] = useState<number>(0);

  const [days, setDays] = useState<number>(0);
  const [hours, setHours] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);

  useEffect(() => {
    if (maxShareExp && !maxShareExpLoading) {
      const d = Math.floor(maxShareExp / (24 * 3600));
      const h = Math.floor((maxShareExp % (24 * 3600)) / 3600);
      const m = Math.floor((maxShareExp % 3600) / 60);
      const remainingSeconds = maxShareExp % 60;

      setMaxDays(d);
      setMaxHours(h);
      setMaxMinutes(m);

      setDays(d);
      setHours(h);
      setMinutes(m);
    }
  }, [maxShareExp, maxShareExpLoading]);

  useEffect(() => {
    if (
      isOpen &&
      bucketDetails !== null &&
      selectedVersion === null &&
      selectedObject !== null
    ) {
      dispatch(
        getObjectVersionsAsync({
          bucketName: bucketDetails.name,
          prefix: selectedObject.name,
        })
      );
    }
  }, [bucketDetails, dispatch, isOpen, selectedObject, selectedVersion]);

  useEffect(() => {
    if (
      selectedVersion &&
      selectedVersion.version_id &&
      bucketDetails &&
      isOpen &&
      !durationError &&
      !maxShareExpLoading
    ) {
      dispatch(
        getObjectShareAsync({
          bucketName: bucketDetails.name,
          prefix: selectedVersion.name,
          versionId: selectedVersion.version_id,
          expires: `${days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60}s`,
        })
      );
    }
  }, [
    bucketDetails,
    dispatch,
    selectedVersion,
    isOpen,
    days,
    hours,
    minutes,
    durationError,
    maxShareExpLoading,
  ]);

  useEffect(() => {
    if (maxShareExp === null) {
      dispatch(getBucketsMaxShareExpAsync());
    }
  }, [dispatch, maxShareExp]);

  const handleChangeDays = (e: ChangeEvent<HTMLInputElement>) => {
    let d = Number(e.target.value);
    if (d < 0) d = 0;
    if (d > 7) d = 7;
    setDays(d);
  };

  const handleChangeHours = (e: ChangeEvent<HTMLInputElement>) => {
    let h = Number(e.target.value);
    if (h < 0) h = 0;
    if (h > 23) h = 23;
    setHours(h);
  };

  const handleChangeMinutes = (e: ChangeEvent<HTMLInputElement>) => {
    let m = Number(e.target.value);
    if (m < 0) m = 0;
    if (m > 59) m = 59;
    setMinutes(m);
  };

  useEffect(() => {
    const secounds = days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60;
    if (maxShareExp) setDurationError(secounds > maxShareExp);
  }, [days, hours, maxShareExp, minutes]);

  const handleOnClose = useCallback(() => {
    dispatch(handleHideObjectShareModal());
    setDays(7);
    setHours(0);
    setMinutes(0);
  }, [dispatch]);

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      contentClassName="max-w-lg"
      title={"Share File"}
    >
      <div className="mt-5 flex flex-col w-full gap-5">
        <div className="flex flex-col gap-2">
          <Typography.Text className="!text-sm">
            This is a temporary URL with integrated access credentials for
            sharing objects valid for up to{" "}
            {maxDays > 0 ? `${maxDays} days, ` : ""}
            {maxHours > 0 ? `${maxHours} hours` : ""}.
          </Typography.Text>
          <Typography.Text uiType="secondary" className="!text-sm">
            The temporary URL expires after the configured time limit.
          </Typography.Text>
        </div>

        <div>
          <Typography.Text className="!text-sm">Active for</Typography.Text>
          <div className="flex items-center justify-between gap-2">
            <div className="flex items-center gap-1 w-1/4">
              <Input
                type="number"
                value={days}
                onChange={handleChangeDays}
                disabled={maxShareExpLoading}
              />
              <Typography.Text className="!text-sm">Days</Typography.Text>
            </div>
            <div className="flex items-center gap-1 w-1/4">
              <Input
                type="number"
                value={hours}
                onChange={handleChangeHours}
                disabled={maxShareExpLoading}
              />
              <Typography.Text className="!text-sm">Hours</Typography.Text>
            </div>
            <div className="flex items-center gap-1 w-1/4">
              <Input
                type="number"
                value={minutes}
                onChange={handleChangeMinutes}
                disabled={maxShareExpLoading}
              />
              <Typography.Text className="!text-sm">Minutes</Typography.Text>
            </div>
          </div>
        </div>
        <Input
          value={!durationError ? shareLink || "" : ""}
          disabled={true}
          copyable={{ text: () => shareLink }}
          loading={shareLoading || versionsLoading}
          error={durationError ? "please select a valid duration" : undefined}
        />
      </div>
    </Modal>
  );
};

export default S3ObjectShareModal;
