import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import S3Banner from "./S3Banner";
import { Flex, Loading, Select, Typography } from "djuno-design";
import {
  getNewS3MetricsAsync,
  selectNewS3Metrics,
  selectNewS3MetricsLoading,
  selectS3Status,
} from "../../../store/s3/s3PublicSlice";
import BucketsCountWidget from "./widgets/BucketsCountWidget";
import ObjectsCountWidget from "./widgets/ObjectsCountWidget";
import {
  getS3MetricsAsync,
  selectS3Metrics,
  selectS3MetricsLoading,
} from "../../../store/s3/overviewSlice";
import ReportedUsageWidget from "./widgets/ReportedUsageWidget";
import { metricsTimesOptions } from "../../web-apps/single-page/WebAppMetricsTab";
import {
  KuberoMetricChart,
  kuberoMetricChartName,
  kuberoMetricChartYName,
} from "../../general/charts/KuberoMetricChart";
import { S3NotActiveTab } from "../../layouts/NotData";

const S3NewOverviewTab = () => {
  const s3Status = useAppSelector(selectS3Status);

  const s3Metrics = useAppSelector(selectNewS3Metrics);
  const s3MetricsLoading = useAppSelector(selectNewS3MetricsLoading);

  const info = useAppSelector(selectS3Metrics);
  const infoLoading = useAppSelector(selectS3MetricsLoading);

  const dispatch = useAppDispatch();
  const [metricTime, setMetricTime] = useState<string>("0");

  useEffect(() => {
    dispatch(getNewS3MetricsAsync({ time: Number(metricTime) }));
  }, [dispatch, metricTime]);

  useEffect(() => {
    if (s3Status) dispatch(getS3MetricsAsync());
  }, [dispatch, s3Status]);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    interval = setInterval(() => {
      if (s3Status)
        dispatch(
          getNewS3MetricsAsync({
            withoutLoading: true,
            time: Number(metricTime),
          })
        );
    }, 10000);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, metricTime, s3Status]);

  if (!s3Status) {
    return <S3NotActiveTab />;
  }

  return (
    <div className="flex flex-col gap-8">
      <S3Banner />

      <div className="mt-10 w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-7">
        <BucketsCountWidget bucketCount={info?.buckets} loading={infoLoading} />
        <ObjectsCountWidget objectCount={info?.objects} loading={infoLoading} />
        <ReportedUsageWidget />
      </div>

      <div className="flex justify-end">
        <Select
          options={metricsTimesOptions}
          value={metricTime.toString()}
          onChange={(v) => setMetricTime(v || "0")}
          buttonClassName="!border-slate-200 dark:!border-secondary-700 !pr-7"
          optionsClassName=" !max-h-none"
          className="!w-[175px]"
        />
      </div>

      {s3MetricsLoading && (
        <Flex items="center" justify="center" className="w-full h-[300px]">
          <Loading borderSize={2} />
        </Flex>
      )}

      {!s3MetricsLoading && s3Metrics && Object.keys(s3Metrics).length > 0 && (
        <Flex direction="col" className="w-full gap-14">
          {Object.keys(s3Metrics).map((key, index) => {
            return (
              <div className="h-[200px]" key={index}>
                <Flex justify="center" className="w-full mt-5">
                  <Typography.Text className="!font-semibold !text-xs">
                    {kuberoMetricChartName(key)}
                  </Typography.Text>
                </Flex>
                <KuberoMetricChart
                  yName={kuberoMetricChartYName(key)}
                  data={s3Metrics[key as keyof typeof s3Metrics]}
                />
              </div>
            );
          })}
        </Flex>
      )}
    </div>
  );
};

export default S3NewOverviewTab;
