import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { IThunkRejectValue, RootState } from "../../types";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import { WebAppOverview } from "../../types/web-app";
import {
  Blockchain,
  BlockchainOverview,
  BlockchainState,
} from "../../types/blockchain";
import {
  getBlockchainApi,
  getBlockchainMetricsApi,
  getBlockchainOverviewApi,
  updateBlockchainApi,
} from "../../apis/blockchainsAPI";

const initialState: BlockchainState = {
  blockchain: null,
  blockchainLoading: false,

  //overview
  overview: null,
  overviewLoading: false,

  //update web-app
  updateLoading: false,

  //metrics
  metrics: null,
  metricsLoading: false,
};

export const getBlockchainAsync = createAsyncThunk<
  { blockchain: Blockchain },
  { blockchainId: string; withoutLoading?: boolean },
  IThunkRejectValue
>(
  "blockchain",
  async (
    { blockchainId, withoutLoading = false },
    { rejectWithValue, fulfillWithValue, requestId, dispatch }
  ) => {
    try {
      dispatch(
        getBlockchainAsync.pending(requestId, {
          blockchainId,
          withoutLoading,
        })
      );

      const response = await getBlockchainApi(blockchainId);
      const blockchain = response.data.Result;

      return fulfillWithValue({ blockchain });
    } catch (e) {
      return rejectWithValue({
        message: getExtractErrors(e),
      });
    }
  }
);

export const getBlockchainOverviewAsync = createAsyncThunk<
  { overview: BlockchainOverview },
  { blockchainId: string; withoutLoading?: boolean },
  IThunkRejectValue
>(
  "blockchain/overview",
  async (
    { blockchainId, withoutLoading = false },
    { rejectWithValue, fulfillWithValue, requestId, dispatch }
  ) => {
    try {
      dispatch(
        getBlockchainOverviewAsync.pending(requestId, {
          blockchainId,
          withoutLoading,
        })
      );
      const response = await getBlockchainOverviewApi(blockchainId);
      const overview = response.data.Result;
      return fulfillWithValue({ overview });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const updateBlockchainAsync = createAsyncThunk<
  boolean,
  { blockchainId: string; data: any },
  IThunkRejectValue
>("blockchain/update", async ({ blockchainId, data }, { rejectWithValue }) => {
  try {
    await updateBlockchainApi(blockchainId, data);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getBlockchainMetricsAsync = createAsyncThunk<
  {
    metrics: any;
  },
  { blockchainId: string; withoutLoading?: boolean; time: number },
  IThunkRejectValue
>(
  "blockchain/metrics",
  async (
    { blockchainId, withoutLoading = false, time },
    { rejectWithValue, fulfillWithValue, dispatch, requestId }
  ) => {
    try {
      dispatch(
        getBlockchainMetricsAsync.pending(requestId, {
          blockchainId,
          withoutLoading,
          time,
        })
      );

      const metrics_response = await getBlockchainMetricsApi(
        blockchainId,
        time
      );
      const metrics = metrics_response.data.Result;

      return fulfillWithValue({
        metrics,
      });
    } catch (e) {
      return rejectWithValue({
        message: getExtractErrors(e),
      });
    }
  }
);

export const blockchainSlice = createSlice({
  name: "blockchain",
  initialState,
  reducers: {
    handleSetBlockchain: (state, action: PayloadAction<Blockchain | null>) => {
      state.blockchain = action.payload;
    },
    handleClearBlockchainSlice: (state) => {
      state.blockchain = initialState.blockchain;
      state.blockchainLoading = initialState.blockchainLoading;
      state.overview = initialState.overview;
      state.overviewLoading = initialState.overviewLoading;
      state.updateLoading = initialState.updateLoading;
      state.metrics = initialState.metrics;
      state.metricsLoading = initialState.metricsLoading;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBlockchainAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.blockchainLoading = true;
      })
      .addCase(getBlockchainAsync.fulfilled, (state, action) => {
        state.blockchain = action.payload.blockchain;
        state.blockchainLoading = false;
      })
      .addCase(getBlockchainAsync.rejected, (state, { payload }) => {
        state.blockchainLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getBlockchainOverviewAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.overviewLoading = true;
      })
      .addCase(getBlockchainOverviewAsync.fulfilled, (state, action) => {
        state.overview = action.payload.overview;
        state.overviewLoading = false;
      })
      .addCase(getBlockchainOverviewAsync.rejected, (state, { payload }) => {
        state.overviewLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(updateBlockchainAsync.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateBlockchainAsync.fulfilled, (state, { payload }) => {
        state.updateLoading = false;
      })
      .addCase(updateBlockchainAsync.rejected, (state, { payload }) => {
        state.updateLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getBlockchainMetricsAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.metricsLoading = true;
      })
      .addCase(getBlockchainMetricsAsync.fulfilled, (state, action) => {
        state.metrics = action.payload.metrics;
        state.metricsLoading = false;
        // state.usage = action.payload.usage;
      })
      .addCase(getBlockchainMetricsAsync.rejected, (state, { payload }) => {
        state.metricsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectBlockchain = (state: RootState) =>
  state.blockchain.blockchain;
export const selectBlockchainLoading = (state: RootState) =>
  state.blockchain.blockchainLoading;

//overview
export const selectBlockchainOverview = (state: RootState) =>
  state.blockchain.overview;
export const selectBlockchainOverviewLoading = (state: RootState) =>
  state.blockchain.overviewLoading;

export const selectBlockchainUpdateLoading = (state: RootState) =>
  state.blockchain.updateLoading;

//metrics
export const selectBlockchainMetrics = (state: RootState) =>
  state.blockchain.metrics;
export const selectBlockchainMetricsLoading = (state: RootState) =>
  state.blockchain.metricsLoading;

export const { handleSetBlockchain, handleClearBlockchainSlice } =
  blockchainSlice.actions;
export default blockchainSlice.reducer;
